import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges, Input, ViewChild } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "../../services/push.service";
import { SharedService } from "app/services/shared.service";
import { isString } from "util";
import { FileValidator } from "../filevalidator.directive";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { IfStmt } from "@angular/compiler";
// import * as $ from 'jquery';

declare var swal: any;
declare var $: any;

@Component({
  selector: "app-kyc2",
  templateUrl: "./kyc2.component.html",
  styleUrls: ["./kyc2.component.css"]
})
export class Kyc2Component implements OnInit {

  @ViewChild('logoFile') logoFile;
  @ViewChild('addressFile')addressFile;
  @ViewChild('shareholderFile')shareholderFile
  @ViewChild('auditFinanceFile')auditFinanceFile
  @ViewChild('personalNoticeFile')personalNoticeFile
  @ViewChild('CBS_MLCB_File')CBS_MLCB_File
  @ViewChild('GST_F5_File')GST_F5_File
  @ViewChild('GST_stmt_File')GST_stmt_File
  @ViewChild('bank_account_proof')bank_account_proof
  @ViewChild('bank_statement_File')bank_statement_File
  @ViewChild('lates_AP_File')lates_AP_File
  @Input() kyc_config_data: any;

  company_info_nav_link: string;
  company_info_tab: string;
  kyc_nav_link: string;
  kyc_status_tab: string;
  credit_check_nav_link: string;
  doc_tab: string;
  bank_info_nav_link: string;
  approval_tab: string;
  worldcheck_nav_link: string;
  star_mine_nav_link: string;
  true_20: boolean;
  true_40: boolean;
  true_60: boolean;
  true_100: boolean;
  val: boolean;
  disp: boolean;
  true_0: boolean;
  kyc_tab: string;
  credit_check_tab: string;
  bank_info_tab: string;
  CompanyForm: FormGroup;
  address_file: any;
  file: any;
  kycForm: FormGroup;
  party_id: any;
  register_address_doc: string;
  director_details: string;
  shareholder_details: string;
  board_resolution: string;
  audited_financial: string;
  personal_notice: string;
  CBS_MLCB: string;
  GST_F5: string;
  GST_statment: string;
  file1: any;
  file2: any;
  file3: any;
  file4: any;
  file5: any;
  file6: any;
  file7: any;
  file8: any;
  file10: any;
  bankForm: FormGroup;
  passbook_file: any;
  file11: any;
  file12: any;
  checkbook_file: any;
  file13: any;
  month6_file: any;
  logo: string;
  approval_nav_link: string;
  company_name: string;
  id: any;
  kyc_tab_display: boolean;
  bank_tab_display: boolean;
  show_finish_btn: boolean;
  display_initial_next: boolean;
  bank_btn: boolean;
  kyc_btn: boolean;
  credit_check_btn: boolean;
  creditForm: any;
  shareholder_doc: any;
  true_80: boolean;
  AP_AR: any;
  credit_btn: boolean;
  kyc_display_btn: boolean;
  bank_btn_display: boolean;
  credit_display_btn: boolean;
  kyc_val: number;
  verify = false;
  message: any;
  data: any;
  acra = false;
  bank: any;
  credit: any;
  res: any;
  GST: any;
  audited_financial_statments: any;
  personal_notice_assessment: any;
  recent_6_months: any;
  registered_office_address: any;
  shareholder_details_display: any;
  GST_F5_display: any;
  AP_AR_display = true;
  display_name: any;
  director_details_display = true;
  board_resolution_display = true;
  director_details_doc: any;
  file14: any;
  board_resolution_doc: any;
  file15: any;
  passbook_display: boolean;
  checkbook_display: boolean;
  month6_display: boolean;
  submiited: boolean;
  uen: string;
  document_name_1: any = "";
  doc_upload_array = [];
  document_name_3: any = "";
  document_name_4: any = "";
  document_name_2: any = "";
  document_3: any = "Choose File";
  document_1: any = "Choose File";
  document_2: any = "Choose File";
  document_4: any = "Choose File";
  items: any;
  file_type: string = "invalid";
  file_type2 :string ='invalid';
  file_type3 :string = 'invalid'
  file_check1 :string = 'invalid'
  file_check2 :string = 'invalid'
  file_check3 :string = 'invalid'
  file_check4 :string = 'invalid'
  file_check5 :string = 'invalid'
  file_check_bank1 :string = 'invalid'
  file_check_bank2 : string ='invalid'

  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {}
  ngOnInit() {
    $("form").attr("autocomplete", "off");
    $("input").attr("autocomplete", "off");
    var display_name;
    var email;
    var uen;
    this.sharedService.sharedMessage.subscribe((res: any) => {
      if (res) {
        this.res = res;

        this.bank = this.res.funder_documents.bank_verification;
        this.credit = this.res.funder_documents.document_verification;
        this.GST_F5_display = this.res.funder_documents.documents_display.GST_F5_statments;
        this.GST = this.res.funder_documents.documents_display.GST_statments;
        this.audited_financial_statments = this.res.funder_documents.documents_display.audited_financial_statments;
        this.personal_notice_assessment = this.res.funder_documents.documents_display.personal_notice_assessment;
        this.recent_6_months = this.res.funder_documents.documents_display.recent_6_months;
        this.registered_office_address = this.res.funder_documents.documents_display.registered_office_address;
        this.shareholder_details_display = this.res.funder_documents.documents_display.shareholder_details;
        (this.director_details_display = this.res.funder_documents.documents_display.director_details),
          (this.board_resolution_display = this.res.funder_documents.documents_display.board_resolution),
          (this.passbook_display = this.res.funder_documents.bank_docs_display.passbook === true ? true : false);
        this.checkbook_display = this.res.funder_documents.bank_docs_display.chequebook === true ? true : false;
        this.month6_display = this.res.funder_documents.bank_docs_display.month6_statment === true ? true : false;
        display_name = this.res.display_name;
        email = this.res.admin_user_id;
        uen = this.res.company_registration_no;
        this.uen = this.res.company_registration_no;
        this.company_info_nav_link = "nav-link active";
        this.company_info_tab = "tab-pane active";

        if (this.acra === false && this.credit === false && this.bank === false) {
          this.show_finish_btn = true;
          this.bank_tab_display = true;
          this.kyc_tab_display = true;
          this.credit_check_btn = true;
          this.credit_btn = true;
          this.kyc_display_btn = true;
          this.credit_display_btn = true;
          this.bank_btn_display = true;
          this.display_initial_next = true;
        } else if (this.acra === true && this.credit === true && this.bank === true) {
          this.kyc_nav_link = "nav-link";
          this.kyc_tab = "tab-pane";
          this.bank_info_nav_link = "nav-link";
          this.bank_info_tab = "tab-pane";
          this.credit_check_nav_link = "nav-link";
          this.credit_check_tab = " tab-pane";

          this.kyc_tab_display = false;
          this.bank_tab_display = false;
          this.credit_check_btn = false;

          this.show_finish_btn = false;
          this.credit_display_btn = true;
          this.kyc_display_btn = true;
          this.bank_btn_display = true;
          this.bank_btn = true;
          this.kyc_btn = true;

          this.display_initial_next = false;
        } else if (this.acra === true && this.credit === true && this.bank === false) {
          this.kyc_nav_link = "nav-link";
          this.kyc_tab = "tab-pane";
          this.credit_check_nav_link = "nav-link";
          this.credit_check_tab = "tab-pane";

          this.kyc_display_btn = false;
          this.credit_display_btn = true;
          this.bank_tab_display = true;
          this.bank_btn_display = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
          this.kyc_val = 1;
        } else if (this.acra === true && this.credit === false && this.bank === true) {
          this.kyc_nav_link = "nav-link";
          this.kyc_tab = "tab-pane";
          this.bank_info_nav_link = "nav-link";
          this.bank_info_tab = "tab-pane";

          this.kyc_display_btn = false;
          this.bank_btn_display = true;
          this.credit_check_btn = true;
          this.credit_display_btn = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
          this.kyc_val = 3;
        } else if (this.acra === true && this.credit === false && this.bank === false) {
          this.kyc_nav_link = "nav-link";
          this.kyc_tab = "tab-pane";
          this.bank_tab_display = true;
          this.bank_btn_display = true;
          this.kyc_display_btn = false;
          this.kyc_tab_display = false;
          this.credit_check_btn = true;
          this.credit_display_btn = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
        } else if (this.acra === false && this.credit === true && this.bank === true) {
          this.bank_info_nav_link = "nav-link";
          this.bank_info_tab = "tab-pane";
          this.credit_check_nav_link = "nav-link";
          this.credit_check_tab = " tab-pane";

          this.bank_btn_display = true;
          this.bank_tab_display = false;
          this.credit_check_btn = false;
          this.credit_display_btn = false;
          this.kyc_display_btn = true;
          this.kyc_tab_display = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
        } else if (this.acra === false && this.credit === true && this.bank === false) {
          this.bank_info_nav_link = "nav-link";
          this.bank_info_tab = "tab-pane";
          this.credit_check_nav_link = "nav-link";
          this.credit_check_tab = " tab-pane";

          this.bank_btn_display = true;
          this.bank_tab_display = true;
          this.credit_check_btn = false;
          this.credit_display_btn = false;
          this.kyc_display_btn = true;
          this.kyc_tab_display = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
        } else {
          this.bank_info_nav_link = "nav-link";
          this.bank_info_tab = "tab-pane";

          this.bank_btn_display = false;
          this.bank_tab_display = false;
          this.credit_check_btn = true;
          this.credit_display_btn = true;
          this.kyc_display_btn = true;
          this.kyc_tab_display = true;
          this.display_initial_next = true;
          this.show_finish_btn = false;
        }
      }

      this.CompanyForm = this.fb.group({
        company_name: [display_name],
        uen: [uen, Validators.required],
        logo: ["", FileValidator.validate],
        title: ["", Validators.required],
        contact_person_suffix: [""],
        full_name: ["", Validators.required],
        admin_user_id: [email],
        display_name: [display_name, Validators.required],
        phone: [""],
        mobile: [""],
        website: [""],
        street: ["", Validators.required],
        city: ["", Validators.required],
        zip: ["", Validators.compose([Validators.required, Validators.pattern("^[0-9][0-9]{5}$")])],
        country_name: ["", Validators.required],
        state_name: [""],

        additional_documents: this.fb.array([]),
        shareholder_details: ["", FileValidator.validate],
        address_proof: ["", FileValidator.validate]
      });

      this.kycForm = this.fb.group({
        shareholder_details: [""],
        address_proof: [""]
      });
      this.creditForm = this.fb.group({
        audited_financial_statements: this.audited_financial_statments === true ? ["", FileValidator.validate] : "",
        personal_notice_assessment: this.personal_notice_assessment === true ? ["", FileValidator.validate] : "",
        CBS_MLCB: this.recent_6_months === true ? ["", FileValidator.validate] : "",
        GST_F5_Statment: this.GST_F5_display === true ? ["", FileValidator.validate] : "",
        GST_Statment: this.GST === true ? ["", FileValidator.validate] : "",
        director_details: this.director_details_display === true ? ["", FileValidator.validate] : "",
        board_resolution: this.board_resolution_display === true ? ["", FileValidator.validate] : ""
      });

      this.bankForm = this.fb.group({
        bank_name: ["", Validators.required],
        bank_branch_address: ["", Validators.required],
        bank_branch_code: ["", Validators.required],
        bank_account_no: ["", Validators.required],
        bank_account_type: ["", Validators.required],
        retype_account: ["", Validators.required],
        passbook: this.passbook_display === true ? ["", FileValidator.validate] : "",
        month6_Statment: this.month6_display === true ? ["", FileValidator.validate] : ""
      });
    });

    this.register_address_doc = "Choose File";
    this.logo = "Choose File";
    this.board_resolution = "Choose File";
    this.audited_financial = "Choose File";
    this.personal_notice = "Choose File";
    this.CBS_MLCB = "Choose File";
    this.GST_F5 = "Choose File";
    this.GST_statment = "Choose File";
    this.passbook_file = 'Choose File';
    this.shareholder_doc = 'Choose File'
    this.month6_file = "Choose File";
  }

  addItem(): void {
    this.items = this.CompanyForm.get("additional_documents") as FormArray;

    this.items.push(
      this.fb.group({
        name: [""],
        file_name: ["Choose File"],
        file: [""]
      })
    );
  }

  removeItem(index) {
    this.items.removeAt(index);
  }

  getnameValidate(i) {
    if (this.items.controls[i]["controls"].name.value === "") {
      return true;
    } else {
      return false;
    }
  }

  getfileValidate(i) {
    if (this.items.controls[i]["controls"].file.value === "") {
      return true;
    } else {
      return false;
    }
  }
  btn_enable() {
    return !this.verify;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngAfterViewInit() {
    $(window).resize(() => {
      $(".card-wizard").each(function() {
        const $wizard = $(this);
        const index = $wizard.bootstrapWizard("currentIndex");
        let $total = $wizard.find(".nav li").length;
        let $li_width = 100 / $total;

        let total_steps = $wizard.find(".nav li").length;
        let move_distance = $wizard.width() / total_steps;
        let index_temp = index;
        let vertical_level = 0;

        let mobile_device = $(document).width() < 600 && $total > 3;

        if (mobile_device) {
          move_distance = $wizard.width() / 2;
          index_temp = index % 2;
          $li_width = 50;
        }

        $wizard.find(".nav li").css("width", $li_width + "%");

        let step_width = move_distance;
        move_distance = move_distance * index_temp;

        let $current = index + 1;

        if ($current == 1 || (mobile_device == true && index % 2 == 0)) {
          move_distance -= 8;
        } else if ($current == total_steps || (mobile_device == true && index % 2 == 1)) {
          move_distance += 8;
        }

        if (mobile_device) {
          let x: any = index / 2;
          vertical_level = parseInt(x);
          vertical_level = vertical_level * 38;
        }

        $wizard.find(".moving-tab").css("width", step_width);
        $(".moving-tab").css({
          transform: "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
          transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
        });

        $(".moving-tab").css({
          transition: "transform 0s"
        });
      });
    });
    this.ngOnInit();
  }

  get email() {
    return this.CompanyForm.get("email");
  }

  get show(): boolean {
    if (this.disp) return this.disp;
    else return false;
  }

  image(event) {
   
    if (event.target.files.length > 0) {
      this.file_type = "invalid";
      this.logo = 'Choose File'
      // console.log((event.target.files[0].size/1000000)," check file extension" )

      if( (event.target.files[0].type === "image/png" || 
      event.target.files[0].type === "image/jpeg" ) ) {
        
        if((event.target.files[0].size/1000000) <= 5){
          this.file_type = "valid";
          this.file = event.target.files[0];
        this.logo = event.target.files[0].name;
        this._auth
          .funder_upload_doc(this.file, sessionStorage.getItem("seller_id"), "funder-doc")
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.file = res["filename"];
            }
          }); 
        }else {
          this.file_type = "invalid";
          this.push.showNotification(" File size Should be less than 5MB", "danger");
          this.CompanyForm.controls['logo'].setErrors({'incorrect': true});
        }
      }else {
        this.file_type = "invalid";
        this.push.showNotification("Incorrect File", "danger");
        this.CompanyForm.controls['logo'].setErrors({'incorrect': true});
      }
    }
  }

  onFocusEvent(event, val1){
    if(val1 == 'logoFile'){
      this.logoFile.nativeElement.value = '';
    }
    if(val1 == 'addressFile'){
      this.addressFile.nativeElement.value = ''
    }
    if(val1 == 'shareholderFile'){
      this.shareholderFile.nativeElement.value = ''
    }
    if(val1 == 'auditFinanceFile'){
      this.auditFinanceFile.nativeElement.value = ''
    }
    if(val1 == 'personalNoticeFile'){
      this.personalNoticeFile.nativeElement.value = ''
    }
    if(val1 == 'CBS_MLCB_File'){
      this.CBS_MLCB_File.nativeElement.value = ''
    }
    if( val1 == 'GST_F5_File'){
      this.GST_F5_File.nativeElement.value = ''
    }
    if(val1 == 'GST_stmt_File'){
      this.GST_stmt_File.nativeElement.value = ''
    }
    if(val1 == 'lates_AP_File'){
      this.lates_AP_File.nativeElement.value = ''
    }
    if(val1 == 'bank_account_proof'){
      this.bank_account_proof.nativeElement.value = ''
    }
    if(val1 == 'bank_statement_File'){
      this.bank_statement_File.nativeElement.value = ''
    }
    
  }

  upload_address_proof(event) {

    if (event.target.files.length > 0) {
    this.file_type2 = "invalid";
    this.register_address_doc = "Choose File"
    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){

      if((event.target.files[0].size/1000000) <= 5){
        this.file_type2 = "valid";
       
          var file = event.target.files[0];
          this.register_address_doc = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file1 = res["filename"];
              }
            });
        }else {
          this.file_type2 = "invalid";
          this.push.showNotification("File Size should be less than 5MB", "danger");
          this.CompanyForm.controls['address_proof'].setErrors({'incorrect': true});
        }
        }else {
          this.file_type2 = "invalid";
          this.push.showNotification("Incorrect File", "danger");
          this.CompanyForm.controls['address_proof'].setErrors({'incorrect': true});
        }

      }
  }

  upload_shareholder(event) {

    if (event.target.files.length > 0) {
      this.file_type3 = 'invalid'
      this.shareholder_doc = "Choose File"

    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf") ){

      if((event.target.files[0].size/1000000) <= 5){
        this.file_type3 = 'valid';
     
        var file = event.target.files[0];
        this.shareholder_doc = event.target.files[0].name;
        this._auth
          .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.file4 = res["filename"];
            }
          });
      }else {
        this.file_type3 = "invalid";
        this.push.showNotification("File Size should be less than 5MB", "danger");
        this.CompanyForm.controls['shareholder_details'].setErrors({'incorrect': true});
      }
    }else {
      this.file_type3 = "invalid";
      this.push.showNotification(" Incorrect File ", "danger");
      this.CompanyForm.controls['shareholder_details'].setErrors({'incorrect': true});
    }

      }    
  }

  upload_director_details(event) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      this.director_details_doc = event.target.files[0].name;
      this._auth
        .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
        .toPromise()
        .then((res: any) => {
          if (res) {
            this.file15 = res["filename"];
          }
        });
    }
  }

  upload_board_resolution(event) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      this.board_resolution_doc = event.target.files[0].name;
      this._auth
        .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
        .toPromise()
        .then((res: any) => {
          if (res) {
            this.file14 = res["filename"];
          }
        });
    }
  }

  CBS_MLCB_file(event) {
    if (event.target.files.length > 0) {

    this.file_check3 = 'invalid'
    this.CBS_MLCB = "Choose File"

    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){


      if(event.target.files[0].size/1000000 <= 5){
        this.file_check3 = 'valid'
       
          var file = event.target.files[0];
          this.CBS_MLCB = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file2 = res["filename"];
              }
            });
        }else {
          this.file_check3 = "invalid";
          this.push.showNotification("File size Should be less than 5MB", "danger");
          this.creditForm.controls['CBS_MLCB'].setErrors({'incorrect': true});
        }
      }else {
        this.file_check3 = "invalid";
        this.push.showNotification("Incorrect File", "danger");
        this.creditForm.controls['CBS_MLCB'].setErrors({'incorrect': true});
      }
      }
      

    
  }

  audited_financial_statement(event) {
    if (event.target.files.length > 0) {
    this.file_check1 = "invalid";
    this.audited_financial = "Choose File"

    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){


      if(event.target.files[0].size/1000000 <= 5){
        this.file_check1 = 'valid';
        
          var file = event.target.files[0];
          this.audited_financial = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file6 = res["filename"];
              }
            });
        }else {
          this.file_check1 = "invalid";
          this.push.showNotification("File Should be less than 5Mb", "danger");
          this.creditForm.controls['audited_financial_statements'].setErrors({'incorrect': true});
        }
      }else {
        this.file_check1 = "invalid";
        this.push.showNotification("Incorrect File", "danger");
        this.creditForm.controls['audited_financial_statements'].setErrors({'incorrect': true});
      } 

      }  
  }

  personal_notice_assess(event) {
    if (event.target.files.length > 0) {
    this.file_check2 = "invalid";
    this.personal_notice = "Choose file"

    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){


      if(event.target.files[0].size/1000000 <= 5 ){
        this.file_check2 = 'valid'
        
          var file = event.target.files[0];
          this.personal_notice = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file7 = res["filename"];
              }
            });
        }else {
          this.file_check2 = "invalid";
          this.push.showNotification("File size shold be less than 5Mb", "danger");
          this.creditForm.controls['personal_notice_assessment'].setErrors({'incorrect': true});
        }
      }else {
        this.file_check2 = "invalid";
        this.push.showNotification("Incorrect File", "danger");
        this.creditForm.controls['personal_notice_assessment'].setErrors({'incorrect': true});
      }
      }   
  }

  GST_F5_statment(event) {
    if (event.target.files.length > 0) {
    this.file_check4 = 'invalid'
    this.GST_F5 = "Choose File"

    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){

      if((event.target.files[0].size/1000000 <= 5)){
        this.file_check4 = 'valid'
        
          var file = event.target.files[0];
          this.GST_F5 = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file8 = res["filename"];
              }
            });
        }else {
          this.file_check4 = "invalid";
          this.push.showNotification("File Size Should be less than 5MB", "danger");
          this.creditForm.controls['GST_F5_Statment'].setErrors({'incorrect': true});
        }
        
      }else {
        this.file_check4 = "invalid";
        this.push.showNotification("InCorrect file", "danger");
        this.creditForm.controls['GST_F5_Statment'].setErrors({'incorrect': true});
      }
      
      }  
  }

  GST_Statment(event) {
    if (event.target.files.length > 0) {
    this.file_check5 = 'invalid'
    this.GST_statment = "Choose File"
    if( (event.target.files[0].type === "image/png" || 
    event.target.files[0].type === "image/jpeg" || 
    event.target.files[0].type === "application/pdf")){

      if(event.target.files[0].size/1000000 <= 5){
        this.file_check5 = 'valid'
      
          var file = event.target.files[0];
          this.GST_statment = event.target.files[0].name;
          this._auth
            .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
            .toPromise()
            .then((res: any) => {
              if (res) {
                this.file10 = res["filename"];
              }
            });
        }else {
          this.file_check5 = "invalid";
          this.push.showNotification("File Size Should be less than 5Mb", "danger");
          this.creditForm.controls['GST_Statment'].setErrors({'incorrect': true});
        }
  
      }else {
        this.file_check5 = "invalid";
        this.push.showNotification("Incorrect File", "danger");
        this.creditForm.controls['GST_Statment'].setErrors({'incorrect': true});
      }
      }
        
  }

  passbook(event) {
    if (event.target.files.length > 0) {
    this.file_check_bank1 = 'invalid'
    this.passbook_file = "Choose File"

    if((event.target.files[0].type === "application/pdf")){

      if(event.target.files[0].size/1000000 <= 5){
        this.file_check_bank1 = 'valid'
      
        var file = event.target.files[0];
        this.passbook_file = event.target.files[0].name;
        this._auth
          .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.file11 = res["filename"];
            }
          });
      }else {
        this.file_check_bank1 = "invalid";
        this.push.showNotification("File size Should be less than 5MB", "danger");
        this.bankForm.controls['passbook'].setErrors({'incorrect': true});
      }
    }else {
      this.file_check_bank1 = "invalid";
      this.push.showNotification("Incorrect File", "danger");
      this.bankForm.controls['passbook'].setErrors({'incorrect': true});
    }

      }
  }

  checkbook(event) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      this.checkbook_file = event.target.files[0].name;
      this._auth
        .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
        .toPromise()
        .then((res: any) => {
          if (res) {
            this.file12 = res["filename"];
          }
        });
    }
  }

  month6(event) {
    if (event.target.files.length > 0) {
    this.file_check_bank2 = 'invalid'
    this.month6_file = "Choose File"
    if( (event.target.files[0].type === "application/pdf")){

      if(event.target.files[0].size/1000000 <= 5){
        this.file_check_bank2 = 'valid'
      
        var file = event.target.files[0];
        this.month6_file = event.target.files[0].name;
        this._auth
          .funder_upload_doc(file, sessionStorage.getItem("seller_id"), "funder-doc")
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.file13 = res["filename"];
            }
          });
      } else {
        this.file_check_bank2 = "invalid";
        this.push.showNotification("File Size Should be less than 5MB", "danger");
        this.bankForm.controls['month6_Statment'].setErrors({'incorrect': true});
      }

    }else {
      this.file_check_bank2 = "invalid";
      this.push.showNotification("Incorrect File", "danger");
      this.bankForm.controls['month6_Statment'].setErrors({'incorrect': true});
    }
   
      }
      


    
  }
  /*============================================================================================================================================
                                            Tab Change & Information Submission
  ============================================================================================================================================*/
  tab_change() {
    this.submiited = true;
    let value = this.validate_upload_docs();
    if (this.CompanyForm.invalid || value) {
      this.push.showNotification("Please Fill Required Information", "danger");
      this.CompanyForm.markAllAsTouched();
      return;
    }

    this.CompanyForm.value.logo = this.file;
    this.data = this.CompanyForm.value;

    this.company_info_nav_link = "nav-link";
    this.company_info_tab = "tab-pane";

    if (this.acra === true && this.credit === false && this.bank === true) {
      this.kyc_nav_link = "nav-link active";
      this.kyc_tab = "tab-pane active";
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";

      this.bank_btn_display = false;

      this.credit_check_btn = true;
      this.credit_display_btn = true;

      this.show_finish_btn = true;
    } else if (this.acra === true && this.credit === true && this.bank === false) {
      this.kyc_nav_link = "nav-link active";
      this.kyc_tab = "tab-pane active";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = "tab-pane";

      this.kyc_display_btn = false;
      this.kyc_tab_display = false;
      this.credit_check_btn = false;
      this.credit_display_btn = false;
      this.bank_tab_display = true;
      this.bank_btn_display = true;
      this.display_initial_next = true;
      this.show_finish_btn = true;
    } else if (this.acra === true && this.credit === true && this.bank === true) {
      this.kyc_nav_link = "nav-link active";
      this.kyc_tab = "tab-pane active";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = "tab-pane";
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";

      this.credit_display_btn = false;
      this.bank_btn_display = true;
      this.show_finish_btn = true;
    } else {
      this.kyc_nav_link = "nav-link active";
      this.kyc_tab = "tab-pane active";

      this.bank_tab_display = true;
      this.credit_check_btn = true;
      this.show_finish_btn = false;
      this.bank_btn_display = true;
      this.credit_display_btn = true;
    }
    document.getElementById("main-content").scrollIntoView();
  }

  company_info() {
    this.company_info_nav_link = "nav-link active";
    this.company_info_tab = "tab-pane active";

    if (this.acra === false && this.credit === false && this.bank === false) {
      this.show_finish_btn = true;
      this.bank_tab_display = true;
      this.kyc_tab_display = true;
      this.credit_check_btn = true;
      this.credit_btn = true;
      this.kyc_display_btn = true;
      this.credit_display_btn = true;
      this.bank_btn_display = true;
      this.display_initial_next = true;
    } else if (this.acra === true && this.credit === true && this.bank === true) {
      this.kyc_nav_link = "nav-link";
      this.kyc_tab = "tab-pane";
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = " tab-pane";

      this.kyc_tab_display = false;
      this.bank_tab_display = false;
      this.credit_check_btn = false;

      this.show_finish_btn = false;
      this.credit_display_btn = true;
      this.kyc_display_btn = true;
      this.bank_btn_display = true;
      this.bank_btn = true;
      this.kyc_btn = true;

      this.display_initial_next = false;
    } else if (this.acra === true && this.credit === true && this.bank === false) {
      this.kyc_nav_link = "nav-link";
      this.kyc_tab = "tab-pane";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = "tab-pane";

      this.kyc_display_btn = false;
      this.credit_display_btn = true;
      this.bank_tab_display = true;
      this.bank_btn_display = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
      this.kyc_val = 1;
    } else if (this.acra === true && this.credit === false && this.bank === true) {
      this.kyc_nav_link = "nav-link";
      this.kyc_tab = "tab-pane";
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";

      this.kyc_display_btn = false;
      this.bank_btn_display = true;
      this.credit_check_btn = true;
      this.credit_display_btn = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
      this.kyc_val = 3;
    } else if (this.acra === true && this.credit === false && this.bank === false) {
      this.kyc_nav_link = "nav-link";
      this.kyc_tab = "tab-pane";
      this.bank_tab_display = true;
      this.bank_btn_display = true;
      this.kyc_display_btn = false;
      this.credit_check_btn = true;
      this.credit_display_btn = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
    } else if (this.acra === false && this.credit === true && this.bank === true) {
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = " tab-pane";

      this.bank_btn_display = true;
      this.bank_tab_display = false;
      this.credit_check_btn = false;
      this.credit_display_btn = false;
      this.kyc_display_btn = true;
      this.kyc_tab_display = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
    } else if (this.acra === false && this.credit === true && this.bank === false) {
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";
      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = " tab-pane";

      this.bank_btn_display = true;
      this.bank_tab_display = true;
      this.credit_check_btn = false;
      this.credit_display_btn = false;
      this.kyc_display_btn = true;
      this.kyc_tab_display = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
    } else {
      this.bank_info_nav_link = "nav-link";
      this.bank_info_tab = "tab-pane";

      this.bank_btn_display = false;
      this.bank_tab_display = false;
      this.credit_check_btn = true;
      this.credit_display_btn = true;
      this.kyc_display_btn = true;
      this.kyc_tab_display = true;
      this.display_initial_next = true;
      this.show_finish_btn = false;
    }
  }
  credit_info(val) {
    if (val == 1) {
      this.submiited = true;
      if (!this.CompanyForm.valid) {
        this.push.showNotification("Please Fill Required Information", "danger");
        this.CompanyForm.markAllAsTouched();
        return;
      }

      this.CompanyForm.value.logo = this.file;

      this.company_info_nav_link = "nav-link";
      this.company_info_tab = "tab-pane";

      if (this.acra === false && this.credit === true && this.bank === true) {
        this.credit_check_nav_link = "nav-link active";
        this.credit_check_tab = "tab-pane active";
        this.bank_info_nav_link = "nav-link";
        this.bank_info_tab = "tab-pane";

        this.kyc_tab_display = true;
        this.bank_btn_display = false;
        this.show_finish_btn = true;
      } else if (this.acra === false && this.credit === true && this.bank === false) {
        this.credit_check_nav_link = "nav-link active";
        this.credit_check_tab = "tab-pane active";

        this.kyc_tab_display = true;
        this.bank_tab_display = true;
        this.show_finish_btn = false;
        this.bank_btn_display = true;
      } else {
      }

      document.getElementById("main-content").scrollIntoView();
    } else {
      this.company_info_nav_link = "nav-link";
      this.company_info_tab = "tab-pane";

      if (this.acra === true && this.credit === true && this.bank === false) {
        this.kyc_nav_link = "nav-link";
        this.kyc_tab = "tab-pane";
        this.credit_check_nav_link = "nav-link active";
        this.credit_check_tab = "tab-pane active";

        this.bank_tab_display = true;
        this.bank_btn_display = true;
        this.credit_check_btn = false;
        this.show_finish_btn = false;
      } else {
        this.kyc_nav_link = "nav-link";
        this.kyc_tab = "tab-pane";
        this.credit_check_nav_link = "nav-link active";
        this.credit_check_tab = "tab-pane active";
        this.bank_info_nav_link = "nav-link";
        this.bank_info_tab = "tab-pane";

        this.bank_tab_display = false;
        this.credit_check_btn = false;
        this.credit_display_btn = true;
        this.kyc_tab_display = false;
        this.bank_btn_display = false;

        this.show_finish_btn = true;
      }
      document.getElementById("main-content").scrollIntoView();
    }
  }

  bank_info(val) {
    if (val === 1) {
      if (!this.creditForm.valid) {
        this.submiited = true;
        this.push.showNotification("Please Fill Required Information", "danger");
        this.creditForm.markAllAsTouched();
        return;
      }

      this.creditForm.value.CBS_MLCB = { file: this.file2 };
      this.creditForm.value.AP_AR_Statment = { file: this.file3 };
      this.creditForm.value.audited_financial_statements = { file: this.file6 };
      this.creditForm.value.personal_notice_assessment = { file: this.file7 };
      this.creditForm.value.GST_F5_Statment = { file: this.file8 };
      this.creditForm.value.GST_Statment = { file: this.file10 };

      this.creditForm.value.address_proof = { file: this.file1 };
      this.creditForm.value.shareholder_details = { file: this.file4 };
      var data = [];
      data.push(this.creditForm.value);
      var data1 = {
        credit_docs: data
      };
      this.company_info_nav_link = "nav-link";
      this.company_info_tab = "tab-pane";
      if (this.acra === true) {
        this.kyc_nav_link = "nav-link";
        this.kyc_tab = "tab-pane";
      }

      this.credit_check_nav_link = "nav-link";
      this.credit_check_tab = "tab-pane";

      this.bank_info_nav_link = "nav-link active";
      this.bank_info_tab = "tab-pane active";

      this.bank_tab_display = false;
      this.bank_btn_display = false;
      this.credit_check_btn = false;
      this.credit_display_btn = true;
      this.show_finish_btn = true;
      this.kyc_display_btn = true;

      document.getElementById("main-content").scrollIntoView();
    } else if (val === 2) {
      this.company_info_nav_link = "nav-link";
      this.company_info_tab = "tab-pane";
      this.kyc_nav_link = "nav-link";
      this.kyc_tab = "tab-pane";
      this.bank_info_nav_link = "nav-link active";
      this.bank_info_tab = "tab-pane active";

      this.credit_check_btn = true;
      this.credit_display_btn = true;

      this.show_finish_btn = true;
    } else {
      this.submiited = true;
      if (!this.CompanyForm.valid) {
        this.push.showNotification("Please Fill Required Information", "danger");
        this.CompanyForm.markAllAsTouched();
        return;
      } else {
        this.CompanyForm.value.logo = this.file;
        this.data = this.CompanyForm.value;

        this.company_info_nav_link = "nav-link";
        this.company_info_tab = "tab-pane";
        this.bank_info_nav_link = "nav-link active";
        this.bank_info_tab = "tab-pane active";
        this.bank_btn_display = false;
        this.bank_tab_display = false;
        this.credit_check_btn = true;
        this.credit_display_btn = true;
        document.getElementById("main-content").scrollIntoView();
      }
    }
  }
  validate_upload_docs(): boolean {
    let array_item = this.CompanyForm.value.additional_documents;
    for (let a of array_item) {
      if (a.name === "" || a.file === "" || (a.name === "" || a.file === "")) {
        return true;
      } else {
        return false;
      }
    }
  }

  upload_docs(event, i) {
    // console.log(this.items);
    if (event.target.files.length > 0) {

      if( (event.target.files[0].type === "image/png" || 
      event.target.files[0].type === "image/jpeg" || 
      event.target.files[0].type === "application/pdf") ) {

        if((event.target.files[0].size/1000000) <= 5){
          var file = event.target.files[0];
          this.items.value[i].file_name = event.target.files[0].name;

      this._auth
        .funder_upload_doc(event.target.files[0], sessionStorage.getItem("seller_id"), "funder-doc")
        .toPromise()
        .then((res: any) => {
          if (res) {
          this.items.value[i].file = res["filename"];
          // console.log(this.items);
        }
      });
            }else {
          
          this.push.showNotification("File Size Should be less than 5MB", "danger");
          }
          }else {
            
            this.push.showNotification("Incorrect File", "danger");
            }
      
    }
  }
  submit(val) {
    if (val === 1) {  
      let value = this.validate_upload_docs();
      if (this.CompanyForm.invalid || value) {
        this.push.showNotification("Please Fill Required Information", "danger");
        this.CompanyForm.markAllAsTouched();
        return;
      } else {
        this.CompanyForm.value.logo = this.file;
        this.data = this.CompanyForm.value;
        var data1 = {
          ...this.data,
          ...this.res,
          credit_docs: {
            address_proof: { file: this.file1 },
            shareholder_details: { file: this.file4 }
          }
        };

        this._auth.invite_funder(JSON.stringify(data1)).subscribe(
          (res: any) => {
            if (res) {
              this.router.navigate(["/funders/funder-list"]);
              document.getElementById("main-content").scrollIntoView();
            }
          },
          (err: any) => {
            this.push.showNotification("Something Went Wrong", "danger");
          }
        );
      }
    } else if (val === 2) {
      this.CompanyForm.value.logo = this.file;
      this.data = this.CompanyForm.value;

      this._auth
        .invite_funder(
          JSON.stringify({
            ...this.data,
            ...this.res,

            address_proof: { file: this.file1 },
            shareholder_details: { file: this.file4 }
          })
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.router.navigate(["/funders/funder-list"]);
              document.getElementById("main-content").scrollIntoView();
            }
          },
          (err: any) => {
            this.push.showNotification("Something Went Wrong", "danger");
          }
        );
    } else if (val === 3) {
      if (!this.creditForm.valid) {
        this.submiited = true;
        this.push.showNotification("Please Fill Required Information", "danger");
        this.creditForm.markAllAsTouched();
        return;
      }

      this.creditForm.value.CBS_MLCB = { file: this.file2 };
      this.creditForm.value.AP_AR_Statment = { file: this.file3 };
      this.creditForm.value.audited_financial_statements = { file: this.file6 };
      this.creditForm.value.personal_notice_assessment = { file: this.file7 };
      this.creditForm.value.GST_F5_Statment = { file: this.file8 };
      this.creditForm.value.GST_Statment = { file: this.file10 };

      this.CompanyForm.value.address_proof = { file: this.file1 };
      this.CompanyForm.value.shareholder_details = { file: this.file4 };
      var data = [];
      data.push(this.creditForm.value);
      var data1 = {
        credit_docs: {
          ...data,

          address_proof: { file: this.file1 },
          shareholder_details: { file: this.file4 }
        },
        ...this.CompanyForm.value,
        ...this.res
      };

      this._auth.invite_funder(data1).subscribe(
        (res: any) => {
          if (res) {
            this.router.navigate(["/funders/funder-list"]);
          }
        },
        (err: any) => {
          this.push.showNotification("Something Went Wrong", "danger");
        }
      );
    } else {
      if (!this.bankForm.valid) {
        this.push.showNotification("Please Fill Required Information", "danger");
        this.bankForm.markAllAsTouched();
        return;
      }

      var bank_doc = [];
      bank_doc.push({ passbook: this.file11 }, { cheqbook: this.file12 }, { month6_Statment: this.file13 });

      this.creditForm.value.CBS_MLCB = { file: this.file2 };
      this.creditForm.value.AP_AR_Statment = { file: this.file3 };
      this.creditForm.value.audited_financial_statements = { file: this.file6 };
      this.creditForm.value.personal_notice_assessment = { file: this.file7 };
      this.creditForm.value.GST_F5_Statment = { file: this.file8 };
      this.creditForm.value.GST_Statment = { file: this.file10 };

      this.creditForm.value.director_details = { file: this.file14 };
      this.creditForm.value.board_resolution = { file: this.file15 };

      var data2 = {
        bank_name: this.bankForm.value.bank_name,
        bank_branch_address: this.bankForm.value.bank_branch_address,
        bank_branch_code: this.bankForm.value.bank_branch_code,
        bank_account_no: this.bankForm.value.bank_account_no,
        bank_account_type: this.bankForm.value.bank_account_type,
        bank_docs: bank_doc,
        credit_docs: {
          ...this.creditForm.value,

          address_proof: { file: this.file1 },
          shareholder_details: { file: this.file4 }
        },
        ...this.CompanyForm.value,
        ...this.res
      };

      // console.log(data2)
      this._auth.invite_funder(data2).subscribe(
        (res: any) => {
          if (res) {
            this.router.navigate(["/funders/funder-list"]);
          }
        },
        (err: any) => {
          this.push.showNotification("Something Went Wrong", "danger");
        }
      );
    }
  }

  redirect() {
    this.router.navigate(["/logout"]);
  }

  display(val) {
    if (val === true) {
      return false;
    } else {
      return true;
    }
  }
}
