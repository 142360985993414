import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "../../../../services/push.service";
import { environment } from "environments/environment";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
declare var swal: any;
declare var $: any;

@Component({
  selector: "app-buyer-profile",
  templateUrl: "./buyer-profile.component.html",
  styleUrls: ["./buyer-profile.component.css"]
})
export class BuyerProfileComponent implements OnInit {
  logo: string;
  kyc_status: any;
  title: any;
  suffix: any;
  full_name: any;
  email: any;
  phone: any;
  mobile: any;
  website: any;
  street: any;
  city: any;
  zip: any;
  state: any;
  perm_id: any;
  country: any;
  uen: any;
  party_id: any;
  buyer_id: any;
  name: any;
  status: any;
  date: any;
  acc_type: any;
  acc_no: any;
  bank_add: any;
  branch_code: any;
  CBS_MLCB: any;
  GST_F5_Statment: any;
  GST_Statment: any;
  audited_financial_statements: any;
  boardResolution: any;
  directorDetails: any;
  personal_notice_assessment: any;
  shareholderDetails: any;
  registered_office: any;
  passbook: any;
  checkbook: any;
  month6_Statment: any;
  bank_name: any;
  AR_PR: any;
  upload_url: any;
  credit_check: any;
  bank_info: any;
  additional_documents: any;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private envService: EnvService
  ) {
    this.upload_url = this.envService.File_upload_buyer;
  }
  ngOnInit() {
    this._auth
      .getPermId(sessionStorage.getItem("seller_id"))
      .toPromise()
      .then(res => {
        if (res) {
          this.sharedService.nextMessage2(sessionStorage.getItem("seller_id"));

          this.logo = res["logo"] === null ? null : res["logo"];

          this.kyc_status = res["kyc_status"] === null ? "N/A" : res["kyc_status"];
          this.title = res["title"] === null ? "N/A" : res["title"];
          this.suffix = res["contact_person_suffix"] === null ? "N/A" : res["contact_person_suffix"];
          this.full_name = res["full_name"] === null ? "N/A" : res["full_name"];
          this.email = res["admin_user_id"] === null ? "N/A" : res["admin_user_id"];
          this.phone = res["phone"] === null ? "N/A" : res["phone"];
          this.mobile = res["mobile"] === null ? "N/A" : res["mobile"];
          this.website = res["website"] === null ? "N/A" : res["website"];
          this.street = res["street"] === null ? "N/A" : res["street"];
          this.city = res["city"] === null ? "N/A" : res["city"];
          this.zip = res["zip"] === null ? "N/A" : res["zip"];
          this.additional_documents = res["additional_documents"] === null ? null : res["additional_documents"];
          this.state = res["state_name"] === null ? "N/A" : res["state_name"];
          this.perm_id = res["perm_id"] === null ? "N/A" : res["perm_id"];
          this.country = res["country_name"] === null ? "N/A" : res["country_name"];
          this.uen = res["uen"] === null ? "N/A" : res["uen"];
          this.party_id = res["id"] === null ? "N/A" : res["id"];
          this.buyer_id = res["party_id"] === null ? "N/A" : res["party_id"];
          this.name = res["display_name"] === null ? "N/A" : res["display_name"];
          this.status = res["user_status"] === null ? "N/A" : res["user_status"];
          this.date = res["create_date"] === null ? "N/A" : res["create_date"];
          this.acc_no = res["bank_account_no"] === null ? "N/A" : res["bank_account_no"];
          this.acc_type = res["bank_account_type"] === null ? "N/A" : res["bank_account_type"];
          this.bank_add = res["bank_branch_address"] === null ? "N/A" : res["bank_branch_address"];
          this.branch_code = res["bank_branch_code"] === null ? "N/A" : res["bank_branch_code"];
          this.bank_name = res["bank_name"] === null ? "N/A" : res["bank_name"];
          this.credit_check = res["buyer_documents"].document_verification;
          this.bank_info = res["buyer_documents"].bank_verification;
          if (Object.keys(res["credit_docs"]).length > 0) {
            var i = res["credit_docs"];
            this.CBS_MLCB = i.CBS_MLCB === undefined ? undefined : i.CBS_MLCB.file;
            this.GST_F5_Statment = i.GST_F5_Statment === undefined ? undefined : i.GST_F5_Statment.file;
            this.GST_Statment = i.GST_Statment === undefined ? undefined : i.GST_Statment.file;
            this.audited_financial_statements =
              i.audited_financial_statements === undefined ? undefined : i.audited_financial_statements.file;
            this.personal_notice_assessment =
              i.personal_notice_assessment === undefined ? undefined : i.personal_notice_assessment.file;
            this.shareholderDetails = i.shareholder_details.file;

            this.registered_office = i.address_proof.file;
            this.AR_PR = i.AP_AR_Statment === undefined ? undefined : i.AP_AR_Statment.file;
          }

          if (res["bank_docs"] != null) {
            for (var i of res["bank_docs"]) {
              if (i.passbook != undefined) {
                this.passbook = i.passbook;
              }

              if (i.cheqbook != undefined) {
                this.checkbook = i.cheqbook;
              }

              if (i.month6_Statment != undefined) {
                this.month6_Statment = i.month6_Statment;
              }
            }
          }
        }
      });
  }

  download(file_name) {
    window.location.href = this.upload_url + "/" + file_name;
  }

  perm_id_display(id: string): string {
    if (id != undefined) {
      var spl = id.split("-");

      return "B" + spl[1];
    }
  }
}
