import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "../../../../services/push.service";
import { environment } from "environments/environment";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
// import * as $ from 'jquery';

declare var swal: any;
declare var $: any;

@Component({
  selector: "app-buyer-kyc",
  templateUrl: "./buyer-kyc.component.html",
  styleUrls: ["./buyer-kyc.component.css"]
})
export class BuyerKycComponent implements OnInit {
 
  kyc_data: any[];
  upload_url: string;
  selected:number = 0;
  tab_count: number;
  agree: boolean=false;
  terms_btn: string=  '';
  id: any;
  show: boolean=true;
  status_name: any;
  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private envService: EnvService
  ) {
    this.upload_url = this.envService.File_upload_funder;
  }
  ngOnInit() {
    let names_list = []
    this._auth
      .getPermId(sessionStorage.getItem("seller_id"))
      .toPromise()
      .then(res => {
        if (res) {
          
          this.kyc_data =  res['kyc_data_points']
          this.tab_count = res['kyc_data_points'].length+2
          for(var i=0 ; i<=this.kyc_data.length;i++){
            
            if(i === this.kyc_data.length){
              names_list.push('Terms')
              break;
            }else{
              names_list.push(this.kyc_data[i].name)
            }
          }
            // this.sharedService.nextMessage2(sessionStorage.getItem("seller_id"));
            for(let i=0;i<names_list.length;i++){
              if(names_list[i] === sessionStorage.getItem('status')){
                this.selected = i+1
              }
              
            }
        }
      })
      

      this.id = setInterval(() => {
        this.status();
      }, 10000);
  }



  next(){
    if(this.selected < this.tab_count-1){
      console.log(document.getElementsByClassName("active")[0].textContent)
      this._auth.updateStatus(sessionStorage.getItem("seller_id"), document.getElementsByClassName("active")[0].textContent.toUpperCase()).subscribe((res: any) => {
        this.selected += 1;
      })
     
    }
  }

 
  back=()=>{
    this.agree = false
    if(this.selected > -1){
      this.selected -= 1;
    }
  }


  ngDoCheck() {
    if(this.selected === this.tab_count-2){
      this.terms_btn = ''
      if (this.agree === true) {
        this.terms_btn = 'show'
      } else {
        this.terms_btn = ''
      }
    }else if(this.selected === this.tab_count-1){
      this.terms_btn = '' 
    }else{
      this.terms_btn = 'show'
    }
  }

    status=()=>{     
      this._auth.getStatus(sessionStorage.getItem("seller_id")).toPromise().then(
        res => {         
          if (res["user_status"] === "APPROVED") {   
            this.show = false
            this.status_name = res['user_status']
            this.push.showNotification("Your KYC Has Been Approved", "success");          
             clearInterval(this.id);         
            }       
          },
        err=>{
          this.push.showNotification("Something Went Wrong", "success");
        });   
      }

        ngOnDestroy() {
          if (this.id) {
            clearInterval(this.id);
          }
      
          sessionStorage.clear();
        }
      
        redirect() {
          this.router.navigate(["/logout"]);
        }
      
}
