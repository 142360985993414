import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, FormArray, ValidatorFn, Validators, ValidationErrors, AbstractControl } from "@angular/forms";
import { AuthService } from "app/services/auth.service";
import { PushService } from 'app/services/push.service';

@Component({
  selector: 'app-view-as',
  templateUrl: './view-as.component.html',
  styleUrls: ['./view-as.component.css']
})
export class ViewAsComponent implements OnInit {

  SwitchForm: FormGroup;
  party_name: Object;
  data = [];
  btn: boolean;

  constructor(private _auth: AuthService,
  private fb: FormBuilder,
  private _router: Router,
  private _route: ActivatedRoute,
  private push: PushService) { }

  ngOnInit() {
    this.SwitchForm= this.fb.group({
      role: [''],
      company: [''],
      username:[''],
    })

    this.btn =  true
  }

  back() {
    this._router.navigate(['/admin'])
  }

  getbtn(){
    this.btn=true
    if( this.SwitchForm.value.username){
      this.btn = false;
    }
  }

  save(){
    var admin = 'false'
    var inperson = 'true'
    let id = this.SwitchForm.value.username
    console.log(id, '/invoices/'+`${id}`)
    sessionStorage.setItem('admin', admin)
    sessionStorage.setItem('inperson', inperson)
    sessionStorage.setItem('impersonate_role', '5')
    sessionStorage.setItem('imperson_user', this.SwitchForm.value.company)

    window.open('/invoices/'+`${id}`,'_self');
  }
  cancel(){
    this._router.navigate(['/admin'])
  }

  getParty(){


    this._auth.party(this.SwitchForm.value.role).subscribe((res:any )=> {
      if (res )

        for(var i=0;i<res.length;i++){
          if(res[i]['user_status'] === 'APPROVED'){
            this.data.push(res[i])

          }
        }

    });
  }


}
