import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { EnvService } from 'app/services/env.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-all-profile',
  templateUrl: './all-profile.component.html',
  styleUrls: ['./all-profile.component.css']
})
export class AllProfileComponent implements OnInit {
  selected: any = 0;
  kyc_data_points: any;
  acra: boolean= false;

  constructor(private sharedService: SharedService,private _auth:AuthService,private envService: EnvService) { }

  ngOnInit(): void {
    

    this._auth
    .getPermId(sessionStorage.getItem("seller_id"))
    .toPromise()
    .then(async(res:any) => {
      try {
        this.kyc_data_points = res["kyc_data_points"];
        this.acra = await res["acra"]
        if(this.acra === true){
          this.sharedService.nextMessage2(sessionStorage.getItem("seller_id"));
        }
      } catch (error) {
        console.log(error)
      }
     
    })
  }


  setSelected= (selected_index: any)=>{
    this.selected = selected_index
  }

}
