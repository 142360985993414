import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-as-seller-profile',
  templateUrl: './view-as-seller-profile.component.html',
  styleUrls: ['./view-as-seller-profile.component.css']
})
export class ViewAsSellerProfileComponent implements OnInit {
  shareholder: any;
  ssic: any;
  officerDetails: any;
  register: any;
  perm_id: any;
  party_id: any;
  uen: any;
  seller_id: string;
  secondarySSIC: any;


    constructor(private _auth: AuthService, private router: Router,
      private _route: ActivatedRoute) { }

    ngOnInit() {
      this.seller_id = this._route.snapshot.paramMap.get("id");
    sessionStorage.setItem('impersonate_seller_id',this.seller_id)

      this._auth.getPermId(this.seller_id).toPromise().then(res=>{
        if(res){
          console.log(res);
          this.perm_id = res["perm_id"]
          this.party_id = res["party_id"]
          this.uen = res["uen"];
        }
      })

      this._auth.getkycDetails(sessionStorage.getItem("seller_id")).subscribe(res=>{
        console.log(res)
        this.shareholder = res['shareholders'].party_kyc_json.entities,

        this.ssic = res['ssic'].party_kyc_json.entities

        if( res['officers']!= null){
          this.officerDetails = res['officers'].party_kyc_json.entities[0].officerAppointmentDetails
        }else{
          this.officerDetails = true
        }

        if(res['ssic'].party_kyc_json.entities[0].secondarySSIC != null){
          this.secondarySSIC = res['ssic'].party_kyc_json.entities[0].secondarySSIC
        }else{
          this.secondarySSIC = false
        }

        this.register = res['address'].party_kyc_json.entities

    })
  }

  back() {
    this.router.navigate(['/invoices']);
  }
  //
  // shareholder=
  // [{
  // 		uen: "111111111M",
  // 		name: "SIM LIM PTE. LTD.",
  // 		companyType: "PRIVATE COMPANY LIMITED BY SHARES",
  // 		shareholders: {
  // 			individual: [{
  // 					shareholderId: "S23BT2997U",
  // 					shareholderName: "EFGH2345",
  // 					shareAllotted: 10000,
  // 					shareType: "Ordinary",
  // 					currency: "SINGAPORE<DOLLARS",
  // 					appointmentDate: "15-09-2015",
  // 					cessationDate: "08-05-2016",
  // 					status: "Withdrawn"
  // 				},
  //         {
  // 					shareholderId: "S23BT2997U",
  // 					shareholderName: "EFGH2345",
  // 					shareAllotted: 10000,
  // 					shareType: "Ordinary",
  // 					currency: "SINGAPORE<DOLLARS",
  // 					appointmentDate: "15-09-2015",
  // 					cessationDate: "08-05-2016",
  // 					status: "Withdrawn"
  // 				},
  // 			],
  // 			grouping: []
  // 		}
  // 	}]
  // register = [{
  // 		uen: "11111111J",
  // 		name: "ABC MEDIA",
  // 		addressEffectiveDate: "19-10-2015",
  // 		registeredAddress: {
  // 		  unitNo: "21",
  // 			levelNo: "03",
  // 			blkhseNo: "306C",
  // 			streetName: "XYZ LINK",
  // 			postalCode: "333333",
  // 			buildingName: "ABC GARDEN"
  // 		},
  // 		invalidAddressIndicator: "N"
  //   }]
  //
  //   ssic = {
  //     entities: [
  //     {
  //       uen: "11111111J",
  //       name: "ABC MEDIA",
  //       primarySSIC: {
  //         code: "01149",
  //         description: "GROWING OF NURSERY PRODUCTS N.E.C.",
  //         otherDescription: "NURSERY PRODUCTS"
  //       },
  //     }]
  //   }
  //  secondarySSIC = {
  //     code: "01119",
  //     description: "GROWING OF FOOD CROPS (NON-HYDROPONICS) N.E.C."
  //   }
  //   officerDetails =  [{
  //     officerId: "XX222222B",
  //     officerName: "ABC, DEF (GHI-YXXXX",
  //     position: "AUTHORISED REPRESENTATIVE",
  //     appointedDate: "06-11-2017",
  //     status: "ACTIVE"
  //   },{
  //     officerId: "XX222222B",
  //     officerName: "ABC, DEF (GHI-YXXXX",
  //     position: "AUTHORISED REPRESENTATIVE",
  //     appointedDate: "06-11-2017",
  //     status: "ACTIVE"
  //   }]

}
