import { Component, OnInit, ViewChild, Inject, ElementRef } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { SelectionModel } from "@angular/cdk/collections";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
import { SellerParticipateComponent } from './seller-participate/seller-participate.component'
import { DataService } from "app/services/data.service";

declare interface User {
  text?: string;
  email?: string; //  must be valid email format
  password?: string; // required, value must be equal to confirm password.
  confirmPassword?: string; // required, value must be equal to password.
  number?: string; // required, value must be equal to password.
  url?: string;
  idSource?: string;
  idDestination?: string;
}

declare var require: any;
declare var $: any;

@Component({
  selector: "app-seller-view",
  templateUrl: "./seller-view.component.html",
  styleUrls: ["./seller-view.component.css"]
})
export class SellerViewComponent implements OnInit {

  @ViewChild(SellerParticipateComponent) seller: SellerParticipateComponent;
  @ViewChild('inputBox') elementRef: ElementRef;

  selectedValue = true
  trueval = true
  id: any;
  perm_id: any;
  uen: any;
  shareholder: {};
  ssic: {};
  secondarySSIC: any;
  officerDetails: {};
  register: {};
  disp: boolean;
  name: any;
  status: any;
  date: any;
  status_update_date: any;
  btn_disabled: boolean;
  AdditionalForm: FormGroup;

  worldcheck: any;
  party_id: any;
  worldcheck_status: any;
  update_btn: boolean;
  flag = "";
  color: string;
  address: any;
  url: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ["select", "permid", "organizationName", "entity_type"];
  displayedColumns1: string[] = ["permid", "organizationName", "entity_type", "name", "number", "action"];
  // displayedColumns2: string[] = [
  //   "program_name",
  //   "lead_by",
  //   "start_date",
  //   "end_date",
  //   "program_currency",
  //   "interest_pct",
  //   "service_charges_pct",
  //   "status"
  // ];
  // dataSource2: MatTableDataSource<any>;
  dataSource: MatTableDataSource<any>;
  dataSource1: MatTableDataSource<any>;
  org_name: any;
  random_val: number;
  generated_permid: any = "";
  wc_btn: boolean;
  pid: any;
  display_perm: boolean;
  kyc_status: any;
  reference_data = [];
  show_world_check_data: boolean = true;
  show_data: boolean;
  show_reference_data: boolean;
  reference_id: any;
  worldcheck_id: any;
  wc_data = [];
  reference_name: any;
  generate_wc_id: boolean;
  ds_length: any;
  send_invite_display: boolean;
  CBS_MLCB: any;
  GST_F5_Statment: any;
  GST_Statment: any;
  audited_financial_statements: any;
  personal_notice_assessment: any;
  shareholderDetails: any;
  registered_address_display: any;
  director_details: any;
  board_resolution: any;
  passbook: any;
  checkbook: any;
  month6_Statment: any;
  bank_docs: any;
  credit_docs: any;
  acra: any;
  upload_url: string;
  full_name: any;
  email: any;
  phone: any;
  street: any;
  city: any;
  zip: any;
  state: any;
  country: any;
  designation: any;
  shareholder_doc: any;
  acc_no: any;
  acc_type: any;
  bank_add: any;
  branch_code: any;
  bank_name: any;
  wc_date: any;
  reinvite: any;
  hide: boolean = true;
  case_system_id: any;
  process_wo_wc: boolean = false;
  additional_documents: any;
  address_proof: any;
  shareholder_details: any;
  logo: any;
  seller_limit: any;
  seller_ref_id: any;
  wc_resolution: any;
  wc_perm_update_val: boolean = false;

  acra_update_val: boolean = false;
  uen_update_data: any;
  name_update_data: any;
  alias_name: any;
  alias_name1: any
  return_val: boolean = false;
  kyc_data_points: any;
  financials: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private push: PushService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private env: EnvService,
    private dataServices: DataService
  ) {
    this.upload_url = this.env.File_upload_seller;
  }

  public typeValidation: User;

  ngOnInit(): void {
    this.financials = this.dataServices.get_financials(1);
    this.show_data = false;
    this.btn_disabled = false;
    this.id = this._route.snapshot.paramMap.get("id");
    this.sharedService.nextMessage2(this.id);
    this._auth
      .getPermId(this.id)
      .toPromise()
      .then(res => {
        if (res) {

          // this._auth.participated_programs("seller", this._route.snapshot.paramMap.get("id")).subscribe(
          //   (res: any) => {
          //     this.dataSource2 = new MatTableDataSource(res);

          //     this.dataSource2.sort = this.sort;
          //     this.dataSource2.paginator = this.paginator;
          //   },
          //   err => {
          //     this.push.showNotification(err.error.errMsg, "danger");
          //   }
          // );
          this.show_data = true;
          this.send_invite_display = res["user_status"] === "ADDED" ? true : false;
          this.perm_id = res["perm_id"];
          this.uen = res["uen"] === null ? "N/A" : res["uen"];
          this.party_id = res["id"];
          this.pid = res["id"];

          this.full_name = res["full_name"] === null ? "N/A" : res["full_name"];
          this.email = res["admin_user_id"] === null ? "N/A" : res["admin_user_id"];
          this.phone = res["phone"] === null ? "N/A" : res["phone"];
          this.street = res["street"] === null ? "N/A" : res["street"];
          this.city = res["city"] === null ? "N/A" : res["city"];
          this.zip = res["zip"] === null ? "N/A" : res["zip"];
          this.state = res["state_name"] === null ? "N/A" : res["state_name"];
          this.country = res["country_name"] === null ? "N/A" : res["country_name"];
          this.name = res["display_name"] === null ? "N/A" : res["display_name"];
          this.designation = res["title"] === null ? "N/A" : res["title"];
          this.status = res["user_status"];
          this.date = res["create_date"];
          this.kyc_status = res["kyc_status"];
          this.status_update_date = res["status_updated_on"];
          this.worldcheck_status = res["worldcheck_status"];
          this.address_proof = res["address_proof"].length === 0 ? null : res["address_proof"];
          this.shareholder_details = res["shareholder_details"].length === 0 ? null : res["shareholder_details"];
          this.logo = res["logo"] === null ? null : res["logo"];
          this.id = res["seller_docs"] === null ? "N/A" : res["seller_docs"]["id"];
          this.worldcheck_id = res["worldcheck_id"];
          this.acc_no = res["bank_account_no"] === null ? "N/A" : res["bank_account_no"];
          this.acc_type = res["bank_account_type"] === null ? "N/A" : res["bank_account_type"];
          this.bank_add = res["bank_branch_address"] === null ? "N/A" : res["bank_branch_address"];
          this.branch_code = res["bank_branch_code"] === null ? "N/A" : res["bank_branch_code"];
          this.bank_name = res["bank_name"] === null ? "N/A" : res["bank_name"];
          this.wc_date = res["worldcheck_last_updated"] === null ? "N/A" : res["worldcheck_last_updated"];
          this.reinvite = res["reinvite"];
          this.case_system_id = res["case_system_id"] === null ? "N/A" : res["case_system_id"];
          this.wc_resolution = res['wc_resulution'] === null ? "N/A" : res["wc_resolution"]
          this.seller_limit = res["credit_limit"];
          this.seller_ref_id = res["seller_sb_ref"];
          this.alias_name = res['alias_name'] === null ? '' : res['alias_name'],
            this.alias_name1 = res['alias_name'] === null ? 'N/A' : res['alias_name']
          this.kyc_data_points = res["kyc_data_points"]



          this.additional_documents = res["additional_documents"] === null ? null : res["additional_documents"];
          this.kyc_data_points.forEach(element => {
            if(element.name === 'Financials'){
              this.financials = element.children;              
            }
          });
          if (res["case_system_id"] === null) {
            this.process_wo_wc = false;
          } else {
            this.process_wo_wc = true;
          }

          if (res["seller_docs"] != null) {
            this.credit_docs = res["seller_docs"].document_verification;
            this.bank_docs = res["seller_docs"].bank_verification;
          } else {
            this.credit_docs = false;
            this.bank_docs = false;
          }

          this.acra = res["seller_docs"] === null ? "N/A" : res["seller_docs"].acra;
          console.log(this.acra)
          if (this.status == "APPROVED" || this.worldcheck_status == "RED") {
            this.btn_disabled = true;
          }
          if (this.worldcheck_status == null) {
            this.update_btn = false;
          } else {
            this.update_btn = true;
          }

          if (Object.keys(res["credit_docs"]).length > 0) {
            var j = res["credit_docs"];
            for (var i of j) {
              this.CBS_MLCB = i.CBS_MLCB === undefined ? undefined : i.CBS_MLCB.file;
              this.GST_F5_Statment = i.GST_F5_Statment === undefined ? undefined : i.GST_F5_Statment.file;
              this.GST_Statment = i.GST_Statment === undefined ? undefined : i.GST_Statment.file;
              this.audited_financial_statements =
                i.audited_financial_statements === undefined ? undefined : i.audited_financial_statements.file;
              this.personal_notice_assessment =
                i.personal_notice_assessment === undefined ? undefined : i.personal_notice_assessment.file;
              (this.director_details = i.director_details === undefined ? undefined : i.director_details.file),
                (this.board_resolution = i.board_resolution === undefined ? undefined : i.board_resolution.file);
            }
          }

          if (res["bank_docs"] != null) {
            var i = res["bank_docs"];
            for (var j of i) {
              if (j.passbook != undefined) {
                this.passbook = j.passbook;
              }

              if (j.month6_Statment != undefined) {
                this.month6_Statment = j.month6_Statment;
              }
            }
          }

          if (res["perm_id"] === "N/A" || res["perm_id"] === null) {
            this.display_perm = true;

            this._auth.get_permid(res["display_name"]).subscribe((res: any) => {
              if (res["entities"].length > 0) {
                this.dataSource = new MatTableDataSource(res["entities"]);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              } else {
              }
            });
          } else {
            this.display_perm = false;
          }
        }
      });

    this.typeValidation = {
      text: "",
      number: ""
    };



  }

  get_val(val) {
    if (val == true) {
      return true;
    } else {
      return false;
    }
  }
  onDataChanged(event: any[]): void {
    this.financials = event;
  }
  rejectCalled() {
    this._router.navigate(["/admin"]);
  }

  get_status(status) {
    if (status === "INVITED" || status === "ADDED") {
      return true;
    } else {
      return false;
    }
  }

  Account_Hold() {
    this._auth
      .updateStatus(this._route.snapshot.paramMap.get("id"), "HOLD")
      .toPromise()
      .then(res => {
        if (res) {
          this.push.showNotification("User Account Is Temporarily Suspended", "info");
          this._router.navigate(["sellers/seller-list"]);
        }
      });
  }

  Account_UnHold() {
    this._auth
      .updateStatus(this._route.snapshot.paramMap.get("id"), "UNHOLD")
      .toPromise()
      .then(res => {
        if (res) {
          this.push.showNotification("User Account Is Activated", "success");
          this._router.navigate(["sellers/seller-list"]);
        }
      });
  }
  showSwal() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      confirmButtonText: "Confirm",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        if (this.generate_wc_id === true) {

          this._auth
            .update_worldcheck_data(this._route.snapshot.paramMap.get("id"), {
              referenceId: "0"
            })
            .subscribe(
              (res: any) => {
                console.log(this.case_system_id)
                this._auth
                  .update_flag_status(this.party_id, this.case_system_id === 'N/A' ? 'green' : 'grey')
                  .toPromise()
                  .then((res: any) => {
                    if (res) {
                      Swal.fire({
                        title: "Updated!",
                        text: "Status Updated Successfully",
                        type: "success",
                        confirmButtonClass: "btn btn-success",
                        buttonsStyling: false
                      });
                      this.generate_wc_id = false
                      this.ngOnInit();
                    }
                  });
              },
              err => {
                this.push.showNotification("Something Went Wrong", "danger");
              }
            );
        } else {
          this._auth
            .update_flag_status(this.party_id, this.flag)
            .toPromise()
            .then((res: any) => {
              if (res) {
                Swal.fire({
                  title: "Updated!",
                  text: "Status Updated Successfully",
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
                this.generate_wc_id = false
                this.ngOnInit();
              }
            });
        }
      }
    });
  }

  get_status_color(status) {
    if (status === "AMBER") {
      return "fa fa-flag fa-2x amber";
    } else if (status === "RED") {
      return "fa fa-flag fa-2x danger";
    } else if (status === "GREEN") {
      return "fa fa-flag fa-2x success";
    } else if (status === "GREY") {
      return "fa fa-flag fa-2x secondary";
    } else if (status === "PURPLE") {
      return "fa fa-flag fa-2x purple";
    } else {
      return "fa fa-flag fa-2x default";
    }
  }

  store_flag_value(value) {
    this.flag = value;

    if (value == "red") {
      this.color = "#FF0000";
    } else if (value === "green") {
      this.color = "#008000";
    } else {
      this.color = "#ffbf00";
    }
  }
  // get flag_status() {
  //   if (this.flag === "red" || this.flag === '') {
  //     return false
  //   } else {
  //     return true
  //   }
  // }

  get_perm_id(id: string) {
    var spl = id.split("/");

    return spl[3];
  }

  perm_id_display(id: string): string {
    let val = id === "N/A" ? null : id;
    if (val != null) {
      var spl = id.split("-");
      if (spl[1].length >= 10) {
        return "S" + spl[1].substr(1);
      } else {
        return "S" + spl[1];
      }
    } else {
      return "N/A";
    }
  }

  show(name, url) {
    this.url = url;
    this.org_name = name;

    $("#myModal1").modal("show");
  }

  cancel_modal() {
    this.generated_permid = "";
    $("#myModal2").modal("hide");

    let val = <HTMLInputElement>document.getElementById('selectedValue')
    val.value = ""
  }

  generate_id(event, value) {
    this.generated_permid = event.target.value + "-" + value;
  }

  display_wc_btn(event, id) {


    if (event.target.checked) {
      this.generated_permid = this.get_perm_id(id);
      this.wc_btn = true;
    } else {
      this.generated_permid = "";
      this.wc_btn = false;
    }
  }

  generate_random_val() {
    var date = new Date();
    this.random_val = Math.floor(date.getTime() / 1000);
    $("#myModal2").modal("show");
  }

  update_permid() {
    this._auth.update_permid(this.pid, this.generated_permid).subscribe(
      (res: any) => {
        console.log("perm called")
        this.ngOnInit();
        this.push.showNotification("Perm Id Successfully Updated", "success");


      },
      err => {
        this.push.showNotification("Something Went Wrong. Please Try Again Later", "danger");
      }
    );

    let val = <HTMLInputElement>document.getElementById('selectedValue')
    val.value = ""

  }

  get_referenceid_data(id, name) {
    this.show_reference_data = false;
    this._auth.get_referenceid_data(id).subscribe(
      (res: any) => {
        this.show_reference_data = true;
        this.reference_data = res.details;
        this.reference_id = id;
        this.reference_name = name;
        $("#myModal3").modal("show");
      },
      err => {
        this.push.showNotification("Something Went Wrong", "danger");
      }
    );
  }

  proceed_wc(event) {
    if (event.target.checked) {
      this.generate_wc_id = true;
    } else {
      this.generate_wc_id = false;
    }
  }

  update_Wc_data(id) {
    this._auth
      .update_worldcheck_data(this._route.snapshot.paramMap.get("id"), {
        referenceId: id
      })
      .subscribe(
        (res: any) => {
          this.push.showNotification("WorldCheck Id Successfully Updated", "success");
          this.ngOnInit();
        },
        err => {
          this.push.showNotification("Something Went Wrong", "danger");
        }
      );
  }
  save2() {
    this._auth
      .update_status_for_admin_seller_approve(this._route.snapshot.paramMap.get("id"), "APPROVED")
      .toPromise()
      .then(res => {
        if (res) {
          if (res["user_status"] === "APPROVED") {
            if (res) {
              this.btn_disabled = true;
              var data = {
                pid: `${this.id}`,
                action: "KYC Approved",
                message: "KYC Has Been Approved For " + `${this.id}`
              };
              this._auth
                .post_notifications(JSON.stringify(data))
                .toPromise()
                .then(res => {
                  this.push.showNotification("KYC Successfully Approved", "success");
                  this._router.navigate(["/sellers/seller-list"]);
                });
            }
          }
        }
      });
  }

  update_status(status) {
    this._auth
      .update_flag_status(this.party_id, status)
      .toPromise()
      .then((res: any) => {
        if (res) {
        }
      });
  }
  cancelCalled() {
    this._router.navigate(["sellers/seller-list"]);
  }

  back() {
    this._router.navigate(["sellers/seller-list"]);
  }

  download(file_name) {
    if (file_name.length == 0) {
      this.push.showNotification("File Not Found", 'danger')
    }
    else {
      window.location.href = this.upload_url + "/" + file_name;
    }
  }

  get_entity(id: string) {
    if (id != undefined) {
      var spl = id.split("-");

      if (spl[0] === "98") {
        return "Individual";
      } else {
        return "Organisation";
      }
    }
  }

  send_invite() {
    if (this.status != "APPROVED") {
      if (this.reinvite === true) {
        this._auth.seller_reinvite(this._route.snapshot.paramMap.get("id")).subscribe(
          (res: any) => {
            this.push.showNotification("Seller Invited Successfully", "success");
            this.ngOnInit();
          },
          err => {
            this.push.showNotification("Unable To Invite Seller", "danger");
          }
        );
      } else {
        this._auth.seller_send_invite(this._route.snapshot.paramMap.get("id")).subscribe(
          (res: any) => {
            this.push.showNotification("Seller Invited Successfully", "success");
            this.ngOnInit();
          },
          err => {
            this.push.showNotification("Unable To Invite Seller", "danger");
          }
        );
      }
    } else {
      this.push.showNotification("This Account Has Already Been Approved", "info");
    }
  }

  get_wc_data() {
    if (this.perm_id != 'N/A') {
      this.show_world_check_data = false;
      this.process_wo_wc = false;
      this.hide = false;

      if (this.worldcheck_id != 0) {
        if (this.perm_id != "N/A" && this.case_system_id === "N/A") {
          this._auth.get_worldcheck_details({ name: this.name, permId: this.perm_id }).subscribe(
            (res: any) => {
              this.show_world_check_data = true;
              this.process_wo_wc = true;
              this.case_system_id = res["caseSystemId"];
              this.ngOnInit();

            },
            err => {
              this.show_world_check_data = true;
              this.process_wo_wc = true;
              this.case_system_id = "N/A";
              this.push.showNotification(err.error.error, "danger");
            }
          );
        } else {
          this.show_world_check_data = true;
        }
      } else {
        this.show_world_check_data = true;
      }
    } else {
      this.push.showNotification("Please Generate Perm ID", 'danger')
      this.hide = true

    }

  }

  onUpload(event, val1, val2) {

    if (val1 == 'logo') {
      let file: any = null;
      if (event.target.files.length > 0) {

        if ((event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpeg")) {

          if ((event.target.files[0].size / 1000000) <= 5) {
            file = event.target.files[0];
            if (file != null) {
              this._auth
                .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "seller-doc")
                .toPromise()
                .then((res: any) => {
                  if (res) {
                    let data = {
                      field_name: val2,
                      new_file: res,
                      type: val1,
                      party_id: this._route.snapshot.paramMap.get("id")
                    };


                    this._auth.update_documents(data).subscribe(
                      (res: any) => {
                        this.push.showNotification(res, "success");
                        this.ngOnInit();
                      },
                      err => {
                        this.push.showNotification(err.error.errMsg, "danger");
                      }
                    );
                  }
                });
            }
          } else {
            this.push.showNotification("File Size Should be less than 5MB", "danger");
          }
        } else {
          this.push.showNotification("Incorrect File", "danger");
        }
      }

    }

    if (val1 == 'address_proof' || val1 == "credit_doc") {
      let file: any = null;
      if (event.target.files.length > 0) {

        if ((event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "application/pdf")) {

          if ((event.target.files[0].size / 1000000) <= 5) {
            file = event.target.files[0];
            if (file != null) {
              this._auth
                .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "seller-doc")
                .toPromise()
                .then((res: any) => {
                  if (res) {
                    let data = {
                      field_name: val2,
                      new_file: res,
                      type: val1,
                      party_id: this._route.snapshot.paramMap.get("id")
                    };


                    this._auth.update_documents(data).subscribe(
                      (res: any) => {
                        this.push.showNotification(res, "success");
                        this.ngOnInit();
                      },
                      err => {
                        this.push.showNotification(err.error.errMsg, "danger");
                      }
                    );
                  }
                });
            }
          } else {
            this.push.showNotification("File Size Should be less than 5MB", "danger");
          }
        } else {
          this.push.showNotification("Incorrect File", "danger");
        }
      }

    }

    if (val1 == 'bank_doc') {

      let file: any = null;
      if (event.target.files.length > 0) {

        if (event.target.files[0].type === "application/pdf") {

          if ((event.target.files[0].size / 1000000) <= 5) {
            file = event.target.files[0];
            if (file != null) {
              this._auth
                .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "seller-doc")
                .toPromise()
                .then((res: any) => {
                  if (res) {
                    let data = {
                      field_name: val2,
                      new_file: res,
                      type: val1,
                      party_id: this._route.snapshot.paramMap.get("id")
                    };

                    console.log(JSON.stringify(data));
                    this._auth.update_documents(data).subscribe(
                      (res: any) => {
                        this.push.showNotification(res, "success");
                        this.ngOnInit();
                      },
                      err => {
                        this.push.showNotification(err.error.errMsg, "danger");
                      }
                    );
                  }
                });
            }
          } else {
            this.push.showNotification("File Size Should be less than 5MB", "danger");
          }
        } else {
          this.push.showNotification("Incorrect File", "danger");
        }
      }
    }

    this.onFocusEvent(val2)

  }

  seller_max_limit_update() {
    Swal.fire({
      title: "Enter Seller Max Limit",
      html:
        '<div class="form-group">' +
        '<input id="input-field"  type="text" class="form-control" value=' + parseFloat(this.seller_limit) + ' currencyMask [options]="{ prefix: "", thousands: ",", align: "left", allowNegative: false }" />' +
        "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then(result => {
      // console.log($("#input-field").val());

      if (result.value === true) {
        if (parseInt($("#input-field").val()) > 0) {
          this._auth.update_seller_max_limit("seller", $("#input-field").val(), this._route.snapshot.paramMap.get("id")).subscribe(
            (res: any) => {
              Swal.fire({
                type: "success",
                html: "Successfully Updated",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              }).then(() => {
                this.ngOnInit();
              });
            },
            (err: any) => {
              console.log(err);
            });
        } else {
          this.push.showNotification('Value Must Be Greater Than Zero', 'danger')

        }
      }


    });
  }


  async uen_update() {
    const { value: value } = await Swal.fire({
      title: "Update UEN",
      input: 'text',
      inputValue: this.uen.toUpperCase(),

      inputAttributes: {
        style: 'text-transform:uppercase'
      },

      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
      inputValidator: async (value) => {
        let val: boolean;
        value = this.trim(value)
        if (value != "" && value != this.uen) {
          await this.check_uen(value.toUpperCase()).then(res => {
            console.log(res)
            val = res;
          })
          if (val) {
            return "UEN Already Exists"
          } else {
            return ""
          }
        } else {
          return "Please Enter UEN To Update"
        }
      }
    })
    if (value) {

      let val = value
      val = this.trim(val);
      if (val != '' && val != this.uen) {
        this.uen_update_data = val
        $("#myModal4").modal("show");

      }
    }

  }

  uen_update_call() {
    console.log(this.uen_update_data)
    this._auth.update_uen({ uen: this.uen_update_data.toUpperCase(), acra: this.acra_update_val, party_id: this._route.snapshot.paramMap.get("id") }).subscribe(
      (res: any) => {
        Swal.fire({
          type: "success",
          html: "Successfully Updated",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
        }).then(() => {
          this.ngOnInit();
        });
      },
      (err: any) => {
        console.log(err);
      });
  }
  check_uen(value: string) {
    if (value != "") {
      return this._auth.check_uen(value).toPromise()
        .then((res: any) => {
          console.log(res)
          return false
        }, (err: any) => {
          return true
        }
        )
    }
  }
  name_update() {
    Swal.fire({
      title: "Update Company Name",
      input: 'text',
      inputValue: this.name,
      // html:
      // '<div class="form-group">' +
      // '<input id="input-field"  type="text" class="form-control" value='+ `'${this.name}'` +' />' +
      // "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,

    }).then(result => {
      // console.log($("#input-field").val());

      let val = result.value
      val = $.trim(val);
      if (val != '' && val != this.name) {
        this.name_update_data = val

        $("#myModal5").modal("show");

      }
      else {
        this.push.showNotification('Please Enter Name To Update', 'danger')

      }


    });
  }


  name_update_call() {

    this._auth.update_name({ name: this.name_update_data, perm_id_update: this.wc_perm_update_val, wc_screening: this.wc_perm_update_val, party_id: this._route.snapshot.paramMap.get("id") }).subscribe(
      (res: any) => {
        Swal.fire({
          type: "success",
          html: "Successfully Updated",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        }).then(() => {
          console.log("called")
          this.ngOnInit();
        });
        this.ngOnInit()
      },
      (err: any) => {
        console.log(err);
      });

    this.wc_perm_update_val = false


  }

  alias_update() {

    Swal.fire({
      title: "Update Alias Name",
      input: 'text',
      inputValue: this.alias_name,
      // html:
      // '<div class="form-group">' +
      // '<input id="input-field"  type="text" class="form-control"  value= '+`'${this.alias_name}'`+' autofocus />' +
      //   "</div>",

      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,

    }).then(result => {
      // this.elementRef.nativeElement.focus();

      let val = result.value
      val = $.trim(val);

      if (val != '' || val != this.alias_name || val != undefined || val != null) {

        this._auth.update_alias({ party_id: this._route.snapshot.paramMap.get("id"), alias_name: val }).subscribe((res: any) => {
          this.push.showNotification(res, 'success')
          this.ngOnInit();
        }, (err) => {
          this.push.showNotification(err.error.errMsg, 'danger')
        })

      }
      else {
        this.push.showNotification('Please Enter UEN To Update', 'danger')

      }


    });

    // document.getElementById('input-field').autofocus = true;

  }

  trim(el) {
    console.log(el)
    el = el.
      replace(/(^\s*)|(\s*$)/gi, ""). // removes leading and trailing spaces
      replace(/[ ]{2,}/gi, " ")
    return el;
  }

  sellerParticipate() {
    this.seller.sellerParticipate()
  }

  onFocusEvent(val) {


    // let arr = ['logo' , 'address_proof' , 'shareholder_details', 'audited_financial_statements', 
    // 'personal_notice_assessment','CBS_MLCB', 'GST_F5_Statment', 'AP_AR_Statement', 'passbook', 'month6_Statment']


    // arr.forEach( ids=>{
    //   if(val == ids){
    //     console.log(ids);
    //     (document.getElementById('ids') as HTMLInputElement).value = ''   
    //   }
    // })


    if (val == 'logo') {
      let val = <HTMLInputElement>document.getElementById('logo')
      val.value = ''
    }
    if (val == 'address_proof') {
      let val = <HTMLInputElement>document.getElementById('address_proof')
      val.value = ''
    }
    if (val == 'shareholder_details') {
      let val = <HTMLInputElement>document.getElementById('shareholder_details')
      val.value = ''
    }
    if (val == 'audited_financial_statements') {
      let val = <HTMLInputElement>document.getElementById('audited_financial_statements')
      val.value = ''
    }
    if (val == 'personal_notice_assessment') {
      let val = <HTMLInputElement>document.getElementById('personal_notice_assessment')
      val.value = ''
    }
    if (val == 'CBS_MLCB') {
      let val = <HTMLInputElement>document.getElementById('CBS_MLCB')
      val.value = ''
    }
    if (val == 'GST_F5_Statment') {
      let val = <HTMLInputElement>document.getElementById('GST_F5_Statment')
      val.value = ''
    }
    if (val == "AP_AR_Statement") {
      let val = <HTMLInputElement>document.getElementById('AP_AR_Statement')
      val.value = ''
    }
    if (val == "passbook") {
      let val = <HTMLInputElement>document.getElementById('passbook')
      val.value = ''
    }
    if (val == "month6_Statment") {
      let val = <HTMLInputElement>document.getElementById('month6_Statment')
      val.value = ''
    }
  }

}



