import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-lazysearch',
  templateUrl: './lazysearch.component.html',
  styleUrls: ['./lazysearch.component.css']
})
export class LazysearchComponent implements OnInit {
  @ViewChild('auto') auto;
 
  openPanel(e): void {
    e.stopPropagation();
    this.auto.open();
  }
  
  closePanel(e): void {
    e.stopPropagation();
    this.auto.close();
    }
    
  focus(e): void {
    e.stopPropagation();
    this.auto.focus();
  } 
  data1: any;
  type: any;
  keyword :string;
  data = [];
  item: any;
  constructor(private _auth: AuthService, private sharedService: SharedService) { }

  ngOnInit(): void {
  
    this.sharedService.sharedMessage3.subscribe((res: any) => {
      this.type = res
      if(this.type === "party"){
        this.keyword =  "display_name"
      }else{
        this.keyword =  "program_name"
      }
    })
  }
 
  selectEvent(item) {
    this.item = item.id
    this.sharedService.nextMessage4({item: this.item, type: this.type})
  }
 
  onChangeSearch(val: string) {
    if(val.length > 2){
      this._auth.logs_prefill(this.type,val).subscribe((res:any)=>{
        this.data = res
      },(err)=>{
        console.log(err)
      })
    }
   
  }

  getKeyword(){
    if(this.type === "party"){
      return "item.display_name"
    }else{
      return "item.program_name"
    }
  }

  
  onFocused(e){
    // do something when input is focused
  }

}
