import { Component, OnInit } from "@angular/core";
import Chart from "chart.js";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AuthService } from "app/services/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from "@angular/material/table";
import { PushService } from "app/services/push.service";
declare var $: any;

@Component({
  selector: "app-funder-dash",
  templateUrl: "./funder-dash.component.html",
  styleUrls: ["./funder-dash.component.css"]
})
export class FunderDashComponent implements OnInit {
  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public chartActivity;

  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  gradientStroke: any;

  portfolio = [];
  chartActivity2: any;
  total_amount: any;
  data: any;
  total_investments_outstanding: any;
  total_cash: any;
  current_programs = [];
  active_programs = [];
  portfoloio_participation_program = [];
  portfoloio_participation_data = [];
  portfoloio_performance_cash = [];
  portfoloio_performance_yield = [];
  portfoloio_performance_month = [];
  portfoloio_performance_investment = [];

  portfoloio_performance_historical_month = [];
  claimed_programs_data = [];
  claimed_programs_name = [];
  available_programs_data = [];
  available_programs_name = [];
  portfolio_summary = [];
  load: boolean;
  id: any;
  perm_id: any;
  // graph_overall_portfolio_performance_historical=[
  //   {
  //     month: [
  //       'Jan',
  //       'Feb',
  //       'March'
  //     ],
  //     program: [
  //       {
  //         label: 'Refinitive 123',
  //         data: [
  //           {
  //             amount: 475000,
  //             month: 'Jan'
  //           },
  //           {
  //             amount: 575000,
  //             month: 'Feb'
  //           },
  //           {
  //             amount: 425000,
  //             month: 'March'
  //           }
  //         ],
  //         stack: 'available'
  //       },
  //       {
  //         label: 'Refinitive If-2',
  //         data: [
  //           {
  //             amount: 575000,
  //             month: 'Jan'
  //           },
  //           {
  //             amount: 475000,
  //             month: 'Feb'
  //           },
  //           {
  //             amount: 425000,
  //             month: 'March'
  //           }
  //         ],
  //         stack: 'available'
  //       },
  //       {
  //         label: 'Refinitive 123',
  //         data: [
  //           {
  //             amount: 575000,
  //             month: 'Jan'
  //           },
  //           {
  //             amount: 0,
  //             month: 'Feb'
  //           },
  //           {
  //             amount: 0,
  //             month: 'March'
  //           }
  //         ],
  //         stack: 'claimed'
  //       },
  //       {
  //         label: 'Refinitive If-2',
  //         data: [
  //           {
  //             amount: 475000,
  //             month: 'Jan'
  //           },
  //           {
  //             amount: 0,
  //             month: 'Feb'
  //           },
  //           {
  //             amount: 425000,
  //             month: 'March'
  //           }
  //         ],
  //         stack: 'claimed'
  //       }
  //     ]
  //   }
  // ]
  graph_overall_portfolio_performance_historical = [];
  datasets = [];
  pallete = ["#4acccd", "#fcc468", "#797c7d", "rgb(51, 102, 0)"];
  spin_show: boolean;
  color: string;
  http: any;
  dataSource: any;
  sort: any;
  paginator: any;
  loading: boolean;
  btn_color: string;
  party_name: any;
  party_name_len: any;
  data1: any;
  dashboard_deployed_amt: any;
  deployed_total: number;
  programs_list: any;

  constructor(
    private router: Router,
    private _auth: AuthService,
    private SpinnerService: NgxSpinnerService,
    private push: PushService
  ) {
    window.location.hash = "redirect";
    window.location.hash = "redirect"; //again because google chrome don't insert first hash into history
    window.onhashchange = function () {
      window.location.hash = "redirect";
    };
  }

  ngOnInit() {
    this.SpinnerService.show();
    this._auth
      .getProgramList()
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.SpinnerService.hide();
          this.programs_list = res;
          this.programs_list = this.programs_list.filter(t => t.status == "IN-PROGRESS");

          this.loading = true;
        }
      });

    this._auth
      .funder_details(sessionStorage.getItem("seller_id"))
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.SpinnerService.hide();

          this.data = res;
          for (var i = 0; i < this.data.length; i++) {
            this.total_amount = this.data[i].total_amount == null ? 0 : this.data[i].total_amount;
            this.total_investments_outstanding =
              this.data[i].total_investments_outstanding == null ? 0 : this.data[i].total_investments_outstanding;
            this.total_cash = this.data[i].total_cash == null ? 0 : this.data[i].total_cash;

            this.current_programs = this.data[i].current_programs;
            this.perm_id = this.data[i].perm_id;
            this.active_programs = this.data[i].active_programs;
            this.portfolio_summary = this.data[i].portfolio_summary;
            for (var j = 0; j < this.data[i].graph_portfolio_participation.length; j++) {
              this.portfoloio_participation_program.push(this.data[i].graph_portfolio_participation[j].program_name);
              this.portfoloio_participation_data.push(parseFloat(this.data[i].graph_portfolio_participation[j].invoice_value));
            }
            this.canvas = document.getElementById("chartEmail");
            this.ctx = this.canvas.getContext("2d");

            this.chartEmail = new Chart(this.ctx, {
              type: "doughnut",
              data: {
                labels: this.portfoloio_participation_program,
                datasets: [
                  {
                    label: "Emails",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    backgroundColor: [
                      // '#e3e3e3',
                      "#4acccd",
                      "#fcc468",
                      "#797c7d"
                      // '#ef8157'
                    ],
                    borderWidth: 0,
                    data: this.portfoloio_participation_data
                  }
                ]
              },

              options: {
                aspectRatio: 2.05,

                legend: {
                  display: true
                },

                pieceLabel: {
                  render: "percentage",
                  fontColor: ["white"],
                  precision: 2
                },

                tooltips: {
                  enabled: true
                },

                scales: {
                  yAxes: [
                    {
                      ticks: {
                        display: false
                      },
                      gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                      }
                    }
                  ],

                  xAxes: [
                    {
                      barPercentage: 1.6,
                      gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent"
                      },
                      ticks: {
                        display: false
                      }
                    }
                  ]
                }
              }
            });

            for (var j = 0; j < this.data[i].graph_overall_portfolio_performance.length; j++) {
              this.portfoloio_performance_cash.push(parseInt(this.data[i].graph_overall_portfolio_performance[j].cash_on_hand));
              this.portfoloio_performance_investment.push(
                parseInt(this.data[i].graph_overall_portfolio_performance[j].investment)
              );
              this.portfoloio_performance_month.push(this.data[i].graph_overall_portfolio_performance[j].month);
              this.portfoloio_performance_yield.push(parseInt(this.data[i].graph_overall_portfolio_performance[j].yield));
            }
            var flags = {};
            this.portfoloio_performance_month = this.portfoloio_performance_month.filter(x => {
              if (flags[x]) {
                return false;
              }
              flags[x] = true;
              return true;
            });

            console.log("hello");
            this.chartColor = "#FFFFFF";

            this.canvas = document.getElementById("chartActivity");
            this.ctx = this.canvas.getContext("2d");

            this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
            this.gradientStroke.addColorStop(0, "#80b6f4");
            this.gradientStroke.addColorStop(1, this.chartColor);

            this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
            this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
            this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");

            this.chartActivity = new Chart(this.ctx, {
              type: "bar",
              data: {
                labels: this.portfoloio_performance_month,
                datasets: [
                  {
                    label: "Investment",
                    type: "bar",
                    borderColor: "#fcc468",
                    fill: true,
                    backgroundColor: "#fcc468",
                    hoverBorderColor: "#fcc468",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 0,
                    data: [3232, 3252, 4452, 4472, 4989],
                    order: 1
                  },
                  {
                    label: "Cash On Hand",
                    type: "bar",
                    borderColor: "#4cbdd7",
                    fill: true,
                    backgroundColor: "#4cbdd7",
                    hoverBorderColor: "#4cbdd7",
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    borderWidth: 0,
                    data: [321, 311, 371, 361, 851],
                    // yAxisID: "id1",
                    order: 1
                  },
                  {
                    label: "Yield",
                    borderColor: "#919294",
                    fill: false,
                    backgroundColor: "#919294",
                    hoverBorderColor: "#919294",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    pointBackgroundColor: "rgba(244, 81, 30, 0.5)",
                    pointBorderColor: "rgba(244, 81, 30, 0.8)",
                    showLine: true,
                    pointRadius: 4,
                    pointHoverRadius: 4,
                    borderWidth: 4,
                    data: [6.0, 6.25, 6.35, 6.3, 6.4],
                    type: "line",
                    lineTension: 0,
                    // order: 1,
                    yAxisID: "id2"
                  }
                ]
              },
              options: {
                tooltips: {
                  tooltipFillColor: "rgba(0,0,0,0.5)",
                  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipFontSize: 14,
                  tooltipFontStyle: "normal",
                  tooltipFontColor: "#fff",
                  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipTitleFontSize: 14,
                  tooltipTitleFontStyle: "bold",
                  tooltipTitleFontColor: "#fff",
                  tooltipYPadding: 6,
                  tooltipXPadding: 6,
                  tooltipCaretSize: 8,
                  tooltipCornerRadius: 6,
                  tooltipXOffset: 10
                },

                legend: {
                  display: true
                },
                scales: {
                  yAxes: [
                    {
                      position: "left",
                      ticks: {
                        fontColor: "#9f9f9f",
                        fontStyle: "bold",
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 0
                      },
                      gridLines: {
                        zeroLineColor: "transparent",
                        display: true,
                        drawBorder: false,
                        color: "#9f9f9f"
                      },
                      id: "id1"
                    },
                    {
                      position: "right",
                      ticks: {
                        fontColor: "#9f9f9f",
                        fontStyle: "bold",
                        beginAtZero: false,
                        maxTicksLimit: 5,
                        padding: 0
                      },
                      gridLines: {
                        zeroLineColor: "transparent",
                        display: true,
                        drawBorder: false,
                        color: "#9f9f9f"
                      },
                      id: "id2"
                    }
                  ],
                  xAxes: [
                    {
                      barPercentage: 1,
                      gridLines: {
                        zeroLineColor: "white",
                        display: false,

                        drawBorder: false,
                        color: "transparent"
                      },
                      ticks: {
                        padding: 0,
                        fontColor: "#9f9f9f",
                        fontStyle: "bold"
                      }
                    }
                  ]
                }
              }
            });

            for (var k = 0; k < this.data[i].graph_overall_portfolio_performance_historical.month.length; k++) {
              this.portfoloio_performance_historical_month.push(
                this.data[i].graph_overall_portfolio_performance_historical.month[k] + " (Available, Claimed)"
              );
            }
            var count = -1;
            var count1 = -1;
            for (var k = 0; k < this.data[i].graph_overall_portfolio_performance_historical.program.length; k++) {
              var data = [];
              for (var l = 0; l < this.data[i].graph_overall_portfolio_performance_historical.program[k].data.length; l++) {
                if (
                  this.data[i].graph_overall_portfolio_performance_historical.month[l] ==
                  this.data[i].graph_overall_portfolio_performance_historical.program[k].data[l].month
                ) {
                  data.push(this.data[i].graph_overall_portfolio_performance_historical.program[k].data[l].amount);
                }
              }

              if (this.data[i].graph_overall_portfolio_performance_historical.program[k].stack === "available") {
                // console.log(this.data[i].graph_overall_portfolio_performance_historical.program[k].label,this.pallete[++count])
                this.datasets.push({
                  label: this.data[i].graph_overall_portfolio_performance_historical.program[k].label,
                  data: data,
                  backgroundColor: this.pallete[++count],
                  stack: this.data[i].graph_overall_portfolio_performance_historical.program[k].stack
                });
              }

              if (this.data[i].graph_overall_portfolio_performance_historical.program[k].stack === "claimed") {
                // console.log(k, ++count1)
                this.datasets.push({
                  label: this.data[i].graph_overall_portfolio_performance_historical.program[k].label,
                  data: data,
                  backgroundColor: this.pallete[++count1],
                  stack: this.data[i].graph_overall_portfolio_performance_historical.program[k].stack
                });
              }
            }
            this.canvas = document.getElementById("chartActivity2");
            this.ctx = this.canvas.getContext("2d");

            this.chartActivity2 = new Chart(this.ctx, {
              type: "bar",
              data: {
                labels: this.portfoloio_performance_historical_month,
                datasets: this.datasets
              },
              options: {
                tooltips: {
                  tooltipFillColor: "rgba(0,0,0,0.5)",
                  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipFontSize: 14,
                  tooltipFontStyle: "normal",
                  tooltipFontColor: "#fff",
                  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                  tooltipTitleFontSize: 14,
                  tooltipTitleFontStyle: "bold",
                  tooltipTitleFontColor: "#fff",
                  tooltipYPadding: 6,
                  tooltipXPadding: 6,
                  tooltipCaretSize: 8,
                  tooltipCornerRadius: 6,
                  tooltipXOffset: 10
                },

                legend: {
                  display: true
                },
                scales: {
                  yAxes: [
                    {
                      stacked: true,
                      ticks: {
                        fontColor: "#9f9f9f",
                        fontStyle: "bold",
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 0
                      },
                      gridLines: {
                        zeroLineColor: "transparent",
                        display: true,
                        drawBorder: false,
                        color: "#9f9f9f"
                      }
                    }
                  ],
                  xAxes: [
                    {
                      stacked: true,
                      barPercentage: 1,
                      gridLines: {
                        zeroLineColor: "white",
                        display: false,

                        drawBorder: false,
                        color: "transparent"
                      },
                      ticks: {
                        padding: 0,
                        fontColor: "#9f9f9f",
                        fontStyle: "bold"
                      }
                    }
                  ]
                }
              }
            });

            // }
          }
        }
      });

    var cardStatsMiniLineColor = "#fff",
      cardStatsMiniDotColor = "#fff";
  }

  ngAfterViewInit() {
    $("#datatable").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });
  }

  view_program(id, name) {
    this.router.navigate(["/programView", id, name]);
  }

  showSwal(type) {
    if (type == "basic") {
      Swal.fire({
        title: "Here's a message!",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success"
      });
    } else if (type == "success-message") {
      Swal.fire({
        title: "Thank You",
        text: "Our Sales Team Will Contact You At The Earliest",
        buttonsStyling: false,
        confirmButtonClass: "btn btn-success",
        type: "success"
      });
    }
  }

  checked(id) {
    this.id = id;
  }
  participate(id) {
    this.router.navigate(["/participate", id]);
  }

  get_cash(iv_amt, amt_fund) {
    return parseInt(amt_fund) - parseInt(iv_amt);
  }

  get_util(iv_amt, amt_fund) {
    return ((parseInt(iv_amt) / parseInt(amt_fund)) * 100).toFixed(2);
  }

  onChange(deviceValue) {
    if (deviceValue != 'Select Report') {
      window.open('http://localhost:3000/download/qb/' + deviceValue, '_blank')
      //this._auth.download_report(deviceValue);
      //console.log(deviceValue);
    }

  }

}
