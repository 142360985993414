import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { AuthService } from "app/services/auth.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

declare var $: any;


@Component({
  selector: 'app-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.css']
})
export class AuditLogsComponent implements OnInit {
  @Input() id:number
  data: [];

  displayedColumns3: string[] = ["user_name", "invoice_number", "notes", "created", "details"];
  dataSource3: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor( private _auth: AuthService,) { }

  ngOnInit(): void {
   
  }

  auditData(){
    this._auth
    .search_type("program", this.id)
    .toPromise()
    .then(
      (res: any) => {
      
        let data = res;
        this.dataSource3 = new MatTableDataSource(data);
        this.dataSource3.sort = this.sort;
        this.dataSource3.paginator = this.paginator;
      },
      err => {
        console.log(err);
      }
    );
  }

  display(data) {
    this.data = data.message === undefined ? JSON.stringify(data, null, "\t") : data.message;
    $("#view_audit").modal("show");
  }

}
