import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import * as jwt_decode from "jwt-decode";
import { PushService } from "app/services/push.service";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
declare interface User {
  text?: string;
  email?: string; //  must be valid email format
  password?: string; // required, value must be equal to confirm password.
  confirmPassword?: string; // required, value must be equal to password.
  number?: number; // required, value must be equal to password.
  url?: string;
  idSource?: string;
  idDestination?: string;
}

@Component({
  selector: "app-layout",
  templateUrl: "./auth-layout.component.html"
})
export class AuthLayoutComponent implements OnInit {
  test : Date = new Date();
  registerForm: FormGroup;
  submitted: boolean;
  public register: User;
  returnUrl: any;
  hide: boolean = true;
  logo_url: string;
  page_name: string;
  fav_icon: string;

  constructor(
    private formBuilder: FormBuilder,
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private env_serv : EnvService
  ) {

    if ((this.env_serv.Page_Name === 'Refinitiv')) {
      this.logo_url = './assets/img/refinitiv-logo.png';
    } else {
      this.logo_url = './assets/img/sb-logo.png';
    }
  }

  ngOnInit(): void {
    this.register = {
      email: "",
      password: ""
    };
  }

  save=(data: User, isValid: boolean)=>{
    this.hide = false;
    if (isValid) {
      this._auth.getUser(JSON.stringify(data)).subscribe(async(res:any) => {
          if (res) {
            var token_val = jwt_decode(res["token"]);
            this.hide = true;
            sessionStorage.setItem("logo", token_val["party"].logo);
            window.sessionStorage.setItem("login", token_val["user"].user_login);
            window.sessionStorage.setItem("token", res["token"]);
            window.sessionStorage.setItem("id", token_val["user"].role_id);
            window.sessionStorage.setItem("seller_id", token_val["user"].user_party_id);
            window.sessionStorage.setItem("company_name", token_val["party"].display_name);
            window.sessionStorage.setItem("credit_limit", token_val["party"].credit_limit);
            window.sessionStorage.setItem("user_id", token_val["user"].user_id);
            var role = parseInt(sessionStorage.getItem("id"));
            this._auth.validUser(role);

            if (token_val["user"].active === true) {
              if (role === 1) {
                sessionStorage.setItem("admin", "true");
                this.push.showNotification("Successfully Logged In", "success");
                
                this.router.navigate(["/admin"]);
              } else if (role === 2) {
                if (sessionStorage.getItem("seller_id") != "ADMIN") {
                  this._auth
                    .getStatus(sessionStorage.getItem("seller_id"))
                    .toPromise()
                    .then(res => {
                      sessionStorage.setItem("status", res["user_status"]);
                      if (res["user_status"] !== "APPROVED") {
                        this.router.navigate(["/kyc-process"]);
                      } else {
                        this.router.navigate(["/invoice"]);
                      }
                    });
                }
              } else if (role === 3) {
                if (sessionStorage.getItem("seller_id") != "ADMIN") {
                  this._auth
                    .getStatus(sessionStorage.getItem("seller_id"))
                    .toPromise()
                    .then((res:any) => {
                      let status = res["user_status"]
                      console.log(status)
                      window.sessionStorage.setItem("status", status);
                      if(res["user_status"] !== "APPROVED") {
                        this.router.navigate(["/Buyer-Onboarding"]);
                      } else {
                        this.router.navigate(["/buyer"]);
                      }
                    });
                  this.push.showNotification("Successfully Logged In", "success");
                }
              } else if (role === 4) {
                sessionStorage.setItem("b_info_chk", token_val["party"].bank_verification);
                sessionStorage.setItem("ky_info_chk", token_val["party"].kyc_verification);
                if (sessionStorage.getItem("seller_id") != "ADMIN") {
                  this._auth
                    .getStatus(sessionStorage.getItem("seller_id"))
                    .toPromise()
                    .then(
                      res => {
                        sessionStorage.setItem("status", res["user_status"]);
                        if(res["user_status"] !== "APPROVED") {
                        
                          this.router.navigate(["/Funder-Onboarding"]);
                        } else {
                          if (sessionStorage.getItem("program_id_read") != null) {
                            this.router.navigate(["/participate/", sessionStorage.getItem("program_id_read")]);
                          } else {
                            this.router.navigate(["/funder"]);
                          }
                        } 
                      },
                      (err: any) => {
                        this.push.showNotification(err.error.errMsg, "danger");
                      }
                    );
                  this.push.showNotification("Successfully Logged In", "success");
                }
              } else {
                this.router.navigate(["/login"]);
              }
            }
          }
        },
        err => {
          if (err.status === 404) {
            this.push.showNotification("Invalid Credentials", "danger");
          }
        }
      );
    }
  }

 
}
