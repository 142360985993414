import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PushService } from 'app/services/push.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
  constructor(private _router: Router,private push: PushService) { }

  ngOnInit() {
    this._router.navigate(['/']);
    this.push.showNotification("Successfully Logged Out", "success")
      window.sessionStorage.clear();
      window.localStorage.clear();
      
  }

}
