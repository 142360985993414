import { Component, OnInit } from '@angular/core';
import { FileValidator } from 'app/shared/filevalidator.directive';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';

@Component({
  selector: 'app-invoice-settlement',
  templateUrl: './invoice-settlement.component.html',
  styleUrls: ['./invoice-settlement.component.css']
})
export class InvoiceSettlementComponent implements OnInit {
  CompanyForm: any;
  invoice_file: any;
  file: File;

  constructor(private fb: FormBuilder, private _auth: AuthService, private push:  PushService) { }

  ngOnInit(): void {
    this.CompanyForm = this.fb.group({
   
      invoice: ["", FileValidator.validate],

    });

  }

  invoice_upload(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.invoice_file = event.target.files[0].name;
      var type = this.file.name.split('.')
      if(type[1] != "csv"){
        this.push.showNotification('Incorrect File Type', 'danger')
        this.CompanyForm.reset();
      }

    }
  }

  submit_invoice(){
    this._auth
  .invoice_settlement(this.file)
  .toPromise()
  .then((res: any) => {
    if (res) {
      this.push.showNotification("File Uploaded Successfully",'success')
    }
  },(err)=>{
    this.push.showNotification("Failed To Upload File",'danger')
  });
}

}
