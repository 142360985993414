import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { SharedService } from "app/services/shared.service";
import { trim } from "jquery";
declare interface User {
  text: "";
  email: "";
  idSource: "";
  invite_funder: "";
  party_id: "";
}
@Component({
  selector: "app-funder-invite",
  templateUrl: "./funder-invite.component.html",
  styleUrls: ["./funder-invite.component.css"]
})
export class FunderInviteComponent implements OnInit {
  submitted = false;
  Select: FormGroup;
  company_info_nav_link: string;
  company_info_tab: string;
  option_nav_link: string;
  option_info_tab: string;
  data_nav_link: string;
  data_info_tab: string;
  user_journey_tab: string;
  data: any;
  btn_disabled: boolean;
  id: string;
  email: any;
  name: any;
  acra: any;
  world_check: any;
  perm_id_tab: any;
  bank_info: any;
  documents_tab: any;
  credit_documents: any;
  data2 = [];
  data3 = [];
  passbook_value = true;
  audited_financial_statments: boolean;
  user_type: string;
  program_categories: any;
  category_value: any;
  program_type_val: any;
  program_type_id: any;
  display: boolean = false;
  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {

    this.user_type = window.location.href.split('/')[3] === 'funders' ? 'funder' : null;
    
  }

  public typeValidation: User;

  public login: User;

  inputvalue = "";
  director_details = true;
  display_name: string;
  admin_user_id: string;
  invite_funder: false;

  files = "";
  selectedFiles: any;
  Invite: any;

  ngOnInit() {
    
    this.company_info_tab = "tab-pane active";
  
    this.user_journey_tab = "tab-pane";


    this._auth.program_categories().subscribe(async(res: any) => {
      console.log(res);
      this.display = true;
      this.program_categories = await res
      this.Invite = this.fb.group({
        display_name: ["", Validators.required],
        admin_user_id: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$"
            )
          ]
        ],
        company_registration_no: ["", Validators.required],
        program_type_id: ["", Validators.required],
        category_id: ["", Validators.required],
        acra:false,
        is_company: [true, Validators.required],
        country_name:["",Validators.required]

      });
    })
  }

  handleFileInput(ob) {
    this.files = ob;
    // console.log(this.files);
  }

  openFile() {
    // console.log('select')
    document.querySelector("input").click();
  }

  handle(e) {
    // console.log('Change input file')
  }

  call() {
    this.submitted = true;
    if (this.Invite.invalid) {
      this.Invite.markAllAsTouched();
      this.push.showNotification("Please Fill The Required Fields", "danger");
      return;
    }

    this.data = {
      display_name: this.Invite.value.display_name,
      admin_user_id: this.Invite.value.admin_user_id,
      is_company: this.Invite.value.is_company === true ? true : false,
      company_registration_no: this.Invite.value.company_registration_no.trim(),
      user_type: this.user_type,
      acra: this.Invite.value.acra,
      program_type_id: this.Invite.value.program_type_id,
      program_category: this.Invite.value.category_id,
      country_name: this.Invite.value.country_name

    };

    this._auth.invite_user(JSON.stringify(this.data)).subscribe((res: any) => {
      console.log(res)
      this.sharedService.nextMessage1(res);
      this.company_info_tab = "tab-pane";
      // this.option_nav_link = "nav-link";
      // this.option_info_tab = "tab-pane";
      // this.data_nav_link = "nav-link";
      // this.data_info_tab = "tab-pane";
    
      this.user_journey_tab = "tab-pane active";
      this.btn_disabled = false;
      this.push.showNotification(res.msg,'success')
    },(err)=>{
      this.push.showNotification("KYC Defination Must Be Defined", "danger")
    })
  


    
  }

  // option() {
  //   var data = {};
  //   var data1 = {};
  //   for (var i in this.data2) {
  //     for (let j in this.data2[i]) {
  //       data[j] = this.data2[i][j];
  //     }
  //   }

  //   for (var i in this.data3) {
  //     for (let j in this.data3[i]) {
  //       data1[j] = this.data3[i][j];
  //     }
  //   }

  //   this.Invite.value.display_name = this.titleCase(this.Invite.value.display_name).trim();

  //   this.Invite.value.admin_user_id = this.Invite.value.admin_user_id.toLowerCase().trim();

  //   this.data = {
  //     display_name: this.Invite.value.display_name,
  //     admin_user_id: this.Invite.value.admin_user_id,

  //     is_company: this.Invite.value.is_company === true ? true : false,
  //     company_registration_no: this.Invite.value.company_registration_no.trim(),
  //     funder_documents: {
  //       acra: this.Invite.value.acra,
  //       world_check: true,
  //       perm_id: true,
  //       document_verification: this.Invite.value.document_verification,
  //       documents_display: data,
  //       bank_verification: this.Invite.value.bank_verification,
  //       bank_docs_display: data1
  //     }
  //   };

  //   this.company_info_nav_link = "nav-link";
  //   this.company_info_tab = "tab-pane";
  //   this.option_nav_link = "nav-link";
  //   this.option_info_tab = "tab-pane";
  //   this.data_nav_link = "nav-link";
  //   this.data_info_tab = "tab-pane";
  //   this.user_journey_tab = "tab-pane active";
  //   this.btn_disabled = false;

    
  // }

  titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)(\w)/g, function(x) {
      return x.toUpperCase();
    });
  }

  onSubmit(value: any): void {
    // console.log(value);
  }
  get_program_type() {
    this.category_value = parseInt(this.Invite.value.category_id)
    this._auth.get_program_types(parseInt(this.Invite.value.category_id)).subscribe(async (res: any) => {
      this.program_type_val = await res[`${this.program_categories.filter(x=>x.id === this.category_value)[0].name.toLowerCase()}`]    },(err)=>{
      this.push.showNotification("Something Went Wrong", "danger")
    })
  }

  check_email() {
    if (this.Invite.value.admin_user_id != "") {
      this._auth.check_email(this.Invite.value.admin_user_id.trim()).subscribe((res: any) => {
        if (res["active"] === true) {
          this.push.showNotification("Email ID Already Exist", "danger");
          this.Invite.get("admin_user_id").reset();
        } else {
        }
      });
    }
  }

  check_uen() {
    if (this.Invite.value.company_registration_no != "") {
      this._auth.check_uen(this.Invite.value.company_registration_no.trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.Invite.get("company_registration_no").reset();
        }
      );
    }
  }

  check_username() {
    if (this.Invite.value.display_name != "") {
      this._auth.check_username(this.titleCase(this.Invite.value.display_name).trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.Invite.get("display_name").reset();
        }
      );
    }
  }
}
