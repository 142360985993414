import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

@Component({
  selector: 'app-buyer-participate',
  templateUrl: './buyer-participate.component.html',
  styleUrls: ['./buyer-participate.component.css']
})
export class BuyerParticipateComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;  

  displayedColumns2: string[] = [
    "program_name",
    "lead_by",
    "start_date",
    "end_date",
    "program_currency",
    "interest_pct",
    "service_charges_pct",
    "status"
  ];

  dataSource2: MatTableDataSource<any>;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private push: PushService,
  ) { }

  ngOnInit(): void {
   
  }

  buyerParticipate(){
    this._auth.getPermId(this._route.snapshot.paramMap.get("id")).toPromise().then(res => {
      if(res){
        this._auth.participated_programs("buyer", this._route.snapshot.paramMap.get("id")).subscribe(
          (res: any) => {
            console.log(res);
            this.dataSource2 = new MatTableDataSource(res);
    
            this.dataSource2.sort = this.sort;
            this.dataSource2.paginator = this.paginator;
          },
          err => {
            this.push.showNotification(err.error.errMsg, "danger");
          }
        );
      }
    })
  }

}
