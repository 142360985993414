import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { PushService } from 'app/services/push.service';
// import * as $ from 'jquery';

declare var swal:any;
declare var $:any;
@Component({
  selector: 'app-individual-kyc',
  templateUrl: './individual-kyc.component.html',
  styleUrls: ['./individual-kyc.component.css']
})
export class IndividualKycComponent implements OnInit {
  personal_info_nav_link: string;
  personal_info_tab: string;
  kyc_nav_link: string;
  kyc_status_tab: string;
  credit_check_nav_link: string;
  doc_tab: string;
  bank_info_nav_link: string;
  approval_tab: string;
  worldcheck_nav_link: string;
  star_mine_nav_link: string;
  true_25: boolean;
  true_50: boolean;
  true_75: boolean;
  true_100: boolean;
  val: boolean;
  disp: boolean;
  true_0: boolean;
  kyc_tab: string;
  credit_check_tab: string;
  bank_info_tab: string;

 
  constructor(private _auth: AuthService, private router: Router, private push : PushService, private fb: FormBuilder,) { }
  ngOnInit(){
    this.personal_info_nav_link= "nav-link active"
          this.personal_info_tab = "tab-pane active"
          this.kyc_nav_link = "nav-link"
          this.kyc_tab = "tab-pane"
          this.credit_check_nav_link = "nav-link"
          this.credit_check_tab = "tab-pane"
          this.bank_info_nav_link = "nav-link"
      
          this.bank_info_tab = "tab-pane"
          this.worldcheck_nav_link = "nav-link"
          this.star_mine_nav_link = "nav-link"
          this.true_0 = true
          this.true_25 = false
          this.true_50 = false
          this.true_75 = false
          this.true_100 = false
          this.val = false
          this.disp =false
//     if(sessionStorage.getItem("status") === "TERMS"){
//       this.check_kyc_nav_link = "nav-link"
//       this.check_kyc_tab = "tab-pane"
//       this.kyc_status_nav_link = "nav-link"
//       this.kyc_status_tab = "tab-pane"
//       this.doc_nav_link = "nav-link"
//       this.doc_tab = "tab-pane"
//       this.approval_nav_link = "nav-link active"
//       this.approval_tab = "tab-pane active"
//       this.true_25 = false
//       this.true_50 = false
//       this.true_75 = true
//       this.true_100 = false
//       this.val = false
//       this.disp =false
//     }else if(sessionStorage.getItem("status") === "RECEIVED"){
//         this.check_kyc_nav_link = "nav-link"
//         this.check_kyc_tab = "tab-pane"
//         this.kyc_status_nav_link = "nav-link active"
//         this.kyc_status_tab = "tab-pane active"
//         this.doc_nav_link = "nav-link"
//         this.doc_tab = "tab-pane"
//         this.approval_nav_link = "nav-link"
//         this.approval_tab = "tab-pane"
//         this.true_25 = true
//         this.true_50 = false
//         this.true_75 = false
//         this.true_100 = false
//         this.disp = true;
//     }else{
//       this.check_kyc_nav_link = "nav-link"
//       this.check_kyc_tab = "tab-pane"
//       this.kyc_status_nav_link = "nav-link"
//       this.kyc_status_tab = "tab-pane"
//       this.doc_nav_link = "nav-link active"
//       this.doc_tab = "tab-pane active"
//       this.approval_nav_link = "nav-link"
//       this.approval_tab = "tab-pane"
//       this.true_25 = true
//       this.true_50 = false
//       this.true_75 = false
//       this.true_100 = false
//       this.disp = false
//     }

//   this.id = setInterval(() => {
//     this.status()
// }, 10000);

//   if(sessionStorage.getItem("status") === "RECEIVED"){
//     this.disp = true;
//   }




    }




    ngAfterViewInit() {

        $( window ).resize( () => { $('.card-wizard').each(function(){

            const $wizard = $(this);
            const index = $wizard.bootstrapWizard('currentIndex');
            let $total = $wizard.find('.nav li').length;
            let  $li_width = 100/$total;

            let total_steps = $wizard.find('.nav li').length;
            let move_distance = $wizard.width() / total_steps;
            let index_temp = index;
            let vertical_level = 0;

            let mobile_device = $(document).width() < 600 && $total > 3;

            if(mobile_device){
                move_distance = $wizard.width() / 2;
                index_temp = index % 2;
                $li_width = 50;
            }

            $wizard.find('.nav li').css('width',$li_width + '%');

            let step_width = move_distance;
            move_distance = move_distance * index_temp;

            let $current = index + 1;

            if($current == 1 || (mobile_device == true && (index % 2 == 0) )){
                move_distance -= 8;
            } else if($current == total_steps || (mobile_device == true && (index % 2 == 1))){
                move_distance += 8;
            }

            if(mobile_device){
                let x: any = index / 2;
                vertical_level = parseInt(x);
                vertical_level = vertical_level * 38;
            }

            $wizard.find('.moving-tab').css('width', step_width);
            $('.moving-tab').css({
                'transform':'translate3d(' + move_distance + 'px, ' + vertical_level +  'px, 0)',
                'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
            });

            $('.moving-tab').css({
                'transition': 'transform 0s'
            });
            });
        });
    }

    ngOnDestroy(){
     
    }

    tab_change(){
      this.personal_info_nav_link= "nav-link"
          this.personal_info_tab = "tab-pane"
          this.kyc_nav_link = "nav-link active"
          this.kyc_tab = "tab-pane active"
          this.credit_check_nav_link = "nav-link"
          this.credit_check_tab = "tab-pane"
          this.bank_info_nav_link = "nav-link"
      
          this.bank_info_tab = "tab-pane"
          this.worldcheck_nav_link = "nav-link"
          this.star_mine_nav_link = "nav-link"
          this.true_0 = false
          this.true_25 = true
          this.true_50 = false
          this.true_75 = false
          this.true_100 = false
          this.val = false
          this.disp =false

          document.getElementById("main-content").scrollIntoView();
    }

    credit_check(){
      this.personal_info_nav_link= "nav-link"
          this.personal_info_tab = "tab-pane"
          this.kyc_nav_link = "nav-link"
          this.kyc_tab = "tab-pane"
          this.credit_check_nav_link = "nav-link active"
          this.credit_check_tab = "tab-pane active"
          this.bank_info_nav_link = "nav-link"
      
          this.bank_info_tab = "tab-pane"
          this.worldcheck_nav_link = "nav-link"
          this.star_mine_nav_link = "nav-link"
          this.true_0 = false
          this.true_25 = false
          this.true_50 = true
          this.true_75 = false
          this.true_100 = false
          this.val = false
          this.disp =false
          document.getElementById("main-content").scrollIntoView();
    }

    bank_info(){
      this.personal_info_nav_link= "nav-link"
          this.personal_info_tab = "tab-pane"
          this.kyc_nav_link = "nav-link"
          this.kyc_tab = "tab-pane"
          this.credit_check_nav_link = "nav-link"
          this.credit_check_tab = "tab-pane"
          this.bank_info_nav_link = "nav-link active"
      
          this.bank_info_tab = "tab-pane active"
          this.worldcheck_nav_link = "nav-link"
          this.star_mine_nav_link = "nav-link"
          this.true_0 = false
          this.true_25 = false
          this.true_50 = false
          this.true_75 = true
          this.true_100 = false
          this.val = false
          this.disp =false

          document.getElementById("main-content").scrollIntoView();
    }
  
}
