import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder } from "@angular/forms";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  logo: string;
  kyc_status: any;
  title: any;
  suffix: any;
  full_name: any;
  email: any;
  phone: any;
  mobile: any;
  website: any;
  street: any;
  city: any;
  zip: any;
  state: any;
  perm_id: any;
  country: any;
  uen: any;
  party_id: any;
  buyer_id: any;
  name: any;
  status: any;
  date: any;
  acc_type: any;
  acc_no: any;
  bank_add: any;
  branch_code: any;
  CBS_MLCB: any;
  GST_F5_Statment: any;
  GST_Statment: any;
  audited_financial_statements: any;
  boardResolution: any;
  directorDetails: any;
  personal_notice_assessment: any;
  shareholderDetails: any;
  registered_office: any;
  passbook: any;
  checkbook: any;
  month6_Statment: any;
  bank_name: any;
  AR_PR: any;
  upload_url: any;
  credit_check: any;
  bank_info: any;
  designation: any;
  shareholder_doc: any;
  address: any;
  id: any;
  pid: any;
  credit_docs: any;
  bank_docs: any;
  acra: any;
  additional_documents: any;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private envService: EnvService,
    private _router: ActivatedRoute
  ) {
    this.upload_url = this.envService.File_upload_seller;
  }
  ngOnInit() {
    this.id = sessionStorage.getItem("seller_id");
    this.sharedService.nextMessage2(this.id);
   
  }

  download(file_name) {
    window.location.href = this.upload_url + "/" + file_name;
  }

  perm_id_display(id: string): string {
    if (id != undefined) {
      var spl = id.split("-");

      return "B" + spl[1];
    }
  }
}
