
import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { AuthService } from 'app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
declare const $: any;
declare interface DataTable {

  dataRows: string[][];

}
@Component({
  selector: 'app-buyer-view-program',
  templateUrl: './buyer-view-program.component.html',
  styleUrls: ['./buyer-view-program.component.css']
})
export class BuyerViewProgramComponent implements OnInit {
  public canvas: any;
  public dataTable: DataTable;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public chartActivity;

  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  gradientStroke: any;
  chartActivity3: any;


  data1 = {
    program_id: {},
    program_name: {},
    program_type_id: {},
    buyer_id: {},
    seller_id: {},
    program_status: {},
    seller_max_limit: {},
    program_max_limit: {},
    max_funding_pct: {},
    service_charges_pct: {},
    interest_pct: {},
    max_inv_credit_days: {},
    with_recourse: {},
    program_currency: {},
    program_value: {},
    status: {},
    alert_threshold_pct: {},
    additional_transaction_fees: {}
  };
  funder_list_array = {};
  fund_list: any;
  record_length: any;
  loading: boolean;
  data: [];
  file: File;
  hide: boolean;
  import_btn: boolean;
  deployed_amt: number;
  program_list: any;
  bid: string;

  percentage_invoices_paid_on_time: any;
  month = [];
  claimed = [];
  claimable = [];
  program_details = [];
  program_claimed = [];
  program_claimable = [];
  data2: any;

  total_active_programs: any;
  total_approved_programs: any;
  total_amount_funded: any;
  total_funding_value: any;
  total_invoice_value_claimable: any;
  total_invoice_value_claimed: any;
  total_invoices_claimable: any;
  total_invoices_claimed: any;
  total_suppliers_active: any;
  total_suppliers_inactive: any;
  top_5_Sellers = [];
  party_name: Object;
  id: number;
  party_name_len: any;
  b_name: any;


  constructor(
    private _auth: AuthService,

    private _router: Router,

    private _route: ActivatedRoute,

    private http: HttpClient,
    private SpinnerService: NgxSpinnerService,
  ) { }


  ngOnInit(): void {
    this.SpinnerService.show()
    this.id = parseInt(this._route.snapshot.paramMap.get("id"));
    this.bid = this._route.snapshot.paramMap.get("bid")

    this.loading = true;
    this._auth
      .editProgram(this.id)
      .toPromise()
      .then((res) => {
  
        this.data1 = {
          program_id: res["program_id"],
          program_name: res["program_name"],
          program_type_id: res["program_type_id"],
          buyer_id: res["buyer_id"],
          seller_id: res["seller_id"],
          program_status: res["program_status"],
          seller_max_limit: res["seller_max_limit"],
          program_max_limit: res["program_max_limit"],
          max_funding_pct: res["max_funding_pct"],
          service_charges_pct: res["service_charges_pct"],
          interest_pct: res["interest_pct"],
          max_inv_credit_days: res["max_inv_credit_days"],
          with_recourse: res["with_recourse"],
          program_currency: res["program_currency"],
          program_value: res["program_value"],
          status: res["status"],
          alert_threshold_pct: '',
          additional_transaction_fees: res['additional_transaction_fees']
        };



        if (this.data1.alert_threshold_pct == null) {
          this.data1.alert_threshold_pct = 0
        } else {
          this.data1.alert_threshold_pct = res["alert_threshold_pct"]
        }
        this._auth
          .party(3)
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.SpinnerService.hide()
              this.party_name = res;
            this.party_name_len = res.length;
            for (var i = 0; i < res.length; i++) {
              // console.log(this.party_name[i].display_name)
              this.data1[i] = {
                party_id: this.party_name[i].party_id,
                b_name: this.party_name[i].display_name,
              };
              if (this.data1[i].party_id === this.data1.buyer_id) {
                this.b_name = this.party_name[i].display_name;
              }
            }
            }
          
          });
      });

    this._auth.buyer_details(this.bid, this.id).toPromise().then((res: any) => {
      this.data2 = res
      console.log(res)
      if (this.data2.length <= 0) {
        this.total_active_programs = 0
        this.total_approved_programs = 0
        this.total_amount_funded = 0
        this.total_funding_value = 0
        this.total_invoice_value_claimable = 0
        this.total_invoice_value_claimed = 0
        this.total_invoices_claimable = 0
        this.total_invoices_claimed = 0
        this.total_suppliers_active = 0
        this.total_suppliers_inactive = 0
        this.percentage_invoices_paid_on_time = 0

      } else {
        for (var i = 0; i < this.data2.length; i++) {
          this.total_active_programs = this.data2[i].total_active_programs
          this.total_approved_programs = this.data2[i].total_approved_programs
          this.total_amount_funded = this.data2[i].total_amount_funded
          this.total_funding_value = res[i]["total_funding_value"]
          this.total_invoice_value_claimable = this.data2[i].total_invoice_value_claimable
          this.total_invoice_value_claimed = this.data2[i].total_invoice_value_claimed
          this.total_invoices_claimable = this.data2[i].total_invoices_claimable
          this.total_invoices_claimed = this.data2[i].total_invoices_claimed
          this.total_suppliers_active = this.data2[i].total_suppliers_active
          this.total_suppliers_inactive = this.data2[i].total_suppliers_inactive
          this.percentage_invoices_paid_on_time = this.data2[i].percentage_invoices_paid_on_time
          this.top_5_Sellers = this.data2[i].top_5_sellers

          if (this.data2[i].invoices_claimable_claimed.length > 0) {
            for (var j = 0; j < this.data2[i].invoices_claimable_claimed.length; j++) {
              this.month.push(this.data2[i].invoices_claimable_claimed[j].month)
              this.claimed.push(parseInt(this.data2[i].invoices_claimable_claimed[j].claimed) + parseInt(this.data2[i].invoices_claimable_claimed[j].claimable))
              this.claimable.push(parseInt(this.data2[i].invoices_claimable_claimed[j].claimable))
            }
          } else {

            this.claimed.push(0)
            this.claimable.push(0)
          }

          this.month = this.month.reverse()
          this.claimable = this.claimable.reverse()
          this.claimed = this.claimed.reverse()
          this.dataTable = {

            dataRows: this.top_5_Sellers
          };

        }
        this.loading = false;
      }

      this.chartColor = "#FFFFFF";

      this.canvas = document.getElementById("chartActivity3");
      this.ctx = this.canvas.getContext("2d");

      this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
      this.gradientStroke.addColorStop(0, '#80b6f4');
      this.gradientStroke.addColorStop(1, this.chartColor);

      this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
      this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");

      this.chartActivity3 = new Chart(this.ctx, {
        type: 'bar',
        data: {
          labels: this.month,
          datasets: [

            {
              label: "Available",
              // type: 'bar',
              borderColor: '#fcc468',
              fill: true,
              backgroundColor: '#fcc468',
              hoverBorderColor: '#fcc468',
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 0,
              data: this.claimed,
              // order: 1
            },
            {
              label: "Claimed",
              borderColor: '#4cbdd7',
              fill: true,
              backgroundColor: '#4cbdd7',
              hoverBorderColor: '#4cbdd7',
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 4,
              data: this.claimable,
              // type: 'line',
              // order: 2
            }
          ]
        },
        options: {
          aspectRatio: 1.44,
          tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10,
          },


          legend: {

            display: true
          },
          scales: {

            yAxes: [{
              ticks: {
                fontColor: "#9f9f9f",
                fontStyle: "bold",
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 0
              },
              gridLines: {
                zeroLineColor: "transparent",
                display: true,
                drawBorder: false,
                color: '#9f9f9f',
              }

            }],
            xAxes: [{
              barPercentage: .9,
              gridLines: {
                zeroLineColor: "white",
                display: false,

                drawBorder: false,
                color: 'transparent',
              },
              ticks: {
                padding: 0,
                fontColor: "#9f9f9f",
                fontStyle: "bold"
              }
            }]
          }
        }
      });

    })
  }

  ngAfterViewInit() {
    $('#datatable4').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      processing: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }

    });

    var table = $('#datatable').DataTable();

  }

  getPercentage(offered, claimed) {

    return parseInt(claimed) == 0 ? 0 : ((parseFloat(claimed) / parseFloat(offered)) * 100).toFixed(2)


  }
  dashboard(){
    this._router.navigate(['/funder'])
  }

  program(){
    this._router.navigate(['/program'])
  }



}
