import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  BASE_URL = '';

  constructor(private envService: EnvService, private http2: HttpClient, private http: HttpClient, private http1: HttpClient, private handler: HttpBackend, private router: Router) {
    this.BASE_URL = this.envService.apiUrl;
  }
  get_financials(seller_id) {


    return [
      {
        "year": "2021",
        "revenue": "",
        "EBITDA": "",
        "EBIT": "",
        "interest-expense": "",
        "net-income": "",
        "diluted-net-income-incl-extra-items": "",
        "cash": "",
        "short-term-investments": "",
        "accounts-receivable": "",
        "total-inventory": "",
        "total-current-assets": "",
        "goodwill-and-intangibles": "",
        "total-assets": "",
        "current-liabilities": "",
        "short-term-debt": "",
        "long-term-debt": "", "total-debt": "",
        "total-equity": "",
        "CF-from-ops": "",
        "capEx": ""
      },
      {
        "year": "2020",
        "revenue": "",
        "EBITDA": "",
        "EBIT": "",
        "interest-expense": "",
        "net-income": "",
        "diluted-net-income-incl-extra-items": "",
        "cash": "",
        "short-term-investments": "",
        "accounts-receivable": "",
        "total-inventory": "",
        "total-current-assets": "",
        "goodwill-and-intangibles": "",
        "total-assets": "",
        "current-liabilities": "",
        "short-term-debt": "",
        "long-term-debt": "", "total-debt": "",
        "total-equity": "",
        "CF-from-ops": "",
        "capEx": ""
      },
      {
        "year": "2019",
        "revenue": "",
        "EBITDA": "",
        "EBIT": "",
        "interest-expense": "",
        "net-income": "",
        "diluted-net-income-incl-extra-items": "",
        "cash": "",
        "short-term-investments": "",
        "accounts-receivable": "",
        "total-inventory": "",
        "total-current-assets": "",
        "goodwill-and-intangibles": "",
        "total-assets": "",
        "current-liabilities": "",
        "short-term-debt": "",
        "long-term-debt": "", "total-debt": "",
        "total-equity": "",
        "CF-from-ops": "",
        "capEx": ""
      },
      {
        "year": "2018",
        "revenue": "",
        "EBITDA": "",
        "EBIT": "",
        "interest-expense": "",
        "net-income": "",
        "diluted-net-income-incl-extra-items": "",
        "cash": "",
        "short-term-investments": "",
        "accounts-receivable": "",
        "total-inventory": "",
        "total-current-assets": "",
        "goodwill-and-intangibles": "",
        "total-assets": "",
        "current-liabilities": "",
        "short-term-debt": "",
        "long-term-debt": "", "total-debt": "",
        "total-equity": "",
        "CF-from-ops": "",
        "capEx": ""
      },
      {
        "year": "2017",
        "revenue": "",
        "EBITDA": "",
        "EBIT": "",
        "interest-expense": "",
        "net-income": "",
        "diluted-net-income-incl-extra-items": "",
        "cash": "",
        "short-term-investments": "",
        "accounts-receivable": "",
        "total-inventory": "",
        "total-current-assets": "",
        "goodwill-and-intangibles": "",
        "total-assets": "",
        "current-liabilities": "",
        "short-term-debt": "",
        "long-term-debt": "", "total-debt": "",
        "total-equity": "",
        "CF-from-ops": "",
        "capEx": ""
      }
    ];
  }

}
