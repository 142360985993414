import { Injectable } from "@angular/core";

declare var $: any;
@Injectable({
  providedIn: "root"
})
export class PushService {
  constructor() {}

  // showSuccess() {

  //   // this.toastr.success(msg, 'Success');

  // }

  showNotification(msg, type) {
    // var type = ['','info','success','warning','danger'];

    // var color = Math.floor((Math.random() * 4) + 1);
    var text = this.titleCase(msg);
    $.notify(
      {
        icon: "ti-gift",
        message: `${text}`
      },
      {
        type: `${type}`,
        timer: 120,
        placement: {
          from: "top",
          align: "right"
        },
        template:
          '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
      }
    );
  }

  public titleCase(str) {

    if(str.includes("UEN")){
      return str
    }
    if(str.includes("5MB")){
      return str
    }
    else{
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    }
  
  }
}
