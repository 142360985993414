import { Component, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// declare var $:any;

declare const $: any;
declare interface DataTable {
    headerRow: string[];

    dataRows: string[][];
}

@Component({
  selector: 'app-program-view',
  templateUrl: './program-view.component.html',
  styleUrls: ['./program-view.component.css']
})
export class ProgramViewComponent implements OnInit {
  public canvas : any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public chartActivity;

  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  gradientStroke: any;
  public dataTable: DataTable;
  chartActivity3: any;

//   avl_vs_clm = [
//     {
//         month: ['Jan', 'Feb', 'Mar','Apr'],
//         data: [
//             {
//               claimed: 100,
//               available : 150,
//                 yield: 300,
//                 month: 'Jan'
//             },
//               {
//                 claimed: 100,
//                 available : 150,
//                 yield: 260,
//                 month: 'Feb'
//             },
//               {
//                 claimed: 100,
//               available : 150,
//                 yield: 150,
//                 month: 'Mar'
//             },
//               {
//                 claimed: 100,
//                 available : 150,
//                 yield: 210,
//                 month: 'Apr'
//             },
//         ]
//     }
// ]

  avl_vs_clm=[]
  month=[];
  available=[];
  claimed=[];
  yield=[]
  seller = [
    {
      sellers: 'ACCO',
      last_kyc_date: '20/03/2020',
      seabridge_rating: '7',
      credit_score: '650-700',
      funding_amount: '100,000,000',
      download: ''
    },
    {
      sellers: 'C2O',
      last_kyc_date: '10/03/2020',
      seabridge_rating: '8',
      credit_score: '500-750',
      funding_amount: '85,000,000',
      download: ''
    }
  ]
  id: number;
  data: any;
  display: boolean;
  name: string;
  program_limit: any;
  transaction_fee: any;
  discount_rate: any;
  currency: any;
  additional_transaction_charges: any;
  max_funding_pct: any;
  max_funded_amt: any;
  participation: any;
  max_funding_amt: any;
  cash_in_hand: number;
  tot_deployed: any;
  tot_commit: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  

  displayedColumns: string[] = ['sellers', 'last_kyc_date', 'seabridgetfx_rating','credit_score','funding_amount','download'];
  dataSource: MatTableDataSource<any>;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

     
    }
  constructor( private _auth: AuthService, private _route: ActivatedRoute, private router: Router,private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.SpinnerService.show();
    this.id = parseInt(this._route.snapshot.paramMap.get("id"));
    this.name = this._route.snapshot.paramMap.get("name")
    this.display = true
    
    this._auth.funder_details(sessionStorage.getItem("seller_id")).toPromise().then((res:any)=>{
      if(res){
        this.data = res
        this.SpinnerService.hide();
        for(var i=0;i<this.data.length;i++){
          console.log(this.data[i])
          for(var p=0; p<this.data[i].current_programs.length;p++){
            
            if(parseInt(this.data[i].current_programs[p].program_id)==this.id){

              this.program_limit = this.data[i].current_programs[p].program_value
              this.transaction_fee = this.data[i].current_programs[p].service_charges_pct
              this.discount_rate = this.data[i].current_programs[p].interest_pct
              this.currency = this.data[i].current_programs[p].program_currency
              this.additional_transaction_charges = this.data[i].current_programs[p].additional_transaction_fees
              this.max_funding_pct = this.data[i].current_programs[p].funded_pct
              this.max_funding_amt = this.data[i].current_programs[p].amount_funded
              this.participation = this.data[i].current_programs[p].funded_pct
              this.cash_in_hand =  parseFloat(this.max_funding_amt) - parseFloat(this.data[i].current_programs[p].investment_outstanding)
              this.tot_deployed = this.data[i].current_programs[p].investment_outstanding
              this.tot_commit = this.data[i].current_programs[p].amount_funded
            }
          }
          for(var b in this.data[i].table_seller_funded){
            if(parseInt(b)== this.id){
              var seller =[]
              if(this.data[i].table_seller_funded[b].length > 0){

                for(var a=0; a< this.data[i].table_seller_funded[b].length;a++ ){
                  seller = this.data[i].table_seller_funded[b]
                  console.log(seller)
                  this.dataSource = new MatTableDataSource(seller);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
                 
                }  
              }else{
                this.display = false
              }
                           
           }
        }
    
         
     
              for(var k =0; k< this.data[i].graph_programwise_available_claimed.month.length;k++){
           
                this.month.push(this.data[i].graph_programwise_available_claimed.month[k])
              }

          for(var j in this.data[i].graph_programwise_available_claimed.data ){
            if(parseInt(j)== this.id){
              for(var k =0; k< this.data[i].graph_programwise_available_claimed.data[j].length;k++){
                if(this.data[i].graph_programwise_available_claimed.month[k] == this.data[i].graph_programwise_available_claimed.data[j][k].month){
                  this.available.push(parseInt(this.data[i].graph_programwise_available_claimed.data[j][k].available))
                  this.claimed.push(parseInt(this.data[i].graph_programwise_available_claimed.data[j][k].claimed))
                  this.yield.push(parseInt(this.data[i].graph_programwise_available_claimed.data[j][k].yield))
                }       
              }
            }
          }
          this.chartColor = "#FFFFFF";

    this.canvas = document.getElementById("chartActivity3");
    this.ctx = this.canvas.getContext("2d");

    this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
    this.gradientStroke.addColorStop(0, '#80b6f4');
    this.gradientStroke.addColorStop(1, this.chartColor);

    this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
    this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
    this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");

    this.chartActivity3 = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: this.month,
        datasets: [

          {
            label: "Available",
            type: 'bar',
            borderColor: '#fcc468',
            fill: true,
            backgroundColor: '#fcc468',
            hoverBorderColor: '#fcc468',
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 0,
            data: this.available,
            yAxisID: "id1"

          },
          {
            label: "Claimed",
            type: 'bar',
            borderColor: '#4cbdd7',
            fill: true,
            backgroundColor: '#4cbdd7',
            hoverBorderColor: '#4cbdd7',
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 0,
            data: this.claimed,
            yAxisID: "id1"

          }, {
            label: "Yield",
            borderColor: '#919294',
            fill: false,
            backgroundColor: '#919294',
            hoverBorderColor: '#919294',
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 4,
            data: this.yield,
            type: 'line',
            // order: 2,
            yAxisID: "id2"
          }
        ]
      },
      options: {

        tooltips: {
          tooltipFillColor: "rgba(0,0,0,0.5)",
          tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipFontSize: 14,
          tooltipFontStyle: "normal",
          tooltipFontColor: "#fff",
          tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          tooltipTitleFontSize: 14,
          tooltipTitleFontStyle: "bold",
          tooltipTitleFontColor: "#fff",
          tooltipYPadding: 6,
          tooltipXPadding: 6,
          tooltipCaretSize: 8,
          tooltipCornerRadius: 6,
          tooltipXOffset: 10,
        },


        legend: {

          display: true
        },
        scales: {

          yAxes: [{
            position: 'left',
            ticks: {
              fontColor: "#9f9f9f",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 0
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: '#9f9f9f',
            },
            id: "id1"

          }, {
            position: 'right',
            ticks: {
              fontColor: "#9f9f9f",
              fontStyle: "bold",
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 0
            },
            gridLines: {
              zeroLineColor: "transparent",
              display: true,
              drawBorder: false,
              color: '#9f9f9f',
            },
            id: "id2"

          }],
          xAxes: [{
            barPercentage: 1,
            gridLines: {
              zeroLineColor: "white",
              display: false,

              drawBorder: false,
              color: 'transparent',
            },
            ticks: {
              padding: 0,
              fontColor: "#9f9f9f",
              fontStyle: "bold"
            }
          }]
        }
      }
    });

      }
    }
    })

    
    
    var seller = [];
    seller = this.seller
 
   
    var cardStatsMiniLineColor = "#fff",
    cardStatsMiniDotColor = "#fff";

  }
  uniq_prgs(uniq_prgs: any) {
    throw new Error("Method not implemented.");
  }

  ngAfterViewInit(){
    $('#datatable4').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }
    });
  }

  back(){
    this.router.navigate(['/funder'])
  }


}
