import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";

import { SelectionModel } from "@angular/cdk/collections";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { environment } from "environments/environment";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
import { BuyerParticipateComponent } from './buyer-participate/buyer-participate.component'

export interface PeriodicElement {
  perm_id: string;
  entity_type: string;
  name: string;
  city: string;
  country: string;
  dob: string;
}

declare var $: any;

declare interface User {
  text?: string;
  email?: string; //  must be valid email format
  password?: string; // required, value must be equal to confirm password.
  confirmPassword?: string; // required, value must be equal to password.
  number?: string; // required, value must be equal to password.
  url?: string;
  idSource?: string;
  idDestination?: string;
}

@Component({
  selector: "app-buyer-view",
  templateUrl: "./buyer-view.component.html",
  styleUrls: ["./buyer-view.component.css"]
})
export class BuyerViewComponent implements OnInit {
  @ViewChild('uploader')uploader;

  @ViewChild(BuyerParticipateComponent ) buyer: BuyerParticipateComponent ;
  id: any;
  perm_id: any;
  uen: any;

  disp: boolean;
  name: any;
  status: any;
  date: any;
  status_update_date: any;
  btn_disabled: boolean;
  AdditionalForm: FormGroup;

  worldcheck: any;
  party_id: any;
  worldcheck_status: any;
  update_btn: boolean;
  flag = "";
  color: string;
  address: any;

  registered_office: any;
  CBS_MLCB: any;
  GST_F5_Statment: any;
  GST_Statment: any;
  audited_financial_statements: any;
  boardResolution: any;
  directorDetails: any;
  personal_notice_assessment: any;
  shareholderDetails: any;
  passbook: any;
  checkbook: any;
  month6_Statment: any;
  buyer_id: any;
  title: any;
  suffix: any;
  full_name: any;
  email: any;
  phone: any;
  mobile: any;
  website: any;
  street: any;
  city: any;
  zip: any;
  state: any;
  country: any;
  logo: string;
  kyc_status: any;
  passbook_btn: boolean;
  registered_address_display: any;
  kyc_tab_display: any;
  bank_docs: any;
  credit_docs: any;
  upload_url: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ["select", "permid", "organizationName", "entity_type"];
  displayedColumns1: string[] = ["permid", "organizationName", "entity_type", "name", "number", "action"];
  // displayedColumns2: string[] = [
  //   "program_name",
  //   "lead_by",
  //   "start_date",
  //   "end_date",
  //   "program_currency",
  //   "interest_pct",
  //   "service_charges_pct",
  //   "status"
  // ];
  dataSource: MatTableDataSource<any>;
  dataSource1: MatTableDataSource<any>;
  // dataSource2: MatTableDataSource<any>;

  org_name: any;
  generated_permid: any = "";
  wc_btn: boolean;
  url: any;
  display_perm: boolean;
  pid: any;
  worldcheck_id: any;
  show_world_check_data: boolean = true;
  show_reference_data: boolean;
  reference_id: any;
  reference_data = [];
  wc_data = [];
  reference_name: any;
  generate_wc_id: boolean;
  ds_length: any;
  send_invite_display: boolean;
  random_val: number;
  wc_date: any;
  reinvite: any;
  hide: boolean = true;
  case_system_id: any;
  process_wo_wc: boolean;
  AR_PR: any;
  acc_no: any;
  acc_type: any;
  bank_add: any;
  branch_code: any;
  bank_name: any;
  additional_documents: any;
  shareholder_details: any;
  address_proof: any;
  buyer_ref_id: any;
  buyer_limit: any;
  wc_resolution: any;
  uen_update_data: any;
  acra_update_val: boolean = false;
  name_update_data: any;
  wc_perm_update_val: any;
  alias_name: any;
  alias_name1 :any
  kyc_data_points: any;
  selected:any = 0;
  selected1:any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private push: PushService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private envService: EnvService
  ) {
    this.upload_url = this.envService.File_upload_buyer;
  }

  public typeValidation: User;

  ngOnInit(): void {
    this.btn_disabled = false;
    this.id = this._route.snapshot.paramMap.get("id");
    this.sharedService.nextMessage2(this.id);
    this._auth
      .getPermId(this.id)
      .toPromise()
      .then(res => {
        if (res) {


          // this._auth.participated_programs("buyer", this._route.snapshot.paramMap.get("id")).subscribe(
          //   (res: any) => {
          //     console.log(res);
          //     this.dataSource2 = new MatTableDataSource(res);

          //     this.dataSource2.sort = this.sort;
          //     this.dataSource2.paginator = this.paginator;
          //   },
          //   err => {
          //     this.push.showNotification(err.error.errMsg, "danger");
          //   }
          // );

          this.logo = res["logo"] === null ? null : res["logo"];
          this.send_invite_display = res["user_status"] === "ADDED" ? true : false;

          this.reinvite = res["reinvite"];
          this.kyc_status = res["kyc_status"];
          this.title = res["title"];
          this.suffix = res["contact_person_suffix"] === null ? "N/A" : res["contact_person_suffix"];
          this.full_name = res["full_name"];
          this.email = res["admin_user_id"];
          this.phone = res["phone"];
          this.mobile = res["mobile"];
          this.website = res["website"];
          this.street = res["street"];
          this.city = res["city"];
          this.zip = res["zip"];
          this.state = res["state_name"];
          this.perm_id = res["perm_id"] === null ? 'N/A': res['perm_id'];
          this.pid = res["id"];
          this.country = res["country_name"];
          this.uen = res["uen"];
          this.party_id = res["id"];
          this.buyer_id = res["party_id"];
          this.name = res["display_name"];
          this.status = res["user_status"];
          this.date = res["create_date"];
          this.status_update_date = res["status_updated_on"];
          this.worldcheck_status = res["worldcheck_status"];
          var i = res["buyer_documents"];
          this.kyc_tab_display = i.acra;
          this.bank_docs = i.bank_verification;
          this.credit_docs = i.document_verification;
          this.wc_date = res["worldcheck_last_updated"] === null ? "N/A" : res["worldcheck_last_updated"];
          this.acc_no = res["bank_account_no"] === null ? "N/A" : res["bank_account_no"];
          this.acc_type = res["bank_account_type"] === null ? "N/A" : res["bank_account_type"];
          this.bank_add = res["bank_branch_address"] === null ? "N/A" : res["bank_branch_address"];
          this.branch_code = res["bank_branch_code"] === null ? "N/A" : res["bank_branch_code"];
          this.bank_name = res["bank_name"] === null ? "N/A" : res["bank_name"];
          this.worldcheck_id = res["worldcheck_id"];
          this.case_system_id = res["case_system_id"] === null ? "N/A" : res["case_system_id"];
          this.additional_documents = res["additional_documents"] === null ? null : res["additional_documents"];
          this.wc_resolution = res['wc_resulution'] === null ? "N/A": res["wc_resolution"]
          this.alias_name =  res['alias_name'] === null? '': res['alias_name']
          this.alias_name1 = res['alias_name'] === null? 'N/A' : res['alias_name']
          this.kyc_data_points = res["kyc_data_points"]

          this.buyer_limit = res["credit_limit"];
          this.buyer_ref_id = res["seller_sb_ref"];
          if (res["case_system_id"] === null) {
            this.process_wo_wc = false;
          } else {
            this.process_wo_wc = true;
          }
          if (res["perm_id"] === "N/A" || res["perm_id"] === null) {
            this.display_perm = true;
            this._auth.get_permid(res["display_name"]).subscribe((res: any) => {
              if (res["entities"].length > 0) {
                this.dataSource = new MatTableDataSource(res["entities"]);

                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
              } else {
              }
            });
          } else {
            this.display_perm = false;
          }

          if (Object.keys(res["credit_docs"]).length > 0) {
            var i = res["credit_docs"];

            if (i[0] != null || i[0] != undefined) {
              this.CBS_MLCB = i[0].CBS_MLCB === undefined ? undefined : i[0].CBS_MLCB.file;
              this.GST_F5_Statment = i[0].GST_F5_Statment === undefined ? undefined : i[0].GST_F5_Statment.file;
              this.GST_Statment = i[0].GST_Statment === undefined ? undefined : i[0].GST_Statment.file;
              this.audited_financial_statements =
                i[0].audited_financial_statements === undefined ? undefined : i[0].audited_financial_statements.file;
              this.personal_notice_assessment =
                i[0].personal_notice_assessment === undefined ? undefined : i[0].personal_notice_assessment.file;
              this.AR_PR = i[0].AP_AR_Statment === undefined ? undefined : i[0].AP_AR_Statment.file;
              this.address_proof = i[0].address_proof === undefined ? undefined : i[0].address_proof.file;
              this.shareholder_details = i[0].shareholder_details === undefined ? undefined : i[0].shareholder_details.file;
            } else {
              this.CBS_MLCB = i.CBS_MLCB === undefined ? undefined : i.CBS_MLCB.file;
              this.GST_F5_Statment = i.GST_F5_Statment === undefined ? undefined : i.GST_F5_Statment.file;
              this.GST_Statment = i.GST_Statment === undefined ? undefined : i.GST_Statment.file;
              this.audited_financial_statements =
                i.audited_financial_statements === undefined ? undefined : i.audited_financial_statements.file;
              this.personal_notice_assessment =
                i.personal_notice_assessment === undefined ? undefined : i.personal_notice_assessment.file;
              this.AR_PR = i.AP_AR_Statment === undefined ? undefined : i.AP_AR_Statment.file;
              this.address_proof = i.address_proof === undefined ? undefined : i.address_proof.file;
              this.shareholder_details = i.shareholder_details === undefined ? undefined : i.shareholder_details.file;
            }
          }

          if (res["bank_docs"] != null) {
            for (var i of res["bank_docs"]) {
              if (i.passbook != undefined) {
                this.passbook = i.passbook;
              }

              if (i.cheqbook != undefined) {
                this.checkbook = i.cheqbook;
              }

              if (i.month6_Statment != undefined) {
                this.month6_Statment = i.month6_Statment;
              }
            }
          }

          if (this.status == "APPROVED" || this.worldcheck_status == "RED") {
            this.btn_disabled = true;
          }

          if (this.worldcheck_status == null) {
            this.update_btn = false;
          } else {
            this.update_btn = true;
          }
        }
      });
    this.typeValidation = {
      text: "",
      number: ""
    };
  }

  get_val(val: boolean): boolean {
    if (val == true) {
      return true;
    } else {
      return false;
    }
  }

  get_entity(id: string): string {
    if (id != undefined) {
      var spl = id.split("-");

      if (spl[0] === "98") {
        return "Individual";
      } else {
        return "Organisation";
      }
    }
  }

setSelected= (selected_index)=>{
  this.selected = selected_index
  console.log(this.selected)
}
  perm_id_display(id: string): string {
    let val = id === "N/A" ? null : id;
    if (val != null) {
      var spl = id.split("-");
      if (spl[1].length >= 10) {
        return "B" + spl[1].substr(1);
      }
    } else {
      return "N/A";
    }
  }

  get_perm_id(id) {
    var spl = id.split("/");

    return spl[3];
  }

  show(name, url) {
    this.url = url;
    this.org_name = name;

    $("#myModal1").modal("show");
  }

  generate_id(event, value) {
    this.generated_permid = event.target.value + "-" + value;
  }

  display_wc_btn(event, id) {
    if (event.target.checked) {
      this.generated_permid = this.get_perm_id(id);
      this.wc_btn = true;
    } else {
      this.generated_permid = "";
      this.wc_btn = false;
    }
  }

  get_referenceid_data(id, name) {
    this.show_reference_data = false;
    this._auth.get_referenceid_data(id).subscribe(
      (res: any) => {
        this.show_reference_data = true;
        this.reference_data = res.details;
        this.reference_id = id;
        this.reference_name = name;
        $("#myModal3").modal("show");
      },
      err => {
        this.push.showNotification("Something Went Wrong", "danger");
      }
    );
  }

  proceed_wc(event) {
    if (event.target.checked) {
      this.generate_wc_id = true;
    } else {
      this.generate_wc_id = false;
    }
  }

  update_Wc_data(id) {
    this._auth
      .update_worldcheck_data(this._route.snapshot.paramMap.get("id"), {
        referenceId: id
      })
      .subscribe(
        (res: any) => {
          this.push.showNotification("WorldCheck Id Successfully Updated", "success");
          this.ngOnInit();
        },
        err => {
          this.push.showNotification("Something Went Wrong", "danger");
        }
      );
  }

  generate_random_val() {
    var date = new Date();
    this.random_val = Math.floor(date.getTime() / 1000);
    $("#myModal2").modal("show");
  }

  cancel_modal() {
    this.generated_permid = "";
    $("#myModal2").modal("hide");

    let val = <HTMLInputElement>document.getElementById('selectedValue')
    val.value = ""

  }

  update_permid() {
    this._auth.update_permid(this.pid, this.generated_permid).subscribe(
      (res: any) => {
        if (res) {
          this.push.showNotification("Perm Id Successfully Updated", "success");
          this.ngOnInit();
        }
      },
      err => {
        this.push.showNotification("Something Went Wrong. Please Try Again Later", "danger");
      }
    );

    let val = <HTMLInputElement>document.getElementById('selectedValue')
    val.value = ""


  }

  rejectCalled() {
    this._router.navigate(["/admin"]);
  }
  showSwal() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      confirmButtonText: "Confirm",
      buttonsStyling: false
    }).then(result => {
      if (result.value) {
        if (this.generate_wc_id === true) {
          this._auth
            .update_worldcheck_data(this._route.snapshot.paramMap.get("id"), {
              referenceId: "0"
            })
            .subscribe(
              (res: any) => {
                this._auth
                  .update_flag_status(this.party_id, "Grey")
                  .toPromise()
                  .then((res: any) => {
                    if (res) {
                      Swal.fire({
                        title: "Updated!",
                        text: "Status Updated Successfully",
                        type: "success",
                        confirmButtonClass: "btn btn-success",
                        buttonsStyling: false
                      });
                      this.generate_wc_id = false
                      this.ngOnInit();
                    }
                  });
              },
              err => {
                this.push.showNotification("Something Went Wrong", "danger");
              }
            );
        } else {
          this._auth
            .update_flag_status(this.party_id, this.flag)
            .toPromise()
            .then((res: any) => {
              if (res) {
                Swal.fire({
                  title: "Updated!",
                  text: "Status Updated Successfully",
                  type: "success",
                  confirmButtonClass: "btn btn-success",
                  buttonsStyling: false
                });
                this.generate_wc_id = false
                this.ngOnInit();
              }
            });
        }
      }
    });
  }
  get_status_color(status) {
    if (status === "AMBER") {
      return "fa fa-flag fa-2x amber";
    } else if (status === "RED") {
      return "fa fa-flag fa-2x danger";
    } else if (status === "GREEN") {
      return "fa fa-flag fa-2x success";
    } else if (status === "GREY") {
      return "fa fa-flag fa-2x secondary";
    } else if (status === "PURPLE") {
      return "fa fa-flag fa-2x purple";
    } else {
      return "fa fa-flag fa-2x default";
    }
  }

  updatestatus() {
    this._auth
      .updateStatus(this.id, "APPROVED")
      .toPromise()
      .then(res => {
        if (res) {
          if (res["user_status"] === "APPROVED") {
            if (res) {
              this.btn_disabled = true;
              var data = {
                pid: `${this.id}`,
                action: "KYC Approved",
                message: "KYC Has Been Approved For " + `${this.id}`
              };
              this._auth
                .post_notifications(JSON.stringify(data))
                .toPromise()
                .then(res => {
                  this.push.showNotification("KYC Successfully Approved", "success");
                  this._router.navigate(["buyers/buyer-list"]);
                });
            }
          }
        }
      });
  }

  Account_Hold() {
    this._auth
      .updateStatus(this.id, "HOLD")
      .toPromise()
      .then(res => {
        if (res) {
          this.push.showNotification("User Account Is Temporarily Suspended", "info");
          this._router.navigate(["buyers/buyer-list"]);
        }
      });
  }

  Account_UnHold() {
    this._auth
      .updateStatus(this._route.snapshot.paramMap.get("id"), "UNHOLD")
      .toPromise()
      .then(res => {
        if (res) {
          this.push.showNotification("User Account Is Activated", "success");
          this._router.navigate(["buyers/buyer-list"]);
        }
      });
  }
  store_flag_value(value) {
    this.flag = value;

    if (value == "red") {
      this.color = "#FF0000";
    } else if (value === "green") {
      this.color = "#008000";
    } else {
      this.color = "#ffbf00";
    }
  }

  update_status(status) {
    this._auth
      .update_flag_status(this.party_id, status)
      .toPromise()
      .then((res: any) => {
        if (res) {
        }
      });
  }

  cancelCalled() {
    this._router.navigate(["buyers/buyer-list"]);
  }

  back() {
    this._router.navigate(["buyers/buyer-list"]);
  }

  download(file_name) {
    if(file_name.length == 0){
      this.push.showNotification("File Not Found", 'danger')
    }else{
      window.location.href = this.upload_url + "/" + file_name;
    }
  }

  swal1(user_name) {
    var name = user_name;
    var perm_id = "1-4298409204";
    var uen = "198400208H";
    var country = "Spain";

    var entity_type = "Individual";
    var city = "Barcelona";
    var country = "Spain";
    var dob = "10/09/1989";
    Swal.fire({
      title: name,

      html: ` <div class="">

                                        <div class="d-flex justify-content-between">
                                            <p> <strong>Perm ID</strong></p>
                                            <p>${perm_id}</p>

                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p><strong>UEN</strong></p>
                                            <p>${uen}</p>
                                        </div>


                                    <div class="d-flex justify-content-between">
                                    <p><strong>Entity Type</strong></p>
                                    <p>${entity_type}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                <p><strong>City</strong></p>
                                <p>${city}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                            <p><strong>Country</strong></p>
                            <p>${country}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                                        <p><strong>DOB</strong></p>
                                        <p>${dob}</p>
                                    </div>


                                    </div>`,
      buttonsStyling: false,
      confirmButtonClass: "btn btn-info"
    });
  }

  get_status(status) {
    if (status === "INVITED" || status === "ADDED") {
      return true;
    } else {
      return false;
    }
  }
  send_invite() {
    if (this.status != "APPROVED") {
      if (this.reinvite === true) {
        this._auth.seller_reinvite(this._route.snapshot.paramMap.get("id")).subscribe(
          (res: any) => {
            this.push.showNotification("Buyer Invited Successfully", "success");
            this.ngOnInit();
          },
          err => {
            this.push.showNotification("Unable To Invite Buyer", "danger");
          }
        );
      } else {
        this._auth.seller_send_invite(this._route.snapshot.paramMap.get("id")).subscribe(
          (res: any) => {
            this.push.showNotification("Buyer Invited Successfully", "success");
            this.ngOnInit();
          },
          err => {
            this.push.showNotification("Unable To Invite Buyer", "danger");
          }
        );
      }
    } else {
      this.push.showNotification("This Account Has Already Been Approved", "info");
    }
  }

  get_wc_data() {
    if (this.perm_id != 'N/A') {
    this.show_world_check_data = false;
    this.process_wo_wc = false;
    this.hide = false;

      if (this.worldcheck_id != 0) {
        if (this.perm_id != "N/A" && this.case_system_id === "N/A") {
          this._auth.get_worldcheck_details({ name: this.name, permId: this.perm_id }).subscribe(
            (res: any) => {
              this.show_world_check_data = true;
              this.process_wo_wc = true;
              this.case_system_id = res["caseSystemId"];

              this.ngOnInit();
            },
            err => {
              this.show_world_check_data = true;
              this.process_wo_wc = true;
              this.case_system_id = "N/A";

              this.push.showNotification(err.error.error, "danger");
            }
          );


        } else {
          this.show_world_check_data = true;
        }
      } else {
        this.show_world_check_data = true;
      }
    } else {
      this.push.showNotification("Please Generate Perm ID", 'danger')
      this.hide = true

    }

  }

  // ----------------- Edit- check -------------------


  onUpload(event, val1, val2) {

  if(val1 == 'logo'){
    let file: any = null;

    if (event.target.files.length > 0) {

      if( (event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpeg" )) {
        if((event.target.files[0].size/1000000) <= 5){
          file = event.target.files[0];


          if (file != null) {
            this._auth
              .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "buyer-doc")
              .toPromise()
              .then((res: any) => {
                if (res) {


                  let data = {
                    field_name: val2,
                    new_file: res,
                    type: val1,
                    party_id: this._route.snapshot.paramMap.get("id")
                  };


                  this._auth.update_documents(data).subscribe(
                    (res: any) => {
                      this.push.showNotification(res, "success");
                      this.ngOnInit();
                    },
                    err => {
                      this.push.showNotification(err.error.errMsg, "danger");
                    }
                  );
                }
              });
          }

        }else {
          this.push.showNotification("File Size Should be less than 5MB", "danger");
        }
      }else {
        this.push.showNotification("Incorrect File", "danger");
      }

    }

  }

  if(val1 == 'credit_doc'){
    let file: any = null;

    if (event.target.files.length > 0) {

      if( (event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "application/pdf") ) {
                  if((event.target.files[0].size/1000000) <= 5){
          file = event.target.files[0];


          if (file != null) {
            this._auth
              .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "buyer-doc")
              .toPromise()
              .then((res: any) => {
                if (res) {


                  let data = {
                    field_name: val2,
                    new_file: res,
                    type: val1,
                    party_id: this._route.snapshot.paramMap.get("id")
                  };


                  this._auth.update_documents(data).subscribe(
                    (res: any) => {
                      this.push.showNotification(res, "success");
                      this.ngOnInit();
                    },
                    err => {
                      this.push.showNotification(err.error.errMsg, "danger");
                    }
                  );
                }
              });
          }

        }else {
          this.push.showNotification("File Size Should be less than 5MB", "danger");
        }
      }else {
        this.push.showNotification("Incorrect File", "danger");
      }

    }

  }

  if(val1 == 'bank_doc'){

    let file: any = null;

    if (event.target.files.length > 0) {

      if(event.target.files[0].type === "application/pdf"){
        if((event.target.files[0].size/1000000) <= 5){
          file = event.target.files[0];


          if (file != null) {
            this._auth
              .funder_upload_doc(file, this._route.snapshot.paramMap.get("id"), "buyer-doc")
              .toPromise()
              .then((res: any) => {
                if (res) {


                  let data = {
                    field_name: val2,
                    new_file: res,
                    type: val1,
                    party_id: this._route.snapshot.paramMap.get("id")
                  };


                  this._auth.update_documents(data).subscribe(
                    (res: any) => {
                      this.push.showNotification(res, "success");
                      this.ngOnInit();
                    },
                    err => {
                      this.push.showNotification(err.error.errMsg, "danger");
                    }
                  );
                }
              });
          }

        }else {
          this.push.showNotification("File Size Should be less than 5MB", "danger");
        }
      }else {
        this.push.showNotification("Incorrect File", "danger");
      }

    }
  }

  }

  buyer_max_limit_update() {
    Swal.fire({
      title: "Enter Buyer Max Limit",
      html:
      '<div class="form-group">' +
      '<input id="input-field"  type="text" class="form-control" value='+this.buyer_limit+' currencyMask [options]="{ prefix: "", thousands: ",", align: "left", allowNegative: false }" />' +
      "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then(result => {
      // console.log($("#input-field").val());
      if(result.value === true){
        if(parseInt($("#input-field").val())> 0){
          this._auth.update_seller_max_limit("buyer", $("#input-field").val(), this._route.snapshot.paramMap.get("id")).subscribe(
            (res: any) => {
              Swal.fire({
                type: "success",
                html: "Successfully Updated",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false
              }).then(() => {
                this.ngOnInit();
              });
            },
            (err: any) => {
              console.log(err);
            });
        }
        else{
          this.push.showNotification('Value Must Be Greater Than Zero','danger')

        }
      }

    });
  }


  uen_update() {
    Swal.fire({
      title: "Update UEN",
      html:
      '<div class="form-group">' +
      '<input id="input-field"  type="text" class="form-control" value='+this.uen+' />' +
      "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then(result => {
      // console.log($("#input-field").val());
      if(result.value === true){
        let val = $("#input-field").val()
        val = $.trim(val);

        if (val != '' && val  != this.uen) {
          this.uen_update_data = val
          $("#myModal4").modal("show");

        }
        else{
          this.push.showNotification('Please Enter UEN To Update','danger')

        }
      }

    });
  }

  uen_update_call() {

    this._auth.update_uen({ uen: this.uen_update_data, acra: this.acra_update_val, party_id: this._route.snapshot.paramMap.get("id") }).subscribe(
      (res: any) => {
        Swal.fire({
          type: "success",
          html: "Successfully Updated",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        }).then(() => {
          this.ngOnInit();
        });
      },
      (err: any) => {
        this.push.showNotification(err.error.err,'danger')
      });
  }

  name_update() {
    Swal.fire({
      title: "Update Company Name",
      html:
      '<div class="form-group">' +
      '<input id="input-field"  type="text" class="form-control" value='+ `'${this.name}'` +' />' +
      "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then(result => {
      // console.log($("#input-field").val());
      if (result.value === true) {

        let val = $("#input-field").val()

        val = $.trim(val);

        if (val != '' && val != this.name) {
          console.log("Inside Function")
          this.name_update_data = val

            $("#myModal5").modal("show");

        }
        else{
          this.push.showNotification('Please Enter Name To Update','danger')

        }
      }

    });
  }


  name_update_call() {

    this._auth.update_name({ name: this.name_update_data, perm_id_update: this.wc_perm_update_val, wc_screening: this.wc_perm_update_val , party_id: this._route.snapshot.paramMap.get("id") }).subscribe(
      (res: any) => {
        Swal.fire({
          type: "success",
          html: "Successfully Updated",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        }).then(() => {
          this.ngOnInit();
        });
      },
      (err: any) => {
        console.log(err);
      });

  }

  buyerParticipate(){
    this.buyer.buyerParticipate()
  }



  alias_update() {
    Swal.fire({
      title: "Update Alias Name",
      html:
      '<div class="form-group">' +
      '<input id="input-field"  type="text" class="form-control"   value='+`'${this.alias_name}'`+' />' +
      "</div>",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then(result => {
      // console.log($("#input-field").val());
      if (result.value === true) {
        let val = $("#input-field").val()


        val = $.trim(val);
        // val = val.replace(/\s/g, "");


        if (val != '' || val != this.alias_name || val != undefined || val != null) {

          this._auth.update_alias({ party_id: this._route.snapshot.paramMap.get("id"), alias_name: val }).subscribe((res: any) => {
            this.push.showNotification(res, 'success')
            this.ngOnInit();
          }, (err) => {
            this.push.showNotification(err.error.errMsg,'danger')
          })

        }
        else{
          this.push.showNotification('Please Enter UEN To Update','danger')

        }
      }

    });

  }

  trim(el) {
    console.log(el)
    el = el.
       replace (/(^\s*)|(\s*$)/gi, ""). // removes leading and trailing spaces
       replace (/[ ]{2,}/gi," ")
    return el;
}​
}
