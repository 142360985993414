import { AuthService } from "app/services/auth.service";
import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  FormArray,
  ValidatorFn,
  Validators,
  ValidationErrors,
  AbstractControl
} from "@angular/forms";
import { PushService } from "app/services/push.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import Chart from "chart.js";
import * as jwt_decode from "jwt-decode";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

declare var $;

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"]
})
export class SearchComponent implements OnInit {
  data = [];
  dashboard_deployed_amt = [];
  deployed_total: number;
  SearchForm: FormGroup;
  btn: boolean;
  loading: boolean;
  btn1: boolean;
  program: [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    "program_name",
    "buyer_name",
    "start_date",
    "end_date",
    "program_currency",
    "program_value",
    "deployed_amt",
    "program_status"
  ];
  dataSource: MatTableDataSource<any>;
  count1: number;
  count2: number;
  programs: any;
  btn_color: string;
  count3: any;
  active: any;
  inactive: any;
  inactive_programs: number;
  total_fund: number;
  avg_fund: any;
  router: any;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private push: PushService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.btn1 = false;
    this.btn = true;
    this.SearchForm = this.fb.group({
      name: this.fb.group({
        name_criteria: [""],
        program_name: [""]
      }),
      led_by: this.fb.group({
        ledby_criteria: [""],
        led_by: [""]
      }),
      start_date: this.fb.group({
        start_date: [""]
      }),
      end_date: this.fb.group({
        end_date: [""]
      }),
      currency: this.fb.group({
        currency_criteria: [""],
        currency: [""]
      }),
      value: this.fb.group({
        value_criteria: [""],
        value: [""]
      }),
      status: this.fb.group({
        status_criteria: [""],
        status: [""]
      })
    });
  }

  redirect(id) {
    // console.log(id)
    this._router.navigate(["/viewProgram", id]);
  }

  get_Deployed_amt(id) {
    for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
      if (this.dashboard_deployed_amt[i].program_id == id) {
        return this.dashboard_deployed_amt[i].program_deployed_amount;
      }
    }
    return 0;
  }

  search() {
    if (this.SearchForm.value.start_date.start_date === "") {
      var day = parseInt(this.SearchForm.value.start_date.start_date.day);
      var month = parseInt(this.SearchForm.value.start_date.start_date.month);
      var year = parseInt(this.SearchForm.value.start_date.start_date.year);
      var date = new Date(Date.UTC(year, month - 1, day));
      this.SearchForm.value.start_date.start_date = date;
    }

    if (this.SearchForm.value.end_date.end_date != "") {
      var day1 = parseInt(this.SearchForm.value.end_date.end_date.day);
      var month1 = parseInt(this.SearchForm.value.end_date.end_date.month);
      var year1 = parseInt(this.SearchForm.value.end_date.end_date.year);
      var date1 = new Date(Date.UTC(year1, month1 - 1, day1));
      this.SearchForm.value.end_date.end_date = date1;
    }

    let data;
    if (
      this.SearchForm.value.name.name === "" &&
      this.SearchForm.value.led_by.led_by === "" &&
      this.SearchForm.value.start_date.start_date === "" &&
      this.SearchForm.value.end_date.end_date === "" &&
      this.SearchForm.value.value === "" &&
      this.SearchForm.value.currency.currency === "" &&
      this.SearchForm.value.status.status === ""
    ) {
      data = {
        offset: 0,
        limit: 1000
      };
    } else {
      data = {
        offset: 0,
        limit: 1000,
        ...this.SearchForm.value.name,
        ...this.SearchForm.value.led_by,
        ...this.SearchForm.value.start_date,
        ...this.SearchForm.value.end_date,
        ...this.SearchForm.value.currency,
        ...this.SearchForm.value.value,
        ...this.SearchForm.value.status
      };
    }

    console.log(data);
    
    // this.accordion();
    this.loading = true;
    this.count1 = 0;
    this.count2 = 0;
    this.deployed_total = 0;
    this._auth
      .program_search(data)
      .toPromise()
      .then((res: any) => {
        if (res) {
          console.log('result', res);
          
          this.loading = false;
          this.data = res.data;
          this.dataSource = new MatTableDataSource(this.data);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.programs = res;

          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].status == "IN-PROGRESS") {
              this.btn_color = "btn btn-warning btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "ACTIVE") {
              this.btn_color = "btn btn-success btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "CLOSED") {
              this.btn_color = "btn btn-danger btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "PENDING") {
              this.btn_color = "btn btn-secondary btn-sm";
              this.data[i][8] = this.btn_color;
            } else {
              this.btn_color = "btn btn-info btn-sm";
              this.data[i][8] = this.btn_color;
            }
          }
        }
      });
  }

  // accordion() {
  //   if (this.btn === true) {
  //     this.btn = false;
  //     this.btn1 = true;
  //   } else {
  //     this.btn = true;
  //     this.btn1 = false;
  //     // this.dataSource = null;
  //   }
  // }

  get_Deployed_amount(id) {
    for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
      if (this.dashboard_deployed_amt[i].program_id == id) {
        return this.dashboard_deployed_amt[i].program_deployed_amount;
      }
    }
    return 0;
  }

  reset() {
    this.SearchForm.reset();
    this.ngOnInit();
  }

 
}
