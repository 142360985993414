import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "app/services/push.service";
import { DatePipe } from "@angular/common";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

declare var $: any;

declare interface DataTable {
  dataRows: string[][];
}
@Component({
  selector: "app-seller-list",
  templateUrl: "./seller-list.component.html",
  styleUrls: ["./seller-list.component.css"]
})
export class SellerListComponent implements OnInit {
  public dataTable: DataTable;
  btn_color: string;
  sellers: any;
  loading: boolean;
  data: any;
  dat = [];
  d1: any;
  d: any;
  date = [];
  selected: any;
  date1: Date;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    "display_name",
    "create_date",

    "Lst_Rec_days",
    "region",
    "kyc_status",
    "perm_id",
    "worldcheck",
    "user_status",
    "status_updated_on"
  ];
  dataSource: MatTableDataSource<any>;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(private _auth: AuthService, private _route: Router, private push: PushService) {}

  ngOnInit() {
    this.loading = true;
    this._auth
      .getseller()
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.loading = false;

          this.data = res;
          this.sellers = this.data;
          this.d1 = res;
          this.dataSource = new MatTableDataSource(this.data);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].user_status == "RECEIVED") {
              this.btn_color = "btn btn-warning btn-sm";
              this.data[i][7] = this.btn_color;
            } else if (this.data[i].user_status == "APPROVED") {
              this.btn_color = "btn btn-success btn-sm";
              this.data[i][7] = this.btn_color;
            } else if (this.data[i].user_status == "INVITE") {
              this.btn_color = "btn btn-info btn-sm";
              this.data[i][7] = this.btn_color;
            } else if (this.data[i].user_status == "TERMS") {
              this.btn_color = "btn btn-secondary btn-sm";
              this.data[i][7] = this.btn_color;
            } else if (this.data[i].user_status == "KYC") {
              this.btn_color = "btn btn-primary btn-sm";
              this.data[i][7] = this.btn_color;
            } else if (this.data[i].user_status == "HOLD") {
              this.btn_color = "btn btn-danger btn-sm";
              this.data[i][7] = this.btn_color;
            } else {
              this.btn_color = "btn btn-primary btn-sm";
              this.data[i][7] = this.btn_color;
            }
          }

          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].kyc_status == "pending") {
              this.btn_color = "btn btn-warning btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].kyc_status == "waived") {
              this.btn_color = "btn btn-primary btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].kyc_status == "unavailable") {
              this.btn_color = "btn btn-secondary btn-sm";
              this.data[i][8] = this.btn_color;
            } else {
              this.btn_color = "btn btn-success btn-sm";
              this.data[i][8] = this.btn_color;
            }
          }
        }
      });
  }

  ngAfterViewInit() {
    $("#datatable").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });
  }

  get_days() {
    this.d = new Date();

    for (var i = 0; i < this.d1.length; i++) {
      this.dat[i] = new Date(this.d1[i].create_date);

      return Math.round(Math.round((this.d.getTime() - new Date(this.dat[i]).getTime()) / (1000 * 3600 * 24)));
    }
  }

  get_status_days(date) {
    if (date == null) {
      return "N/A";
    } else {
      this.date1 = new Date();
      var date2 = new Date(date);

      return Math.round((this.date1.getTime() - date2.getTime()) / (1000 * 3600 * 24));
    }
  }

  navigate(id) {
    this._route.navigate(["/sellerView", id]);
  }

  get_status_color(status) {
    if (status === "AMBER") {
      return "fa fa-flag fa-2x amber";
    } else if (status === "RED") {
      return "fa fa-flag fa-2x danger";
    } else if (status === "GREEN") {
      return "fa fa-flag fa-2x success";
    } else if (status === "GREY") {
      return "fa fa-flag fa-2x secondary";
    } else if (status === "PURPLE") {
      return "fa fa-flag fa-2x purple";
    } else {
      return "fa fa-flag fa-2x default";
    }
  }
}
