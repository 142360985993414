import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { EnvService } from "app/services/env.service";
import { PushService } from "app/services/push.service";
import { ReCaptcha2Component } from "ngx-captcha";

@Component({
  selector: "app-on-boarding",
  templateUrl: "./on-boarding.component.html",
  styleUrls: ["./on-boarding.component.css"]
})
export class OnBoardingComponent implements OnInit {
  @ViewChild("captchaElem", { static: false }) captchaElem: ReCaptcha2Component;
  @ViewChild("langInput", { static: false }) langInput: ElementRef;
  registerForm: FormGroup;
  submitted: boolean;
  passcode: any;
  encodeurl: string;
  decodeurl: string;
  isDisabled: boolean;
  user_type: string;
  logo_url: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private push: PushService,
    private env_serv: EnvService
  ) {
    if ((this.env_serv.Page_Name === 'Refinitiv')) {
      this.logo_url = './assets/img/refinitiv-logo.png';
    } else {
      this.logo_url = './assets/img/sb-logo.png';
    }
  }

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "en";
  public type: "image" | "audio";

  ngOnInit() {
    this.passcode = this._route.snapshot.paramMap.get("id");
    this.encodeurl = this._route.snapshot.paramMap.get("url");
    this.decodeurl = decodeURI(this.encodeurl);
    this.user_type = this._route.snapshot.queryParamMap.get("q");

    const lang = "en";

    this.registerForm = this.formBuilder.group({
      email: [this.decodeurl],
      password: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      retyped: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      trxnpass: this.user_type === "B" ? "" : ["", [Validators.required, Validators.minLength(8), Validators.maxLength(20)]],
      trxnpass1: this.user_type === "B" ? "" : ["", [Validators.required, Validators.minLength(8), Validators.maxLength(20)]]
      //  recaptcha: ['', Validators.required]
    });
  }

  //  handleSuccess(data) {
  //    // console.log(data);
  //  }

  authenticate() {
    this.submitted = true;
    console.log(this.registerForm.invalid)
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      this.push.showNotification("Please Fill The Required Fields", "danger");
      return;
    }

    var data = {
      user_email: this.registerForm.value.email,
      user_password: this.registerForm.value.password,
      txn_password: this.registerForm.value.trxnpass
    };
    var d = JSON.stringify(data);
    // console.log(d);
    this._auth
      .updatePassword(this.passcode, d)
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.push.showNotification("Your Credentials Have Been Updated! Please login with new details.", "success");
          this.router.navigate(["/login"]);
        }
      });
  }
}
