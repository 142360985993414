import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';

import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-eikon-check',
  templateUrl: './eikon-check.component.html',
  styleUrls: ['./eikon-check.component.css']
})
export class EikonCheckComponent implements OnInit {

  constructor(private _auth: AuthService, private router: Router, private push: PushService, private _route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log(this._route.snapshot.paramMap.get("email"))
    this._auth.eikon_user_check(JSON.stringify({ eikon: true, email: this._route.snapshot.paramMap.get("email") })).toPromise().then((res: any) => {

      var token_val = jwt_decode(res["token"]);
      console.log(token_val)
      sessionStorage.setItem("logo", token_val["party"].logo);
      window.sessionStorage.setItem("login", token_val["user"].user_login);
      window.sessionStorage.setItem("token", res["token"]);
      window.sessionStorage.setItem("id", token_val["user"].role_id);
      window.sessionStorage.setItem("seller_id", token_val["user"].user_party_id);
      window.sessionStorage.setItem("company_name", token_val["party"].display_name);
      window.sessionStorage.setItem("credit_limit", token_val["party"].credit_limit);
      window.sessionStorage.setItem("user_id", token_val["user"].user_id);
      var role = parseInt(sessionStorage.getItem("id"));
      console.log(role)
      if (token_val["user"].active === true) {
        if (role === 1) {
          sessionStorage.setItem("admin", "true");

          this.router.navigate(["/admin"]);
        } else if (role === 3) {

          this._auth
            .getStatus(sessionStorage.getItem("seller_id"))
            .toPromise()
            .then((res: any) => {
              let status = res["user_status"]
              console.log(status)
              window.sessionStorage.setItem("status", status);
              if (res["user_status"] === "APPROVED") {
                this.router.navigate(["/buyer"]);
              } else {
                this.router.navigate(["/login"]);
                this.push.showNotification("Unauthorized User", "danger");

              }
            });

        } else if (role === 4) {

          this._auth
            .getStatus(sessionStorage.getItem("seller_id"))
            .toPromise()
            .then((res: any) => {
              let status = res["user_status"]
              console.log(status)
              window.sessionStorage.setItem("status", status);
              if (res["user_status"] === "APPROVED") {
                this.router.navigate(["/funder"]);
              } else {
                this.router.navigate(["/login"]);
                this.push.showNotification("Unauthorized User", "danger");

              }
            });
        }
        else {
          this.router.navigate(["/login"]);
          sessionStorage.clear();
          this.push.showNotification("Unauthorized User", "danger");
        }
      }
    }, err => {
      this.router.navigate(["/login"]);
      sessionStorage.clear();
      this.push.showNotification("Unauthorized User", "danger");
    })
  }

}
