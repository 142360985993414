import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';
import { SharedService } from 'app/services/shared.service';
// import * as $ from 'jquery';


@Component({
  selector: 'app-onboarding-form',
  templateUrl: './onboarding-form.component.html',
  styleUrls: ['./onboarding-form.component.css']
})
export class OnboardingFormComponent implements OnInit {
  data: any;
  file: any;
  party_id: any;
  user_page_list: string='';

  constructor(private sharedService: SharedService, private _auth: AuthService, private push: PushService, private _router: Router)
  {
    this.user_page_list = window.location.href.split('/')[3] === 'funders' ? "/funders/funder-list" : 'buyers/buyer-list';

  }
  
  ngOnInit() {
    this.sharedService.sharedMessage1.subscribe(async (res: any) => { 
      this.data =  await res.kyc_data_points
      this.party_id = res.party_id
    })
  }
  show() {
    console.log(this.data)
    this._auth.invite_user_patch(JSON.stringify({kyc_data_points:this.data, party_id: this.party_id})).subscribe(async(res: any)=>{
      this.push.showNotification('Success','success')
      this._router.navigate([this.user_page_list]);

    }, (err) => {
      this.push.showNotification('Something Went Wrong','success')
    })
  }

  file_upload(event,node, n) { 

    if (event.target.files.length > 0) {
      this.file = event.target.files[0];

      this._auth
        .file_upload('12234',this.file )
        .toPromise()
        .then(
          (res: any) => {
            n.value = res.filename
            console.log(n)
            event.target.value = "";
            this.push.showNotification("Upload Success", "success");
          },
          error => {
            this.push.showNotification("Upload Failed", "danger");
          }
        );
    }
  }

  ngOnDestroy(){
    this.sharedService.clear()
  }
 }
