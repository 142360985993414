import { Component, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DatePipe, formatDate, KeyValue } from "@angular/common";
import { PushService } from "app/services/push.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SharedService } from "app/services/shared.service";
import { iif } from "rxjs";
import { AuditLogsComponent } from './audit-logs/audit-logs.component'
import { MappingTableComponent } from './mapping-table/mapping-table.component'
import { TreeComponent } from "@circlon/angular-tree-component";
declare const $: any;


@Component({
  selector: "app-view-program",
  templateUrl: "./view-program.component.html",
  styleUrls: ["./view-program.component.css"]
})
export class ViewProgramComponent implements OnInit {

  @ViewChild(AuditLogsComponent) audit: AuditLogsComponent;
  @ViewChild(MappingTableComponent) mapping: MappingTableComponent;
  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  objectkeys = Object.keys;
  nodes = [];
  nodes1 = []
  options = {
    allowDrag: true,
    allowDrop: true,
   
  };
  list: any;
  FunderForm: FormGroup;
  id: number;

  ProgramForm: FormGroup;
  prg: any;
  value = "";
  funded: any;
  sub: number;
  per: any;
  isDisabled = true;
  clicked = false;
  clicked1 = false;
  pr_id: any;
  record: any;
  fund: number;
  maxval: any;
  maxval1: number;
  party_name: Object;
  funderlist: any;
  status: any;
  program_id: any;
  pct: number;
  total_fund: any;
  prg_name: any;
  length: any;
  party_name_len: any;
  b_name: any;
  load: boolean;
  curr: {};
  public adjust: any = {};
  public adjust_pct: any = {};
  data1 = {
    program_id: {},
    program_name: {},
    program_type_id: {},
    buyer_id: {},
    seller_id: {},
    sponsor_id: "ADMIN",
    description: {},
    date_created: {},
    start_date: {},
    end_date: {},
    date_approved: {},
    program_status: {},
    seller_max_limit: {},
    program_max_limit: {},
    max_funding_pct: {},
    service_charges_pct: {},
    interest_pct: {},
    max_inv_credit_days: {},
    insurance_details: {},
    with_recourse: {},
    program_currency: {},
    notes: {},
    create_uid: {},
    program_value: {},
    status: {},
    alert_threshold_pct: {},
    additional_transaction_fees: {},
    invite_participation: {},
    display: false,
    stage:{}
  };
  funder_list_array = {};
  fund_list: any;
  record_length: any;
  loading: boolean=false;
  data: [];
  file: File;
  hide: boolean;
  import_btn: boolean;
  uploadForm: FormGroup;
  deployed_amt: number;
  AdditionalForm: FormGroup;
  funders: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** Funder Data Table Columns Defination */
  displayedColumns: string[] = [ "display_name", "amount_funded", "funded_pct", "action"];
  dataSource: MatTableDataSource<any>;

  /** Funder Participated Data Table Columns Defination */
  displayedColumns1: string[] = ["action", "display_name", "amount_funded", "funded_pct", "adjustment", "adjusted_funded_pct"];
  dataSource1: MatTableDataSource<any>;
data3=[]
  data4 = [];

  displayedColumns3: string[] = ["user_name", "invoice_number", "notes", "created", "details"];
  dataSource3: MatTableDataSource<any>;

  loading_btn_show: boolean;
  invite_participation: any;
  per1: number = 0;
  data5 = [];
  data6: any;
  insurance_details: any;
  minDate1: { year: number; month: number; day: number };
  minDate: { year: number; month: number; day: number };
  shared_res: boolean=false;
  commodity_data: any;

  program_data_points: any;
  program_redacted_data_points: any;
  settle_data: any;
  settle_id: any;
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();

    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }
  }

  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private push: PushService,
    private http: HttpClient,
    private sharedService: SharedService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  ngOnInit() {
   
       this.id = parseInt(this._route.snapshot.paramMap.get("id"));
        this.loading = true;
        this.data4 = this.data3;
        for (const i of this.data4) {
          i.checked = false;
        }
  
        this.FunderForm = this.fb.group({
          amount_funded: ["", [Validators.required]],
          funded_pct: [""],
          funder_id: ["", Validators.required],
          date_created: "",
          date_approved: "",
          program_id: 1,
          notes: ""
        });
  
        this.FunderForm.controls["amount_funded"].disable();
  
        this.uploadForm = this.fb.group({
          uploaded: [""]
        });
        var pipe = new DatePipe("en-US");
        this.loading = true;
        this._auth
          .editProgram(this.id)
          .toPromise()
          .then(async(res:any) => {

            /**
             * 
             * Checking If the Response 'TYPE' is Commodity
             * 
             */
            this.shared_res = await res.type === 'Commodity' ? true: false
            
            this.ProgramForm = this.fb.group(
              {
                program_id: res["program_id"],
                description: res["description"],
                start_date: this.formatDate(res["start_date"]),
                end_date: this.formatDate(res["end_date"]),
  
                alert_threshold_pct: [res["alert_threshold_pct"], [Validators.min(1), Validators.max(80)]],
                max_funding_pct: [res["max_funding_pct"], [Validators.min(1), Validators.max(100)]],
  
                service_charges_pct: [res["service_charges_pct"], [Validators.min(0), Validators.max(3.5)]],
                interest_pct: [res["interest_pct"], [Validators.min(0), Validators.max(15)]],
                max_inv_credit_days: [res["max_inv_credit_days"]],
                insurance_details: this.fb.group({
                  policy_number: res["insurance_details"] === null ? "" : res["insurance_details"].policy_number,
                  policy_holder: res["insurance_details"] === null ? "" : res["insurance_details"].policy_holder,
                  other_parties_to_policy:
                    res["insurance_details"] === null ? "" : res["insurance_details"].other_parties_to_policy,
                  inception_date: res["insurance_details"] === null ? "" : this.formatDate(res["insurance_details"].inception_date),
                  coverage_period_start_date:
                    res["insurance_details"] === null ? "" : this.formatDate(res["insurance_details"].coverage_period_start_date),
                  coverage_period_end_date:
                    res["insurance_details"] === null ? "" : this.formatDate(res["insurance_details"].coverage_period_end_date),
                  credit_limit: res["insurance_details"] === null ? "" : res["insurance_details"].credit_limit,
                  credit_period: res["insurance_details"] === null ? "" : res["insurance_details"].credit_period,
                  description_coverage: res["insurance_details"] === null ? "" : res["insurance_details"].description_coverage
                }),
                with_recourse: res["with_recourse"],
                program_currency: res["program_currency"],
  
                program_value: [res["program_value"], [Validators.min(1)]],
                notes: [res["notes"]],
                additional_transaction_fees: [res["additional_transaction_fees"]]
              },
              { validators: [this.checkDate, this.checkDate2] }
            );
            
            this.nodes1 = await res['program_data_points']
            this.data1 = {
              program_id: res["program_id"],
              program_name: res["program_name"],
              program_type_id: res["program_type_id"],
              buyer_id: res["buyer_id"],
              seller_id: res["seller_id"],
              sponsor_id: "ADMIN",
              description: res["description"],
              date_created: res["date_created"],
              start_date: res["start_date"],
              end_date: res["end_date"],
              date_approved: res["date_approved"],
              program_status: res["program_status"],
              seller_max_limit: res["seller_max_limit"],
              program_max_limit: res["program_max_limit"],
              max_funding_pct: res["max_funding_pct"],
              service_charges_pct: res["service_charges_pct"],
              interest_pct: res["interest_pct"],
              max_inv_credit_days: res["max_inv_credit_days"],
              insurance_details: typeof res["insurance_details"] === "string" ? null : res["insurance_details"],
              with_recourse: res["with_recourse"],
              program_currency: res["program_currency"],
              notes: res["notes"],
              create_uid: res["create_uid"],
              program_value: res["program_value"],
              invite_participation: true,
              status: res["status"],
              alert_threshold_pct: "",
              additional_transaction_fees: res["additional_transaction_fees"],
              display: true,
              stage: res['stage'] === null ?"" : res['stage'].status
            };
            
            this.program_data_points = await res['program_data_points']


            this.program_data_points =  [
    {
      "name": "COUNTER PARTIES",
      "children": [
        {
          "id": 6965083794168,
          "name": "OBLIGOR",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 5563455049989,
              "name": "COMPANY OVERVIEW",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 8087253608567,
              "name": "OWNERSHIP SUMMARY",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 5287154068593,
              "name": "CREDIT RATING",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 9614939099664,
              "name": "DBB CREDIT REPORT 2018",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "FILE"
            },
            {
              "id": 1327593933877,
              "name": "PARENT COMPANY",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 7764808112092,
                  "name": "COMPANY OVERVIEW",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "url_name": "",
                  "field_type": "URL"
                },
                {
                  "id": 4312805277688,
                  "name": "OWNERSHIP SUMMARY",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "url_name": "",
                  "field_type": "URL"
                },
                {
                  "id": 1049037574828,
                  "name": "CREDIT RATING",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "url_name": "",
                  "field_type": "URL"
                },
                {
                  "id": 8277504541534,
                  "name": "CORPORATE ISSUER CURVE",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "url_name": "",
                  "field_type": "URL"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 7908925044487,
          "name": "SELLER",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 4020899896963,
              "name": "COMPANY OVERVIEW",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 1193349532151,
              "name": "OWNERSHIP SUMMARY",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 2634176334029,
              "name": "CREDIT RATING",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 7611825276296,
              "name": "CORPORATE ISSUER CURVE",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        }
      ]
    },
    {
      "name": "Transaction",
      "children": [
        {
          "id": 3441919098164,
          "name": "Commodity",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 2357947972267,
              "name": "Commodity Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 8020555390499,
              "name": "Chemical Composition",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2191229241417,
              "name": "physical Composition",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 2657826677467,
          "name": "Vessel",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 1573996500666,
              "name": "Vessel Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 9641496210770,
              "name": "Vessel Screening",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 18824591925,
              "name": "Vessel Location",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 7624420812771,
          "name": "Voyage Details",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 8942353428163,
              "name": "Country of Origin",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 1445046559784,
              "name": "Port of Loading",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 4373155736097,
              "name": "Port of Discharge",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "url_name": "",
              "field_type": "URL"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 2431503975461,
          "name": "Supporting Documents",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 2657117585528,
              "name": "Bill Of Lading",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 804656757971,
                  "name": "B/L NO",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "children": [
                    {
                      "id": 6735344877126,
                      "name": "DATE OF ISSUE",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "DATE"
                    },
                    {
                      "id": 4440877688430,
                      "name": "PLACE OF ISSUE",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 9219396081539,
                      "name": "Shipper Name",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 6915617202551,
                      "name": "Shipper Address",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 3139695104366,
                      "name": "Consignee Name",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 1526726023297,
                      "name": "Consignee Address",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 2649551635572,
                      "name": "Vessel",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 8380375575126,
                      "name": "Port Of Loading",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 7400565515007,
                      "name": "Port Of Discharge",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 8920467947793,
                      "name": "Description of Goods",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 3480362300222,
                      "name": "Gross Weight (WMT)",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    },
                    {
                      "id": 9703865357805,
                      "name": "Charterer/Agent",
                      "value": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participcation_approved": ""
                      },
                      "append_text": {
                        "pre_interest": "",
                        "interest_approved": "",
                        "participation_approved": ""
                      },
                      "field_type": "INPUT"
                    }
                  ],
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "INPUT"
            },
            {
              "id": 7027388296202,
              "name": "LETTER OF CREDIT",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 7680615653321,
                  "name": "Date of Creation",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 8408784014743,
                  "name": "ISN/OSN",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9585937020208,
                  "name": "Session Number",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5469775466186,
                  "name": "From",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8799451045111,
                  "name": "To",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 827031391350,
                  "name": "Form of DC",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5438217065016,
                  "name": "DC #",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9730805357100,
                  "name": "Date of Issue",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 5322067209786,
                  "name": "Date of Expiry",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 9130417805886,
                  "name": "Company",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1093170532009,
                  "name": "Applicant Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4713771712887,
                  "name": "Applicant Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1309796196071,
                  "name": "Beneficiary Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3415169030312,
                  "name": "Beneficiary Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1126722727126,
                  "name": "Currency Code",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7967164038967,
                  "name": "Amount",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3125087073010,
                  "name": "Credit Amount Tolerance 1 (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3963357875109,
                  "name": "Credit Amount Tolerance 2 (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 687832227085,
                  "name": "By Identifier Code",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7829131097244,
                  "name": "Drawee Identifier Code",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7412915343174,
                  "name": "Partial Shipments",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5976033676353,
                  "name": "Transshipment",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3075469913380,
                  "name": "Port of Loading",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2177146917800,
                  "name": "Port of Discharge",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5118573010704,
                  "name": "Date of Shipment",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2764764506640,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5984151997471,
                  "name": "Quantity (WMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4479586917553,
                  "name": "Packing",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5350219895794,
                  "name": "Origin",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 2539296613489,
              "name": "CERTIFICATE OF ORIGIN",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 3623621648121,
                  "name": "Certificate Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 6053325502499,
                  "name": "Issuer Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8944959404390,
                  "name": "Issuer Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1507014711544,
                  "name": "Name of The Carrying Vessel",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6872737381170,
                  "name": "BOL Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6411445558517,
                  "name": "Port Of Loading",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6832181498871,
                  "name": "Port Of Discharge",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1517861422548,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5956445244344,
                  "name": "Total Wet Weight (WMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 412794254381,
                  "name": "Total Dry Weight (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2522743618047,
                  "name": "Country of Origin",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8097437419073,
                  "name": "Packing Type",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 3720856013083,
              "name": "CERTIFICATE OF ANALYSIS",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 118700284650,
                  "name": "Certificate Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 3686639288686,
                  "name": "Issuer Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3407663848407,
                  "name": "Issuer Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4177864376955,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9456549611027,
                  "name": "Total Dry Weight (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4719600292850,
                  "name": "FE (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1046208125058,
                  "name": "SIO2 (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6256333843549,
                  "name": "AL203 (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 349034962853,
                  "name": "PHOSPHORUS (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4489035216994,
                  "name": "MN %)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1729107843366,
                  "name": "LOI (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 317152485847,
                  "name": "SIZE ABOVE 6.30 MM (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1738075592424,
                  "name": "SIZE BELOW 0.15 MM (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 31315914926,
                  "name": "MOISTURE (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 543026656689,
              "name": "CERTIFICATE OF WEIGHT",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 6621692847284,
                  "name": "Certificate Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 3746339593431,
                  "name": "Issuer Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1510373420937,
                  "name": "Issuer Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7856739484925,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9382881065376,
                  "name": "Total Dry Weight (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2013388911422,
                  "name": "Total Wet Weight (WMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7028566435159,
                  "name": "MOISTURE (MT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 658745644588,
                  "name": "MOISTURE (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 2667628927504,
              "name": "Sales Invoice",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 5592139124598,
                  "name": "Beneficiary Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9529396144577,
                  "name": "Beneficiary Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9794481103396,
                  "name": "Invoice No.",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8161835359100,
                  "name": "Invoice Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 5386210206256,
                  "name": "B/L Number",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2626234443739,
                  "name": "Shipment Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 5960253482814,
                  "name": "Buyer Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6288137998007,
                  "name": "Buyer Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4468243940893,
                  "name": "Country of Origin",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5144669734107,
                  "name": "Export Terms",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7809656971311,
                  "name": "Payment Terms",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7922836050381,
                  "name": "Vessel Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7391323447208,
                  "name": "Port Of Loading",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9619090417298,
                  "name": "Port Of Discharge",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1001950950613,
                  "name": "Final Destination",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 216168181096,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3081425438414,
                  "name": "Total Wet Weight (WMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6959373462150,
                  "name": "Moisture (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2800755832880,
                  "name": "Total Dry Weight (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8904579584051,
                  "name": "CHEMICAL COMPOSITION",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5558829164822,
                  "name": "PHYSICAL COMPOSITION",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8639768400959,
                  "name": "Quantity (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6372937674651,
                  "name": "Currency",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 4934320366228,
                  "name": "Rate",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3713302583865,
                  "name": "Total Payables",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5358403441564,
                  "name": "Invoice Assigned To",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6014495439481,
                  "name": "Account Number",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            },
            {
              "id": 5320497074954,
              "name": "SALES CONTRACT",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "children": [
                {
                  "id": 4115929521189,
                  "name": "Beneficiary Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5000725590222,
                  "name": "Beneficiary Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6783179348537,
                  "name": "Invoice No.",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9268096745991,
                  "name": "Invoice Date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 1801131717012,
                  "name": "B/L Number",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 322707813500,
                  "name": "Shipment date",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "DATE"
                },
                {
                  "id": 2763734459901,
                  "name": "Buyer Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8954422677972,
                  "name": "Buyer Address",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6773450964999,
                  "name": "Country of Origin",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2357951668999,
                  "name": "Export Terms",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 3850966861844,
                  "name": "Payment Terms",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5070838537560,
                  "name": "Vessel Name",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7442176382029,
                  "name": "Port Of Loading",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 8801280311422,
                  "name": "Port Of Discharge",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 9400164339291,
                  "name": "Final Destination",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 1841160013679,
                  "name": "Commodity",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6210639604859,
                  "name": "Total Wet Weight (WMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5070840797152,
                  "name": "Moisture (%)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6638167484343,
                  "name": "Total Dry Weight (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6333978309147,
                  "name": "CHEMICAL COMPOSITION",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 7321122495066,
                  "name": "PHYSICAL COMPOSITION",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2569408204542,
                  "name": "Quantity (DMT)",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 5954644832715,
                  "name": "Currency",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6509598284329,
                  "name": "Rate",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 2315161781435,
                  "name": "Total Payables",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6070742825423,
                  "name": "Invoice Assigned To",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                },
                {
                  "id": 6263073772946,
                  "name": "Account Number",
                  "value": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participcation_approved": ""
                  },
                  "append_text": {
                    "pre_interest": "",
                    "interest_approved": "",
                    "participation_approved": ""
                  },
                  "field_type": "INPUT"
                }
              ],
              "url_name": "",
              "field_type": "URL"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        }
      ]
    },
    {
      "name": "Financing",
      "children": [
        {
          "id": 2672721939553,
          "name": "RE-purchase Agreement",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 2266971172613,
          "name": "BAFT",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 6669761176112,
          "name": "NDA",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 5503370705376,
          "name": "NOA",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 4329605642229,
              "name": "Date of Issue",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 8767737099316,
              "name": "Place of Issue",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 5879932651626,
              "name": "Assignor",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 1355666141475,
              "name": "Assignor Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 3267367818514,
              "name": "Debtor Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 330793278826,
              "name": "Debtor Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 5815012634016,
              "name": "Bank Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2619877106470,
              "name": "Bank Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 22639375095,
              "name": "Bank Swift",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 698109169343,
              "name": "Bank Account Number",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 3471700555263,
              "name": "Correspondent Bank",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2903370291914,
              "name": "Correspondent Bank Swift",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 1807465738656,
              "name": "Purchase Agreement Number",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 5611067463357,
              "name": "Purchase Agreement Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 4296314956560,
              "name": "Invoice No.",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 7370837872070,
              "name": "Invoice Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 6275448890646,
              "name": "Invoice Due Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 6164599371294,
              "name": "Quantity (DMT)",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 3125879292252,
              "name": "Currency",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 1315426006171,
              "name": "Rate",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 5747547762565,
              "name": "Total Payables",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 5743717058406,
          "name": "Acknowledgement of NOA",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "children": [
            {
              "id": 3400655054982,
              "name": "Date of Issue",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 3783546784358,
              "name": "NOA Issue Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 873831023791,
              "name": "Assignor",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2522923263039,
              "name": "Assignor Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 6936059607027,
              "name": "Debtor Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 4170195734671,
              "name": "Debtor Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 712062985038,
              "name": "Bank Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 75418491454,
              "name": "Bank Address",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 3516945612526,
              "name": "Bank Swift",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 596325543,
              "name": "Bank Account Number",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2978256281443,
              "name": "Supplier Name",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 3197586027035,
              "name": "Purchase Agreement Number",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 8317738067520,
              "name": "Purchase Agreement Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 9527580235221,
              "name": "Payment Due Date",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "DATE"
            },
            {
              "id": 1634480483107,
              "name": "Currency",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            },
            {
              "id": 2375998145124,
              "name": "Total Payables",
              "value": {
                "pre_interest": "",
                "interest_approved": "",
                "participcation_approved": ""
              },
              "append_text": {
                "pre_interest": "",
                "interest_approved": "",
                "participation_approved": ""
              },
              "field_type": "INPUT"
            }
          ],
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 5011373371250,
          "name": "Irrevocable Payment undertaking",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        }
      ]
    },
    {
      "name": "STRUCTURE",
      "children": [
        {
          "id": 2861337095306,
          "name": "Custodian",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        },
        {
          "id": 2085452646763,
          "name": "Trustee",
          "value": {
            "pre_interest": "",
            "interest_approved": "",
            "participcation_approved": ""
          },
          "append_text": {
            "pre_interest": "",
            "interest_approved": "",
            "participation_approved": ""
          },
          "url_name": "",
          "field_type": "URL"
        }
      ]
    }
  ]






            this.insurance_details = res["insurance_details"];
            this.program_redacted_data_points =  await res['program_redacted_data_points']
            this.invite_participation = res["invite_participation"];
  
            /**
             *
             *
             * API Call For Listing All The Funders Who Have
             * Accepted the Invitation And Participated In the Program.
             *
             *
             *
             * */
  
            // if (this.invite_participation === true) {



              /***
               * 
               * Based On the Status (ACCEPTED, SELECTED,APPROVED)
               * DATA gets Segregated
               * 
               * 
               *  */            
              this._auth
                .funders_accepted_invitation(this._route.snapshot.paramMap.get("id"))
                .toPromise()
                .then(
                  (res: any) => {
                    let data = res;
                    this.data4 = data;
                    this.data5 = this.data4.filter(x => x.prospect === "ACCEPTED");
                    if (this.data1.status === "IN-PROGRESS") {
                      this.data4 = this.data4.filter(x => x.prospect === "SELECTED");
                      this.record = this.data4;
                      for (var i = 0; i < this.data4.length; i++) {
                        this.funder_list_array[i] = this.record[i].funder_id;
                      }
                    } else {
                      this.data4 = this.data4.filter(x => x.prospect === "APPROVED");
                    }
  
                    for (const i of this.data4) {
                      i.adjusted_amount_funded = 0;
                      i.adjusted_funded_pct = 0;
                      // i.checked = false;
                    }
  
                    this.dataSource = new MatTableDataSource(this.data4);
  
                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
  
                    this.dataSource1 = new MatTableDataSource(this.data5);
  
                    this.dataSource1.sort = this.sort;
                    this.dataSource1.paginator = this.paginator;
                    if(this.data1.status === 'IN-PROGRESS'){
                      this.Total();

                    }
                  },
                  err => {
                    this.push.showNotification(err.errMsg, "danger");
                  }
                );
            // } else {
            //   this.addFunders(res["program_id"]);
            // }
  
            if (this.data1.alert_threshold_pct == null) {
              this.data1.alert_threshold_pct = 0;
            } else {
              this.data1.alert_threshold_pct = res["alert_threshold_pct"];
            }
  
            if (this.data1.notes == "") {
              this.data1.notes = "N/A";
            } else {
              this.data1.notes = res["notes"];
            }
  
            if (this.data1.description == null || this.data1.description == "") {
              this.data1.description = "N/A";
            } else {
              this.data1.description = res["description"];
            }
  
            sessionStorage.setItem("program_id", res["program_id"]);
            sessionStorage.setItem("bid", res["buyer_id"]);
            sessionStorage.setItem("program_value", res["program_value"]);
            sessionStorage.setItem("max_fund_pct", res["max_funding_pct"]);
            sessionStorage.setItem("program_type_id", res["program_type_id"]);
            sessionStorage.setItem("program_name", res["program_name"]);
            this._auth
              .program_deployed_amount(this.id)
              .toPromise()
              .then((res: any) => {
                if (res) {
                  if (res["program_deployed_amount"] === null) {
                    this.deployed_amt = 0;
                  } else {
                    this.deployed_amt = res["program_deployed_amount"];
                  }
                }
              });
            this.prg_name = this.data1.program_name;
            this.curr = this.data1.program_currency;
            // console.log(this.data);
            var party_id = sessionStorage.getItem("program_type_id");
            // console.log(party_id);
            this.getFunders();
            // this.addFunders();
            this.loading = false;
          });
  
        /**
         *
         *
         * API Call For Logs Table. All The Logs Getting Generated Related
         * To This Program Will Get Recorded And Being Displayed.
         *
         *
         * */
        // this._auth
        //   .search_type("program", parseInt(this._route.snapshot.paramMap.get("id")))
        //   .toPromise()
        //   .then(
        //     (res: any) => {
        //       let data = res;
        //       this.dataSource3 = new MatTableDataSource(data);
        //       this.dataSource3.sort = this.sort;
        //       this.dataSource3.paginator = this.paginator;
        //     },
        //     err => {
        //       console.log(err);
        //     }
        //   );
  
        // this._auth.getRefresh().subscribe(() => {
        //   // this.getFunders();
        //   this.addFunders();
        // });
  
        // this.getFunders();
        this.get_button_hide();
        this.get_import_hide();
      
  

    


  }

  /**
   *
   *
   *
   * Adjustment Calculation Function Of Funder Participation
   *
   *
   *  */
  calculate(data) { }

  /**
   *
   *
   *
   * On Change Event Enable Text Value Will get Push
   *
   *
   *
   * */

  checkbox_value(event, i) {
    this.data5[i].checked = event.target.checked;
    if (event.target.checked === false) {
      for (const i of this.data5) {
        i.adjusted_amount_funded = 0;
        i.adjusted_funded_pct = 0;
      }
    }
  }

  onSearchChange(searchValue) {
    var year = searchValue.split("-");
    this.minDate1 = {
      year: Number(year[0]),
      month: Number(year[1]),
      day: Number(year[2])
    };
  }

  enable_end_date1() {
    if (
      this.ProgramForm.value.insurance_details.coverage_period_start_date === "" ||
      this.ProgramForm.value.insurance_details.coverage_period_start_date === null
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date) {
    var d = new Date(date);
    let date1 = { year: d.getUTCFullYear(), month: d.getUTCMonth() + 1, day: d.getUTCDate() };

    return date1;
  }

  checkDate(control: FormGroup) {
    let startingDatefield = control.get("start_date");
    let endingDatefield = control.get("end_date");

    if (startingDatefield.value != null && endingDatefield.value != null) {
      var day = parseInt(startingDatefield.value.day);
      var month = parseInt(startingDatefield.value.month);
      var year = parseInt(startingDatefield.value.year);
      var date = new Date(Date.UTC(year, month - 1, day));

      var day1 = parseInt(control.get("end_date").value.day);
      var month1 = parseInt(control.get("end_date").value.month);
      var year1 = parseInt(control.get("end_date").value.year);
      var date1 = new Date(Date.UTC(year1, month1 - 1, day1));

      if (date > date1) {
        endingDatefield.setErrors({ checkDate: true });
        // this.push.showNotification("Error In date", "danger");
      } else {
        null;
      }
    } else {
      null;
    }
  }

  checkDate2(control: FormGroup) {
    let startingDatefield1 = control.get("insurance_details").value.coverage_period_start_date;
    let endingDatefield1 = control.get("insurance_details").value.coverage_period_end_date;
    let dateerror = control.get("insurance_details").get("coverage_period_end_date");

    if (startingDatefield1 != null && endingDatefield1 != null) {
      var day2 = parseInt(startingDatefield1.day);
      var month2 = parseInt(startingDatefield1.month);
      var year2 = parseInt(startingDatefield1.year);
      var date2 = new Date(Date.UTC(year2, month2 - 1, day2));

      var day3 = parseInt(endingDatefield1.day);
      var month3 = parseInt(endingDatefield1.month);
      var year3 = parseInt(endingDatefield1.year);
      var date3 = new Date(Date.UTC(year3, month3 - 1, day3));

      if (date2 > date3) {
        dateerror.setErrors({ checkDate2: true });
      } else {
        null;
      }
    } else {
      null;
    }
  }
  pass_val() {
    if (this.ProgramForm.value.with_recourse == "true") {
      this.ProgramForm.value.program_id = "PRG-R";
      this.prg = this.ProgramForm.value.program_id;
    } else {
      this.ProgramForm.value.program_id = "PRG-N";
      this.prg = this.ProgramForm.value.program_id;
    }
  }
  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
  /**
   *
   *
   *
   *
   *
   * Hide Sttaus From Funders table
   */

  hide_status(val) {
    if (val === "IN-PROGRESS") {
      return false;
    } else {
      return true;
    }
  }

  /**End */
  update_program_data() {
    if (!this.ProgramForm.valid) {
      this.ProgramForm.markAllAsTouched();
      return;
    }

    if (this.ProgramForm.value.start_date != null) {
      var day = parseInt(this.ProgramForm.value.start_date.day);
      var month = parseInt(this.ProgramForm.value.start_date.month);
      var year = parseInt(this.ProgramForm.value.start_date.year);
      var date = new Date(Date.UTC(year, month - 1, day));
      this.ProgramForm.get("start_date").setValue(date);
    }
    if (this.ProgramForm.value.start_date != null) {
      var day1 = parseInt(this.ProgramForm.value.end_date.day);
      var month1 = parseInt(this.ProgramForm.value.end_date.month);
      var year1 = parseInt(this.ProgramForm.value.end_date.year);
      var date1 = new Date(Date.UTC(year1, month1 - 1, day1));
      this.ProgramForm.get("end_date").setValue(date1);
    }
    /**
     * Insurance Coverage Dates Conversion
     *
     *
     */
    if (this.ProgramForm.value.insurance_details.inception_date != null) {
      var day = parseInt(this.ProgramForm.value.insurance_details.inception_date.day);
      var month = parseInt(this.ProgramForm.value.insurance_details.inception_date.month);
      var year = parseInt(this.ProgramForm.value.insurance_details.inception_date.year);
      var date3 = new Date(Date.UTC(year, month - 1, day));
      this.ProgramForm.value.insurance_details.inception_date = date3;
    }

    if (this.ProgramForm.value.insurance_details.coverage_period_start_date != null) {
      var day = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.day);
      var month = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.month);
      var year = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.year);
      var date4 = new Date(Date.UTC(year, month - 1, day));
      this.ProgramForm.value.insurance_details.coverage_period_start_date = date4;
    }

    if (this.ProgramForm.value.insurance_details.coverage_period_end_date != null) {
      var day1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.day);
      var month1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.month);
      var year1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.year);
      var date5 = new Date(Date.UTC(year1, month1 - 1, day1));

      this.ProgramForm.value.insurance_details.coverage_period_end_date = date5;
    }
    this._auth.update_program(this.ProgramForm.value).subscribe(
      (res: any) => {
        $("#myModal2").modal("hide");
        this.ngOnInit();
        this.push.showNotification('Update Successful', "success");
      },
      err => {
        this.push.showNotification(err.error.errMsg, "danger");
      }
    );
  }

  accept_btn_enable(): boolean {
    let per = 0;
    for (const i of this.data5) {
      if (i.checked === true) {
        per += parseFloat(i.adjusted_funded_pct);

        if (per === 100) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
  accept_funders_funding() {
    let participated_funders = [];
    participated_funders = this.data5.filter(x => {
      return x.checked === true;
    });
    for (let a of participated_funders) {
      if (a.adjusted_amount_funded === 0) {
        this.push.showNotification("Amount Must Greater Than Zero", "danger");
      } else {
        let data = {
          funders: participated_funders,
          program_id: sessionStorage.getItem("program_id")
        };

        this._auth
          .interest_shown_selected(data)
          .toPromise()
          .then(
            (res: any) => {
              this.ngOnInit();
              this.push.showNotification("Funder Added For Participation", "success");
            },
            err => {
              this.push.showNotification(err.error.errMsg, "danger");
            }
          );
      }
    }
  }
  display(data) {
    this.data = data.message === undefined ? JSON.stringify(data, null, "\t") : data.message;
    $("#myModal1").modal("show");
  }
  getFunders() {
    this._auth
      .funderList()
      .toPromise()
      .then((res: any) => {
        this.length = res.length;
        if (res) {
          this.funderlist = res;
          this.funderlist = this.funderlist.filter(t => t.user_status == "APPROVED");
        }
        this.load = true;
        this._auth
          .party(3)
          .toPromise()
          .then((res: any) => {
            if (res) this.party_name = res;
            this.party_name_len = res.length;
            for (var i = 0; i < res.length; i++) {
              this.data1[i] = {
                party_id: this.party_name[i].party_id,
                b_name: this.party_name[i].display_name
              };
              if (this.data1[i].party_id === this.data1.buyer_id) {
                this.b_name = this.data1[i].b_name;
              }
            }
            this.load = false;
          });
      });
  }
  get checkstatus(): boolean {
    if (this.data1.program_status == false) {
      return true;
    } else {
      return false;
    }
  }

  get_button_hide(): boolean {
    if (this.data1.status === "PENDING") {
      return false;
    } else {
      return true;
    }
  }

  get_import_hide(): boolean {
    if (this.data1.status === "ACTIVE") {
      return false;
    } else {
      return true;
    }
  }

  get_update_hide(): boolean {
    if (this.data1.status === "IN-PROGRESS") {
      if (this.invite_participation) {
        let per = 0;
        for (let a of this.data4) {
          per += parseFloat(a.funded_pct);
        }
        if (per === 100) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  get_hide() {
    let per = 0;
    for (let a of this.data4) {
      per += parseFloat(a.funded_pct);
    }

    if (per != 100 && this.invite_participation && this.data1.status === "IN-PROGRESS") {
      return true;
    } else {
      return false;
    }
  }
  get_enable(): boolean {
    if (this.sub != 0) {
      return false;
    } else {
      return true;
    }
  }

  getFundername(id) {
    for (var i = 0; i < this.length; i++) {
      if (this.funderlist[i].party_id === id) {
        return this.funderlist[i].display_name;
      }
    }
  }

  Total() {
    this.total_fund = parseInt(sessionStorage.getItem("program_value"));
    this.pct = parseInt(sessionStorage.getItem("max_fund_pct"));
    this.maxval = parseInt(sessionStorage.getItem("program_value"));
    var fund_amt = 0;
    for (var i = 0; i < this.record.length; i++) {
      fund_amt = fund_amt + parseInt(this.record[i].amount_funded);
    }

    this.sub = 0;
    if (fund_amt < this.maxval) {
      this.sub = this.maxval - fund_amt;
      this.maxval = this.sub;
    } else {
      this.isDisabled = false;
    }
  }

  calc() {
    var fund = parseFloat(this.FunderForm.value.amount_funded);
    
    

    this.maxval1 = parseFloat(sessionStorage.getItem("program_value"));
    this.maxval = parseInt(sessionStorage.getItem("program_value"));

    if (fund != 0 && fund <= this.maxval && this.sub != 0) {
      this.isDisabled = true;
      this.per = ((fund / this.maxval1) * 100).toFixed(2);
    } else {
      this.isDisabled = false;
      if (fund === 0) {
        this.FunderForm.controls["amount_funded"].reset();
        this.FunderForm.controls["funded_pct"].reset();
        this.push.showNotification("Amount Must Be Greater Than Zero", "danger");
      }
      if (fund > this.maxval) {
        this.FunderForm.controls["amount_funded"].reset();
        this.FunderForm.controls["funded_pct"].reset();
        this.push.showNotification("Amount Can Not Exceed Pending Amount", "danger");
      }
    }
  }

  /**
   *
   *
   * Participation Request Tab Calculation Function.
   * On Key Up Or Change Event Following Event Will Get Triggered.
   *
   * Calculated Data & New Data Points will be Added.
   */

  collect_data(index) {
    let fund = parseFloat(this.FunderForm.value.amount_funded);

    this.maxval1 = parseFloat(sessionStorage.getItem("program_value"));
    let per = 0;

    for (const i of this.data5) {
      i.program_name = sessionStorage.getItem("program_name");

      if (i.checked === true && i.adjusted_amount_funded != 0) {
        per = (parseFloat(i.adjusted_amount_funded) / parseFloat(sessionStorage.getItem("program_value"))) * 100;
        i.adjusted_funded_pct = per;
        this.data5[index].adjusted_funded_pct = per;
      }
    }
  }


  /**
   * 
   * File Upload Function For KYC Meta Defination
   * 
   * (DYNAMIC FILE UPLOADER)
   */

   file_upload(event,node, n) { 
    console.log(node[n])
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
  
      this._auth
        .file_upload('12234',this.file )
        .toPromise()
        .then(
          (res: any) => {
            node[n] = res.filename
           
            event.target.value = "";
            this.push.showNotification("Upload Success", "success");
          },
          error => {
            this.push.showNotification("Upload Failed", "danger");
          }
        );
    }
  }


  updatestatus() {
    // if (this.invite_participation === true) {
      this.per1 = 0;
      for (let i of this.data4) {
        i.adjusted_amount_funded = parseFloat(i.amount_funded);
        i.adjusted_funded_pct = parseFloat(i.funded_pct);
        this.per1 += parseFloat(i.funded_pct);
      }
      this.data5 = this.data5.concat(this.data4);
      this.data6 = { funders: this.data5, program_id: sessionStorage.getItem("program_id") };
    // }

    var s = true;
    if (this.per1 != 100) {
      this.push.showNotification("Funding Requirements Not Met", "danger");
    } else {
      // if (this.invite_participation === true) {
        this.loading_btn_show = true;
        this._auth
          .admin_accept_reject_invitaion_funders(this.data6)
          .toPromise()
          .then(
            (res: any) => {
              this._auth.updatestatus(this.id, "PENDING").subscribe((res: any) => {
                if (res) {
                  this.loading_btn_show = false;
                  this.push.showNotification("Congratulation, Funders Successfully Added", "success");
                  this._router.navigate(["/admin"]);
                }
              });
            },
            err => {
              this.push.showNotification(err.error.errMsg, "danger");
            }
          );
      // } else {
      //   this._auth.updatestatus(this.id, "PENDING").subscribe((res: any) => {
      //     if (res) {
      //       this.loading_btn_show = false;
      //       this.push.showNotification("Congratulation, Funders Successfully Added", "success");
      //       this._router.navigate(["/admin"]);
      //     }
      //   });
      // }
    }
  }

  removeFunder(id) {
    // if (this.invite_participation === true) {
      let data = this.data4.filter(x => x.funder_id === id);

      let data1 = {
        funder: data[0],
        program_id: sessionStorage.getItem("program_id")
      };
      this._auth.cancel_funding(data1).subscribe((res: any) => {
        this.ngOnInit()
        this.push.showNotification("Funder Deleted Successfully", "success");
      });
    // } else {
    //   this._auth.popFunder(id).subscribe(res => {
    //     if (res === 1) {
    //       this.addFunders(sessionStorage.getItem("program_id"));
    //       this.Total();
    //       this.push.showNotification("Funder Deleted Successfully", "success");
    //     }
    //   });
    // }


  }

  /**
   *
   * Fetching & Displaying Funder Details
   * Who Have Been Added Recently
   *
   */

  // addFunders(id) {
  //   let data = [];
  //   this._auth.getFunder(id).subscribe((res: any) => {
  //     if (res) {
     
  //       this.record = res;
  //       this.data = res;
  //       data = res;
  //       this.per1 = 0;
  //       for (const i of data) {
  //         this.per1 += parseFloat(i.funded_pct);
  //       }
  //       this.dataSource = new MatTableDataSource(this.data);

  //       this.dataSource.sort = this.sort;
  //       this.dataSource.paginator = this.paginator;

  //       this.record_length = res.length;

  //       this.Total();

  //       this.FunderForm.reset();
  //       this.per = "";

  //       for (var i = 0; i < res.length; i++) {
  //         this.funder_list_array[i] = this.record[i].funder_id;
  //       }
  //     }
  //     //
  //   });
  // }

  checkfunder() {
    var id = this.FunderForm.value.funder_id;
    if (this.sub === 0) {
      this.FunderForm.controls["amount_funded"].disable();
    } else {
      this.FunderForm.controls["amount_funded"].enable();
    }
    for (var i = 0; i < this.record.length; i++) {
      if (id != this.funder_list_array[i]) {
      } else {
        this.push.showNotification("This Funder Is Already Part Of The Program", "danger");
        this.FunderForm.reset();
      }
    }
  }

  import(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.uploadForm.value.uploaded = this.file;
    }
  }

  onSubmit(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.uploadForm.value.uploaded = this.file;
      event.target.value = "";

      this._auth
        .postFile(this.file, this.id, this.data1.buyer_id)
        .toPromise()
        .then(
          (res: any) => {
            if (res) {
              this.push.showNotification("Invoice Imported.<br> Please Check Logs For More Information ", "success");
            }
          },
          error => {
            this.push.showNotification("Upload Failed", "danger");
          }
        );
    }
  }

  submit_data(data,id){
    if(data.expected_date != null){
      data.submitted_date = null
    }
    this._auth.settlement_data(JSON.stringify({id: id,settlement_document:data})).subscribe((res:any)=>{
      this.push.showNotification('Data Updated', 'success')
    },err=>{
      this.push.showNotification('Something Went Wrong', 'danger')
    })
  }

  addFunderDetails(): void { 

    if (this.FunderForm.value.amount_funded > 0) {
      let data;

      // if (this.invite_participation === false) {
      //   data = {
      //     program_id: sessionStorage.getItem("program_id"),

      //     funder_id: this.FunderForm.value.funder_id,
      //     amount_funded: this.FunderForm.value.amount_funded,
      //     funded_pct: this.per,
      //     date_created: "",
      //     date_approved: "",
      //     notes: "",
      //     program_name: sessionStorage.getItem("program_name")
      //   };
      //   var obj = JSON.stringify(data);

      //   this._auth.addFunder(obj).subscribe(
      //     res => {
            
      //       // console.log(data);
      //       this.addFunders(sessionStorage.getItem("program_id"));
      //       this.push.showNotification("Funder Added Successfully", "success");
      //     },
      //     err => {
      //       if (err.status === 400) {
      //         this.push.showNotification("Cannot Add Funder, Please Try Again Later", "danger");
      //       } else if (err.status === 501) {
      //         this.push.showNotification("Internal Server Error, Please Try Again Later", "danger");
      //       } else {
      //         this.push.showNotification("Something Went Worng, Please Try Again Later", "danger");
      //       }
      //     }
      //   );
      // } else {
        data = {
          program_id: sessionStorage.getItem("program_id"),

          funder_id: this.FunderForm.value.funder_id,
          amount_funded: this.FunderForm.value.amount_funded,
          funded_pct: this.per,
          prospect: "SELECTED"
        };

        this._auth.addFunder(data).subscribe(
          res => {
            // console.log(data);
            this.ngOnInit()
            this.push.showNotification("Funder Added Successfully", "success");
          },
          err => {
            if (err.status === 400) {
              this.push.showNotification("Cannot Add Funder, Please Try Again Later", "danger");
            } else if (err.status === 501) {
              this.push.showNotification("Internal Server Error, Please Try Again Later", "danger");
            } else {
              this.push.showNotification("Something Went Worng, Please Try Again Later", "danger");
            }
          }
        );
      // }
    } else {
      this.push.showNotification("Amount Must Be Greater Than Zero", "danger");
    }
  }

  get_recourse(val) {
    if (val === true) {
      return "Yes";
    } else {
      return "No";
    }
  }

  back() {
    this._router.navigate(["/admin"]);
  }
  approve() {
    this._auth
      .updatestatus(this.id, "APPROVED")
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.push.showNotification(
            "Congratulations " + sessionStorage.getItem("program_name") + " Successfully Approved",
            "success"
          );
          this._router.navigate(["/admin"]);
        }
      });
  }

  ngOnDestroy() {
    sessionStorage.removeItem("program_id");
    sessionStorage.removeItem("bid");
    sessionStorage.removeItem("program_value");
    sessionStorage.removeItem("program_type_id");
    this.sharedService.clear();
  }

  navigate() {
    this._router.navigate(["/admin"]);
  }
  program() {
    this._router.navigate(["/admin"]);
  }

  show_value(val) {
    this._auth.update_program({'program_data_points': val, 'program_id': sessionStorage.getItem('program_id') }).subscribe(
      (res: any) => {
  
        this.ngOnInit();
        this.push.showNotification('Update Successful', "success");
      },
      err => {
        this.push.showNotification(err.error.errMsg, "danger");
      }
    );
  }

  show_value1(val) {
    this._auth.update_program({'program_redacted_data_points': val, 'program_id': sessionStorage.getItem('program_id') }).subscribe(
      (res: any) => {
  
        this.ngOnInit();
        this.push.showNotification('Update Successful', "success");
      },
      err => {
        this.push.showNotification(err.error.errMsg, "danger");
      }
    );
  }

  /**
   *
   *
   *
   * Admin Accepts / Reject Accepted Invitation Of The Funders.
   */
  accept_reject_invitation() {
    this._auth
      .admin_accept_reject_invitaion_funders("data")
      .toPromise()
      .then(
        (res: any) => {
          this._auth.updatestatus(this.id, "PENDING").subscribe((res: any) => {
            if (res) {
              this.loading_btn_show = false;
              this.push.showNotification("Success", "success");
              this._router.navigate(["/admin"]);
            }
          });
        },
        (err: any) => {
          this.push.showNotification(err.errMsg, "danger");
        }
      );
  }

  callAudit() {
    if (!this.shared_res) {
      
    this.audit.auditData()
    }
  }

  mappingData() {
    if (!this.shared_res) {
      this.mapping.mappingData()
    }
    
  }

  /***
   * Refresh Table Or Tab Data
   * 
   */
  refresh(){
    this.ngOnInit();
  }
//=========End Of Refresh=======

  /**
   * 
   * Settlement Pop-Up With Rendering & Updation
   * Of Data 
   */
  open_settlement(data: any){
    console.log(data)
    this.settle_data = data.settlement_document
   
    this.settle_id = data.id
    $("#myModal3").modal("show");
  }


  submit_data1(data,id){
    console.log(data)
    if(data.expected_date != null){
      data.submitted_date = null
    }
    this._auth.settlement_data(JSON.stringify({id: id,settlement_document:data})).subscribe((res:any)=>{
      this.push.showNotification('Data Updated', 'success')
    },err=>{
      this.push.showNotification('Something Went Wrong', 'danger')
    })
  }

//============End of Settlement Functionality===========================
orderByRowNum = (a,b) => {
  // console.log('A', a);
  return a
}
}
