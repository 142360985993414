import { Routes } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AddProgramsComponent } from "./manage-programs/add-programs/add-programs.component";
import { AdminComponent } from "./layouts/admin/admin-components/admin/admin.component";
import { ViewProgramComponent } from "./layouts/admin/admin-components/view-program/view-program.component";
import { BuyerListComponent } from "./manage-buyer/buyer-list/buyer-list.component";
import { SellerListComponent } from "./manage-seller/seller-list/seller-list.component";
import { SellerComponent } from "./layouts/seller/seller.component";
import { DashboardComponent } from "./layouts/seller/seller-pages/dashboard/dashboard.component";
import { InvoicesComponent } from "./layouts/seller/seller-pages/invoices/invoices.component";
import { BuyerComponent } from "./layouts/buyer/buyer.component";
import { BuyerDashComponent } from "./layouts/buyer/buyer-component/buyer-dash/buyer-dash.component";
import { BuyerProgramsComponent } from "./layouts/buyer/buyer-component/buyer-programs/buyer-programs.component";
import { LogoutComponent } from "./layouts/logout/logout.component";
import { FunderComponent } from "./layouts/funder/funder.component";
import { FunderDashComponent } from "./layouts/funder/funder-component/funder-dash/funder-dash.component";
import { ProgramViewComponent } from "./layouts/funder/funder-component/program-view/program-view.component";
import { ParticipateComponent } from "./layouts/funder/funder-component/participate/participate.component";
import { BuyerViewProgramComponent } from "./layouts/buyer/buyer-component/buyer-view-program/buyer-view-program.component";
import { ProfileComponent } from "./layouts/seller/seller-pages/profile/profile.component";
import { KycJourneyComponent } from "./layouts/seller/seller-pages/kyc-journey/kyc-journey.component";
import { BuyerInviteComponent } from "./manage-buyer/buyer-invite/buyer-invite.component";
import { SellerImportComponent } from "./manage-seller/seller-import/seller-import.component";
import { SellerViewComponent } from "./manage-seller/seller-view/seller-view.component";
import { ViewAsComponent } from "./layouts/admin/admin-components/view-as/view-as.component";
import { ViewAsSellerComponent } from "./view_seller/view-as-seller/view-as-seller.component";
import { SearchComponent } from "./manage-programs/search/search.component";
import { ViewAsSellerDashComponent } from "./view_seller/view-as-seller-dash/view-as-seller-dash.component";
import { ViewAsSellerProfileComponent } from "./view_seller/view-as-seller-profile/view-as-seller-profile.component";
import { OnBoardingComponent } from "./layouts/on-boarding/on-boarding/on-boarding.component";
import { AuthService } from "./services/auth.service";
import { AuthGuardGuard } from "./services/auth-guard/auth-guard.guard";
import { LoggedInGuard } from "./services/logged-in.guard";
import { ViewInvoicesComponent } from "./manage-programs/view-invoices/view-invoices.component";

import { FunderListComponent } from "./manage-funder/funder-list/funder-list.component";
import { FunderInviteComponent } from "./manage-funder/funder-invite/funder-invite.component";
import { IndividualKycComponent } from "./layouts/funder/funder-component/individual-kyc/individual-kyc.component";
import { CompanyKycComponent } from "./layouts/funder/funder-component/company-kyc/company-kyc.component";

import { FunderViewComponent } from "./manage-funder/funder-view/funder-view.component";
import { BuyerViewComponent } from "./manage-buyer/buyer-view/buyer-view.component";
import { BuyerKycComponent } from "./layouts/buyer/buyer-component/buyer-kyc/buyer-kyc.component";
import { BuyerProfileComponent } from "./layouts/buyer/buyer-component/buyer-profile/buyer-profile.component";
import { ProfileFunderComponent } from "./layouts/funder/funder-component/profile-funder/profile-funder.component";
import { LogsComponent } from "./layouts/admin/admin-components/logs/logs.component";
import { SellerInviteComponent } from "./manage-seller/seller-invite/seller-invite.component";
import { InvoiceSettlementComponent } from "./layouts/admin/admin-components/invoice-settlement/invoice-settlement.component";
import { MappingTableComponent } from "./layouts/admin/admin-components/view-program/mapping-table/mapping-table.component";
import { TreeStructureComponent } from "./shared/tree-structure/tree-structure.component";
import { MetaDefinationComponent } from "./manage-programs/meta-defination/meta-defination.component";
import { EikonCheckComponent } from "./layouts/eikon-check/eikon-check.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "admin",

        canActivate: [AuthGuardGuard],
        component: AdminComponent
      },
      {
        path: "viewProgram/:id",
        component: ViewProgramComponent,
        // canActivate: [AuthGuardGuard]
      },

      {
        path: "sellerView/:id",
        canActivate: [AuthGuardGuard],
        component: SellerViewComponent
      },

      {
        path: "viewAs",

        component: ViewAsComponent
      },
      {
        path: "invoices/:id",
        component: ViewAsSellerComponent
      },
      {
        path: "dashSeller/:id",
        component: ViewAsSellerDashComponent
      },
      {
        path: "profileSeller/:id",
        component: ViewAsSellerProfileComponent
      },
      {
        path: "mapping",
        component: MappingTableComponent
      },
      {
        path: "programs",
        children: [
          {
            path: "add-program/:name/:id",
            canActivate: [AuthGuardGuard],
            component: AddProgramsComponent
          },
          {
            path: "meta-definition",
            component: MetaDefinationComponent
          },
          {
            path: "search",
            component: SearchComponent
          },
          {
            path: "view-invoices",
            component: ViewInvoicesComponent
          }
        ]
      },
      {
        path: "invoice-settlement",
        component: InvoiceSettlementComponent
      },
      {
        path: "sellers",
        children: [
          {
            path: "seller-invite",
            component: SellerInviteComponent
          },
          {
            path: "seller-list",
            canActivate: [AuthGuardGuard],
            component: SellerListComponent
          },
          {
            path: "seller-import",
            component: SellerImportComponent
          }
        ]
      },
      {
        path: "buyers",
        children: [
          {
            path: "buyer-list",
            canActivate: [AuthGuardGuard],
            component: BuyerListComponent
          },
          {
            path: "buyer-invite",
            component: BuyerInviteComponent
          },
          {
            path: "buyer-view/:id",
            component: BuyerViewComponent
          }
        ]
      },
      {
        path: "funders",
        children: [
          {
            path: "funder-list",
            canActivate: [AuthGuardGuard],
            component: FunderListComponent
          },
          {
            path: "funder-invite",
            component: FunderInviteComponent
          },
          {
            path: "funder-view/:id",
            component: FunderViewComponent
          }
        ]
      },
      {
        path: "logs",
        component: LogsComponent
      }
    ]
  },
  {
    path: "",
    component: SellerComponent,
    children: [
      {
        path: "kyc-process",
        canActivate: [AuthGuardGuard],
        component: KycJourneyComponent
      },
      {
        path: "seller",
        canActivate: [AuthGuardGuard],
        component: DashboardComponent
      },
      {
        path: "invoice",
        canActivate: [AuthGuardGuard],
        component: InvoicesComponent
      },
      {
        path: "profile",
        canActivate: [AuthGuardGuard],
        component: ProfileComponent
      }
    ]
  },
  {
    path: "",
    component: BuyerComponent,
    children: [
      {
        path: "buyer",

        component: BuyerDashComponent
      },
      {
        path: "program",

        component: BuyerProgramsComponent
      },
      {
        path: "program-view/:id/:bid",

        component: BuyerViewProgramComponent
      },
      {
        path: "Buyer-Onboarding",
        component: BuyerKycComponent
      },
      {
        path: "buyer-Profile",
        component: BuyerProfileComponent
      }
    ]
  },
  {
    path: "",
    component: FunderComponent,
    children: [
      {
        path: "individual-kyc",
        component: IndividualKycComponent
      },
      {
        path: "Funder-Onboarding",
        component: CompanyKycComponent
      },
      {
        path: "funder",
        canActivate: [AuthGuardGuard],
        component: FunderDashComponent
      },
      {
        path: "programView/:id/:name",
        canActivate: [AuthGuardGuard],
        component: ProgramViewComponent
      },
      {
        path: "participate/:id",
        component: ParticipateComponent
      },
      {
        path: "funder-Profile",
        component: ProfileFunderComponent
      }
    ]
  },

  {
    path: "login",
    component: AuthLayoutComponent
  },
  {
    path: "Tree-View",
    component: TreeStructureComponent
  },

  {
    path: "logout",
    component: LogoutComponent
  },
  {
    path: "on-boarding/:id/:url",
    component: OnBoardingComponent
  },
  {
    path: "eikon-user/:email",
    component: EikonCheckComponent
  },
];
