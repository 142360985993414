import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare const $: any;

@Component({
  selector: "app-buyer-programs",
  templateUrl: "./buyer-programs.component.html",
  styleUrls: ["./buyer-programs.component.css"]
})
export class BuyerProgramsComponent implements OnInit {
  names = [];
  postTitle = "";
  postBody = "";
  total_prgrams: any;
  total_fund: number;
  avg_fund: any;
  inactive_programs: number;
  party_name: Object;
  data = [];
  party_name_len: any;
  b_name: any;
  list = [];
  data1 = [];
  filterData: any[];
  loading: boolean;
  selected: any;
  value: any;
  count1: any;
  active: any;
  count2: any;
  inactive: any;
  dashboard_deployed_amt = [];
  deployed_amt: any;
  deployed_total: any;
  count3: any;
  dataTable: {
    dataRows: string[][];
  };
  btn_color: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    "program_name",
    "buyer_name",
    "start_date",
    "end_date",
    "program_currency",
    "program_value",
    "deployed_amt",
    "program_status"
  ];
  dataSource: MatTableDataSource<any>;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  constructor(
    private _auth: AuthService,
    private router: Router,
    private _route: ActivatedRoute,
    private http: HttpClient,
    private SpinnerService: NgxSpinnerService
  ) {}

  public ngOnInit() {
    this.SpinnerService.show();
    this.loading = false;
    this.count1 = 0;
    this.count2 = 0;
    this.deployed_total = 0;
    this._auth
      .buyer_programs(sessionStorage.getItem("seller_id"))
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.SpinnerService.hide();

          for (var j of res) {
            if (j.status === "ACTIVE") {
              this.data.push(j);
            }
          }

          this.dataSource = new MatTableDataSource(this.data);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.loading = true;
        }

        for (var i = 0; i < this.data.length; i++) {
          if (this.data[i].status == "IN-PROGRESS") {
            this.btn_color = "btn btn-warning btn-sm";
            this.data[i][8] = this.btn_color;
          } else if (this.data[i].status == "ACTIVE") {
            this.btn_color = "btn btn-success btn-sm";
            this.data[i][8] = this.btn_color;
          } else if (this.data[i].status == "CLOSED") {
            this.btn_color = "btn btn-danger btn-sm";
            this.data[i][8] = this.btn_color;
          } else if (this.data[i].status == "PENDING") {
            this.btn_color = "btn btn-secondary btn-sm";
            this.data[i][8] = this.btn_color;
          } else {
            this.btn_color = "btn btn-info btn-sm";
            this.data[i][8] = this.btn_color;
          }
        }
      });

    this._auth.party(3).subscribe((res: any) => {
      if (res) this.party_name = res;
      this.party_name_len = res.length;
      for (var i = 0; i < res.length; i++) {
        this.data1[i] = { party_id: this.party_name[i].party_id, b_name: this.party_name[i].display_name };
        // console.log(this.data[i])
      }
    });

    this._auth
      .dashboard_deployed_amt()
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.dashboard_deployed_amt = res["deployed_amounts"];

          for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
            if (this.dashboard_deployed_amt.length > 0) {
              this.deployed_total += parseInt(this.dashboard_deployed_amt[i].program_deployed_amount);
            } else {
              this.deployed_total = 0;
            }
          }
        }
      });

    var cardStatsMiniLineColor = "#fff",
      cardStatsMiniDotColor = "#fff";
  }

  get_Deployed_amount(id) {
    for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
      if (this.dashboard_deployed_amt[i].program_id == id) {
        return this.dashboard_deployed_amt[i].program_deployed_amount;
      }
    }
    return 0;
  }

  ngAfterViewInit() {
    $("#datatable").DataTable({
      pagingType: "full_numbers",
      lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
      responsive: true,
      processing: true,

      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records"
      }
    });
  }

  program_view(id, buyer_id) {
    this.router.navigate(["/program-view", id, buyer_id]);
  }
}
