import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "../../../../services/push.service";
import { environment } from "environments/environment";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
declare var swal: any;
declare var $: any;

@Component({
  selector: "app-profile-funder",
  templateUrl: "./profile-funder.component.html",
  styleUrls: ["./profile-funder.component.css"]
})
export class ProfileFunderComponent implements OnInit {
  logo: string;
  kyc_status: any;
  title: any;
  suffix: any;
  full_name: any;
  email: any;
  phone: any;
  mobile: any;
  website: any;
  street: any;
  city: any;
  zip: any;
  state: any;
  perm_id: any;
  country: any;
  uen: any;
  party_id: any;
  buyer_id: any;
  name: any;
  status: any;
  date: any;
  acc_type: any;
  acc_no: any;
  bank_add: any;
  branch_code: any;
  CBS_MLCB: any;
  GST_F5_Statment: any;
  GST_Statment: any;
  audited_financial_statements: any;
  boardResolution: any;
  directorDetails: any;
  personal_notice_assessment: any;
  shareholderDetails: any;
  registered_office: any;
  passbook: any;
  checkbook: any;
  month6_Statment: any;
  bank_name: any;
  AR_PR: any;
  upload_url: any;
  branch_address: any;
  bank_account_type: any;
  bank_account_no: any;
  director_details: any;
  board_resolution: any;
  credit_check: any;
  bank_info: any;
  additional_documents: any;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private envService: EnvService
  ) {
    this.upload_url = this.envService.File_upload_funder;
  }
  ngOnInit() {
    // console.log("hello")
  
  }

  download(file_name) {
    window.location.href = this.upload_url + "/" + file_name;
  }

  display(file_name) {
    if (file_name != undefined) {
      return false;
    } else {
      return true;
    }
  }

  perm_id_display(id: string): string {
    if (id != undefined) {
      var spl = id.split("-");

      return "F" + spl[1];
    }
  }
}
