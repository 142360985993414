import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { PushService } from "app/services/push.service";
import { NgbDatepickerConfig, NgbDate, NgbCalendar, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
declare const $: any;


@Component({
  selector: "app-add-programs",
  templateUrl: "./add-programs.component.html",
  styleUrls: ["./add-programs.component.css"]
})
export class AddProgramsComponent implements OnInit {

  hoveredDate: NgbDate | null = null;

  Threshold = ''
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  value = "";
  funded: any;
  sub: number;
  per: any;
  isDisabled = true;
  ProgramForm: FormGroup;
  FunderForm: FormGroup;
  pr_id: any;
  record: any;
  fund: number;
  maxval: number;
  prg: any;
  maxval1: number;
  party_name: any;
  funderlist: Object;
  startDate: Date;
  today: any;
  submitted: boolean;
  minDate: { year: number; month: number; day: number };
  data_socket_message: { message: string; id: any; };
  id: number;
  program_type_val: any;
  type_program: number;
  seller_list: any;
  commodity_program_status: any[];
  stages: any;


  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private push: PushService,
    private http: HttpClient,
    private config: NgbDatepickerConfig,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private cdRef: ChangeDetectorRef
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }

  ngOnInit() {
    var now = new Date();
    var pipe = new DatePipe("en-US");
    try{
      this.type_program = parseInt(this._route.snapshot.paramMap.get("id"))
      this._auth.get_program_types(parseInt(this._route.snapshot.paramMap.get("id"))).subscribe(async(res: any) => {
        this.program_type_val = await ((parseInt(this._route.snapshot.paramMap.get("id"))===2)?res.commodity: res.corporate)
        this.stages = res.stage
        this.commodity_program_status =  res.stage.stages
      })
  
    }catch(e){
      console.log(e)
    }
    
    this.ProgramForm = this.fb.group(
      {
        program_id: this.prg,
        program_name: ["", Validators.required],
        program_type_id: ["", Validators.required],
        buyer_id: ["", Validators.required],
        seller_id: "",
        sponsor_id: "ADMIN",
        description: [""],
        date_created: [now],
        start_date: [null, [Validators.required, Validators.maxLength(10)]],
        end_date: [null, [Validators.required, Validators.maxLength(10)]],
        date_approved: now,
        program_status: ["false"],
        invite_participation: ["", Validators.required],
        seller_max_limit: ["", [Validators.required, Validators.min(1), this.check]],
        alert_threshold_pct: ["", [Validators.required, Validators.min(1), Validators.max(80)]],
        max_funding_pct: ["", [Validators.required, Validators.min(1), Validators.max(100)]],
        service_charges_pct: ["", [Validators.required, Validators.min(0), Validators.max(3.5)]],
        interest_pct:parseInt(this._route.snapshot.paramMap.get("id")) === 2? 0: ["", [Validators.required, Validators.min(0), Validators.max(15)]],
        max_inv_credit_days: [0],
        insurance_details: this.fb.group({
          policy_number: [""],
          policy_holder: [""],
          other_parties_to_policy: [""],
          inception_date: [null],
          coverage_period_start_date: [null],
          coverage_period_end_date: [null],
          credit_limit: [0],
          credit_period: [0],
          description_coverage: [""]
        }),
        with_recourse: ["", Validators.required],
        program_currency: ["", Validators.required],
        notes: [""],
        create_uid: 1,
        program_value: ["", [Validators.required, Validators.min(1)]],
        program_max_limit: [""],
        additional_transaction_fees: [0],
        minumum_pct: ["", [Validators.min(0), Validators.max(100)]],
        gross_quantity:"",
        net_quantity: "",
        instruments: "",
        yield_pct: parseInt(this._route.snapshot.paramMap.get("id")) === 2? ["", Validators.required] : "",
        product: "",
        stage:parseInt(this._route.snapshot.paramMap.get("id")) === 2?["",Validators.required]:""
      },
      { validators: [this.checkDate, this.checkDate2, this.check] }
    );



  }

  onSearchChange() {

    let val = parseFloat(this.ProgramForm.value.alert_threshold_pct)
    let val1 = val.toString().split('.')[0]

    if (parseInt(val1) > 80) {
      this.ProgramForm.controls['alert_threshold_pct'].reset()
    }
  }

  onSearchChange1() {
    let val = parseFloat(this.ProgramForm.value.max_funding_pct)
    let val1 = val.toString().split('.')[0]

    if (parseInt(val1) > 100) {
      this.ProgramForm.controls['max_funding_pct'].reset()
    }
  }

  discountCalc() {
    let val = parseFloat(this.ProgramForm.value.interest_pct)
    let val1 = val.toString().split('.')[0]

    if (parseInt(val1) > 15) {
      this.ProgramForm.controls['interest_pct'].reset()
    }
  }

  serviceCalc() {
    let val = parseFloat(this.ProgramForm.value.service_charges_pct)
    let val1 = val.toString().split('.')[0]

    if (parseInt(val1) > 3.5) {
      this.ProgramForm.controls['service_charges_pct'].reset()
    }
  }

  check_programName(){

    if (this.ProgramForm.value.program_name != "") {
      this._auth.check_programName(this.titleCase(this.ProgramForm.value.program_name).trim()).subscribe(
        (res: any) => {  
        },
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.ProgramForm.get("program_name").reset();
        }
      );
    } 
  }


  titleCase(str: string) {
    return str.toLowerCase().replace(/(^|\s)(\w)/g, function(x) {
      return x.toUpperCase();
    });
  }

 
 

  checkDate(control: FormGroup) {
    let startingDatefield = control.get("start_date");
    let endingDatefield = control.get("end_date");

    if (startingDatefield && endingDatefield && startingDatefield.value != null && endingDatefield.value != null) {
      var day = parseInt(startingDatefield.value.day);
      var month = parseInt(startingDatefield.value.month);
      var year = parseInt(startingDatefield.value.year);
      var date = new Date(Date.UTC(year, month - 1, day));

      var day1 = parseInt(control.get("end_date").value.day);
      var month1 = parseInt(control.get("end_date").value.month);
      var year1 = parseInt(control.get("end_date").value.year);
      var date1 = new Date(Date.UTC(year1, month1 - 1, day1));

      if (date > date1) {
        endingDatefield.setErrors({ checkDate: true });
      } else {
        null;
      }
    }
  }

  checkDate2(control: FormGroup) {
    let startingDatefield1 = control.get("insurance_details").value.coverage_period_start_date;
    let endingDatefield1 = control.get("insurance_details").value.coverage_period_end_date;
    let dateerror = control.get("insurance_details").get("coverage_period_end_date");

    if (startingDatefield1 != null && endingDatefield1 != null) {
      var day2 = parseInt(startingDatefield1.day);
      var month2 = parseInt(startingDatefield1.month);
      var year2 = parseInt(startingDatefield1.year);
      var date2 = new Date(Date.UTC(year2, month2 - 1, day2));

      var day3 = parseInt(endingDatefield1.day);
      var month3 = parseInt(endingDatefield1.month);
      var year3 = parseInt(endingDatefield1.year);
      var date3 = new Date(Date.UTC(year3, month3 - 1, day3));

      if (date2 > date3) {
        dateerror.setErrors({ checkDate2: true });
      } else {
        null;
      }
    } else {
      null;
    }
  }

  check(control: FormGroup) {
    const program_value = control.get("program_value");
    const seller_max_limit = control.get("seller_max_limit");

    if (program_value && seller_max_limit && program_value.value < seller_max_limit.value) {
      seller_max_limit.setErrors({ check: true });
    } else {
      null;
    }
  }

  save() {
    var now = new Date();
    this.submitted = true;

    if (!this.ProgramForm.valid) {
      this.push.showNotification("Please Fill Required Information", "danger");
      this.ProgramForm.markAllAsTouched();
      return;
    }

    var day = parseInt(this.ProgramForm.value.start_date.day);
    var month = parseInt(this.ProgramForm.value.start_date.month);
    var year = parseInt(this.ProgramForm.value.start_date.year);
    var date = new Date(Date.UTC(year, month - 1, day));
    this.ProgramForm.get("start_date").setValue(date);

    var day1 = parseInt(this.ProgramForm.value.end_date.day);
    var month1 = parseInt(this.ProgramForm.value.end_date.month);
    var year1 = parseInt(this.ProgramForm.value.end_date.year);
    var date1 = new Date(Date.UTC(year1, month1 - 1, day1));
    this.ProgramForm.get("end_date").setValue(date1);

    /**
     * Insurance Coverage Dates Conversion
     *
     *
     */
    if (this.ProgramForm.value.insurance_details.inception_date != null) {
      var day = parseInt(this.ProgramForm.value.insurance_details.inception_date.day);
      var month = parseInt(this.ProgramForm.value.insurance_details.inception_date.month);
      var year = parseInt(this.ProgramForm.value.insurance_details.inception_date.year);
      var date3 = new Date(Date.UTC(year, month - 1, day));
      this.ProgramForm.value.insurance_details.inception_date = date3;
    } else {
      this.ProgramForm.value.insurance_details.inception_date = now;
    }

    if (this.ProgramForm.value.insurance_details.coverage_period_start_date != null) {
      var day = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.day);
      var month = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.month);
      var year = parseInt(this.ProgramForm.value.insurance_details.coverage_period_start_date.year);
      var date4 = new Date(Date.UTC(year, month - 1, day));
      this.ProgramForm.value.insurance_details.coverage_period_start_date = date4;
    } else {
      this.ProgramForm.value.insurance_details.coverage_period_start_date = now;
    }

    if (this.ProgramForm.value.insurance_details.coverage_period_end_date != null) {
      var day1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.day);
      var month1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.month);
      var year1 = parseInt(this.ProgramForm.value.insurance_details.coverage_period_end_date.year);
      var date5 = new Date(Date.UTC(year1, month1 - 1, day1));

      this.ProgramForm.value.insurance_details.coverage_period_end_date = date5;
    } else {
      this.ProgramForm.value.insurance_details.coverage_period_end_date = now;
    }
    /***** End  *****/

    this.ProgramForm.value.program_id = this.prg;
    this.ProgramForm.value.program_max_limit = this.ProgramForm.value.program_value;

    var obj = this.ProgramForm.value;
    this.ProgramForm.value.invite_participation = this.ProgramForm.value.invite_participation === "true" ? true : false;
    if (this.ProgramForm.value.invite_participation != true) {
      this.ProgramForm.value.minumum_pct = 1;
    } else {
      this.ProgramForm.value.minumum_pct = this.ProgramForm.value.minumum_pct;
    }
    this.stages.status = this.ProgramForm.value.stage
    this.ProgramForm.value.stage = this.stages

    this.ProgramForm.value.program_category_id = parseInt(this._route.snapshot.paramMap.get("id"))
    if(this.type_program === 1){
      if(this.ProgramForm.value.program_type_id === '2'){
        this.ProgramForm.value.seller_id =  this.ProgramForm.value.buyer_id
        this.ProgramForm.value.buyer_id = ''
      }
    }
    // console.log(JSON.stringify(obj))
    this._auth.addProgram(JSON.stringify(obj)).subscribe(
      res => {
        if (res) {
          this._auth
            .updatestatus(res["id"], "IN-PROGRESS")
            .toPromise()
            .then((res: any) => {
              this.ProgramForm.reset();
              this.push.showNotification("Program Successfully Created", "success");
              this.data_socket_message = {
                message: "Program Successfully Created",
                id: res.id
              }
              this._router.navigate(["/admin"]);
            });
        }
      },
      err => {
        this.push.showNotification("Unable To Create Program, Please Try Again Later", "danger");
        this._router.navigate(["/admin"]);
      }
    );
  }



  cancel() {
    this._router.navigate(["/admin"]);
  }

  pass_val() {
    if (this.ProgramForm.value.with_recourse == "true") {
      this.ProgramForm.value.program_id = "PRG-R";
      this.prg = this.ProgramForm.value.program_id;
    } else {
      this.ProgramForm.value.program_id = "PRG-N";
      this.prg = this.ProgramForm.value.program_id;
    }
  }

  enable_end_date() {
    if (this.ProgramForm.value.start_date === "" || this.ProgramForm.value.start_date === null) {
      return true;
    } else {
      return false;
    }
  }

  enable_end_date1() {
    if (
      this.ProgramForm.value.insurance_details.coverage_period_start_date === "" ||
      this.ProgramForm.value.insurance_details.coverage_period_start_date === null
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
  getParty() {
    var party_id = this.ProgramForm.value.program_type_id;

    this._auth.party(party_id).subscribe(res => {
      if (res) this.party_name = res;

      this.party_name = this.party_name.filter(t => t.user_status == "APPROVED");
    });
    if (this.type_program === 2) {
      this._auth.party(2).subscribe(res => {
        if (res) this.seller_list = res;
  
        this.seller_list = this.seller_list.filter(t => t.user_status == "APPROVED");
      });
    }
   
  }

  ngOnDestroy() { }

  back() {
    this._router.navigate(["/admin"]);
  }

  disable_field() {
    if (this.ProgramForm.value.invite_participation === true || this.ProgramForm.value.invite_participation === "true") {
      return false;
    } else {
      return true;
    }
  }

}
