import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "app/services/auth.service";
import { PushService } from "app/services/push.service";
import { Router } from "@angular/router";
import { SharedService } from "app/services/shared.service";

@Component({
  selector: "app-mapping-table",
  templateUrl: "./mapping-table.component.html",
  styleUrls: ["./mapping-table.component.css"]
})
export class MappingTableComponent implements OnInit {
  data: Object[];
  currency: any;
  buyer_name: any;
  buyer_id: any;
  seller_id: any;

  // d1 = [
  //   { seller_name: "Hudson", seller_id: "1056" },
  //   { seller_name: "Lightning", seller_id: "1057" },
  //   { seller_name: "Thunder", seller_id: "1058" },
  // ];

  d1: Object[];
  qb_id: any;
  prg_id: any;
  display_table: boolean;
  discount: any;
  service_charges_pct: any;
  state_default: boolean = true;
  Active: string = "ACT";
  Inactive: string = "INA";
  size: string = "normal";
  status: any;
  constructor(
    private http: HttpClient,
    private _auth: AuthService,
    private push: PushService,
    private _router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
   
  }

  mappingData(){
    this.program_data(sessionStorage.getItem("program_id"));
    this.display_table = false;
  }

  program_data(id) {
    this._auth.get_map_program_details(id).subscribe(
      (res: any) => {
        
        this.display_table = true;
        this.currency = res.program_currency;
        this.buyer_name = res.buyer_display_name;
        this.buyer_id = res.b_sbfxid;
        this.qb_id = res.qb_id;
        this.prg_id = res.id;
        this.d1 = res.sellers;
        this.discount = res.interest_pct;
        this.service_charges_pct = res.service_charges_pct;
      },
      err => {
        this.push.showNotification(err.error.message, "danger");
      }
    );
  }

  onChange(event) {
    this.status = event.target.checked;
  }
  save(i, id, prg_id) {
    if (
      (document.getElementById("acc_no_" + i) as HTMLInputElement).value != "" &&
      (document.getElementById("acc_type_" + i) as HTMLInputElement).value != ""
    ) {
      let data = {
        program_id: prg_id,
        seller_id: id,
        status: this.status === undefined ? false : this.status,
        account_type: (document.getElementById("acc_type_" + i) as HTMLInputElement).value,
        account_no: (document.getElementById("acc_no_" + i) as HTMLInputElement).value
      };

      this._auth.update_mapping(JSON.stringify(data)).subscribe(
        (res: any) => {
          this.push.showNotification("Successfully Mapped", "success");
          this.ngOnInit();
        },
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
        }
      );
      // let i1 = this.d1.findIndex((e) => e.seller_id === id);
      // this.d1[i1] = Object.assign(this.d1[i1], data);
      // console.log(this.d1[i1], this.d1);
    } else {
      this.push.showNotification("Please Fill Some Data", "danger");
    }
  }

  cancel() {
    this._router.navigate(["/admin"]);
  }
}
