import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SharedService {
  private message = new BehaviorSubject("");
  sharedMessage = this.message.asObservable();

  private message1 = new BehaviorSubject("");
  sharedMessage1 = this.message1.asObservable();

  private message2 = new BehaviorSubject("");
  sharedMessage2 = this.message2.asObservable();

  private message3 = new BehaviorSubject("");
  sharedMessage3 = this.message3.asObservable();

  private message4 = new BehaviorSubject("");
  sharedMessage4 = this.message4.asObservable();

  private message5 = new BehaviorSubject("");
  sharedMessage5 = this.message5.asObservable();

  private commodity_prg = new BehaviorSubject("");
  commodity_view = this.commodity_prg.asObservable();


  private express_list = new BehaviorSubject("");
  express_funder_list= this.express_list.asObservable();

  private map_val = new BehaviorSubject("");

  map_id = this.map_val.asObservable();

  constructor() {}

  nextMessage(message) {
    this.message.next(message);
  }

  nextMessage1(message) {
    this.message1.next(message);
    console.log(message)
  }

  nextMessage2(message) {
    this.message2.next(message);
  }

  nextMessage3(message) {
    this.message3.next(message);
  }

  nextMessage4(message) {
    this.message4.next(message);
  }

  nextMessage5(message) {
    this.message5.next(message);
  }

  mappingtable(id) {
    this.map_val.next(id);
  }

  commodity_program_view(data) {

    this.commodity_prg.next(data)
  }

  express_interest(data) {

    this.express_list.next(data)
  }
  clear() {
    this.message4 = new BehaviorSubject("");
    this.sharedMessage4 = this.message4.asObservable();
    this.message1 = new BehaviorSubject("");
    this.sharedMessage1 = this.message1.asObservable();
    this.sharedMessage4 = this.message4.asObservable();
    this.commodity_prg= new BehaviorSubject("");
    this.commodity_view = this.commodity_prg.asObservable();
  }
}
