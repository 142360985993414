import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
declare const $: any;
@Component({
  selector: 'app-tree-structure',
  templateUrl: './tree-structure.component.html',
  styleUrls: ['./tree-structure.component.css']
})
export class TreeStructureComponent implements OnInit {

  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  nodes = [];
  nodes1 = []
  options = {
    allowDrag: true,
    allowDrop: true,
   
  };
  model: any = {};
  model1: any = {};
  model2: any = {};
  node: any;
  id: any;

  constructor(private cdRef: ChangeDetectorRef) {
}


  ngOnInit(): void {
    this.cdRef.detectChanges(); 
    if (typeof this.tree !== 'undefined') {
      this.nodes1 = JSON.parse(localStorage.getItem('data'))
      this.tree.treeModel.update();

    }
  }


  node_type() {
    switch (this.model.type) {
      case 'description':
        delete this.model.link
        break;
      case 'link':
        delete this.model.description
        break;
    }
  }


  add_root_node() {
    console.log(this.nodes1)
    if (this.nodes1[this.id].children != undefined) {
      this.nodes1[this.id].children.push(this.model)

    } else {
      this.nodes1[this.id].children = new Array(this.model)

    }
    this.tree.treeModel.update();
    this.model = {}
    localStorage.setItem('data', JSON.stringify(this.nodes1))
    this.ngOnInit()
  }

  show_modal(node: TreeNode) {
    this.node = node
    this.tree.treeModel.update();
    $("#dataModal1").modal('show');
  }
  addNode() {
    if (this.node.data.children) {
      this.node.data.children.push(this.model);
    } else {

      this.node.data.children = [];
      this.node.data.children.push(this.model);
    }
    this.tree.treeModel.update();
    this.model = {}
    localStorage.setItem('data', JSON.stringify(this.nodes1))

    this.ngOnInit()
    
  }
  removeNode(node: TreeNode): void {
    if (node.parent != null) {
        node.parent.data.children.splice(node.parent.data.children.indexOf(node.data), 1);
        this.tree.treeModel.update();
    }
  }
  childrenCount(node: TreeNode): string {
    return node && node.children ? `(${node.children.length})` : '';
  }


  onSubmit() {
    this.model1.commodity_tree =  this.nodes
    console.log(this.model1)
    console.log(this.nodes)
  //   delete this.model1.buyer_id
  //   this._auth.addProgram(JSON.stringify(this.model1)).subscribe(
  //     res => {
  //       if (res) {
  //         this._auth
  //           .updatestatus(res["id"], "IN-PROGRESS")
  //           .toPromise()
  //           .then((res: any) => {
  //             this.treeForm.reset();
  //               this.model1.reset();
  //             this.push.showNotification("Program Successfully Created", "success");
            
  //             this._router.navigate(["/admin"]);
  //           });
  //       }
  //     },
  //     err => {
  //       this.push.showNotification("Unable To Create Program, Please Try Again Later", "danger");
  //       this._router.navigate(["/admin"]);
  //     }
  //   );
  }
  

  create_tabname() {
    console.log(this.model2)
    this.nodes1.push(this.model2);


    console.log(this.nodes1)
    this.model2 ={}
  }

  set_active(event) {
    console.log(event.target.attributes.id.value)
    this.id = event.target.attributes.id.value
    this.tree.treeModel.update();}

}
