import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';
import { SharedService } from 'app/services/shared.service';

@Component({
  selector: 'app-funders-express-interest',
  templateUrl: './funders-express-interest.component.html',
  styleUrls: ['./funders-express-interest.component.css']
})
export class FundersExpressInterestComponent implements OnInit {
  express_data =[];
  participate_amount: number = 0;
  id: any;
  constructor(private _auth: AuthService, private _route: ActivatedRoute, private push: PushService,private sharedService : SharedService) { }

  ngOnInit(): void {

    this._auth.get_funders_expressed_interest(this._route.snapshot.paramMap.get("id")).subscribe((res: any) => {
      this.express_data = res
    }, (err: any) => {
        console.log(err)
    })
  }

  check_amount(event) {
    console.log(event.target.value)
    if (event.target.value != 0) {
      this.participate_amount = parseFloat(event.target.value)
    } else {
      this.push.showNotification("Amount Must Be Greater Than Zero", "danger")
    }
  }
  accept_reject_interest(status, id,email) {
  console.log(this.participate_amount)
    let date = this.convertDate(new Date());
    let data;
    if (this.participate_amount === 0) {
    data = {
        status: status,
        date: date,
      pid: id,
      email: email

      }
  
    } else {
       data = {
        status: status,
        date: date,
         pid: parseInt(id),
        amount: this.participate_amount,
        email: email
      }
  
    }
    if (sessionStorage.getItem('program_category_id') === '1') {
      this._auth.accept_reject_funders_interest1(data).subscribe((res: any) => {
        if (status === 'INTERESTED_APPROVED' || status === 'PARTICIPATE_APPROVED') {
       
            this.push.showNotification("Successfully Updated",'success')
            this.ngOnInit();
          
          
        } else {
          this.push.showNotification("Funder Request Has Been Rejected",'danger')
          this.ngOnInit();
        }
        
        
      })
    } else {
      this._auth.accept_reject_funders_interest(data).subscribe((res: any) => {
        if (status === 'INTERESTED_APPROVED' || status === 'PARTICIPATE_APPROVED') {
       
            this.push.showNotification("Successfully Updated",'success')
            this.ngOnInit();
          
          
        } else {
          this.push.showNotification("Funder Request Has Been Rejected",'danger')
          this.ngOnInit();
        }
        
      })
      }
 
  }


   convertDate(date) {
    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString();
    var dd  = date.getDate().toString();
  
    var mmChars = mm.split('');
    var ddChars = dd.split('');
  
    return yyyy + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]);
  }

}
