import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import Chart from 'chart.js';
import { AuthService } from 'app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

declare const $: any;

// declare interface DataTable {
//     headerRow: string[];
//     dataRows: string[][];
// }
@Component({
  selector: 'app-buyer-dash',
  templateUrl: './buyer-dash.component.html',
  styleUrls: ['./buyer-dash.component.css']
})
export class BuyerDashComponent implements OnInit {
  public canvas : any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public chartHours2;
  public chartActivity;

  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  gradientStroke: any;


  list: any;
  loadData: boolean;
  load: boolean;
  pricing_buc_data_1=[];
  pricing_buc_data_2=[];
  pricing_buc_data_3=[];
  program_list: any;
  id: any;
  data1: any;
  total_active_programs: any;
  total_approved_programs: any;
  total_amount_funded: any;
  total_funding_value: any;
  total_invoice_value_claimable: any;
  total_invoice_value_claimed: any;
  total_invoices_claimable: any;
  total_invoices_claimed: any;
  total_suppliers_active: any;
  total_suppliers_inactive: any;
  top_5_Sellers = [];
  percentage_invoices_paid_on_time: any;
  month=[];
  claimed=[];
  claimable=[];
  program_details=[];
  program_claimed=[];
  program_claimable=[];
  program_id: number;

  btn_color: string;
  dataTable: {dataRows: any; };
  dataRows: any;
  loading: boolean;
  data: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  

  displayedColumns: string[] = ['program_name', 'vendor_name', 'currency','value_offered','value_claimed','percentage','weight_avg','program_status','download'];
  dataSource: MatTableDataSource<any>;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

     
    }

  constructor(private _auth: AuthService, private SpinnerService: NgxSpinnerService) {

    window.location.hash="redirect";
    window.location.hash="redirect";//again because google chrome don't insert first hash into history
    window.onhashchange=function(){window.location.hash="redirect";} 
  }

  

    ngOnInit(){
      this.SpinnerService.show();
      this.id = 0
      this.program_id = 0
      this.load=false
      this.loading = false
      this._auth.buyer_details(sessionStorage.getItem("seller_id"),0).toPromise().then((res:any)=>{
        if(res){
       
          this.data1 = res
          this.SpinnerService.hide()
      
          for(var i =0;i< this.data1.length;i++){
            this.data = this.data1[i].top_5_sellers
            this.dataSource = new MatTableDataSource(this.data);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.total_active_programs = this.data1[i].total_active_programs 
            this.total_approved_programs = this.data1[i].total_approved_programs
            this.total_amount_funded = parseInt(this.data1[i].total_amount_funded) == 0? 0 :parseInt(this.data1[i].total_amount_funded) 
            this.total_funding_value = this.data1[i].total_funding_value
            this.total_invoice_value_claimable = parseInt(this.data1[i].total_invoice_value_claimable) == 0? 0:  parseInt(this.data1[i].total_invoice_value_claimable)
            this.total_invoice_value_claimed = parseInt(this.data1[i].total_invoice_value_claimed) == 0? 0 : parseInt(this.data1[i].total_invoice_value_claimed)
            this.total_invoices_claimable = this.data1[i].total_invoices_claimable
            this.total_invoices_claimed =  this.data1[i].total_invoices_claimed
            this.total_suppliers_active = this.data1[i].total_suppliers_active
            this.total_suppliers_inactive = this.data1[i].total_suppliers_inactive
            this.percentage_invoices_paid_on_time = this.data1[i].percentage_invoices_paid_on_time
            this.top_5_Sellers = this.data1[i].top_5_sellers
           
            this.loading= true
  
            for(var j =0;j< this.data1[i].top_5_sellers.length;j++){
           
              if(this.data1[i].top_5_sellers[j].program_status == 'IN-PROGRESS'){
                this.btn_color = 'btn btn-warning btn-sm'
                this.data1[i].top_5_sellers[j][8] = this.btn_color
              }else if(this.data1[i].top_5_sellers[j].program_status == 'ACTIVE'){
                this.btn_color = 'btn btn-success btn-sm'
                this.data1[i].top_5_sellers[j][8] = this.btn_color
              }else if(this.data1[i].top_5_sellers[j].program_status == 'CLOSED'){
                this.btn_color = 'btn btn-danger btn-sm'
                this.data1[i].top_5_sellers[j][8] = this.btn_color
              }else if(this.data1[i].top_5_sellers[j].program_status == 'PENDING'){
                this.btn_color = 'btn btn-secondary btn-sm'
                this.data1[i].top_5_sellers[j][8] = this.btn_color
              }else{
                this.btn_color = 'btn btn-info btn-sm'
                this.data1[i].top_5_sellers[j][8] = this.btn_color
              }
            }

            if(this.data1[i].invoices_claimable_claimed.length > 0){
              for(var j=0; j<this.data1[i].invoices_claimable_claimed.length;j++){
                this.month.push(this.data1[i].invoices_claimable_claimed[j].month)
                
                this.claimed.push(parseInt(this.data1[i].invoices_claimable_claimed[j].claimed))
                this.claimable.push(parseInt(this.data1[i].invoices_claimable_claimed[j].claimable))
              }
            }else{
              this.claimed.push(0)
              this.claimable.push(0)
            }
          

            this.month = this.month.reverse()
            this.claimable = this.claimable.reverse()
            this.claimed = this.claimed.reverse()
            for(var j=0; j<this.data1[i].active_programs_summary.length;j++){
              this.program_details.push(this.data1[i].active_programs_summary[j].program_name+', '+this.data1[i].active_programs_summary[j].currency+', '+this.data1[i].active_programs_summary[j].percentage + ' %')
              this.program_claimed.push(parseInt(this.data1[i].active_programs_summary[j].claimed))
              this.program_claimable.push(parseInt(this.data1[i].active_programs_summary[j].claimable))
            }
          }
          
          this.chartColor = "#FFFFFF";
  
          this.canvas = document.getElementById("chartActivity");
          this.ctx = this.canvas.getContext("2d");
    
          this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
          this.gradientStroke.addColorStop(0, '#80b6f4');
          this.gradientStroke.addColorStop(1, this.chartColor);
    
          this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
          this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
          this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");
    
          this.myChart = new Chart(this.ctx, {
            type: 'bar',
            data: {
              labels: this.month,
              datasets: [
    
                {
                  label: "Total Available",
                  borderColor: '#fcc468',
                  fill: true,
                  backgroundColor: '#fcc468',
                  hoverBorderColor: '#fcc468',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  borderWidth: 0,
                  data: this.claimed//Only Array WIll Work
                  
                },
                {
                  label: "Total Claimed",
                  borderColor: '#4cbdd7',
                  fill: true,
                  backgroundColor: '#4cbdd7',
                  hoverBorderColor: '#4cbdd7',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  borderWidth: 0,
                  data: this.claimable
                }
                
              ]
            },
            options: {
    
              tooltips: {
                tooltipFillColor: "rgba(0,0,0,0.5)",
                tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                tooltipFontSize: 24,
                tooltipFontStyle: "bold",
                tooltipFontColor: "#fff",
                tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                tooltipTitleFontSize: 24,
                tooltipTitleFontStyle: "bold",
                tooltipTitleFontColor: "#fff",
                tooltipYPadding: 6,
                tooltipXPadding: 6,
                tooltipCaretSize: 8,
                tooltipCornerRadius: 1,
                tooltipXOffset: 10,
              },
    
    
              legend: {
                display: true
              },
              scales: {
    
                yAxes: [{
                  ticks: {
                    fontColor: "#9f9f9f",
                    fontStyle: "bold",
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    padding: 0
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    display: true,
                    drawBorder: false,
                    color: '#9f9f9f',
                  }
    
                }],
                xAxes: [{
                  barPercentage: .9,
                  gridLines: {
                    zeroLineColor: "white",
                    display: true,
                    drawBorder: false,
                    color: 'transparent',
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#9f9f9f",
                    fontStyle: "bold",
                    beginAtZero: true
                  }
                }]
              }
            }
          });
    
    
          this.canvas = document.getElementById("chartHours");
          this.ctx = this.canvas.getContext("2d");
    
          this.myChart = new Chart(this.ctx, {
            type: 'horizontalBar',
            data: {
              labels: this.program_details,
              datasets: [
                {
                  borderColor: '#FCC468',
                  fill: true,
                  backgroundColor: '#FCC468',
                  hoverBorderColor: '#FCC468',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  borderWidth: 3,
                  data: this.program_claimed,
                  label: 'Amount Available'
                },
                {
                  borderColor: '#4CBDD7',
                  fill: true,
                  backgroundColor: '#4CBDD7',
                  hoverBorderColor: '#4CBDD7',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  borderWidth: 3,
                  data: this.program_claimable,
                  label: 'Amount Claimed'
                },
              ]
            },
            options: {
              legend: {
                display: true,
              },
              tooltips: {
                enabled: true
              },
              scales: {
                yAxes: [{
                  ticks: {
                    fontColor: "#9F9F9F",
                    beginAtZero: true,
                    maxTicksLimit: 5,
                  },
                  gridLines: {
                    drawBorder: false,
                    zeroLineColor: "#ccc",
                    color: 'rgba(255,255,255,0.05)'
                  }
                }],
                xAxes: [{
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: '#9f9f9f',
                    zeroLineColor: "transparent",
                    display: true,
                  },
                  ticks: {
                    padding: 20,
                    fontColor: "#9F9F9F",
                    beginAtZero: true,
                  }
                }]
              },
            }
          });
        }

      });
      this.load=true
    }

    ngAfterViewInit(){
      $('#datatable1').DataTable({
        "pagingType": "full_numbers",
        "lengthMenu": [
          [10, 25, 50, -1],
          [10, 25, 50, "All"]
        ],
        responsive: true,
        processing: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search records",
        }

      });
    }
    getPercentage(offered,claimed){
      return ((parseFloat(claimed)/parseFloat(offered))*100).toFixed(2) == "NaN" ? 0 : ((parseFloat(claimed)/parseFloat(offered))*100).toFixed(2) 
    }
  
}
