import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
// import { HttpModule } from "@angular/http";
import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TooltipModule } from "ng2-tooltip-directive";
import { NgpSortModule } from "ngp-sort-pipe";
import { NgxCurrencyModule } from "ngx-currency";
import { AppComponent } from "./app.component";
import { SidebarModule } from "./sidebar/sidebar.module";
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";
import { AddProgramsComponent } from "./manage-programs/add-programs/add-programs.component";
import { AdminComponent } from "./layouts/admin/admin-components/admin/admin.component";
import { ViewProgramComponent } from "./layouts/admin/admin-components/view-program/view-program.component";
import { SellerListComponent } from "./manage-seller/seller-list/seller-list.component";
import { SearchComponent } from "./manage-programs/search/search.component";
import { BuyerListComponent } from "./manage-buyer/buyer-list/buyer-list.component";
import { SellerComponent } from "./layouts/seller/seller.component";
import { InvoicesComponent } from "./layouts/seller/seller-pages/invoices/invoices.component";
import { BuyerComponent } from "./layouts/buyer/buyer.component";
import { BuyerDashComponent } from "./layouts/buyer/buyer-component/buyer-dash/buyer-dash.component";
import { BuyerProgramsComponent } from "./layouts/buyer/buyer-component/buyer-programs/buyer-programs.component";
import { LogoutComponent } from "./layouts/logout/logout.component";
import { FunderComponent } from "./layouts/funder/funder.component";
import { FunderDashComponent } from "./layouts/funder/funder-component/funder-dash/funder-dash.component";
import { ProgramViewComponent } from "./layouts/funder/funder-component/program-view/program-view.component";
import { ParticipateComponent } from "./layouts/funder/funder-component/participate/participate.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { InterceptorService } from "./services/interceptor.service";
import { BuyerViewProgramComponent } from "./layouts/buyer/buyer-component/buyer-view-program/buyer-view-program.component";
import { ProfileComponent } from "./layouts/seller/seller-pages/profile/profile.component";
import { KycJourneyComponent } from "./layouts/seller/seller-pages/kyc-journey/kyc-journey.component";
import { BuyerInviteComponent } from "./manage-buyer/buyer-invite/buyer-invite.component";
import { SellerImportComponent } from "./manage-seller/seller-import/seller-import.component";
import { SellerViewComponent } from "./manage-seller/seller-view/seller-view.component";
import { ViewAsComponent } from "./layouts/admin/admin-components/view-as/view-as.component";
import { ViewAsSellerComponent } from "./view_seller/view-as-seller/view-as-seller.component";
import { ViewAsSellerDashComponent } from "./view_seller/view-as-seller-dash/view-as-seller-dash.component";
import { ViewAsSellerProfileComponent } from "./view_seller/view-as-seller-profile/view-as-seller-profile.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { OnBoardingComponent } from "./layouts/on-boarding/on-boarding/on-boarding.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { AuthGuardGuard } from "./services/auth-guard/auth-guard.guard";
import { LoggedInGuard } from "./services/logged-in.guard";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from "@angular/platform-browser";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDividerModule } from "@angular/material/divider";
import { FunderListComponent } from "./manage-funder/funder-list/funder-list.component";
import { FunderInviteComponent } from "./manage-funder/funder-invite/funder-invite.component";
import { IndividualKycComponent } from "./layouts/funder/funder-component/individual-kyc/individual-kyc.component";
import { CompanyKycComponent } from "./layouts/funder/funder-component/company-kyc/company-kyc.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { FunderViewComponent } from "./manage-funder/funder-view/funder-view.component";
import { BuyerViewComponent } from "./manage-buyer/buyer-view/buyer-view.component";
import { BuyerKycComponent } from "./layouts/buyer/buyer-component/buyer-kyc/buyer-kyc.component";
import { BuyerProfileComponent } from "./layouts/buyer/buyer-component/buyer-profile/buyer-profile.component";
import { KycComponent } from "./shared/kyc/kyc.component";
import { SharedService } from "./services/shared.service";
import { Kyc2Component } from "./shared/kyc2/kyc2.component";
import { ConfirmEqualValidatorDirective } from "./shared/Validators/confirm-equal-validator.directive";

import { SafePipe } from "./services/safe.pipe";
import { AcraComponent } from "./shared/acra/acra.component";
import { ProfileFunderComponent } from "./layouts/funder/funder-component/profile-funder/profile-funder.component";
import { LogsComponent } from "./layouts/admin/admin-components/logs/logs.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { LazysearchComponent } from "./layouts/admin/admin-components/lazysearch/lazysearch.component";
import { EnvService } from "./services/env.service";
import { SellerInviteComponent } from "./manage-seller/seller-invite/seller-invite.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { InvoiceSettlementComponent } from "./layouts/admin/admin-components/invoice-settlement/invoice-settlement.component";
import { MillionPipe } from "./pipes/million.pipe";
import { DebounceClickDirective } from './directive/debounce-click.directive';
import { ReplaceunderscorePipe } from './pipes/replaceunderscore.pipe';
import { AuditLogsComponent } from './layouts/admin/admin-components/view-program/audit-logs/audit-logs.component';
import { FunderdetailsComponent } from './layouts/admin/admin-components/view-program/funderdetails/funderdetails.component';
import { MappingTableComponent} from './layouts/admin/admin-components/view-program/mapping-table/mapping-table.component';
import { SellerParticipateComponent } from './manage-seller/seller-view/seller-participate/seller-participate.component';
import { BuyerParticipateComponent } from './manage-buyer/buyer-view/buyer-participate/buyer-participate.component';
import { FunderParticipateComponent } from './manage-funder/funder-view/funder-participate/funder-participate.component';
import { FundersExpressInterestComponent } from './layouts/admin/admin-components/view-program/funders-express-interest/funders-express-interest.component';
import { TreeModule } from '@circlon/angular-tree-component';


import { OnboardingFormComponent } from "./shared/onboarding-form/onboarding-form.component";
import { TreeStructureComponent } from "./shared/tree-structure/tree-structure.component";
import { MetaDefinationComponent } from "./manage-programs/meta-defination/meta-defination.component";
import { MustMatchDirective } from "./shared/Validators/must-match-validator.directive";
import { EikonCheckComponent } from './layouts/eikon-check/eikon-check.component';
import { DataService } from "./services/data.service";
import { TransposeComponent } from "./shared/transpose/transpose.component";
import { AllProfileComponent } from './shared/all-profile/all-profile.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false
    }),
    NgbModule,

    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedPluginModule,
    TooltipModule,
    HttpClientModule,
    NgpSortModule,
    NgxCurrencyModule,
    NgxSpinnerModule,
    NgxCaptchaModule,
    BrowserModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    TreeModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AddProgramsComponent,
    AdminComponent,
    ViewProgramComponent,
    SellerListComponent,
    SearchComponent,
    BuyerListComponent,
    SellerComponent,
    InvoicesComponent,
    BuyerComponent,
    BuyerDashComponent,
    BuyerProgramsComponent,
    LogoutComponent,
    FunderDashComponent,
    FunderComponent,
    ProgramViewComponent,
    ParticipateComponent,
    BuyerViewProgramComponent,
    ProfileComponent,
    KycJourneyComponent,
    BuyerInviteComponent,
    SellerImportComponent,
    SellerViewComponent,
    ViewAsComponent,
    ViewAsSellerComponent,
    ViewAsSellerDashComponent,
    ViewAsSellerProfileComponent,
    OnBoardingComponent,
    ConfirmEqualValidatorDirective,
    FunderListComponent,
    FunderInviteComponent,
    IndividualKycComponent,
    CompanyKycComponent,
    FunderViewComponent,
    BuyerViewComponent,
 BuyerKycComponent,
    BuyerProfileComponent,
    KycComponent,
    Kyc2Component,
    SafePipe,
    AcraComponent,
    ProfileFunderComponent,
    LogsComponent,
    LazysearchComponent,
    SellerInviteComponent,
    SpinnerComponent,
    InvoiceSettlementComponent,
    MillionPipe,
    DebounceClickDirective,
    ReplaceunderscorePipe,
    AuditLogsComponent,
    FunderdetailsComponent,
    MappingTableComponent,
    SellerParticipateComponent,
    BuyerParticipateComponent,
    FunderParticipateComponent,
    FundersExpressInterestComponent,
    TreeStructureComponent,
    MetaDefinationComponent,
    OnboardingFormComponent,
    EikonCheckComponent,
    TransposeComponent,
    AllProfileComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AuthGuardGuard,
    LoggedInGuard,
    SharedService,
    MustMatchDirective,
    ConfirmEqualValidatorDirective,
    DataService
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
