import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import { PushService } from "app/services/push.service";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { ThrowStmt } from "@angular/compiler";

declare var $: any;
@Component({
  selector: "app-participate",
  templateUrl: "./participate.component.html",
  styleUrls: ["./participate.component.css"]
})
export class ParticipateComponent implements OnInit {
  data1: any;
  id: number;
  b_name: any;
  party_name = [];
  password: any;
  display: string;
  FunderForm: FormGroup;
  maxval1: number;
  per: any;
  maxval: number;
  sub: number;
  total_fund: number;
  pct: number;
  agree_statement3: boolean = false;
  agree_statement1: boolean = false;
  agree_statement2: boolean = false;
  isDisabled: boolean;
  min_pct: any;
  loading: boolean;
  data_points: any;
  display_child: any;
  selected: any;
  rightValue: any;

  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private push: PushService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    if (sessionStorage.getItem("token") === null) {
      sessionStorage.setItem("program_id_read", this._route.snapshot.paramMap.get("id"));
      this._router.navigate(["/"]);
    } else {
      sessionStorage.removeItem("program_id_read");
    }

    this.per = 0;
    var pipe = new DatePipe("en-US");
    this.id = parseInt(this._route.snapshot.paramMap.get("id"));
    this._auth
      .editProgram(this.id)
      .toPromise()
      .then(res => {
        let data1 = {
          program_id: res["program_id"],
          program_name: res["program_name"],
          program_type_id: res["program_type_id"],
          buyer_id: res["buyer_id"],
          seller_id: res["seller_id"],
          sponsor_id: "ADMIN",
          description: res["description"],
          date_created: pipe.transform(res["date_created"], "dd/MM/yyyy"),
          start_date: pipe.transform(res["start_date"], "dd/MM/yyyy"),
          end_date: pipe.transform(res["end_date"], "dd/MM/yyyy"),
          date_approved: pipe.transform(res["date_approved"], "dd/MM/yyyy"),
          program_status: res["program_status"],
          seller_max_limit: res["seller_max_limit"],
          program_max_limit: res["program_max_limit"],
          max_funding_pct: res["max_funding_pct"],
          service_charges_pct: res["service_charges_pct"],
          interest_pct: res["interest_pct"],
          max_inv_credit_days: res["max_inv_credit_days"],
          insurance_details: res["insurance_details"],
          with_recourse: res["with_recourse"],
          program_currency: res["program_currency"],
          notes: "",
          create_uid: res["create_uid"],
          program_value: res["program_value"],
          status: res["status"],
          alert_threshold_pct: "",
          additional_transaction_fees: res["additional_transaction_fees"],
          min_pct: res["minumum_pct"]
        };

        this.data1 = data1;
        if (data1.insurance_details != null) {
          if (data1.insurance_details["coverage_period_end_date"] !== null) {
            let pipe1 = new DatePipe("en-US");
            data1.insurance_details["coverage_period_end_date"] = pipe1.transform(
              res["insurance_details"].coverage_period_end_date,
              "dd/MM/yyyy"
            );
            data1.insurance_details["coverage_period_start_date"] = pipe1.transform(
              res["insurance_details"].coverage_period_start_date,
              "dd/MM/yyyy"
            );
            data1.insurance_details["inception_date"] = pipe1.transform(res["insurance_details"].inception_date, "dd/MM/yyyy");
            data1.insurance_details["credit_limit"] = data1.insurance_details["credit_limit"].toLocaleString();
          }
        }
        if (sessionStorage.getItem("token") != null) {
          sessionStorage.setItem("program_value", res["program_value"]);
          sessionStorage.setItem("program_id", res["program_id"]);
          sessionStorage.setItem("min_pct", res["minumum_pct"]);
        }

        this.min_pct = sessionStorage.getItem("min_pct");
        if (this.data1.alert_threshold_pct == null) {
          this.data1.alert_threshold_pct = 0;
        } else {
          this.data1.alert_threshold_pct = res["alert_threshold_pct"];
        }

        this._auth
          .party(3)
          .toPromise()
          .then((res: any) => {
            if (res) {
              this.party_name = res;
              this.loading = false;
              for (var i = 0; i < res.length; i++) {
                this.data1[i] = {
                  party_id: this.party_name[i].party_id,
                  b_name: this.party_name[i].display_name
                };
                if (this.data1[i].party_id === this.data1.buyer_id) {
                  this.b_name = this.data1[i].b_name;
                }
              }
            }
          });

        this._auth
          .check_funder_participated(res["program_id"], sessionStorage.getItem("seller_id"))
          .toPromise()
          .then(
            (res: any) => { },
            err => {
              this.isDisabled = true;
              this.FunderForm = this.fb.group({
                amount_funded: [{ value: err.error.fundingDetails.amount_funded }]
              });
              this.per = err.error.fundingDetails.funded_pct;
            }
          );

        this._auth
          .funder_participated_programs(sessionStorage.getItem("login"), this.id)
          .toPromise()
          .then(
            (res: any) => {
              this.data_points = this.eachProgramChildren(res.program_data_points);
              console.log(this.data_points);
            },
            err => {
              console.log(err);
            }
          );


      });

    this.FunderForm = this.fb.group(
      {
        amount_funded: ["", [Validators.required, this.check]],
        funded_pct: [""]
      },
      { validators: this.check }
    );
  }
  onCloseHandled() {
    this.display = "none";
  }

  check(control: FormGroup) {
    if (control.get("amount_funded") && control.get("amount_funded").value != NaN) {
      let value = parseFloat(sessionStorage.getItem("program_value")) * (parseFloat(sessionStorage.getItem("min_pct")) / 100);

      if (parseFloat(control.get("amount_funded").value) < value) {
        control.get("amount_funded").setErrors({ check: true });
      } else {
        null;
      }
    }
  }
  // Total() {
  //   this.total_fund = parseInt(sessionStorage.getItem("program_value"));
  //   this.pct = parseInt(sessionStorage.getItem("max_fund_pct"));
  //   this.maxval = parseInt(sessionStorage.getItem("program_value"));
  //   var fund_amt = 0;
  //   for (var i = 0; i < this.record.length; i++) {
  //     fund_amt = fund_amt + parseInt(this.record[i].amount_funded);
  //   }

  //   this.sub = 0;
  //   if (fund_amt < this.maxval) {
  //     this.sub = this.maxval - fund_amt;
  //     this.maxval = this.sub;
  //   } else {
  //     this.isDisabled = false;
  //   }
  // }
  calc() {
    var fund = parseFloat(this.FunderForm.value.amount_funded);

    this.maxval1 = parseFloat(sessionStorage.getItem("program_value"));

    if (fund != 0 && fund <= this.maxval1) {
      this.per = (parseFloat(this.FunderForm.value.amount_funded) / parseFloat(sessionStorage.getItem("program_value"))) * 100;
    } else {
      if (fund === 0) {
        this.FunderForm.controls["amount_funded"].reset();
        this.FunderForm.controls["funded_pct"].reset();
        this.push.showNotification("Amount Must Be Greater Than Zero", "danger");
      }
    }
  }

  modal_display() {
    if (!this.FunderForm.valid) {
      this.push.showNotification("Please Fill Required Information", "error");
      this.FunderForm.markAllAsTouched();
      return;
    } else {
      $("#myModal").modal("show");
    }
  }
  showSwal() {
    let data = {
      funder_id: sessionStorage.getItem("seller_id"),
      amount_funded: this.FunderForm.value.amount_funded,
      funded_pct: this.per,
      program_id: sessionStorage.getItem("program_id")
    };

    this._auth
      .verify_trxn_pass(sessionStorage.getItem("user_id"), this.password)
      .toPromise()
      .then((res: any) => {
        if (res == "ok") {
          this._auth
            .fund_the_program(data)
            .toPromise()
            .then(
              (res: any) => {
                // this.push.showNotification("Success", "success");
                this._router.navigate(["/funder"]);
                Swal.fire({
                  title: "Thank You For Your Interest!",
                  text:
                    "Your Participation Request Is In Processing.You Will Be Notified Via Email On The Results Of Registration After Closing Date.",
                  buttonsStyling: false,
                  confirmButtonClass: "btn btn-success",
                  type: "success"
                });
              },
              (err: any) => {
                this.push.showNotification(err.error.message, "danger");
              }
            );
        }
      });
  }

  get_recourse(val) {
    if (val === true) {
      return "Yes";
    } else {
      return "No";
    }
  }

  dashboard() {
    this._router.navigate(["/funder"]);
  }

  onDestroy(): void {
    sessionStorage.removeItem("program_id");
    sessionStorage.removeItem("program_value");
    sessionStorage.removeItem("min_pct");
  }

  eachProgramChildren(tabs) {
    try {
      return tabs.map(tab => {
        if (tab.children != undefined) {
          tab.children.map(content => {
            console.log(content);
            let level = 0;
            content.level = level;
            if (content.hasOwnProperty("children")) {
              content.children.map(level1 => {
                level1.level = level + 1;
                return level1;
              });
            }
            return content;
          });
        }

        return tab;
      });
    } catch (error) {
      console.log(error)
    }

  }

  selectNodeLabel(node, event) {
    console.log("inside the function", JSON.parse(event.target.attributes['data-value'].value))

    let targetJSON = JSON.parse(event.target.attributes['data-value'].value);
    if (targetJSON.group != undefined) {
      this.display_child = targetJSON.group
      // $scope.prg.rightValue = targetJSON;
      console.log(targetJSON)
    }

  }

  setSelected(selected) {

    this.selected = selected;
    this.rightValue = "";
  }

}
