import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DatePipe, formatDate } from "@angular/common";
import { PushService } from "app/services/push.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SharedService } from "app/services/shared.service";
declare const $: any;
import * as moment from 'moment'; //in your component
@Component({
  selector: 'app-funderdetails',
  templateUrl: './funderdetails.component.html',
  styleUrls: ['./funderdetails.component.css']
})
export class FunderdetailsComponent implements OnInit {

  FunderForm: FormGroup;
  curr: {};
  loading: boolean;
  invite_participation: any;
  insurance_details : any
  record: any;
  total_fund: any;
  prg_name: any;
  pct: number;
  maxval: any;
  maxval1: number;
  sub: number;
  isDisabled = true;
  per: any;
  length: any;
  funderlist: any;
  load: boolean;
  party_name: Object;
  party_name_len: any;
  b_name: any;
  id : any

  data1 = {
    program_id: {},
    program_name: {},
    program_type_id: {},
    buyer_id: {},
    seller_id: {},
    sponsor_id: "ADMIN",
    description: {},
    date_created: {},
    start_date: {},
    end_date: {},
    date_approved: {},
    program_status: {},
    seller_max_limit: {},
    program_max_limit: {},
    max_funding_pct: {},
    service_charges_pct: {},
    interest_pct: {},
    max_inv_credit_days: {},
    insurance_details: {},
    with_recourse: {},
    program_currency: {},
    notes: {},
    create_uid: {},
    program_value: {},
    status: {},
    alert_threshold_pct: {},
    additional_transaction_fees: {},
    invite_participation: {},
    display: false
  };

  funder_list_array = {};
  deployed_amt: number;
  data: [];
  record_length: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public data3 = [];
  data4 = [];
  data5 = [];
  per1: number = 0;

  displayedColumns: string[] = ["funder_name", "display_name", "amount_funded", "funded_pct", "action"];
  dataSource: MatTableDataSource<any>;
  settle_data: { name: string; children: { name: string; field_type: string; value: string; id: number; }[]; }[];
  file: any;
  settle_id: any;


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private push: PushService,
    private http: HttpClient,
    private sharedService: SharedService
  ) { }



  ngOnInit(): void {

    this.FunderForm = this.fb.group({
      amount_funded: ["", [Validators.required]],
      funded_pct: [""],
      funder_id: ["", Validators.required],
      date_created: "",
      date_approved: "",
      program_id: 1,
      notes: ""
    });

    this.FunderForm.controls["amount_funded"].disable(); 
    
    this.id = parseInt(this._route.snapshot.paramMap.get("id"));
    this.loading = true;
    this.data4 = this.data3;
    for (const i of this.data4) {
      i.checked = false;
    }

    var pipe = new DatePipe("en-US");
    this.loading = true;
  
    
  }


  Total() {
    this.total_fund = parseInt(sessionStorage.getItem("program_value"));
    this.pct = parseInt(sessionStorage.getItem("max_fund_pct"));
    this.maxval = parseInt(sessionStorage.getItem("program_value"));
    var fund_amt = 0;
    for (var i = 0; i < this.record.length; i++) {
      fund_amt = fund_amt + parseInt(this.record[i].amount_funded);
    }

    console.log(fund_amt);
    this.sub = 0;
    if (fund_amt < this.maxval) {
      this.sub = this.maxval - fund_amt;
      this.maxval = this.sub;
    } else {
      this.isDisabled = false;
    }
  }

  hide_status(val) {
    if (val === "IN-PROGRESS") {
      return false;
    } else {
      return true;
    }
  }

  open_settlement(data: any){
    console.log(data)
    this.settle_data = data.settlement_document
   
    this.settle_id = data.id
    $("#myModal3").modal("show");
  }


  submit_data(data,id){
    console.log(data)
    if(data.expected_date != null){
      data.submitted_date = null
    }
    this._auth.settlement_data(JSON.stringify({id: id,settlement_document:data})).subscribe((res:any)=>{
      this.push.showNotification('Data Updated', 'success')
    },err=>{
      this.push.showNotification('Something Went Wrong', 'danger')
    })
  }


  addFunderDetails(): void { 

    if (this.FunderForm.value.amount_funded > 0) {
      let data;

      if (this.invite_participation === false) {
        data = {
          program_id: sessionStorage.getItem("program_id"),

          funder_id: this.FunderForm.value.funder_id,
          amount_funded: this.FunderForm.value.amount_funded,
          funded_pct: this.per,
          date_created: "",
          date_approved: "",
          notes: "",
          program_name: sessionStorage.getItem("program_name")
        };
        var obj = JSON.stringify(data);

        this._auth.addFunder(obj).subscribe(
          res => {
            
            // console.log(data);
            this.addFunders(sessionStorage.getItem("program_id"));
            this.push.showNotification("Funder Added Successfully", "success");
          },
          err => {
            if (err.status === 400) {
              this.push.showNotification("Cannot Add Funder, Please Try Again Later", "danger");
            } else if (err.status === 501) {
              this.push.showNotification("Internal Server Error, Please Try Again Later", "danger");
            } else {
              this.push.showNotification("Something Went Worng, Please Try Again Later", "danger");
            }
          }
        );
      } else {
        data = {
          program_id: sessionStorage.getItem("program_id"),

          funder_id: this.FunderForm.value.funder_id,
          amount_funded: this.FunderForm.value.amount_funded,
          funded_pct: this.per,
          prospect: "SELECTED"
        };

        this._auth.addFunder(data).subscribe(
          res => {
            // console.log(data);
            this.ngOnInit()
            this.push.showNotification("Funder Added Successfully", "success");
          },
          err => {
            if (err.status === 400) {
              this.push.showNotification("Cannot Add Funder, Please Try Again Later", "danger");
            } else if (err.status === 501) {
              this.push.showNotification("Internal Server Error, Please Try Again Later", "danger");
            } else {
              this.push.showNotification("Something Went Worng, Please Try Again Later", "danger");
            }
          }
        );
      }
    } else {
      this.push.showNotification("Amount Must Be Greater Than Zero", "danger");
    }
  }

  checkfunder() {
    var id = this.FunderForm.value.funder_id;
    console.log(this.id, this.record);
    if (this.sub === 0) {
      this.FunderForm.controls["amount_funded"].disable();
    } else {
      this.FunderForm.controls["amount_funded"].enable();
    }
    for (var i = 0; i < this.record.length; i++) {
      console.log(this.funder_list_array);
      if (id != this.funder_list_array[i]) {
      } else {
        this.push.showNotification("This Funder Is Already Part Of The Program", "danger");
        this.FunderForm.reset();
      }
    }
  }

  addFunders(id) {
    let data = [];
    this._auth.getFunder(id).subscribe((res: any) => {
      if (res) {
        this.record = res;
        this.data = res;
        data = res;
        this.per1 = 0;
        for (const i of data) {
          this.per1 += parseFloat(i.funded_pct);
        }
        this.dataSource = new MatTableDataSource(this.data);

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.record_length = res.length;

        this.Total();

        this.FunderForm.reset();
        this.per = "";

        for (var i = 0; i < res.length; i++) {
          this.funder_list_array[i] = this.record[i].funder_id;
        }
      }
      //
    });
  }

  removeFunder(id) {
    if (this.invite_participation === true) {
      let data = this.data4.filter(x => x.funder_id === id);

      let data1 = {
        funder: data[0],
        program_id: sessionStorage.getItem("program_id")
      };
      this._auth.cancel_funding(data1).subscribe((res: any) => {
        this.ngOnInit()
        this.push.showNotification("Funder Deleted Successfully", "success");
      });
    } else {
      this._auth.popFunder(id).subscribe(res => {
        if (res === 1) {
          this.addFunders(sessionStorage.getItem("program_id"));
          this.Total();
          this.push.showNotification("Funder Deleted Successfully", "success");
        }
      });
    }


  }

  

  calc() {
    var fund = parseFloat(this.FunderForm.value.amount_funded);
    
    

    this.maxval1 = parseFloat(sessionStorage.getItem("program_value"));
    this.maxval = parseInt(sessionStorage.getItem("program_value"));

    if (fund != 0 && fund <= this.maxval && this.sub != 0) {
      this.isDisabled = true;
      this.per = ((fund / this.maxval1) * 100).toFixed(2);
    } else {
      this.isDisabled = false;
      if (fund === 0) {
        this.FunderForm.controls["amount_funded"].reset();
        this.FunderForm.controls["funded_pct"].reset();
        this.push.showNotification("Amount Must Be Greater Than Zero", "danger");
      }
      if (fund > this.maxval) {
        this.FunderForm.controls["amount_funded"].reset();
        this.FunderForm.controls["funded_pct"].reset();
        this.push.showNotification("Amount Can Not Exceed Pending Amount", "danger");
      }
    }
  }

  getFunders() {
    this._auth
      .funderList()
      .toPromise()
      .then((res: any) => {
        this.length = res.length;
        if (res) {
        
          
          this.funderlist = res;
          this.funderlist = this.funderlist.filter(t => t.user_status == "APPROVED");
        }
        this.load = true;
        this._auth
          .party(3)
          .toPromise()
          .then((res: any) => {
            if (res) this.party_name = res;
            this.party_name_len = res.length;
            for (var i = 0; i < res.length; i++) {
              this.data1[i] = {
                party_id: this.party_name[i].party_id,
                b_name: this.party_name[i].display_name
              };
              if (this.data1[i].party_id === this.data1.buyer_id) {
                this.b_name = this.data1[i].b_name;
              }
            }
            this.load = false;
          });
      });
  }

}
