import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpBackend, HttpParams } from "@angular/common/http";
import { JwtHelperService } from "@auth0/angular-jwt";
import { catchError, tap, map } from "rxjs/operators";
import { throwError, Subject, BehaviorSubject, Observable } from "rxjs";
// import {stage} from '../../environments/environment.staging';
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { EnvService } from "./env.service";


const httpOptions = {
  headers: new HttpHeaders({ Authorization: `Bearer ${sessionStorage.getItem("token")}` })
};

const httpOptions1 = {
  headers: new HttpHeaders({ Authorization: "Bearer lkasyd978as69da7sd6a!736@", 'Content-Type': 'application/json' })
};

const httpOptions2 = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: "root"
})
export class AuthService {
  // readonly APIUrl = ;

  jwtHelper = new JwtHelperService();

  role: any;
  data: any;

  programdata: any;
  currentData: any;
  httpClient: HttpClient;
  APIUrl: string;

  constructor(private envService: EnvService, private http2: HttpClient, private http: HttpClient, private http1: HttpClient, private handler: HttpBackend, private router: Router) {
    this.APIUrl = this.envService.apiUrl;
  }

  private _refreshneded = new Subject<void>();

  getRefresh() {
    return this._refreshneded;
  }

  getUser(obj) {
    return this.http.post(this.APIUrl + "/login", obj);
  }

  addProgram(data) {
    return this.http.post(this.APIUrl + "/program", data);
  }

  editProgram(id) {
    return this.http.get(this.APIUrl + "/programs/" + `${id}`);
  }

  sellerfetchProgram(id) {
    return this.http.get(this.APIUrl + "/programs/" + `${id}`);
  }

  passdata(data) {
    this.currentData = data;
  }
  getdata() {
    return this.currentData;
  }

  validUser(role) {
    this.role = role;
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token);
  }

  get RoleId() {
    if (this.role === 1) return true;
  }

  public addFunder(data) {
    return this.http.post(this.APIUrl + "/funder", data).pipe(
      tap(() => {
        this._refreshneded.next();
      }),
      catchError(this.handleError)
    );
  }

  public getFunder(data) {
    var data = data;
    return this.http.get(this.APIUrl + "/funders/program/" + `${data}`);
  }
  public popFunder(id) {
    return this.http.delete(this.APIUrl + "/funders/program/" + `${id}`);
  }

  public getProgramList() {
    return this.http.get(this.APIUrl + "/programs");
  }

  public getkycDetails(party_id) {
    return this.http.get(this.APIUrl + "/kyc/details/" + `${party_id}`);
  }
  // public party(category_id,type) {
  //   return this.http.get(this.APIUrl + "/users/" + `${category_id}/${type}`);
  // }
  public party(party_id) {
    return this.http.get(this.APIUrl + "/users/type/" + `${party_id}`);
  }
  public getseller() {
    return this.http.get(this.APIUrl + "/users/type/2");
  }

  public funderList() {
    return this.http.get(this.APIUrl + "/users/type/4");
  }

  public invitebuyer(data) {
    return this.http.post(this.APIUrl + "/invite/user", data);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(errorMessage);
  }

  importInv(pid, bid, data) {
    return this.http.post(this.APIUrl + "/import/invoices/" + `${pid}/${bid}`, data);
  }

  updatestatus(pid, status) {
    return this.http.patch(this.APIUrl + "/program/" + pid + "/" + status, {}).pipe(catchError(this.handleError));
  }

  listInvoice(id) {
    return this.http.get(this.APIUrl + "/list/invoices/" + `${id}`);
  }

  getStatus(id) {
    return this.http.get(this.APIUrl + "/status/" + `${id}`);
  }

  updateStatus(uid, status) {
    sessionStorage.setItem("status", status);
    return this.http.patch(this.APIUrl + "/status/" + `${uid}/${status}`, {});
  }

  updatePassword(passcode, data) {
    return this.http.patch(this.APIUrl + "/update/password/" + `${passcode}`, data);
  }

  getPermId(id) {
    return this.http.get(this.APIUrl + "/party/details/" + `${id}`);
  }

  unique_prgs(id) {
    return this.http.get(this.APIUrl + "/list/programs/seller/" + `${id}`);
  }

  total_amt_inv_funded(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/total_amt_of_inv_funded/" + `${id}`);
  }
  total_inv_funded(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/total_inv_funded_date/" + `${id}`);
  }

  total_inv_funded_preceeding_date(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/total_inv_funded_preceeding_date/" + `${id}`);
  }

  total_invoices_in_program(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/total_invoices_in_program/" + `${id}`);
  }

  total_suppliers_in_program(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/total_suppliers_in_program/" + `${id}`);
  }

  approved_discounted(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/approved_discounted/" + `${id}`);
  }

  pricing_buckets(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/pricing_buckets/" + `${id}`);
  }

  top_5_vendors(id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/stats/top_5_vendors/" + `${id}`);
  }

  program_last_month(id) {
    return this.http.get(this.APIUrl + "/seller/dashboard/stats/funding_by_program_last_month/" + `${id}`);
  }

  program_month(id) {
    return this.http.get(this.APIUrl + "/seller/dashboard/stats/funding_by_program_month/" + `${id}`);
  }

  available_vs_fund(id) {
    return this.http.get(this.APIUrl + "/seller/dashboard/stats/inv_available_vs_funded/" + `${id}`);
  }

  tot_amt_inv_fund() {
    return this.http.get(this.APIUrl + "/seller/dashboard/stats/total_amt_of_inv_funded");
  }

  get_buyer_program_list(id) {
    return this.http.get(this.APIUrl + "/programs/list/" + `${id}`);
  }

  get_notifications(id) {
    return this.http.get(this.APIUrl + "/notifications/" + `${id}`);
  }

  post_notifications(data) {
    return this.http.post(this.APIUrl + "/notifications/", data);
  }

  get_top_5_discounted(prg_id) {
    return this.http.get(this.APIUrl + "/seller/vendors/top5/" + `${prg_id}`);
  }

  get_pricing_bucket(prg_id) {
    return this.http.get(this.APIUrl + "/seller/pricing/buckets/" + `${prg_id}`);
  }

  program_deployed_amount(prg_id) {
    return this.http.get(this.APIUrl + "/program/deployed/" + `${prg_id}`);
  }

  postFile(fileToUpload: File, id, b_id) {
    this.httpClient = new HttpClient(this.handler);
    const formData: FormData = new FormData();

    formData.append("invoices", fileToUpload);
    return this.httpClient.post(this.APIUrl + "/dev-import-invoices/" + `${id}/${b_id}`, formData, httpOptions);
  }

  public submitInvoice(data, id) {
    return this.http.post(this.APIUrl + "/invoices/submit/" + `${id}`, data);
  }

  public overall_claimed_amt(b_id) {
    return this.http.get(this.APIUrl + "/program/seller/overall/claimed/" + `${b_id}`);
  }

  public seller_claimed(id, b_id) {
    return this.http.get(this.APIUrl + "/program/seller/claimed/" + `${id}/${b_id}`);
  }
  public dashboard_deployed_amt() {
    return this.http.get(this.APIUrl + "/dashboard/deployed/amounts");
  }

  public dashboard_deployed_amount(id) {
    return this.http.get(this.APIUrl + "/dashboard/deployed/amounts/" + `${id}`);
  }

  public update_status_for_admin_seller_approve(id, status) {
    return this.http.patch(this.APIUrl + "/status/seller/" + `${id}/${status}`, {});
  }

  verify_trxn_pass(id, password) {
    return this.http.get(this.APIUrl + "/dashboard/seller/txnpwd/verify/" + `${id}/${password}`, {});
  }

  funds_to_date_data(id) {
    return this.http.get(this.APIUrl + "/dashboard/seller/fundsclaimed/program/" + `${id}`);
  }

  buyer_details(id, program_id) {
    return this.http.get(this.APIUrl + "/buyer/dashboard/details/" + `${id}/${program_id}`);
  }

  funder_details(id) {
    return this.http.get(this.APIUrl + "/funder/dashboard/details/" + `${id}`);
  }

  todays_claimed(id) {
    return this.http.get(this.APIUrl + "/seller/claimed/today/" + `${id}`);
  }

  seller_dashboard_data(id) {
    return this.http.get(this.APIUrl + "/seller/graph/" + `${id}`);
  }

  update_flag_status(id, status) {
    return this.http.get(this.APIUrl + "/worldcheck/" + `${id}/${status}`);
  }

  upload_address(name, ext, fileToUpload: File, seller_id) {
    this.httpClient = new HttpClient(this.handler);
    const formData = new FormData();

    formData.append("uploadedfile", fileToUpload);
    formData.append("file_name", name);
    formData.append("file_extention", ext);
    // var object = {};
    // formData.forEach((value, key) => {object[key] = value});
    // console.log(object)
    return this.httpClient.post(this.APIUrl + "/import/seller-doc/" + `${seller_id}`, formData, httpOptions);
  }

  upload_id(name, ext, fileToUpload: File, seller_id) {
    this.httpClient = new HttpClient(this.handler);
    const formData: FormData = new FormData();

    formData.append("uploadedfile", fileToUpload);
    return this.httpClient.post(this.APIUrl + "/import/seller-doc/" + `${seller_id}`, formData, httpOptions);
  }

  updating_uploaded_files(docs, seller_id) {
    return this.http.post(this.APIUrl + "/seller-doc/update/" + `${seller_id}`, docs);
  }

  company_info(data) {
    return this.http.put(this.APIUrl + "/onboarding/funder/companyInfo", data);
  }

  kyc(data) {
    return this.http.put(this.APIUrl + "/onboarding/funder/kyc", data);
  }

  bank(data) {
    return this.http.put(this.APIUrl + "/onboarding/funder/bankInfo", data);
  }

  invite_funder(data): Observable<{}> {
    return this.http.post(this.APIUrl + "/funder/invite", data, httpOptions).pipe(catchError(this.handleError));
  }

  funder_upload_doc(fileToUpload: File, funder_id, doc_type) {
    this.httpClient = new HttpClient(this.handler);
    const formData: FormData = new FormData();

    formData.append("uploadedfile", fileToUpload);
    return this.httpClient.post(this.APIUrl + "/import/" + `${doc_type}` + "/" + `${funder_id}`, formData, httpOptions);
  }

  buyer_company_info(data) {
    return this.http.patch(this.APIUrl + "/onboarding/buyer/companyInfo", data);
  }

  buyer_credit_check(data) {
    return this.http.patch(this.APIUrl + "/onboarding/buyer/creditCheck", data);
  }

  buyer_bankInfo(data) {
    return this.http.patch(this.APIUrl + "/onboarding/buyer/bankInfo", data);
  }

  check_email(email) {
    return this.http.get(this.APIUrl + "/user/check/" + `${email}`);
  }

  get_permid(name) {
    return this.http.get(this.APIUrl + "/permid/" + `${name}`);
  }

  update_permid(pid, permid) {
    return this.http.get(this.APIUrl + "/permid/update/" + `${pid}` + "/" + `${permid}`);
  }

  logs_prefill(type, q) {
    return this.http.get(this.APIUrl + "/logs/prefill/" + `${type}/${q}`);
  }

  search_type(type, id) {
    return this.http.get(this.APIUrl + "/logs/search/" + `${type}/${id}`);
  }

  check_uen(uen) {
    return this.http.get(this.APIUrl + "/check/uen/" + `${uen}`);
  }

  get_worldcheck_details(data) {
    return this.http.post(this.APIUrl + "/worldcheck/cases/screeningRequest", JSON.stringify(data));
  }

  get_referenceid_data(id) {
    return this.http.get(this.APIUrl + "/worldcheck/reference/profile/" + `${id}`);
  }

  update_worldcheck_data(id, data) {
    return this.http.post(this.APIUrl + "/update/worldcheck/" + `${id}`, JSON.stringify(data));
  }

  invoice_settlement(fileToUpload: File) {
    this.httpClient = new HttpClient(this.handler);
    const formData: FormData = new FormData();

    formData.append("invoiceSettlementFile", fileToUpload);
    return this.httpClient.post(this.APIUrl + "/upload/invoice-settlement", formData, httpOptions);
  }

  seller_invite(data) {
    return this.http.post(this.APIUrl + "/seller/invite", data);
  }

  seller_reinvite(id) {
    return this.http.post(this.APIUrl + "/re-send/mail/" + `${id}`, {});
  }
  seller_send_invite(id) {
    return this.http.post(this.APIUrl + "/send/mail/" + `${id}`, {});
  }

  seller_onboarding(type, data) {
    data.party_id = sessionStorage.getItem("seller_id");
    return this.http.put(this.APIUrl + "/onboarding/seller/" + `${type}`, JSON.stringify(data));
  }
  get_map_program_details(id) {
    return this.http.get(this.APIUrl + "/mapping/program/" + `${id}`);
  }

  update_mapping(data) {
    return this.http.put(this.APIUrl + "/mapping", data);
  }

  funders_accepted_invitation(program_id: string) {
    return this.http.get(this.APIUrl + "/funders/accepted-invititions/" + `${program_id}`);
  }

  admin_accept_reject_invitaion_funders(data) {
    return this.http.post(this.APIUrl + "/funders/approve-funds", JSON.stringify(data));
  }

  fund_the_program(data) {
    return this.http.post(this.APIUrl + "/accept-program-invite", JSON.stringify(data));
  }

  check_funder_participated(prg_id, fund_id) {
    return this.http.post(
      this.APIUrl + "/funder/participation/status",
      JSON.stringify({
        program_id: prg_id,
        funder_id: fund_id
      })
    );
  }

  check_username(name) {
    return this.http.get(this.APIUrl + "/check/user-name/" + `${name}`);
  }

  check_programName(name: string) {
    let convertedString = name;
    if (name.includes('%')) {
      convertedString = convertedString.replace('%', '_pct_');
    }
    if (name.includes('@')) {
      convertedString = convertedString.replace('@', '_atTheRate_');
    }
    if (name.includes('#')) {
      convertedString = convertedString.replace('#', '_hash_');
    }
    return this.http.get(this.APIUrl + "/dedupe-check/" + `${convertedString}`);
  }

  program_search(data) {
    return this.http.post(this.APIUrl + "/programs/search", JSON.stringify(data));
  }

  buyer_programs(id) {
    return this.http.get(this.APIUrl + "/programs/list/" + `${id}`);
  }

  funder_programs(id) {
    return this.http.get(this.APIUrl + "/programs/list/funder/" + `${id}`);
  }

  bulk_import(data) {
    return this.http.post(this.APIUrl + "/seller-bulk/invite", JSON.stringify(data));
  }

  interest_shown_selected(data) {
    return this.http.post(this.APIUrl + "/funder/sanction-funding", JSON.stringify(data));
  }

  cancel_funding(data) {
    return this.http.put(this.APIUrl + "/funder/cancel-funding", JSON.stringify(data));
  }

  participated_programs(user_name, id) {
    return this.http.get(this.APIUrl + "/" + `${user_name}` + "/participated-programs/" + `${id}`);
  }

  funder_participated_programs(user_name, id) {
    return this.http.get(this.APIUrl + "/external/programs-list/" + `${user_name}` + "/" + `${id}`);
  }

  update_program(data) {
    return this.http.put(this.APIUrl + "/update/program", JSON.stringify(data));
  }

  update_documents(data) {
    return this.http.post(this.APIUrl + "/update/document", JSON.stringify(data));
  }

  update_seller_max_limit(type, val, id) {
    let data = {
      limit: parseFloat(val),
      party_id: id
    };

    return this.http.put(this.APIUrl + "/update-limit/" + `${type}`, JSON.stringify(data));
  }


  update_uen(data) {
    return this.http.put(this.APIUrl + "/update/uen", JSON.stringify(data));
  }

  update_name(data) {
    return this.http.post(this.APIUrl + "/update/name", JSON.stringify(data));
  }

  get_funders_expressed_interest(id: string) {
    this.httpClient = new HttpClient(this.handler);
    return this.httpClient.get(this.APIUrl + "/external/interested/" + `${id}`, httpOptions1)
  }
  accept_reject_funders_interest(data) {
    this.httpClient = new HttpClient(this.handler);

    return this.httpClient.patch(this.APIUrl + "/external/update/status", JSON.stringify(data), httpOptions1)
  }

  accept_reject_funders_interest1(data) {
    this.httpClient = new HttpClient(this.handler);

    return this.httpClient.patch(this.APIUrl + "/external/update/status/corporate", JSON.stringify(data), httpOptions1)
  }


  update_alias(data) {
    return this.http.put(this.APIUrl + "/update/alias", JSON.stringify(data))
  }

  commodity_trade_programs() {
    return this.http.get(this.APIUrl + "/external/programs")
  }

  program_categories() {
    return this.http.get(this.APIUrl + "/program/categories")
  }

  get_program_types(id: number) {
    console.log(id)
    return this.http.get(this.APIUrl + "/program-types/" + `${id}`)
  }

  program_definition(data: any) {
    return this.http.post(this.APIUrl + "/structure/definition", data)
  }

  file_upload(program_id, fileToUpload: File) {
    this.httpClient = new HttpClient(this.handler);
    const formData: FormData = new FormData();

    formData.append("uploadedfile", fileToUpload);
    return this.httpClient.post(this.APIUrl + "/import-eikon/" + `${program_id}`, formData, httpOptions);
  }

  invite_user(data) {
    return this.http.post(this.APIUrl + "/invite/user", data)
  }

  invite_user_patch(data) {
    return this.http.patch(this.APIUrl + "/invite/user", data)
  }

  get_template_name(program_type_id) {
    return this.http.get(this.APIUrl + "/templates/" + `${program_type_id}`)
  }

  get_definition_structure(program_type_id, name, template_type) {
    let params = new HttpParams();
    params = params.append('type', program_type_id);
    params = params.append('name', name);
    params = params.append('template_type', template_type);
    return this.http.get(this.APIUrl + "/structure/definition", { params: params })
  }


  settlement_data(data) {
    return this.http.patch(this.APIUrl + "/external/update/settlement", data)
  }

  public download_report(fname) {
    console.log('In download::' + fname);
    return this.http.get(this.APIUrl + "/download/qb/" + `${fname}`);
  }

  eikon_user_check = (data) => {

    return this.http.post(this.APIUrl + '/login', data)
  }
}
