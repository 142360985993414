import { Component } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { AuthService } from './services/auth.service';
import { EnvService } from './services/env.service';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  someNavigationService: any;
  id: any;
  fav_icon: string;
  page_name: string;
  fav_icon1: string;
  current_url: string;
  scrollTop(event) {
    window.scroll(0,0);
  }

  constructor(private envService: EnvService,location: PlatformLocation, private router: Router, private _route: ActivatedRoute, private _auth: AuthService) {
    console.log(this.envService.Page_Name)
   

      if ((this.envService.Page_Name === 'Refinitiv' )) {

        console.log("Ref")
        this.page_name = 'Refinitiv';
        document.getElementById('appIcon1').setAttribute('href', './assets/img/ref_favicon_io/favicon-32x32.png');
        document.getElementById('appIcon').setAttribute('href', './assets/img/ref_favicon_io/apple-touch-icon.png');
        

      } else {
        console.log("other")

       
        this.page_name = 'Seabridge TFX';
        document.getElementById('appIcon1').setAttribute('href', './assets/img/apple-touch-icon.png');
        document.getElementById('appIcon').setAttribute('href', './assets/img/favicon.ico');
 
      }
    this.id = this._route.snapshot.paramMap.get("id");
    
    if(sessionStorage.getItem('impersonate_role') === '5'){

    }else{
      
      if(sessionStorage.getItem('id') === '1'){
        this.router.navigate(['/admin'])
      }else if(sessionStorage.getItem('id') === '2') {
        if(sessionStorage.getItem('status') === 'APPROVED'){
          console.log(this.current_url)
          console.log(this.getPage())
          this.router.navigate(['/invoice'])
        }else {
          this.router.navigate(['/kyc-process'])
        }
      }else if(sessionStorage.getItem('id') === '3') {
        this._auth.getStatus(sessionStorage.getItem("seller_id")).toPromise().then(res => {
          sessionStorage.setItem("status", res["user_status"]);
          if(res["user_status"] === "INVITED" ||res["user_status"]=== "KYC"|| res["user_status"] === "TERMS" || res["user_status"] === "CREDIT-CHECK" || res["user_status"] === "RECEIVED"){
            this.router.navigate(["/Buyer-Onboarding"])
          }else {
            this.router.navigate(["/buyer"])
          }
          
        });

      }else if(sessionStorage.getItem('id') === '4'){
       

        this._auth.getStatus(sessionStorage.getItem("seller_id")).toPromise().then(res => {
          sessionStorage.setItem("status", res["user_status"]);
          if(res["user_status"] === "INVITED" ||res["user_status"]=== "KYC"|| res["user_status"] === "CREDIT-CHECK" || res["user_status"] === "TERMS" || res["user_status"] === "RECEIVED"){
            this.router.navigate(["/Funder-Onboarding"])
          }else {
            this.router.navigate(["/funder"]);
          }
          
        });
      }
      else {
        
      }
    }

    

  }

 getPage(){
  this.router.events
  .pipe(filter((evt:any)=>
    evt instanceof RoutesRecognized
  ),pairwise()).subscribe( (events: RoutesRecognized[])=>{
    // console.log(events,events[0].urlAfterRedirects, events[1].urlAfterRedirects)
    // this.current_url = events[0].urlAfterRedirects
    return events[0].urlAfterRedirects
  })
 }
}
