import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from "@angular/core";

import { SharedService } from "app/services/shared.service";
import { AuthService } from "app/services/auth.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.css"]
})
export class LogsComponent implements OnInit {
  lazy1: any;
  @ViewChild("lazycomponent", { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  show_dropdown: boolean;
  loading: boolean;
  data1: any;
  type = "";
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ["program_name", "user_name", "invoice_number", "notes", "created", "details", "source"];
  dataSource: MatTableDataSource<any>;
  value: any;
  data: string;
  filterValues = {};
  applyFilter(event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  constructor(private _auth: AuthService, private sharedService: SharedService, private cfr: ComponentFactoryResolver) {}

  ngOnInit(): void {
    this.show_dropdown = false;
    this.loading = true;

    // this.dataSource.filterPredicate = function(data, filter: string): boolean {
    //   return data.source.toLowerCase().includes(filter);
    // };
  }

  async getLazy1(val) {
    this.value = val;
    this.show_dropdown = true;
    this.sharedService.nextMessage3(val);

    const { LazysearchComponent } = await import("app/layouts/admin/admin-components/lazysearch/lazysearch.component");
    this.lazy1 = this.viewContainerRef.createComponent(this.cfr.resolveComponentFactory(LazysearchComponent));
  }

  search() {
    this.sharedService.sharedMessage4.subscribe((res: any) => {
      if (res != "") {
        this._auth.search_type(res.type, res.item).subscribe(
          (res: any) => {
            this.data1 = res;
            this.loading = false;
            this.dataSource = new MatTableDataSource(this.data1);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            this.dataSource.filterPredicate = function(data, filter: string): boolean {
              if (data.source != null) {
                return data.source.toLowerCase().includes(filter);
              }
            };
          },
          err => {
            console.log(err);
          }
        );
      } else {
        console.log("Please Fill some data");
      }
    });
  }

  display(message, data) {
    this.data = message === undefined ? JSON.stringify(data, null, "\t") : message;
    $("#myModal1").modal("show");
  }
  clear() {
    this.type = "";
    this.sharedService.clear();
    this.loading = true;
    this.show_dropdown = false;
    this.viewContainerRef.clear();
  }
}
