import { Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { PushService } from "app/services/push.service";
import { Router } from "@angular/router";
import { FormBuilder, Validators,  FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
declare var $: any;
@Component({
  selector: "app-seller-import",
  templateUrl: "./seller-import.component.html",
  styleUrls: ["./seller-import.component.css"]
})
export class SellerImportComponent implements OnInit {
  programs: any;
  buyer_name: any = "";
  programs_1: any;
  file: any;
  uploadForm: any;
  data1: any;
  file_name: any = "Choose File";
  company_info_nav_link: string;
  company_info_tab: string;

  submitted: boolean;
  Invite: any;
 
  data2 = [];
  data3 = [];
  data: Object;
  btn_disabled: boolean;
  _route: any;
  email: any;
  name: any;
  sharedService: any;
  selectedFiles: any;
 
  file1: any;
  program_id: any;
  buyer_id: any;
  file_type: string = "invalid";
  hide: boolean = true;
  program_bulk_import: boolean = false

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ["Company Name", "UEN", "Contact Person", "Email Address", "status"];
  dataSource: MatTableDataSource<any>;
  failedList: any;
  category_value: number;
  program_categories: any;
  program_type_val: any;
  display: boolean=false;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  constructor(private _auth: AuthService, private push: PushService, private _router: Router, private fb: FormBuilder) {}

  ngOnInit(): void {
    this._auth.getProgramList().subscribe((res: any) => {
      this.programs = res;
      this.programs = this.programs.filter(x => x.status === "ACTIVE");
    });

    this.company_info_nav_link = "nav-link active";
    this.company_info_tab = "tab-pane active";
    // this.option_nav_link = "nav-link";
    // this.option_info_tab = "tab-pane";
    this._auth.program_categories().subscribe(async(res: any) => {
      this.display = true;
      this.program_categories = await res
      this.Invite = this.fb.group({
        program_id : new FormControl(''),
        acra: new FormControl(false),
        program_type_id: new FormControl(''),
        program_category: new FormControl('')
  
      });
    });

  
  }

  getBuyer(event) {
    this.program_id = event.target.value;
    this.programs_1 = this.programs.filter(x => x.id === parseInt(event.target.value));
    this.buyer_name = this.programs_1[0].buyer_name;
    this.buyer_id = this.programs_1[0].buyer_id;
  }

  get_program_type() {
    this.category_value = parseInt(this.Invite.value.program_category)
    this._auth.get_program_types(parseInt(this.Invite.value.program_category)).subscribe(async (res: any) => {
      this.program_type_val = await res[`${this.program_categories.filter(x=>x.id === this.category_value)[0].name.toLowerCase()}`]    },(err)=>{
      this.push.showNotification("Something Went Wrong", "danger")
    })
  }

  onUpload(event) {
    this.file = "";
    this.file_type = "invalid";
    this.file_name = "Choose File";
    if (event.target.files.length > 0) {
      console.log(event.target.files[0].type )
      if (event.target.files[0].type === "text/csv" || event.target.files[0].type === "application/vnd.ms-excel") {
        this.file_type = "valid";
        this.file = event.target.files[0];
        this.file_name = event.target.files[0].name;
        this._auth
          .funder_upload_doc(this.file, sessionStorage.getItem("seller_id"), "seller-doc")
          .toPromise()
          .then(
            (res: any) => {
              if (res) {
                this.file1 = res["filename"];
              }
            },
            error => {
              this.push.showNotification("Upload Failed", "danger");
            }
          );
      } else {
        this.file_type = "invalid";
        this.push.showNotification("Incorrect File Type", "danger");
      }
    }
  }

  
  callChange(){  
    if(this.program_bulk_import === false){ 
      this.Invite.get('program_id').clearValidators()
      this.Invite.controls['program_id'].updateValueAndValidity()

      this.Invite.controls['program_type_id'].setValidators(Validators.required)
      this.Invite.controls['program_type_id'].updateValueAndValidity()

      this.Invite.controls['program_category'].setValidators(Validators.required)
      this.Invite.controls['program_category'].updateValueAndValidity()
    }
    else{
   
      this.Invite.controls['program_id'].setValidators(Validators.required)
      this.Invite.controls['program_id'].updateValueAndValidity()

      this.Invite.controls['program_type_id'].clearValidators()
      this.Invite.controls['program_type_id'].updateValueAndValidity()

      this.Invite.controls['program_category'].clearValidators()
      this.Invite.controls['program_category'].updateValueAndValidity()
      

   }
    }

  call() {
    this.submitted = true;
    if (!this.Invite.valid || this.file_type === "invalid") {
      this.push.showNotification("Please Fill The Required Fields", "danger");
      this.Invite.markAllAsTouched();
      return;
    }else{
      if(this.program_bulk_import){
        this.data = {
          program_id: this.program_id === undefined?"0": this.program_id,
          buyer_id: this.buyer_id === undefined? "0": this.buyer_id,
          file_name: this.file1,
          acra: this.Invite.value.acra
        };
      }else{
        this.data = {
          program_id: this.program_id === undefined?"0": this.program_id,
          buyer_id: this.buyer_id === undefined? "0": this.buyer_id,
          file_name: this.file1,
          program_type_id: this.Invite.value.program_type_id,
          program_category: this.Invite.value.program_category,
          acra: this.Invite.value.acra

        };
      }
    
          console.log(JSON.stringify(this.data))
          this._auth.bulk_import(this.data).subscribe(
            (res: any) => {
              this.hide = false;
              if (res["failedList"].length > 0) {
                this.push.showNotification(res.message, "info");
                this.failedList = res["failedList"];
              } else {
                this.push.showNotification(res.message, "success");
              }
            },
            err => {
              this.push.showNotification("Something Went Wrong", "danger");
              this._router.navigate(["/admin"]);
            }
          );
    }
   

  }

  modal_popup() {
    this.dataSource = new MatTableDataSource(this.failedList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    $("#myModal").modal("show");
  }

  navigate() {
    this._router.navigate(["sellers/seller-list"]);
  }


 

}
