import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { SharedService } from "app/services/shared.service";
@Component({
  selector: 'app-acra',
  templateUrl: './acra.component.html',
  styleUrls: ['./acra.component.css']
})
export class AcraComponent implements OnInit {
  shareholder: any;
  officerDetails: any;
  register: any;
  ssic: any;
  secondarySSIC: any;
  id: any;
  Isshareholder: boolean;
  constructor(private _auth: AuthService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.sharedMessage2.subscribe((res: any) => {

        this._auth.getkycDetails(res).subscribe((res: any) => {
          console.log(res)
          try {
            try {
              this.shareholder = res['shareholders'].party_kyc_json.entities
              this.Isshareholder =  true
            } catch (error) {
              this.shareholder = null
              this.Isshareholder =  false

            }
            // if (res['shareholders'] != null) {
            //   this.shareholder = res['shareholders'].party_kyc_json.entities
            // } else {
            // }
      
         
      
            if (res['officers'] != null) {
              this.officerDetails = res['officers'].party_kyc_json.entities[0].officerAppointmentDetails
            } else {
              this.officerDetails = null
            }
      
            if (res['address'] != null) {
              this.register = res['address'].party_kyc_json.entities
            } else {
              this.register = null
            }
      
            if (res['ssic'] != null) {
              this.ssic = res['ssic'].party_kyc_json.entities
              if (res['ssic'].party_kyc_json.entities[0].ssic != null) {
                this.secondarySSIC = res['ssic'].party_kyc_json.entities[0].secondarySSIC
              } else {
                this.secondarySSIC = null
              }
            } else {
              this.ssic = null
            }
      
            
          } catch (error) {
            
          }
    
         
    
          // this.worldcheck = JSON.stringify(res["worldcheck"].party_kyc_json, null, "\t")
        })
      
    });
    
  }


}
