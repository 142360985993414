import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';
import { Router } from '@angular/router';
import { empty } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  public chartActivity;

  public canvas1: any;
  public ctx1;
  public chartColor1;
  public chartEmail1;
  public chartHours1;
  public chartActivity1;

  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  gradientStroke: any;


  last_month_prg = [];
  prg_name = [];

  prg_name1 = [];
  l_month: boolean;
  month: boolean;

  Inv = []
  Month_Name = [];
  curr_list = [];
  curr_month = [];
  prg_name_list = [];
  month_list = [];
  data: any;
  month_prg = [];
  Inv_Available = [];
  Inv_Funded = [];
  display: boolean;
  prg_name2 = [];
  dounut2_show: boolean;


  constructor(private _auth: AuthService, private push: PushService, private router: Router, private SpinnerService: NgxSpinnerService) { }

  ngOnInit() {
    this.display = true
    this.SpinnerService.show();
    this._auth.seller_dashboard_data(sessionStorage.getItem('seller_id')).toPromise().then((res: any) => {
      if (res) {
        this.SpinnerService.hide()
        this.data = res
        for (var i = 0; i < this.data.length; i++) {
          for (var j = 0; j < this.data[i].inv_avl_funded_6months.length; j++) {
       
            this.month_list.push(this.data[i].inv_avl_funded_6months[j].month)
            this.Inv_Available.push(this.data[i].inv_avl_funded_6months[j].available)
            this.Inv_Funded.push(this.data[i].inv_avl_funded_6months[j].claimed)
          }

          for (var j = 0; j < this.data[i].funding_by_program_last_month.length; j++) {

            this.prg_name1.push(this.data[i].funding_by_program_last_month[j].program_name)
            this.last_month_prg.push(parseFloat(this.data[i].funding_by_program_last_month[j].claimed))
          }

       
            for (var j = 0; j < this.data[i].funding_by_program_this_month.length; j++) {

              this.prg_name2.push(this.data[i].funding_by_program_this_month[j].program_name)
              this.month_prg.push(parseFloat(this.data[i].funding_by_program_this_month[j].claimed))
            }
          
        
          /**===================================Last Month Donut Code======================================================**/
          
          if(this.data[i].funding_by_program_last_month.length > 0){
            this.dounut2_show = true;
            this.canvas = document.getElementById("chartEmail");
            this.ctx = this.canvas.getContext("2d");
            this.chartEmail = new Chart(this.ctx, {
              type: 'doughnut',
              data: {
                labels: this.prg_name1,
                datasets: [{
                  label: "Emails",
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  backgroundColor: [
                    // '#e3e3e3',
                    '#4acccd',
                    '#fcc468',
                    '#797c7d'
                    // '#ef8157'
                  ],
                  borderWidth: 0,
                  data: this.last_month_prg
                }]
              },
  
              options: {
                aspectRatio: 2.5,
  
                legend: {
                  display: true
                },
  
  
                pieceLabel: {
                  render: 'percentage',
                  fontColor: ['white'],
                  precision: 2
                },
  
                tooltips: {
                  enabled: true
                },
  
                scales: {
                  yAxes: [{
  
                    ticks: {
                      display: false
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: 'rgba(255,255,255,0.05)'
                    }
  
                  }],
  
                  xAxes: [{
                    barPercentage: 1.6,
                    gridLines: {
                      drawBorder: false,
                      color: 'rgba(255,255,255,0.1)',
                      zeroLineColor: "transparent"
                    },
                    ticks: {
                      display: false,
                    }
                  }]
                },
              }
            });
          }else{
            this.dounut2_show = false
          }
          /**===================================Last Month Donut Code End======================================================**/
          /**===================================This Month Donut Code======================================================**/
         
          if(this.data[i].funding_by_program_last_month.length > 0){
            this.display = true
            this.canvas1 = document.getElementById("chartEmail1");
            this.ctx1 = this.canvas.getContext("2d");
  
            this.chartEmail1 = new Chart(this.ctx, {
              type: 'doughnut',
              data: {
                labels: this.prg_name2,
                datasets: [{
                  label: "Emails",
                  pointRadius: 0,
                  pointHoverRadius: 0,
                  backgroundColor: [
                    // '#e3e3e3',
                    '#4acccd',
                    '#fcc468',
                    '#797c7d'
                    // '#ef8157'
                  ],
                  borderWidth: 0,
                  data: this.month_prg
                }]
              },
  
              options: {
                aspectRatio: 2.5,
  
                legend: {
                  display: true
                },
  
  
                pieceLabel: {
                  render: 'percentage',
                  fontColor: ['white'],
                  precision: 2
                },
  
                tooltips: {
                  enabled: true
                },
  
                scales: {
                  yAxes: [{
  
                    ticks: {
                      display: false
                    },
                    gridLines: {
                      drawBorder: false,
                      zeroLineColor: "transparent",
                      color: 'rgba(255,255,255,0.05)'
                    }
  
                  }],
  
                  xAxes: [{
                    barPercentage: 1.6,
                    gridLines: {
                      drawBorder: false,
                      color: 'rgba(255,255,255,0.1)',
                      zeroLineColor: "transparent"
                    },
                    ticks: {
                      display: false,
                    }
                  }]
                },
              }
            });
  
          }else{
            this.display = false
          }
           /**===================================This Month Donut Code End======================================================**/

           /**===================================Bar Graph Code======================================================**/

        this.chartColor = "#FFFFFF";

        this.canvas = document.getElementById("chartActivity");
        this.ctx = this.canvas.getContext("2d");

        this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
        this.gradientStroke.addColorStop(0, '#80b6f4');
        this.gradientStroke.addColorStop(1, this.chartColor);

        this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
        this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
        this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");

        this.chartActivity = new Chart(this.ctx, {
          type: 'bar',
          data: {
            labels: this.month_list,
            datasets: [
              {
                label: "Total Available",
                borderColor: '#fcc468',
                fill: true,
                backgroundColor: '#fcc468',
                hoverBorderColor: '#fcc468',
                pointRadius: 0,
                pointHoverRadius: 0,
                borderWidth: 0,
                data: this.Inv_Available
              },
              {
                label: "Total Claimed",
                borderColor: '#4cbdd7',
                fill: true,
                backgroundColor: '#4cbdd7',
                hoverBorderColor: '#4cbdd7',
                pointRadius: 0,
                pointHoverRadius: 0,
                borderWidth: 0,
                data: this.Inv_Funded
              }
            ],
          },
          options: {
            tooltips: {
              tooltipFillColor: "rgba(0,0,0,0.5)",
              tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              tooltipFontSize: 14,
              tooltipFontStyle: "normal",
              tooltipFontColor: "#fff",
              tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
              tooltipTitleFontSize: 14,
              tooltipTitleFontStyle: "bold",
              tooltipTitleFontColor: "#fff",
              tooltipYPadding: 6,
              tooltipXPadding: 6,
              tooltipCaretSize: 8,
              tooltipCornerRadius: 6,
              tooltipXOffset: 10,
            },


            legend: {

              display: true
            },
            scales: {

              yAxes: [{

                ticks: {
                  fontColor: "#9f9f9f",
                  fontStyle: "bold",
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  padding: 0
                },
                gridLines: {
                  zeroLineColor: "transparent",
                  display: true,
                  drawBorder: false,
                  color: '#9f9f9f',
                }

              }],
              xAxes: [{

                barPercentage: 1,
                gridLines: {
                  zeroLineColor: "white",
                  display: false,

                  drawBorder: false,
                  color: 'transparent',
                },

                ticks: {
                  padding: 0,
                  fontColor: "#9f9f9f",
                  fontStyle: "bold"
                }
              }]
            }
          }
        });
      }
      
    }

      
    });


  }
}
