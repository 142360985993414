import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreeComponent, TreeNode } from '@circlon/angular-tree-component';
import { AuthService } from 'app/services/auth.service';
import { PushService } from 'app/services/push.service';
declare const $: any;


@Component({
  selector: 'app-meta-defination',
  templateUrl: './meta-defination.component.html',
  styleUrls: ['./meta-defination.component.css']
})
export class MetaDefinationComponent implements OnInit {

  @ViewChild(TreeComponent)
  private tree: TreeComponent;
  nodes = [];
  nodes1 = []
  options = {
    isExpandedField: 'expanded',
    allowDrag: true,
    allowDrop: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2,
   
  };
  model: any = {};
  model1: any = {};
  model2: any = {};
  node: any;
  id: any;
  program_categories: any;
  admin: boolean;
  user: boolean;
  category_value: any = "";
  program_type_id:any =""
  program_type_val: any;
  modal_title: any;
  program_category_value: any;
  show_new_temp_block: boolean = false;
  show_existing_temp: boolean = false;
  template_list: any;
  template_name: string = "";
  constructor(private cdRef: ChangeDetectorRef, private _auth: AuthService,private push: PushService, private _router: Router) {
}


  ngOnInit(): void {
    if (this.nodes1.length === 0) {
      
    $("#myModal4").modal("show");
    }
    this.cdRef.detectChanges(); 
    if (typeof this.tree !== 'undefined') {
      this.nodes1 = JSON.parse(localStorage.getItem('data'))
      this.tree.treeModel.expandAll()
      this.tree.treeModel.update();

    }
  }

  redirect_to_dash(){
    // $("#myModal4").modal("hide");
    $('.modal-backdrop').remove();
    this._router.navigate(["/admin"]);
    }

  program_category(event) {
    this.program_category_value = event.target.value
    if (event.target.value === 'admin') {
      
      this.admin = true
    
    } else {
      this.user = true
    }
    this._auth.program_categories().subscribe(async(res: any) => {
      this.program_categories = await res
      
     
    })
  }

  get_program_type() {
    
    this._auth.get_program_types(parseInt(this.category_value)).subscribe(async (res: any) => {
      this.program_type_val = await res[`${this.program_categories.filter(x=>x.id === this.category_value)[0].name.toLowerCase()}`]
      // console.log(`${res})
      
    })
  }

  create_definition() {
    this._auth.get_template_name(this.program_type_id).subscribe((res: any) => {
      if (res.length === 0) {
        this.show_new_temp_block = true
      } else {
        this.template_list = res
        this.show_existing_temp =  true
      }
    })
    $("#myModal4").modal("hide");
  }

  /**
   * 
   * User/Option Based Defination STructure
   */
  get_structure() {
    let value = this.program_category_value === 'admin'? 'program': this.program_category_value === 'user_kyc'?'kyc': this.program_category_value === 'redacted_data'?'redacted':this.program_category_value==='settlement_data'? 'settlement_data':'registration'
    this._auth.get_definition_structure(this.program_type_id, this.template_name, value).subscribe((res: any) => {
      this.show_new_temp_block = true
      this.show_existing_temp = false
      if (value === 'program') {
        if (res=== null || res.definition != null) {
          this.nodes1 = res.definition
          localStorage.setItem('data', JSON.stringify(this.nodes1))
        } else {
          this.cdRef.detectChanges(); 
          if (typeof this.tree !== 'undefined') {
            this.nodes1 = JSON.parse(localStorage.getItem('data'))
            this.tree.treeModel.update();
      
          }
        }
      } else if (value === 'kyc') {
        if (res.kyc_definition != null) {
          this.nodes1 = res.kyc_definition
          localStorage.setItem('data', JSON.stringify(this.nodes1))
          
          
        } else {
         
            let defaultStructure = [{"name":"Company Info","children":[{"id":6000439552132,"name":"Company Name","value":"","key":"company_name","is_editable":false,"user_input":false,"field_type":"INPUT"},{"id":4590647232817,"name":"UEN","value":"","key":"uen","is_editable":false,"user_input":false,"field_type":"INPUT"},{"id":3738618855522,"name":"Contact Email Address","value":"","key":"admin_user_id","is_editable":false,"user_input":false,"field_type":"INPUT"},{"id":534618498219,"name":"Address","value":"","key":"street","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":459632066262,"name":"Zip Code","value":"","key":"zip","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":132214304311,"name":"State/Province","value":"","key":"state","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":6639083687690,"name":"Country","value":"","key":"country_name","is_editable":false,"user_input":true,"field_type":"INPUT"}]},{"name":"Credit Check"},{"name":"Bank Info","children":[{"id":6869262557431,"name":"Bank Name","value":"","key":"bank_name","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":9072181183511,"name":"Bank Branch Address","value":"","key":"bank_branch_address","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":71273409320381,"name":"Bank Branch Code","value":"","key":"bank_branch_code","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":772981385662123,"name":"Bank Account Number","value":"","key":"bank_account_no","is_editable":false,"user_input":true,"field_type":"INPUT"},{"id":8880053410931,"name":"Bank Account Type","value":"","key":"bank_account_type","is_editable":false,"user_input":true,"field_type":"INPUT"}]}]
            this.nodes1 = defaultStructure;
            localStorage.setItem('data', JSON.stringify(this.nodes1))
       
      
          
        }
      } else if (value === 'redacted') {
        if (res.program_redacted != null) {
          this.nodes1 = res.program_redacted 
          localStorage.setItem('data', JSON.stringify(this.nodes1))
          
          
        } else {
          this.cdRef.detectChanges(); 
          if (typeof this.tree !== 'undefined') {
            this.nodes1 = JSON.parse(localStorage.getItem('data'))
            this.tree.treeModel.update();
      
          }
        }
      } else if (value === 'settlement_data'){
        if (res.settlement_document != null) {
        this.nodes1 = res.settlement_document
  
        localStorage.setItem('data', JSON.stringify(this.nodes1))
        
        
      } else {
        this.cdRef.detectChanges(); 
        if (typeof this.tree !== 'undefined') {
          this.nodes1 = JSON.parse(localStorage.getItem('data'))
          this.tree.treeModel.update();
    
        }
      }
      }else {
        if (res.registration_def != null) {
          this.nodes1 = res.registration_def
          localStorage.setItem('data', JSON.stringify(this.nodes1))
          
          
        } else {
          this.cdRef.detectChanges(); 
          if (typeof this.tree !== 'undefined') {
            this.nodes1 = JSON.parse(localStorage.getItem('data'))
            this.tree.treeModel.update();
      
          }
        }
      }
    
      
    })
  }

  node_type() {
    switch (this.model.type) {
      case 'description':
        delete this.model.link
        break;
      case 'link':
        delete this.model.description
        break;
    }
  }


  add_root_node() {
    if (this.model.field_type === 'URL') {
      this.model.url_name = ""
      this.model.append_text = {
        pre_interest: "",
        participcation_approved: "",
        interest_approved:"",
      }
    }
    if (this.program_category_value === 'admin') {
      
      this.model.value = {
        pre_interest: "",
        participcation_approved: "",
        interest_approved:"",
      }
      this.model.append_text = {
        pre_interest: "",
        participcation_approved: "",
        interest_approved:"",
      }
    } else if (this.program_category_value === 'redacted_data') {
      this.model.value = {
        pre_interest: "",
        interest_approved:""

      }
    } else if(this.program_category_value === 'settlement_data' ){
      this.model.expected_date = null;
      this.model.submitted_date= null;
      this.model.value=""
    }else {
      this.model.value=""
      this.model.is_editable=true,
      this.model.user_input= true
    }


    if (this.nodes1[this.id].children != undefined) {
      this.nodes1[this.id].children.push(this.model)

    } else {
      this.nodes1[this.id].children = new Array(this.model)

    }
    this.tree.treeModel.update();
    this.model = {}
    localStorage.setItem('data', JSON.stringify(this.nodes1))
    this.ngOnInit()

  }

  show_root_modal(node: TreeNode,val:string) {
    this.node = node
    this.tree.treeModel.update();
    this.model.name = this.node.data.name
    this.model.field_type =  this.node.data.field_type
    $("#dataModal").modal('show');
  }

  show_modal(node: TreeNode) {
    this.node = node
    this.tree.treeModel.update();
    this.modal_title = this.node.data.name
    $("#dataModal1").modal('show');
  }
  addNode() {
      this.model.value = {
        pre_interest: "",
        participcation_approved: "",
        interest_approved:"",
        append_text:""
      }
      this.model.append_text = {
        pre_interest: "",
        participcation_approved: "",
        interest_approved:"",
      }
    
    if (this.model.field_type === 'URL') {
      this.model.url_name = ""

    }
    if (this.node.data.children) {
      this.node.data.children.push(this.model);
    } else {

      this.node.data.children = [];
      this.node.data.children.push(this.model);
    }
    this.tree.treeModel.update();
    this.model = {}
    localStorage.setItem('data', JSON.stringify(this.nodes1))

    this.ngOnInit()
  }
  
  removeNode(node: TreeNode): void {
    node.parent.data.children.splice(node.parent.data.children.indexOf(node.data), 1)
    // this.tree.treeModel.update();
    localStorage.setItem('data', JSON.stringify(this.nodes1))
    this.ngOnInit();

    // if (node.level === 1) {
    //   this.nodes = this.nodes1.filter((n) => n.id !== nodeToDelete.id);
    // } else {
    //   nodeToDelete.parent.children = nodeToDelete.parent.children.filter((n) => n.id !== nodeToDelete.id);
    // }
    // if (node.parent != null) {
    //    node.parent.data.children.splice(node.parent.data.children.indexOf(node.data), 1);
    //   this.tree.treeModel.update();
    //   this.ngOnInit();
    // }else{
    //   )
    // }
  }
  childrenCount(node: TreeNode): string {
    return node && node.children ? `(${node.children.length})` : '';
  }


  edit_root_node() {
      this.node.data.name = this.model.name
      this.node.data.field_type = this.model.field_type
     
      this.node = ""
      this.model = {}

  }


  onSubmit() {
    // this.model1.commodity_tree =  this.nodes53059017D
    // console.log(this.model1)
   
    let data = {
      template_name: this.template_name,
      program_category: this.category_value,
      program_type_id: parseInt(this.program_type_id),
      user_id: parseInt(sessionStorage.getItem('user_id'))
    }

    let definition = {};
    if (this.program_category_value === 'admin') {
      definition = {definition: this.nodes1, type: 'program'}
    } else if (this.program_category_value === 'user_kyc') {
      definition = {kyc_definition: this.nodes1, type: 'kyc'}
    }else if (this.program_category_value === 'redacted_data') {
      definition = {program_redacted: this.nodes1, type: 'redacted'}
    } else if(this.program_category_value === 'settlement_data'){
      definition = {settlement_document: this.nodes1, type: 'settlement_data'}

    }
    else {
      definition = {registration_def: this.nodes1, type: 'registration'}
    }

    data = { ...data, ...definition }
    // console.log(JSON.stringify(data))
  //   delete this.model1.buyer_id
    this._auth.program_definition(JSON.stringify(data)).subscribe(
      res => {
        this.push.showNotification('Updated', 'success')
        this._router.navigate(["/admin"]);

      }
    );
  }

  create_tabname() {
    this.nodes1.push(this.model2);
    this.model2 ={}
  }

  set_active(event) {
    this.id = event.target.attributes.id.value
    this.tree.treeModel.update();}

}
