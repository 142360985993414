import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";

import { SelectionModel } from "@angular/cdk/collections";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SharedService } from "app/services/shared.service";

export interface PeriodicElement {
  perm_id: string;
  entity_type: string;
  name: string;
  city: string;
  country: string;
  dob: string;
}

declare var $: any;

declare interface User {
  text: "";
  email: "";
  idSource: "";
  invite_buyer: "";
  party_id: "";
}

declare var require: any;

@Component({
  selector: "app-buyer-invite",
  templateUrl: "./buyer-invite.component.html",
  styleUrls: ["./buyer-invite.component.css"]
})
export class BuyerInviteComponent implements OnInit {
  submitted: boolean;
  Select: FormGroup;
  company_info_nav_link: string;
  company_info_tab: string;
  option_nav_link: string;
  option_info_tab: string;
  data_nav_link: string;
  data_info_tab: string;
  btn_disabled: boolean;
  id: any;
  dataSource: any;
  sort: any;
 
  message: string;
  data2 = [];
  data3 = [];
  loading: boolean = true;
  display: boolean;
  program_categories: any;
  user_journey_tab: string;
  data: {};
  email: any;
  name: any;
  acra: any;
  world_check: any;
  perm_id_tab: any;
  bank_info: any;
  documents_tab: any;
  program_type_val: any;
  user_type: string;
  category_value: number;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private push: PushService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private sharedService: SharedService
  ) {
    this.user_type = window.location.href.split('/')[3] === 'buyers' ? 'buyer' : null;

  }

  public typeValidation: User;

  public login: User;

  inputvalue = "";

  display_name: string;
  admin_user_id: string;
  Invite_Buyer: false;

  files = "";
  selectedFiles: any;
  Invite: any;
  passbook_value = true;
  audited_financial: boolean;
  ngOnInit() {
    this.sharedService.clear();
    this.company_info_nav_link = "nav-link active";
    this.company_info_tab = "tab-pane active";
    // this.option_nav_link = "nav-link";
    // this.option_info_tab = "tab-pane";
    // this.data_nav_link = "nav-link";
    // this.data_info_tab = "tab-pane";
    this.user_journey_tab = "tab-pane";
    this._auth.program_categories().subscribe(async(res: any) => {
      this.display = true;
      this.program_categories = await res
      this.Invite = this.fb.group({
        display_name: ["", Validators.required],
        admin_user_id: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$"
            )
          ]
        ],
        company_registration_no: ["", Validators.required],
        credit_limit: [0, [Validators.required, Validators.min(1)]],
     
        program_type_id: ["", Validators.required],
          category_id: ["", Validators.required],
          acra:false,
          country_name:["",Validators.required]
      });
    })
 

    this.loading = false;
  }

  handleFileInput(ob) {
    this.files = ob;
    // console.log(this.files);
  }

  openFile() {
    // console.log('select')
    document.querySelector("input").click();
  }

  handle(e) {
    // console.log('Change input file')
  }

  call() {
    this.submitted = true;
    if (!this.Invite.valid) {
      this.Invite.markAllAsTouched();
      this.push.showNotification("Please Fill The Required Fields", "danger");
      return;
    }
    this.Invite.value.display_name = this.titleCase(this.Invite.value.display_name).trim();

    this.Invite.value.admin_user_id = this.Invite.value.admin_user_id.toLowerCase().trim();
    this.data = {
      display_name: this.Invite.value.display_name.trim(),
      admin_user_id: this.Invite.value.admin_user_id,
      limit: this.Invite.value.credit_limit,
      company_registration_no: this.Invite.value.company_registration_no.trim(),
      acra: this.Invite.value.acra,
      user_type: this.user_type,
      program_type_id: this.Invite.value.program_type_id,
      program_category: this.Invite.value.category_id,
      country_name: this.Invite.value.country_name
    };

    console.log(this.data)  

    this._auth.invite_user(JSON.stringify(this.data)).subscribe((res: any) => {
      console.log(res)
      this.sharedService.nextMessage1(res);
      this.company_info_tab = "tab-pane";
      // this.option_nav_link = "nav-link";
      // this.option_info_tab = "tab-pane";
      // this.data_nav_link = "nav-link";
      // this.data_info_tab = "tab-pane";
    
      this.user_journey_tab = "tab-pane active";
      this.btn_disabled = false;
      this.push.showNotification(res.msg,'success')
    },(err)=>{
      this.push.showNotification("KYC Defination Must Be Defined", "danger")
    })

  }

  get_program_type() {
    this.category_value = parseInt(this.Invite.value.category_id)
    this._auth.get_program_types(parseInt(this.Invite.value.category_id)).subscribe(async (res: any) => {
      this.program_type_val = await res[`${this.program_categories.filter(x=>x.id === this.category_value)[0].name.toLowerCase()}`]    },(err)=>{
      this.push.showNotification("Something Went Wrong", "danger")
    })
  }

  showData() {
    console.log(this.Invite.value.acra)
  }


  // option() {
  //   var data = {};
  //   var data1 = {};
  //   for (var i in this.data2) {
  //     for (let j in this.data2[i]) {
  //       data[j] = this.data2[i][j];
  //     }
  //   }

  //   for (var i in this.data3) {
  //     for (let j in this.data3[i]) {
  //       data1[j] = this.data3[i][j];
  //     }
  //   }
  //   this.Invite.value.display_name = this.titleCase(this.Invite.value.display_name).trim();

  //   this.Invite.value.admin_user_id = this.Invite.value.admin_user_id.toLowerCase().trim();
  //   this.data = {
  //     display_name: this.Invite.value.display_name.trim(),
  //     admin_user_id: this.Invite.value.admin_user_id,
  //     limit: this.Invite.value.credit_limit,
  //     // ref: this.Invite.value.buyer_sb_ref,
  //     company_registration_no: this.Invite.value.company_registration_no.trim(),
      
  //   };
  //   this.company_info_nav_link = "nav-link";
  //   this.company_info_tab = "tab-pane";
  //   this.option_nav_link = "nav-link";
  //   this.option_info_tab = "tab-pane";
  //   this.data_nav_link = "nav-link";
  //   this.data_info_tab = "tab-pane";
  //   this.user_journey_tab = "tab-pane active";
  //   this.btn_disabled = false;
  //   this.id = this._route.snapshot.paramMap.get("id");
  //   // this.dataSource.sort = this.sort;

  //   (this.email = this.Invite.value.admin_user_id), (this.name = this.Invite.value.display_name);
  //   this.acra = this.data.acra;
  //   this.world_check = this.data.world_check;
  //   this.perm_id_tab = this.data.perm_id;
  //   this.bank_info = this.data.bank_verification;
  //   this.documents_tab = this.data.document_verification;

  //   this.sharedService.nextMessage1(this.data);
  // }
  titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)(\w)/g, function(x) {
      return x.toUpperCase();
    });
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
  onSubmit(value: any): void {
    // console.log(value);
  }

  check_isEmpty(event) {
    if (this.data2.length >= 0) {
      var name = event.target.name;

      var data = new Object();
      if (event.target.checked === true) {
        data[name] = true;
        this.data2.push(data);
      } else {
        for (var i of this.data2) {
          for (var j in i) {
            if (j === name) {
              delete i[j];
            }
          }
        }
      }

      this.data2 = this.data2.filter(value => Object.keys(value).length !== 0);
      if (this.data2.length === 0) {
        this.Invite.controls.document_verification.setValue(false);
      }
    }
  }

  check_isEmpty2(event) {
    if (this.data3.length >= 0) {
      var name = event.target.name;
      var data1 = new Object();
      if (event.target.checked === true) {
        data1[name] = true;
        this.data3.push(data1);
      } else {
        for (var i of this.data3) {
          for (var j in i) {
            if (j === name) {
              delete i[j];
            }
          }
        }
      }
      this.data3 = this.data3.filter(value => Object.keys(value).length !== 0);

      console.log(this.data3);

      if (this.data3.length === 0) {
        this.Invite.controls.bank_verification.setValue(false);
      }
    } else {
      this.Invite.controls.bank_verification.setValue(false);
    }
  }
  create_doc_data(event) {
    if (event.target.checked === true) {
      this.audited_financial = true;
      this.data2.push({
        audited_financial_statments: this.audited_financial
      });
    } else {
      this.audited_financial = false;
    }
  }

  create_doc_data2(event) {
    if (event.target.checked) {
      this.passbook_value = true;
      this.data3.push({
        passbook: this.passbook_value
      });
    } else {
      this.passbook_value = false;
    }
  }

  onSelect() {
    if (this.Invite.value.document_verification === true) {
      return true;
    } else {
      return false;
    }
  }
  onSelect2() {
    if (this.Invite.value.bank_verification === true) {
      return true;
    } else {
      return false;
    }
  }

  check_email() {
    if (this.Invite.value.admin_user_id != "") {
      this._auth.check_email(this.Invite.value.admin_user_id.toLowerCase().trim()).subscribe((res: any) => {
        if (res["active"] === true) {
          this.push.showNotification("Email ID Already Exist", "danger");
          this.Invite.get("admin_user_id").reset();
        } else {
        }
      });
    }
  }

  check_uen() {
    if (this.Invite.value.company_registration_no != "") {
      this._auth.check_uen(this.Invite.value.company_registration_no.trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");

          this.Invite.get("company_registration_no").reset();
        }
      );
    }
  }

  check_username() {
    if (this.Invite.value.display_name != "") {
      this._auth.check_username(this.titleCase(this.Invite.value.display_name).trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.Invite.get("display_name").reset();
        }
      );
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
}
