import { Injectable } from "@angular/core";

export enum Environment {
  Demo = "demo",
  Staging = "staging",
  Test = "test",
  Dev = "dev",
  Local = "local",
  Build = "local"
}

@Injectable({ providedIn: "root" })
export class EnvService {
  private _env: Environment;
  private _apiUrl: string;
  private _file_upload_buyer: string;
  private _file_upload_seller: string;
  private _file_upload_funder: string;
  private page_name: string;
  display: boolean;
  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get File_upload_buyer(): string {
    return this._file_upload_buyer;
  }

  get File_upload_seller(): string {
    return this._file_upload_seller;
  }

  get File_upload_funder(): string {
    return this._file_upload_funder;
  }

  get Page_Name(): string {
    return this.page_name;
  }

  constructor() { }

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;

    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local;
      this.page_name = "Refinitiv"
      // this.page_name = "Dev"
      this._apiUrl = "https://devapi.seabridgetfx.com";
      this._file_upload_buyer = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://devapi.seabridgetfx.com/download/eikon";

    } else if (/^127.0.0.1/.test(hostname)) {
      this._env = Environment.Build;
      this._apiUrl = "https://staging-api.seabridgetfx.com";
      this._file_upload_buyer = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://staging-api.seabridgetfx.com/download/eikon";

    } else if (/^eikonsp-dev.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Dev;
      this.page_name = "Dev"
      this._apiUrl = "https://devapi.seabridgetfx.com";
      this._file_upload_buyer = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://devapi.seabridgetfx.com/download/eikon";

    } else if (/^app.demo.110techost.com/.test(hostname)) {
      this._env = Environment.Dev;
      this.page_name = "Dev"
      this._apiUrl = "https://devapi.seabridgetfx.com";
      this._file_upload_buyer = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://devapi.seabridgetfx.com/download/eikon";

    } else if (/^dev.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Dev;
      this.page_name = "Dev"
      this._apiUrl = "https://devapi.seabridgetfx.com";
      this._file_upload_buyer = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://devapi.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://devapi.seabridgetfx.com/download/eikon";

    } else if (/^staging.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Staging;
      this.page_name = "Staging"
      this._apiUrl = "https://staging-api.seabridgetfx.com";
      this._file_upload_buyer = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://staging-api.seabridgetfx.com/download/eikon";

    } else if (/^refinitiv.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Staging;
      this.page_name = "Refinitiv"
      this._apiUrl = "https://staging-api.seabridgetfx.com";
      this._file_upload_buyer = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://staging-api.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://staging-api.seabridgetfx.com/download/eikon";

    }
    else if (/^refinitiv-demo.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Demo;
      this.page_name = "Refinitiv";
      this._apiUrl = "https://refinitiv-demo-api.seabridgetfx.com";
      this._file_upload_buyer = "https://refinitiv-demo-api.seabridgetfx.com/download/buyer-docs";
      this._file_upload_seller = "https://refinitiv-demo-api.seabridgetfx.com/download/seller-docs";
      this._file_upload_funder = "https://refinitiv-demo-api.seabridgetfx.com/download/funder-docs";
    }

    else if (/^demo.seabridgetfx.com/.test(hostname)) {
      this._env = Environment.Demo;
      this._apiUrl = "https://api.seabridgetfx.com";
      this._file_upload_buyer = "https://api.seabridgetfx.com/download/eikon";
      this._file_upload_seller = "https://api.seabridgetfx.com/download/eikon";
      this._file_upload_funder = "https://api.seabridgetfx.com/download/eikon";

    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
