import { Component } from '@angular/core';
import { EnvService } from 'app/services/env.service';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})


export class FooterComponent{
    test : Date = new Date();
    display: boolean;
    constructor(private env: EnvService){
        this.display = this.env.Page_Name === 'Refinitiv'? true: false
    }
}
