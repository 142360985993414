import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { AuthService } from "app/services/auth.service";
import { Router } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FileValidator } from "app/shared/filevalidator.directive";
import { SharedService } from "app/services/shared.service";
import { EnvService } from "app/services/env.service";
import { DataService } from "app/services/data.service";
// import * as $ from 'jquery';

declare var swal: any;
declare var $: any;

@Component({
  selector: "app-kyc-journey",
  templateUrl: "./kyc-journey.component.html",
  styleUrls: ["./kyc-journey.component.css"]
})
export class KycJourneyComponent implements OnInit {
  kyc_data: any[];
  upload_url: string;
  selected:number = 0;
  agree: boolean=false;
  terms_btn: string=  '';
  id: any;
  show: boolean=true;
  status_name: any;
  file: any;
  financialsForm: any;
  totalTab: number;
  acra: boolean;
  staticOne: number;
  constructor(
    private _auth: AuthService,
    private router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private envService: EnvService,
    private dataService: DataService
  ) {
  }
  ngOnInit() {
  
this.getFinancialData();
  let names_list = []
  this._auth
    .getPermId(sessionStorage.getItem("seller_id"))
    .toPromise()
    .then(res => {
      if (res) {
        this.kyc_data =  res['kyc_data_points']
        this.acra = res["acra"]
        this.sharedService.nextMessage2(sessionStorage.getItem("seller_id"));
        //FIXME
        console.log("res['kyc_data_points']", res['kyc_data_points']);
        
        if(this.acra){
          this.staticOne = 3;
        }else{
          this.staticOne = 2;
        }
        
        
        for(var i=0 ; i<=this.kyc_data.length;i++){
          
          if(i === this.kyc_data.length){
            names_list.push('Terms')
            break;
          }else{
            names_list.push(this.kyc_data[i].name)
          }
        }

        this.kyc_data.forEach(element => {
          
          if(element.name === 'Financials'){
            this.financialsForm = element.children;
          }
        });
          this.sharedService.nextMessage2(sessionStorage.getItem("seller_id"));
          for(let i=0;i<names_list.length;i++){
            if(names_list[i] === this.toTitleCase(sessionStorage.getItem('status'))){
              this.selected = i+1
            }else{
              this.selected=0
            }
            
          }
      }

    })
    this.id = setInterval(() => {
      this.status();
    }, 10000);
}

getFinancialData(){
  this.financialsForm = this.dataService.get_financials(1);

}

file_upload(event,node, n) { 

  if (event.target.files.length > 0) {
    this.file = event.target.files[0];

    this._auth
      .file_upload('12234',this.file )
      .toPromise()
      .then(
        (res: any) => {
          n.value = res.filename
          console.log(node)
          event.target.value = "";
          this.push.showNotification("Upload Success", "success");
        },
        error => {
          this.push.showNotification("Upload Failed", "danger");
        }
      );
  }
}
toTitleCase=(str)=> {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}


next(){
  if(this.selected < this.staticOne + 3){
    this._auth.invite_user_patch(JSON.stringify({kyc_data_points:this.kyc_data, party_id: sessionStorage.getItem('seller_id')})).subscribe(async(res: any)=>{
      this._auth.updateStatus(sessionStorage.getItem("seller_id"), document.getElementsByClassName("active")[0].textContent.toUpperCase()).subscribe((res: any) => {
        this.selected += 1;
      })
      this.push.showNotification('Success','success')
    }, (err) => {
      this.push.showNotification('Something Went Wrong','success')
    })
   
   
  }
}


back=()=>{
  this.agree = false
  if(this.selected > -1){
    this.selected -= 1;
  }
}


ngDoCheck() {
  if(this.selected === this.staticOne + 2){
    this.terms_btn = ''
    if (this.agree === true) {
      this.terms_btn = 'show'
    } else {
      this.terms_btn = ''
    }
  }else if(this.selected === this.staticOne + 3){
    this.terms_btn = '' 
  }else{
    this.terms_btn = 'show'
  }
}

status=()=>{     
  this._auth.getStatus(sessionStorage.getItem("seller_id")).toPromise().then(
    res => {         
      if (res["user_status"] === "APPROVED") {   
        this.show = false
        this.status_name = res['user_status']
        this.push.showNotification("Your KYC Has Been Approved", "success");          
         clearInterval(this.id);         
        }       
      },
    err=>{
      this.push.showNotification("Something Went Wrong", "success");
    });   
  }

  
  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }

    sessionStorage.clear();
  }

  redirect() {
    this.router.navigate(["/logout"]);
  }

  onDataChanged(event: any[]): void {
    this.financialsForm = event;
    

    let obj = this.kyc_data.find(o => o.name === 'Financials');


    if(!obj) { 
      this.kyc_data.push({name: 'Financials', children: this.financialsForm})
    }
    else{
      this.kyc_data.forEach(element => {
        if(element.name === 'Financials'){
          element.children = this.financialsForm;
        }
      });
      
    }
  }

  getTabCalc=()=>{
    return ((this.selected/(this.staticOne + 3))*100).toString()
  }
}
