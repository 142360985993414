import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PushService } from "app/services/push.service";
import { FormBuilder, Validators } from "@angular/forms";
import { SharedService } from "app/services/shared.service";


declare var $: any;

declare interface User {
  text: "";
  email: "";
  idSource: "";
  invite_seller: "";
  party_id: "";
}

declare var require: any;
@Component({
  selector: "app-seller-invite",
  templateUrl: "./seller-invite.component.html",
  styleUrls: ["./seller-invite.component.css"]
})
export class SellerInviteComponent implements OnInit {
  data: any;
  user_type: any;
  program_type_val: any;
  program_categories: any;
  display: boolean;
  category_value: any;
  
  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private push: PushService,
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {
    this.user_type = window.location.href.split('/')[3] === 'sellers' ? 'seller' : null;

  }

  public typeValidation: User;

  public login: User;

  inputvalue = "";

  Invite: any;

  ngOnInit() {
    
    this._auth.program_categories().subscribe(async(res: any) => {
      this.display = true;
      this.program_categories = await res

      this.Invite = this.fb.group({
        display_name: ["", Validators.required],
        admin_user_id: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern(
              "^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$"
            )
          ]
        ],
        company_registration_no: ["", Validators.required],
        credit_limit: [0, [Validators.required, Validators.min(1)]],
          
        program_type_id: ["", Validators.required],
          category_id: ["", Validators.required],
          acra:false,
          country_name:["",Validators.required]
      });
    },(err)=>{
      this.push.showNotification('Error','danger')
    });


  }
  get_program_type() {
    this.category_value = parseInt(this.Invite.value.category_id)
    this._auth.get_program_types(parseInt(this.Invite.value.category_id)).subscribe(async (res: any) => {
      this.program_type_val = await res[`${this.program_categories.filter(x=>x.id === this.category_value)[0].name.toLowerCase()}`]    },(err)=>{
      this.push.showNotification("Something Went Wrong", "danger")
    })
  }

  handleFileInput(ob) {
    // this.files = ob;
    // console.log(this.files);
  }



  handle(e) {
    // console.log('Change input file')
  }

  call() {
    if (!this.Invite.valid) {
      this.push.showNotification("Please Fill The Required Fields", "danger");
      this.Invite.markAllAsTouched();
      return;
    }
    
    this.Invite.value.display_name = this.titleCase(this.Invite.value.display_name).trim();

    this.Invite.value.admin_user_id = this.Invite.value.admin_user_id.toLowerCase().trim();

    this.data = {
      display_name: this.Invite.value.display_name,
      admin_user_id: this.Invite.value.admin_user_id,
      company_registration_no: this.Invite.value.company_registration_no.trim(),
      limit: this.Invite.value.credit_limit,
      user_type: this.user_type,
      acra: this.Invite.value.acra,
      program_type_id: this.Invite.value.program_type_id,
      program_category: this.Invite.value.category_id,
      country_name: this.Invite.value.country_name
    };

    this._auth.invite_user(JSON.stringify(this.data)).subscribe(
      (res: any) => {
        this.push.showNotification("Invited Successfully", "success");

        this._router.navigate(["/sellers/seller-list"]);
      },
      err => {
        this.push.showNotification("Something Went Wrong", "danger");
        this._router.navigate(["/admin"]);
      }
    );
  }

  titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)(\w)/g, function(x) {
      return x.toUpperCase();
    });
  }

  onSubmit(value: any): void {
    // console.log(value);
  }


  check_email() {
    if (this.Invite.value.admin_user_id != "") {
      this._auth.check_email(this.Invite.value.admin_user_id.trim()).subscribe((res: any) => {
        if (res["active"] === true) {
          this.push.showNotification("Email ID Already Exist", "danger");
          this.Invite.get("admin_user_id").reset();
        } else {
        }
      });
    }
  }

  check_uen() {
    if (this.Invite.value.company_registration_no != "") {
      this._auth.check_uen(this.Invite.value.company_registration_no.trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.Invite.get("company_registration_no").reset();
        }
      );
    }
  }

  check_username() {
    if (this.Invite.value.display_name != "") {
      this._auth.check_username(this.titleCase(this.Invite.value.display_name).trim()).subscribe(
        (res: any) => {},
        err => {
          this.push.showNotification(err.error.errMsg, "danger");
          this.Invite.get("display_name").reset();
        }
      );
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }
}
