import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
environment
@Component({
  selector: 'app-transpose',
  templateUrl: './transpose.component.html',
  styleUrls: ['./transpose.component.css']
})
export class TransposeComponent implements OnInit {
  @Input() tableData: any[];
  @Input() editable: Boolean;
  @Output() dataChanged = new EventEmitter<any[]>();
  tracker = i => i;

  @ViewChild("queryTable") queryTable: ElementRef;
  @ViewChild("queryTableBody") queryTableBody: ElementRef;

  @ViewChild("queryTableHead") queryTableHead: ElementRef;

  tableSource = [
    {
      "column": "year",
      "name": "Year",
      "order": "0",
      "display": false,
      "abbreviation": "Financial Year",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "revenue",
      "name": "Revenue",
      "order": "1",
      "display": "true",
      "abbreviation": "Revenue",
      "alignment": "right", "highlighter": 5
    },
    {
      "column": "EBITDA",
      "name": "EBITDA",
      "order": "2",
      "display": "true",
      "abbreviation": "Earnings Before Interest, Taxes, Depreciation, and Amortization",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "EBIT",
      "name": "EBIT",
      "order": "3",
      "display": "true",
      "abbreviation": "Earnings before interest and taxes",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "interest-expense",
      "name": "Interest Expense",
      "order": "4",
      "display": "true",
      "abbreviation": "Interest Expense",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "net-income",
      "name": "Net Income",
      "order": "5",
      "display": "true",
      "abbreviation": "Net Income",
      "alignment": "right", "highlighter": 5
    },
    {
      "column": "diluted-net-income-incl-extra-items",
      "name": "Diluted Net Income + Ext Items",
      "order": "6",
      "display": "true",
      "abbreviation": "Diluted net income incl extra items",
      "alignment": "right", "highlighter": 5
    },
    {
      "column": "cash",
      "name": "Cash",
      "order": "7",
      "display": "true",
      "abbreviation": "Cash",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "short-term-investments",
      "name": "Short Term Investments",
      "order": "8",
      "display": "true",
      "abbreviation": "Short Term Investments",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "accounts-receivable",
      "name": "Account Receivable",
      "order": "9",
      "display": "true",
      "abbreviation": "Account Receivable",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "total-inventory",
      "name": "Total Inventory",
      "order": "10",
      "display": "true",
      "abbreviation": "Total Inventory",
      "alignment": "right", "highlighter": 2
    },
    {
      "column": "total-current-assets",
      "name": "Total Current Assets",
      "order": "11",
      "display": "true",
      "abbreviation": "Total Current Assets",
      "alignment": "right", "highlighter": 2
    },
    {
      "column": "goodwill-and-intangibles",
      "name": "Goodwill and Intangibles",
      "order": "12",
      "display": "true",
      "abbreviation": "Goodwill and Intangibles",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "total-assets",
      "name": "Total Assects",
      "order": "13",
      "display": "true",
      "abbreviation": "Total Assects",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "current-liabilities",
      "name": "Current Liabilities",
      "order": "14",
      "display": "true",
      "abbreviation": "Current Liabilities",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "short-term-debt",
      "name": "Short Term Debt",
      "order": "15",
      "display": "true",
      "abbreviation": "Short Term Debt",
      "alignment": "right", "highlighter": 1
    },
    {
      "column": "long-term-debt",
      "name": "Long Term Debt",
      "order": "16",
      "display": "true",
      "abbreviation": "Long Term Debt",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "total-debt",
      "name": "Total Debt",
      "order": "16",
      "display": "true",
      "abbreviation": "Total Debt",
      "alignment": "right", "highlighter": 5
    },
    {
      "column": "total-equity",
      "name": "Total Equity",
      "order": "17",
      "display": "true",
      "abbreviation": "Total Equity",
      "alignment": "right", "highlighter": 6
    },
    {
      "column": "CF-from-ops",
      "name": "CF From Ops",
      "order": "18",
      "display": "true",
      "abbreviation": "Operating cash flow",
      "alignment": "right", "highlighter": 0
    },
    {
      "column": "capEx",
      "name": "CapEx",
      "order": "19",
      "display": "true",
      "abbreviation": "Capital expenditures",
      "alignment": "right", "highlighter": 0
    }
  ];
  showCopiedData: boolean = false;
  highlightColor: any;
  highlightColorSecondary: string;
  bgCode: string;
  colorCode: string;
  constructor(private renderer: Renderer2) { }

  get rowKeys(): string[] {
    if (!this.tableData || !this.tableData.length) {
      return [];
    }

    console.log(this.tableData);
    return Object.keys(this.tableData[0]);
  }
  ngOnInit() {
    this.highlightColor = environment.template === 'dark' ? '#3c3c42' : '#EEEEEE';
    this.highlightColorSecondary = environment.template === 'dark' ? '#FFF' : '#000';
    this.colorCode = environment.template === 'dark' ? '#FFF' : '#000';
    this.bgCode = environment.template === 'dark' ? '#1a1a1c' : '#fff';
  }


  onInputChanged(value: string, rowIndex: number, propertyKey: string): void {
    const newValue = this.tableData.map((row, index) => {
      return index !== rowIndex ? row : { ...row, [propertyKey]: value };
    });
    this.dataChanged.emit(newValue);
  }

  selectElementContents(el) {
    var body = document.body, range, sel;
  }

  selectNode(node) {
    let range = document.createRange();
    range.selectNodeContents(node);
    let select = window.getSelection();
    select.removeAllRanges();
    select.addRange(range);
  }

  copy() {
    this.selectNode(this.queryTable.nativeElement);
    document.execCommand("copy");
  }

  demoOnlyfunction(){
    this.getRandomNumber();
  }


  getRandomNumber() {



    this.tableData.forEach(element => {

      this.tableSource.forEach(el => {
        const random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        el.column != 'year' ? element[el.column] = random : null;
      });
    });

}
}



