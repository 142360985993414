import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { PushService } from 'app/services/push.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { FormBuilder } from '@angular/forms';

declare const $: any;

declare interface DataTable {
  headerRow: string[];
  footerRow: string[];
  dataRows: string[][];
}

@Component({
  selector: 'app-view-as-seller',
  templateUrl: './view-as-seller.component.html',
  styleUrls: ['./view-as-seller.component.css']
})
export class ViewAsSellerComponent implements OnInit {

  public dataTable: DataTable;
  public gradientStroke;
  public chartColor;
  public canvas: any;
  public ctx;
  public gradientFill;
  public gradientChartOptionsConfiguration: any;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;

  public activeUsersChartType;
  public activeUsersChartData: Array<any>;
  public activeUsersChartOptions: any;
  public activeUsersChartLabels: Array<any>;
  public activeUsersChartColors: Array<any>
  claimed: any;
  isDisabled: boolean;
  value = "";
  checkForm: any;
  uniq_prgs = [];
  inv_list = [];
  list = [];
  total_invoice: any;
  total_amount_inv: number;
  data: any;
  max_amount: any;
  load: boolean;
  password: string;
  curr: any;
  tot_fund_to_date: any;
  pr_name: any;
  id = [];
  list1 = {};
  inv_len: any;
  inv_count: any;
  check: boolean[] = [];
  total: number;
  invoices = [];
  funding_amt: number;
  transaction_fee: any;
  invoice_list: any;
  credit_limit: number;
  overall_claimed: number;
  credit1: number;
  calculations: any;
  total_financing_amt: any;
  amt = [];
  total_amt: number;
  program_fund_amt: any;
  id1_arr = [];
  id1 = [];
  outstanding = [];
  fund_claimed: number;
  btn_disabled: boolean;
  d: Date;
  max1: any;
  max2: any;
  max3: any;
  x: any;
  deployed_amounts: any;
  invoice: boolean;
  index_pos: any;
  funding_to = [];
  data4: any;
  display: string;
  inv_submitted = [];
  inv_processed = [];
  count = 0;
  mySubscription: any;
  total_amt_inv = []
  submit = [];
  inv_process_count: number;
  total_claimed_today: any;
  seller_id: string;



  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
  public hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  constructor(
    private _auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private push: PushService,
    private _route: ActivatedRoute,
  ) {
  }
  public ngOnInit() {
    this.seller_id = this._route.snapshot.paramMap.get("id");
    console.log(this.id)

    this.plist()
    this._auth.getRefresh().subscribe(() => {

      this.plist();
    });

    this._auth.todays_claimed(this.seller_id).toPromise().then((res: any) => {
      if (res) {

        this.total_claimed_today = res["total_claimed_today"]
      }
    })

    this.chartColor = "#FFFFFF";

    var cardStatsMiniLineColor = "#fff",
      cardStatsMiniDotColor = "#fff";

  }

  plist() {
    this._auth
      .unique_prgs(this.seller_id)
      .toPromise()
      .then(async (res: any) => {
        if (res) {
          console.log(res)
          this.credit_limit = parseInt(sessionStorage.getItem("credit_limit"));
          this.calculations = res["calculations"];
          var flags = {};
          this.uniq_prgs = res["programs"].filter((x) => {
            if (flags[x.program_id]) {
              return false;
            }
            flags[x.program_id] = true;
            return true;
          });

          console.log(this.uniq_prgs);
          this.total_financing_amt = 0;
          this.credit1 = 0;
          this.fund_claimed = 0;
          this.total = 0;

          this.btn_disabled = true;

          this.inv_count = 0;
          var res;
          var res1;
          res = await this._auth.overall_claimed_amt(this.seller_id).toPromise();

          if (res) {
            if (res["program_overall_claimed_amount_for_seller"] == null) {
              this.overall_claimed = 0;
            } else {
              this.overall_claimed = parseInt(
                res["program_overall_claimed_amount_for_seller"]
              );
            }
            this.credit1 = this.credit_limit - this.overall_claimed;
          }
          for (var i = 0; i < this.uniq_prgs.length; i++) {



            this.uniq_prgs[i].total_inv = this.uniq_prgs[i].invoices.length;
            this.uniq_prgs[i].claimed = 0;
            this.uniq_prgs[i].submit = true
            this.uniq_prgs[i].display = true
            this.uniq_prgs[i].display_process = true

            for (var j = 0; j < this.uniq_prgs[i].invoices.length; j++) {
              this.id.push(this.uniq_prgs[i].invoices[j].invoiceId.invoice_id + "-D");

            }

            var Total = 0;



            for (var j = 0; j < this.uniq_prgs[i].total_inv; j++) {
              for (var k in this.calculations) {

                if (
                  this.uniq_prgs[i].invoices[j].invoiceId.invoice_id + "-B" ===
                  k && this.uniq_prgs[i].invoices[j].invoiceId.inv_status == 'IMPORTED'
                ) {
                  if (this.uniq_prgs[i].program_currency === "USD") {
                    Total += parseInt(this.calculations[k].out_amt) * 1.44;
                  } else {
                    Total += parseInt(this.calculations[k].out_amt);
                  }
                }
              }
            }
            this.uniq_prgs[i].inv_total_financing_amt = Total;
          }
          //  console.log(this.inv_list)
          /*=====================================================*/

          /*=====================================================*/

          for (var i = 0; i < this.uniq_prgs.length; i++) {
            this._auth.seller_claimed(this.uniq_prgs[i].program_id, this.seller_id)
              .toPromise()
              .then((res: any) => {
                if (res) {

                  for (var i = 0; i < this.uniq_prgs.length; i++) {
                    for (var j = 0; j < res.length; j++) {

                      if (res[j]["program_id"] == this.uniq_prgs[i].program_id) {
                        this.outstanding.push(parseInt(res[j]["program_deployed_amount_for_seller"]));
                      } else {
                        this.outstanding.push(0)
                      }
                      var flags = {};
                      this.outstanding = this.outstanding.filter((x) => {
                        if (flags[x]) {
                          return false;
                        }
                        flags[x] = true;
                        return true;
                      });
                      this.uniq_prgs[i].program_outstanding = this.outstanding[i]
                    }

                  }


                  for (var i = 0; i < this.uniq_prgs.length; i++) {
                    // console.log(this.uniq_prgs[i].seller_max_limit - parseInt(this.outstanding[i]),this.uniq_prgs[i].seller_max_limit , this.outstanding[i])
                    if (this.credit1 > (this.uniq_prgs[i].seller_max_limit - this.outstanding[i])) {
                      this.uniq_prgs[i].claimed_max_limit = this.uniq_prgs[i].seller_max_limit - this.outstanding[i];
                    } else {
                      this.uniq_prgs[i].claimed_max_limit = this.credit1
                    }
                  }
                }
              });
          }
          var count = 0
          for (var i = 0; i < this.uniq_prgs.length; i++) {

            this.inv_submitted = []
            this.inv_processed = []

            for (var j = 0; j < this.uniq_prgs[i].invoices.length; j++) {

              if (this.uniq_prgs[i].invoices[j].invoiceId.inv_status == "PROCESSED") {
                this.inv_process_count = ++count
                this.inv_submitted.push(this.uniq_prgs[i].invoices[j]);
                var flags = {};
                this.inv_submitted = this.inv_submitted.filter((x) => {
                  if (flags[x.invoiceId.invoice_id]) {
                    return false;
                  }
                  flags[x.invoiceId.invoice_id] = true;
                  return true;
                });
                // console.log(this.inv_submitted)
                this.uniq_prgs[i].inv_process = this.inv_submitted;
                if (this.uniq_prgs[i].inv_process.length <= 0) {
                  this.uniq_prgs[i].display_process = true
                } else {
                  this.uniq_prgs[i].display_process = false
                }
              }
            }
            for (var j = 0; j < this.uniq_prgs[i].invoices.length; j++) {
              if (this.uniq_prgs[i].invoices[j].invoiceId.inv_status == "IMPORTED") {
                this.inv_count = ++this.count;
                this.inv_processed.push(this.uniq_prgs[i].invoices[j]);
                var flags = {};
                this.inv_processed = this.inv_processed.filter((x) => {
                  if (flags[x.invoiceId.id]) {
                    return false;
                  }
                  flags[x.invoiceId.id] = true;
                  return true;
                });
                this.uniq_prgs[i].inv_import = this.inv_processed;
                if (this.uniq_prgs[i].inv_import.length <= 0) {
                  this.uniq_prgs[i].display = true
                } else {
                  this.uniq_prgs[i].display = false
                }
                // this.uniq_prgs[0].aria_expand = true
                // this.uniq_prgs[0].aria_class = 'collapse show'
                // this.uniq_prgs[1].aria_class = 'collapse'
                // this.uniq_prgs[1].aria_expand = false
              }
            }

          }

          for (var i = 0; i < this.uniq_prgs.length; i++) {

            if (this.uniq_prgs[i].program_currency === "USD") {
              this.total_financing_amt +=
                parseInt(this.uniq_prgs[i].inv_total_financing_amt) * 1.44;
            } else {
              this.total_financing_amt += parseInt(
                this.uniq_prgs[i].inv_total_financing_amt
              );
            }
          }
        }
      });

    this._auth.funds_to_date_data(this.seller_id).toPromise().then((res: any) => {
      if (res) {
        this.data4 = res.d1

        for (var i = 0; i < res.d2.length; i++) {
          if (res.d2[i].program_name == res.d1[i].program_name) {
            res.d1[i].deployed_amount_data = res.d2[i].program_deployed_amount

          }
        }
      }
    })

  }


  // openModal() {
  //   if (this.invoices.length > 0) {
  //     this.display = 'block';
  //   } else {
  //     this.push.showNotification("Please Select The Invoice",)
  //   }
  // }

  onCloseHandled() {

    this.display = 'none';

  }
  getTotal(event, index2, value) {
    if (event == true) {

      this.max1 = parseInt(this.uniq_prgs[index2].claimed_max_limit);
      if (parseInt(value) < this.max1) {
        if (this.total_amt_inv[index2] != undefined) {
          this.total_amt_inv[index2] = parseInt(this.total_amt_inv[index2] + parseInt(value))
        } else {
          this.total_amt_inv[index2] = parseInt(value)
        }

        for (var i = 0; i < this.uniq_prgs[index2].invoices.length; i++) {
          if (this.uniq_prgs[index2].invoices[i].check === true) {
            this.invoices.push(this.uniq_prgs[index2].invoices[i].invoiceId.invoice_id);
          }

        }

      } else {
        this.total_amt_inv[index2] =0
        this.push.showNotification("You Have Exceed The Limit", "danger");
      }
    } else {
      if (this.total_amt_inv[index2] != undefined) {
        this.total_amt_inv[index2] = this.total_amt_inv[index2] - parseInt(value) > 0 ? this.total_amt_inv[index2] - parseInt(value) : 0
      }

      this.invoices.pop();

    }

    // for (var i = 0; i < this.total_amt_inv.length; i++) {
        this.uniq_prgs[index2].claimed = this.total_amt_inv[index2]
      if (this.uniq_prgs[index2].claimed != 0) {
        this.uniq_prgs[index2].submit = false
      } else {
        this.uniq_prgs[index2].submit = true
      }
    // }

    var flags = {};
    this.invoices = this.invoices.filter((x) => {
      // console.log(x)
      if (flags[x]) {
        return false;
      }
      flags[x] = true;
      return true;
    });


    this.invoice_list = { invoices: this.invoices };
  }

  showAlerts() {
    if (this.claimed < 50000) {
      this.showAlert();
    } else if (this.claimed > 50000) {
      this.hideAlert();
    }
  }

  get_amt(id) {
    for (var i in this.calculations) {
      if (i == id)
        for (var j in this.calculations[i]) {

          return this.calculations[i].out_amt == null ? 0 : this.calculations[i].out_amt;
        }
    }
  }

  get_now(fund_amt, trxn_fee) {
    var fund;
    var trxn;
    for (var i in this.calculations) {
      if (i == fund_amt)
        for (var j in this.calculations[i]) {
          fund = this.calculations[i].out_amt == null ? 0 : this.calculations[i].out_amt;
        }
    }
    for (var i in this.calculations) {
      if (i == trxn_fee)
        for (var j in this.calculations[i]) {
          trxn = this.calculations[i].out_amt == null ? 0 : this.calculations[i].out_amt;
        }
    }
    return (parseFloat(fund) - parseFloat(trxn))
  }

  get_later(rem, disc) {
    var remain;
    var discount;
    for (var i in this.calculations) {
      if (i == rem)
        for (var j in this.calculations[i]) {
          remain = this.calculations[i].out_amt;
        }
    }
    for (var i in this.calculations) {
      if (i == disc)
        for (var j in this.calculations[i]) {
          discount = this.calculations[i].out_amt;
        }
    }

    return (parseFloat(remain) - parseFloat(discount)) == null ? 0 : (parseFloat(remain) - parseFloat(discount))
  }

  get_days(date) {
    this.d = new Date();
    var date1 = new Date(date);
    if (Math.round((date1.getTime() - this.d.getTime()) / (1000 * 3600 * 24)) == -1) {
      return 0;
    } else {
      return Math.round((date1.getTime() - this.d.getTime()) / (1000 * 3600 * 24));
    }
  }

  showAlert() {
    this.isDisabled = false;

    // showSuccessToast();
  }
  hideAlert() {
    this.isDisabled = true;

    // showWarningToast();
  }

  showSuccess() {
    // console.log(this.password)
    this.display = 'none';
    this._auth
      .verify_trxn_pass(sessionStorage.getItem("user_id"), this.password)
      .toPromise()
      .then((res: any) => {

        if (res == "ok") {

          this._auth
            .submitInvoice(
              this.invoice_list,
              this.seller_id
            )
            .toPromise()
            .then((res: any) => {
           
              if (res) {
                this.push.showNotification(
                  "Successfully Submitted Invoices For Funding", "success"
                );

                // window.location.reload();
                /** Post Notification */
                // var data = {
                //   pid: "ADMIN",
                //   action: "Invoice Submitted",
                //   message:
                //     " Invoice Successfully Submitted By Seller " +
                //     `${this.seller_id}`,
                // };
                // this._auth
                //   .post_notifications(JSON.stringify(data))
                //   .toPromise()
                //   .then((res) => {
                //     if (res) {

                //       this.showAlerts();
                //     }
                //   });
              }
            });
        } else {
          this.push.showNotification(
            "Incorrect Password", "danger"
          );
        }
      });
  }

  showError() {
    var msg = "Incorrect Password";
    this.push.showNotification(msg, "danger");
  }

  ngAfterViewInit() {
    $('#datatable').DataTable({
      "pagingType": "full_numbers",
      "lengthMenu": [
        [10, 25, 50, -1],
        [10, 25, 50, "All"]
      ],
      responsive: true,
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search records",
      }

    });
  }
}
