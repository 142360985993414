import { Component, OnInit, ViewChild } from "@angular/core";
import Chart from "chart.js";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth.service";
import * as jwt_decode from "jwt-decode";
import { HttpClient } from "@angular/common/http";

import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { SharedService } from "app/services/shared.service";

declare var $: any;

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})
export class AdminComponent implements OnInit {
  public canvas: any;
  public ctx;
  public chartColor;
  public chartEmail;
  public chartHours;
  btn_color: any;
  names = [];
  postTitle = "";
  postBody = "";
  total_prgrams: any;
  total_fund: number;
  avg_fund: number;
  inactive_programs: number;
  party_name: Object;
  data = [];
  party_name_len: any;
  b_name: any;
  list = [];
  data1 = [];
  filterData: any[];
  loading: boolean;
  selected: any;
  value: any;
  count1: number;
  active: any;
  count2: any;
  inactive: any;
  dashboard_deployed_amt = [];
  deployed_amt: any;
  deployed_total: any;
  count3: any;
  programs: [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = [
    "program_name",
    "buyer_name",
    "start_date",
    "end_date",
    "program_currency",
    "program_value",
    "deployed_amt",
    "program_status"
  ];
  dataSource: MatTableDataSource<any>;


  displayedColumns1: string[] = [
    "program_name",
    "buyer",
    "start_date",
    "end_date",
    "program_currency",
    "program_value"
  ];
  dataSource1: MatTableDataSource<any>;
  list_programs: any;
  list_prospects: any;
  program_categories = [];
  category_value: any;
  
 


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  constructor(private _auth: AuthService, private router: Router, private http: HttpClient, private sharedService:SharedService) {
    window.location.hash = "redirect";
    window.location.hash = "redirect"; //again because google chrome don't insert first hash into history
    window.onhashchange = function() {
      window.location.hash = "redirect";
    };
  }
  ngOnInit() {
    this.Plist();
    this._auth.party(3).subscribe((res: any) => {
      if (res) this.party_name = res;
      this.party_name_len = res.length;
      for (var i = 0; i < res.length; i++) {
        this.data1[i] = { party_id: this.party_name[i].party_id, b_name: this.party_name[i].display_name };
      }
    });

    this._auth
      .dashboard_deployed_amt()
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.dashboard_deployed_amt = res["deployed_amounts"];

          for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
            if (this.dashboard_deployed_amt.length > 0) {
              this.deployed_total += parseInt(this.dashboard_deployed_amt[i].program_deployed_amount);
            } else {
              this.deployed_total = 0;
            }
          }
        }
      });
  }

 

  view_program(id) {
    this.router.navigate(["/viewProgram", id]);
  }

  view_commodity(data,id) {
    this.list_prospects= this.list_prospects.filter(x => x.pid === id)
 
    this.sharedService.commodity_program_view(data);
    this.sharedService.express_interest(this.list_prospects);

    this.router.navigate(["/viewProgram", id]);
  }
  Plist() {
    this.loading = true;
    this.count1 = 0;
    this.count2 = 0;
    this.deployed_total = 0;
    this._auth
      .getProgramList()
      .toPromise()
      .then((res: any) => {
        if (res) {
          this.loading = false;
          this.data = res;

          this.dataSource = new MatTableDataSource(this.data);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

          this.programs = res;

          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].status == "IN-PROGRESS") {
              this.btn_color = "btn btn-warning btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "ACTIVE") {
              this.btn_color = "btn btn-success btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "CLOSED") {
              this.btn_color = "btn btn-danger btn-sm";
              this.data[i][8] = this.btn_color;
            } else if (this.data[i].status == "PENDING") {
              this.btn_color = "btn btn-secondary btn-sm";
              this.data[i][8] = this.btn_color;
            } else {
              this.btn_color = "btn btn-info btn-sm";
              this.data[i][8] = this.btn_color;
            }
          }

          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].status == "ACTIVE") {
              this.count1++;
            } else if (this.data[i].status == "CLOSED") {
              this.count3++;
            } else {
              this.count2++;
            }
          }

          this.active = this.count1;
          this.inactive = this.count2;

          var fund_amt = 0;
          var count = 0;
          for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].status === "ACTIVE") {
              if (this.data[i].program_currency === "USD") {
                fund_amt = fund_amt + parseFloat(this.data[i].program_value) * 1.44;
              } else {
                fund_amt = fund_amt + parseFloat(this.data[i].program_value);
              }
            }

            if (this.data[i].program_status === false) {
              count++;
            }
          }
          this.inactive_programs = count;
          this.total_fund = fund_amt;
          if (this.data.length > 0) {
            let avg = (this.total_fund / this.count1).toFixed(2);
            this.avg_fund = avg === "NaN" ? 0 : parseFloat(avg);
          } else {
            this.avg_fund = 0;
          }
        }
      });
  }

   commodity_trade_finance() {
     this._auth.commodity_trade_programs().subscribe(async (res: any) => {
       console.log(res.prospects)
       this.list_programs = res.programs
       this.list_prospects = res.prospects

      this.dataSource1 = new MatTableDataSource(await this.list_programs);

      this.dataSource1.sort = this.sort;
      this.dataSource1.paginator = this.paginator;
    })
  }
  addProgram() {
  
      this.router.navigate(["/programs/add-program",this.category_value.name.toLowerCase(), this.category_value.id]);
  }

  program_category() {
    this.category_value = '';
    this._auth.program_categories().toPromise().then((res: any) => {
        if(res.length > 0){
          this.program_categories =  res
          $('#myModal').appendTo("body").modal('show');
        }
      
    })
   
   
  
  }

  get_Deployed_amount(id) {
    for (var i = 0; i < this.dashboard_deployed_amt.length; i++) {
      if (this.dashboard_deployed_amt[i].program_id == id) {
        return this.dashboard_deployed_amt[i].program_deployed_amount;
      }
    }
    return 0;
  }
}
