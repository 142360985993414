import { Component, OnInit } from '@angular/core';
import { PushService } from 'app/services/push.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-view-invoices',
  templateUrl: './view-invoices.component.html',
  styleUrls: ['./view-invoices.component.css']
})
export class ViewInvoicesComponent implements OnInit {
  SearchForm: any;

  constructor(  private _auth: AuthService,
    private fb: FormBuilder,
    private _router: Router,
    private push: PushService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.SearchForm = this.fb.group({
    role:['']
         
    });

    
  }

}
