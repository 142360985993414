import { Injectable, Inject } from "@angular/core";
import { HttpInterceptor, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, of } from "rxjs";
import { PushService } from "./push.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class InterceptorService implements HttpInterceptor {
  push: PushService;
  constructor(@Inject(PushService) push: PushService, private _router: Router) {
    this.push = push;
  }

  intercept(
    req: import("@angular/common/http").HttpRequest<any>,
    next: import("@angular/common/http").HttpHandler
  ): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
    const header = new HttpHeaders({
      "content-type": "application/json",
      "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
      "Access-Control-Allow-Origin": "*"
    });

    const clone = req.clone({ headers: header });
    return next.handle(clone).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.push.showNotification("Unauthorized Access", "danger");
            sessionStorage.clear();
            this._router.navigate(["/login"]);
          }
          if (err.status === 403) {
            this.push.showNotification("Your Account Is Temporarily Suspended", "danger");
            sessionStorage.clear();
            this._router.navigate(["/login"]);
          }
        }
        return throwError(err);
      })
    );
  }
}
