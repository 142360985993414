import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  returnUrl: any;
  constructor(private router : Router,
    private route: ActivatedRoute,) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean  {
      if(sessionStorage.getItem('login') != null) {
        return true
      }else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false
      }


    
  }
  
}
