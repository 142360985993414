import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "app/services/shared.service";
import { environment } from "environments/environment";
import { EnvService } from "app/services/env.service";
declare var $: any;

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  role: string;
  // icon: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/admin",
    title: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-chart-bar-32",
    role: "1"
  },
  {
    path: "/viewAs",
    title: "View As",
    type: "link",
    icontype: "nc-icon nc-zoom-split",
    role: "1"
  },

  {
    path: "/invoice-settlement",
    title: "Invoice Settlement",
    type: "link",
    icontype: "nc-icon nc-paper",
    role: "1"
  },

  {
    path: "/invoices/:id",
    title: "Invoices",
    type: "link",
    icontype: "nc-icon nc-paper",
    role: "5"
  },
  {
    path: "/dashSeller/:id",
    title: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-chart-bar-32",
    role: "5"
  },
  {
    path: "/profileSeller/:id",
    title: "Profile",
    type: "link",
    icontype: "nc-icon nc-badge",
    role: "5"
  },

  {
    path: "/programs",
    title: "Manage Programs",
    type: "sub",
    collapse: "programs",
    icontype: "nc-icon nc-layout-11",
    role: "1",
    children: [
      { path: "add-program", title: "Add Program", ab: "AP" },
      { path: "meta-definition", title: "Meta Definition", ab: "MD" },
      { path: "search", title: "Advanced Search", ab: "S" },

      // { path: 'view-invoices', title: 'View Invoices', ab:'VI'},
    ]
  },
  {
    path: "/sellers",
    title: "Sellers",
    type: "sub",
    collapse: "sellers",
    icontype: "nc-icon nc-delivery-fast",
    role: "1",
    children: [
      { path: "seller-list", title: "Seller List", ab: "SL" },
      { path: "seller-invite", title: "Seller Invite", ab: "SI" },
      { path: "seller-import", title: "Import Sellers", ab: "IS" }
    ]
  },
  {
    path: "/buyers",
    title: "Buyers",
    type: "sub",
    collapse: "buyers",
    icontype: "nc-icon nc-briefcase-24",
    role: "1",
    children: [{ path: "buyer-list", title: "Buyer List", ab: "SL" }, { path: "buyer-invite", title: "Invite Buyer", ab: "IB" }]
  },

  {
    path: "/funders",
    title: "Funders",
    type: "sub",
    collapse: "Funders",
    icontype: "nc-icon nc-briefcase-24",
    role: "1",
    children: [
      { path: "funder-list", title: "Funder List", ab: "FL" },
      { path: "funder-invite", title: "Invite Funder", ab: "IF" }
    ]
  },
  {
    path: "/logs",
    title: "Logs",
    type: "link",
    icontype: "nc-icon nc-badge",
    role: "1"
  },
  {
    path: "/seller",
    title: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-chart-bar-32",
    role: "2"
  },
  {
    path: "/invoice",
    title: "Invoices",
    type: "link",
    icontype: "nc-icon nc-paper",
    role: "2"
  },
  {
    path: "#",
    title: "Download",
    type: "link",
    icontype: "nc-icon nc-basket",
    role: "2"
  },
  {
    path: "/profile",
    title: "Profile",
    type: "link",
    icontype: "nc-icon nc-badge",
    role: "2"
  },
  {
    path: "/buyer",
    title: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-chart-bar-32",
    role: "3"
  },
  {
    path: "/program",
    title: "Programs",
    type: "link",
    icontype: "nc-icon nc-layout-11",
    role: "3"
  },
  {
    path: "/buyer-Profile",
    title: "Profile",
    type: "link",
    icontype: "nc-icon nc-badge",
    role: "3"
  },
  {
    path: "/funder",
    title: "Dashboard",
    type: "link",
    icontype: "nc-icon nc-chart-bar-32",
    role: "4"
  },
  {
    path: "/funder-Profile",
    title: "Profile",
    type: "link",
    icontype: "nc-icon nc-badge",
    role: "4"
  }
];

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html"
})
export class SidebarComponent {
  public menuItems: any[];
  id: string;
  user_name: any;
  img: string;
  userrole: number;
  role_name: string;
  inperson: string;
  imperson_company: string;
  imperson_role: string;
  seller_id: string;
  impersonate_seller_id: string;
  status: string;
  display: boolean;
  logo: any;
  upload_url: string;
  page_name: string;
  applogo: string;
  program_categories: any;
  category_value: any;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private env_serv: EnvService
  ) {
    this.upload_url = environment.File_upload;
    if (env_serv.Page_Name === 'Refinitiv' ) {
      let angElement = <HTMLElement>document.querySelector('#appIcon')
       this.page_name = 'Refinitiv';
    
      this.applogo = "./assets/img/ref_favicon_io/apple-touch-icon.png"


     } else {
      
       this.page_name = 'Seabridge TFX';
       this.applogo = "./assets/img/apple-touch-icon.png"
      

     }
  }

  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.logo = sessionStorage.getItem("logo");

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    // sessionStorage.getItem('id') = sessionStorage.getItem('id')

    this.inperson = sessionStorage.getItem("inperson");
    this.impersonate_seller_id = sessionStorage.getItem("impersonate_seller_id");

    this.imperson_company = sessionStorage.getItem("imperson_user");
    this.imperson_role = sessionStorage.getItem("impersonate_role");
    this.status = sessionStorage.getItem("status");

    if (this.imperson_role == "5") {
      this.id = this.imperson_role;
    }

    if (sessionStorage.getItem("id") == "1") {
      this.user_name = "Administrator";
      this.img = "./assets/img/A.png";
    } else if (sessionStorage.getItem("id") == "2" && sessionStorage.getItem("status") === "APPROVED") {
      this.user_name = "Seller";
      this.img = "./assets/img/S.png";
    } else if (sessionStorage.getItem("id") == "3" && (sessionStorage.getItem("status") === "APPROVED" || sessionStorage.getItem("status") !== "APPROVED")) {
      this.user_name = "Buyer";

      this.img = (this.logo === ""||this.logo === "null") ? "./assets/img/B.png" : this.upload_url + "/" + this.logo;
    } else if (
      sessionStorage.getItem("id") == "3" &&
      (sessionStorage.getItem("status") === "INVITED" ||
        sessionStorage.getItem("status") === "TERMS" ||
        sessionStorage.getItem("status") === "RECEIVED" ||
        sessionStorage.getItem("status") === "KYC" ||
        sessionStorage.getItem("status") === "CREDIT-CHECK")
    ) {
      this.user_name = "Buyer";
      this.img = this.logo === "null" ? "./assets/img/B.png" : this.upload_url + "/" + this.logo;
      this.display = true;
    } else if (sessionStorage.getItem("id") == "4" && sessionStorage.getItem("status") === "APPROVED") {
      this.user_name = "Funder";
      this.img = this.logo === "null" ? "./assets/img/f.png" : this.upload_url + "/" + this.logo;
    } else if (
      sessionStorage.getItem("id") == "4" &&
      (sessionStorage.getItem("status") === "INVITED" ||
        sessionStorage.getItem("status") === "TERMS" ||
        sessionStorage.getItem("status") === "RECEIVED" ||
        sessionStorage.getItem("status") === "KYC")
    ) {
      this.user_name = "Funder";
      this.img = this.logo === "null" ? "./assets/img/f.png" : this.upload_url + "/" + this.logo;
      this.display = true;
    } else {
      if (sessionStorage.getItem("token") === null) {
        this.user_name = "User";
        this.img = "./assets/img/default-avatar.png";
        this.display = true;
      } else {
        this.user_name = "Seller";
        this.img = "./assets/img/S.png";
        this.display = true;
      }
    }
  }

  getRole(role_id) {
    for (var i = 0; i < ROUTES.length; i++) {
      if (sessionStorage.getItem("impersonate_role") === "5") {
        if (role_id == sessionStorage.getItem("impersonate_role")) {
          return true;
        }
      } else {
        if (role_id === sessionStorage.getItem("id")) {
          return true;
        }
      }
    }
  }

  get inPerson(): boolean {
    return this.inperson == "true";
  }

  getImpersonRole() {
    if (this.imperson_role === "5") {
      return "Seller";
    }
  }

  display_logout() {
    if (sessionStorage.getItem("token") != null) {
      return true;
    } else {
      return false;
    }
  }
  back_admin() {
    sessionStorage.setItem("admin", "true");
    sessionStorage.removeItem("inperson");
    sessionStorage.removeItem("impersonate_seller_id");
    sessionStorage.removeItem("imperson_user");
    sessionStorage.removeItem("impersonate_role");
    window.location.href = "/admin";
    // this.router.navigate(["/admin"])
    window.open("/admin", "_self");
  }
  addProgram() {
    this.router.navigate(["/programs/add-program",this.category_value.name.toLowerCase(), this.category_value.id]);
}

program_category() {
  this._auth.program_categories().subscribe(async(res: any) => {
    if(res.length !== 0){

      this.program_categories = await res
      $('#myModal1').appendTo("body").modal('show');


    }
  })
 

}
}
