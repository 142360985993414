import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "million"
})
export class MillionPipe implements PipeTransform {
  transform(value: string): any {
    return parseFloat(value) / 1000000;
  }
}
