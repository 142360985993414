import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceunderscore"
})
export class ReplaceunderscorePipe implements PipeTransform {
  transform(value: string): unknown {
    return value ? value.replace(/_/g, " ") : value;
  }
}
